import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Responsive, { WidthProvider } from "react-grid-layout";
import axios from 'axios';
import OutsideClickHandler from 'react-outside-click-handler';
import { Task_sidebar } from './task_sidebar';
import {Task_searchlocation} from './task_searchlocation';
import {Task_title_menu} from './task_title_menu';
import {Task_name_menu} from './task_name_menu';
import {Task_posted} from './task_posted';
import {Task_new_task} from './Task_new_task';
import {Task_bigcalendar} from './Task_bigcalendar';
import uuid from 'react-uuid';
import GridLayout from 'react-grid-layout';
// import Tour from 'reactour';
import Joyride from 'react-joyride';
import steps from '../category_selector/Tour_task.json';

const ResponsiveReactGridLayout = WidthProvider(Responsive);


export class Taskmanager extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    accounts: [],
    currenttype: {},
    calendarview: true,
    tag_prospect: [],
    temp_selected: false,
    tag_business: [],
    layout_header: [],
    layout_body: [],
    layout_footer: [],
    cols:[],
    current_array: [],
    numbercols: 0,
    onedimension: true,
    numberofpages: [],
    numbercurrentpage: 0,
    searchlocation: false,
    temp_type: [],
    temp_markerlatlng: [],
    temp_searchterm: [],
    temp_name: '',
    temp_tags: [],
    updater: false,
    sidebarshow: true,
    newvalueadded: false,
    height: 6,
    num:0,
    coordinates: null,
    labels_list: {},
    tagselected: null,
    statearchived: false,
    searchval: "",
    all_locations: [],
    tagnames: [],
    layout: [],
    layoutbackup: [],
    showTour: false,
    useraccount: {},
    labels:[],
    title: "My Tasks"
  }
  this.tasktitle = this.tasktitle.bind(this);
  this.indexadd = this.indexadd.bind(this);
  this.generatelayout = this.generatelayout.bind(this);
  this.searchlocation = this.searchlocation.bind(this);
  this.getstarted = this.getstarted.bind(this);
  this.newvaluesetfalse = this.newvaluesetfalse.bind(this);
  this.tagnames = this.tagnames.bind(this);
  this.hidesidebar = this.hidesidebar.bind(this);
  this.tasknextpage = this.tasknextpage.bind(this);
  this.editlocation = this.editlocation.bind(this);
  this.archivelocation = this.archivelocation.bind(this);
  this.deletelocation = this.deletelocation.bind(this);
  this.taskmoreinformation = this.taskmoreinformation.bind(this)
  this.selectedlabels = this.selectedlabels.bind(this);
  this.onLayoutChange = this.onLayoutChange.bind(this);
  this.deletethispost = this.deletethispost.bind(this);
  this.duplicatecurrentpost = this.duplicatecurrentpost.bind(this);
  this.duplicateanotherlocation = this.duplicateanotherlocation.bind(this);
  this.taskcustom = this.taskcustom.bind(this);
  this.calendarshow = this.calendarshow.bind(this);
  this.searchval = this.searchval.bind(this);
  this.send = this.send.bind(this);
  }
  async send(){
    function urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
    
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
    
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }
    var publicVapidKey= "BEdZw3kp8ADHRdJnIKU7zT0ktQrWzxPWqfHuWmPS9k_cLTuQeQbjpPusODD62B1gXEueXgJWtgl_bSuWTYCR86o";
    var registerCheck = await navigator.serviceWorker.getRegistrations();
    // console.log(registerCheck)
    if(registerCheck && navigator.userAgent === this.state.notification) return;
    const register = await navigator.serviceWorker.register('sw.js');
    const subscription = await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
    });
    console.log(subscription)
    // if(subscription.endpoint !== this.state.notification) return;
    var token = localStorage.getItem("auth-token");
    await axios.post("/tasksapi/subscribe", { subscription,useragent: navigator.userAgent},
      {headers: {"auth-token": token} 
    });
  }
  pushnotifications = () => { 
    if ("serviceWorker" in navigator) { this.send().catch(err => console.error(err));}
  }
  
  componentWillMount(){
    var height = (window.innerWidth < 1000)?8:6;
    this.setState({height})
    this.getstarted();
    
  }
  async getstarted(){
    var token = localStorage.getItem("auth-token");
    var response = null;
    var rep = null;
    
    try{
      
      response =  await axios.post('/map/getstarted', {}, {headers:{ "auth-token": token}})
      rep = await axios.post('/accountsub/accountvalues', {}, {headers:{ "auth-token": token}})
      /** IF ACCOUNT TYPE IS TRUE THEN PARENT ELSE CHILD */
      var account_type = await rep.data.account_type;
      var useraccount = rep.data.customertemp;
      var notification = (rep.data.customertemp.useragent)? rep.data.customertemp.useragent: null;
      var Tasktour = rep.data.Tasktour == null || rep.data.Tasktour ? true : false;

      rep =  rep.data.children;
      
      }catch(response){
        console.log(response)
       if(response.response.status === 401) window.location.replace("/account");
       
       if(response.response.status === 400) 
       {
         localStorage.removeItem("auth-token")
         window.location.replace("/login");
        }
      }
    /* SETTING UP THE SCREEN SIZE*/ 
      
      // var numbercols = Math.floor( window.innerWidth / 400)
      
      var responsedata = await response.data;
      let responsedata_co = responsedata.coordinates;
      let repdata = await rep.data;
      this.setState({ coordinates: responsedata_co,account_type,  accounts: rep, useraccount: useraccount, notification , showTour: !Tasktour}, () => {this.pushnotifications() })
  }
  taskvalue(task, array_c, archived, searchfilter){
    var cols = [];
    var allloc = this.state.all_locations;
    let allaccounts = allloc.map((loc) => loc.name)
    task = task.filter((all) => allaccounts.includes(all.idval));
    
    if(searchfilter){
      let searchval = this.state.searchval.toLowerCase()
      task = task.filter((e) => {  
        
        let title = (e.title)? e.title.toLowerCase(): "";
        let description = (e.description)? e.description.toLowerCase(): "";
        console.log(title, description, 'e value inside taskvalue loop')
        if(searchval !=="" && (!title.includes(searchval) && !description.includes(searchval))) return false;
        return true
      })
    }
    let data = task.map((e) => {
      let x= 0;
      for(let v in array_c){ if(array_c[v] === e.idval){x = v; break}}
      let acc = (e.selectedaccounts)? e.selectedaccounts: [];
      let priority = (e.priority)? e.priority: [];
      let status = (e.status)? e.status: [];
      let labels = (e.labels)? e.labels: [];
      let title = (e.title)? e.title: "";
      let description = (e.description)? e.description: "";
      var tasknotification = e.tasknotification? e.tasknotification:  {Tasknote: false, selectedaccounts: []};
      let reqidval = e.idval;
      return {data: { title: title, description: description,tasknotification, selectedlabels: labels, status: status, duedate: e.duedate, dateformat:e.dateformat, priority: priority, selectedaccounts: acc, reqidval},i: e.id, x: parseInt(x), y: e.y, w: 1, h: this.state.height, class:"task-box", minH:6, name:e.idval, clicker:false, type:"Body", idval: e.idval}
    })
    
    var layout_header = array_c.map((e, index) => { 
      if(!archived)
      cols.push({type: "Footer", i:"footer"+ e, x: index, y: 1, w: 1, h: 1, static: true, isDraggable: false, class:"task-addmore", name:"+", clicker:true})
      
      return {type: "Header",archived, i: e, x: index, y: 0, w: 1, h: 1, static: true, class:"task-secondary-title", name:e, clicker: false, idval:e} 
    });
    var layout = layout_header.concat(cols);
    layout = layout.concat(data)
    return layout;
  }
  /** Type: My task or location clicked, e: Title, addbutton: for Mytasks don't have add */
  async taskcustom(type, e, searchfilter){
    var token = localStorage.getItem("auth-token");
    let layout = null;
    if(type.type === "tasks"){
      let getall = await axios.post('/tasksapi/getall', {}, {headers:{ "auth-token": token}})
      let datall = await getall.data;
       layout = await this.taskvalue(datall.task, [e], true, searchfilter);
       var label = {};
       if(datall.lab) label = (Object.keys(datall.lab).length > 0 )? datall.lab: {};
    }else{
      
    let get = await axios.post('/tasksapi/get', { idval: e}, {headers:{ "auth-token": token}})
    let data = await get.data;
    let task = data.task
    layout = await this.taskvalue(task, [e], false, searchfilter); 
    var label = {};
    if(data.lab) label = (Object.keys(data.lab).length > 0 )? data.lab: {};   
    console.log(data.lab, 'data label is here')
    }
    let searchval = (!searchfilter)?"": this.state.searchval;
    this.setState({currenttype: type, numberofpages: [0], title:e, layout,layoutbackup:layout, numbercols:1, onedimension:true,  labels_list: label, calendarview: true, searchval})
  }
  /*  incoming type, title, values, tag (selected for tag values), pagechange (tasknextpage function), searchfilter searchval()*/ 
  async tasktitle(type, e, array, tag, archived, pagechange, searchfilter){
    console.log(type, e, array, tag, archived,pagechange, searchfilter )
    
    var numbercols = Math.floor( window.innerWidth / 475)
    numbercols = (numbercols > array.length)? array.length: numbercols;
    let onedimension = (window.innerWidth > 900 && numbercols === 1);
    var numberofpages=[];
    if(!pagechange){
    var numpages =  Math.ceil(array.length/numbercols);
    
    for(let ii = 0; ii < numpages; ii++){
      numberofpages.push(ii)
    }
    }
    var array_c = array.slice(0, numbercols)
    
    /** ARRAY WILL BE SENT TO GET THE NEWEST VALUES FROM HERE */
    var token = localStorage.getItem("auth-token");
    let get = await axios.post('/tasksapi/get', { idval: array_c}, {headers:{ "auth-token": token}})
    let data = await get.data;
    /** Label list is getting return from tasks/get */
    var label = {};
    if(data.lab) label = (Object.keys(data.lab).length > 0 )? data.lab: {};
    let task = data.task
    let layout = await this.taskvalue(task, array_c, archived, searchfilter);
    let searchval = (!searchfilter)?"": this.state.searchval;
    if(pagechange){
      this.setState({numbercols,onedimension, currenttype: type, title:e, layout, layoutbackup:layout, tagselected: tag, statearchived: archived, array, labels_list: label, searchval})
    }else{
    this.setState({numbercurrentpage: 0, numbercols,onedimension, currenttype: type, numberofpages, title:e, layout, layoutbackup:layout, current_array: array, array, tagselected: tag, statearchived: archived, labels_list: label, calendarview: true, searchval})
    }
  }
  searchval(e){
    this.setState({searchval: e, })
    var type = (this.state.currenttype.type == null)? {type: "tasks"}: this.state.currenttype;
    switch (type.type) {
      case "tasks":
          this.taskcustom({type: "tasks"}, "My Tasks", true)
          break;
      case "place":
          this.taskcustom(this.state.currenttype, this.state.currenttype.value, true)
          break;
      default:
          this.tasktitle(this.state.currenttype, this.state.title, this.state.array, this.state.tagselected, this.state.statearchived, true, true)

    }
  }
  tasknextpage(num){
    
    var cols =[];
    var numbercols = Math.floor( window.innerWidth / 400)
    numbercols = (numbercols > this.state.current_array.length)? this.state.current_array.length: numbercols;
    let len = numbercols;
    let onedimension = (window.innerWidth > 900 && numbercols === 1);
    if(num >= this.state.numberofpages.length || num <0)
      return false
    let max = (num * len) + len
    
    var array = this.state.current_array.slice(len * num, max)
    var layout_header = array.map((e, index) => { 
      cols.push({ type: "Footer", i:"footer"+ e, x: index, y: 1, w: 1, h: 1, static: true, isDraggable: false, class:"task-addmore", name:"+", clicker:true})
      
      return {type: "Header", i: e, x: index, y: 0, w: 1, h: 1, static: true, class:"task-secondary-title", name:e, clicker: false, idval:e, tag: array} 
    });
    var layout = layout_header.concat(cols);
    this.setState({numbercurrentpage: num, layout: layout, onedimension,  numbercols}, () => {
      this.tasktitle(this.state.currenttype, this.state.title, array, this.state.tagselected, this.state.statearchived, true )
    })
  }
  /*either add by id or index value */
  async indexadd(index, id){
    var val = null;
    if(index === null){
      val = this.state.current_array.filter((e, ind) => {
        if(e === id)
          { index = ind;
            return true
          }
      })[0]
    }else{
      val = this.state.current_array[index];
    }
    let layout = this.state.layout;
    let idtemp = layout.filter((e) => (e.type === "Header" && e.x === index));
    let idval = idtemp[0].i
    let uuval = uuid();
    var token = localStorage.getItem("auth-token");
    try{
    await axios.post('/tasksapi/create', {y: 2, id: uuval, idval, useraccount: this.state.useraccount }, {headers:{ "auth-token": token}})
    }catch(response){
      if(response.response.status === 401) alert(response.response.data);
       
      if(response.response.status === 400) 
      {
        localStorage.removeItem("auth-token")
        window.location.replace("/login");
       }
    }
    
    layout.push({data:{selectedaccounts: [this.state.useraccount], description: "", status: [], priority:[]}, i: uuval, x: index, y: 1, w: 1, h: 6, class:"task-box", minH:6, name:val, clicker:false, type:"Body", idval: idval})
    this.setState({layout, num: this.state.num+1, layoutbackup:layout})
  }
  onLayoutChange(values){
    var layout = this.state.layout;
    
    layout.map((e) => {
      
      if(e.type !== "Body") return e
      let value = values.filter((val) => val.i === e.i)[0].y;
      
      e.y = value;
      var x = values.filter((ee) => (ee.i === e.i))[0].x;
      let idval = values.filter((ee) => (ee.y === 0 && ee.x === x))[0].i;
      
      e.idval= idval;
    })
    
    this.setState({layout, layoutbackup:layout})
    
  }  
  editlocation(name){
    var loc = this.state.all_locations.filter((e) => e.name === name);
    var type = (loc[0].type === "prospect")? [{value:"prospect", label:"Prospecting Business"}]:[{value:"business", label:"Current Business"}];
    var tags = loc[0].tags //.map((e) => {return{value:e, label:e}})
    var name = loc[0].name
    
    this.setState({searchlocation: true, updater: true, temp_type: type, temp_tags: tags, temp_name: name, temp_searchterm: loc[0].address, temp_markerlatlng:loc[0].coordinates })
  }
  generatelayout(){
    var allloc = this.state.all_locations;
    let allaccounts = allloc.map((loc) => {return { value: loc.name, label: loc.name }})
  return this.state.layout.map((e) => {
    
    if(e.type ==="Header")  
    return <div key={e.i} className={e.class} data-grid={e} ><div className="tasks-value-name">{e.name}</div>{(this.state.title !== "My Tasks")?<Task_name_menu account_type={this.state.account_type} title={this.state.title} deletelocation={this.deletelocation} statearchived={this.state.statearchived} indexadd={this.indexadd} archivelocation={this.archivelocation} editlocation={this.editlocation} idval={e.idval} id={e.i}/>:null}</div>
    if(e.type ==="Footer") 
    return <div key={e.i}  data-grid={e} onClick={() => {if(e.clicker)this.indexadd(e.x)}}><div className={e.class}>{e.name}</div></div>
    if(e.type ==="Body"){
      e.data.description = (!e.data.description)? "": e.data.description;
      
      var idvalue = null;
      if(e.idval === "My Tasks"){
        idvalue = e.data.reqidval
      }else{
        idvalue = e.idval
      }
      /**  IF TASK DOESN"T FIND an account it is going to pass */
      var tags=  allloc.filter((all) => all.name === idvalue)[0];
      
      if(tags) 
       tags = tags.tags;
       else{
        return;
       }
        
      var acc = this.state.accounts.filter((acc) => { 
        for(let j in acc.tags) 
        { 
          if(tags.includes(acc.tags[j].value))
          return true} return false})
      
      return <div key={e.i} className={e.class} data-grid={e}  >
        <Task_posted  newval={this.state.searchval} newdata={e.newdata} useraccount={this.state.useraccount} archived={e.data.archived} title={this.state.title} selectedlabels={this.selectedlabels} labels_list={this.state.labels_list} duplicateanotherlocation={this.duplicateanotherlocation} allaccounts={allaccounts} duplicatecurrentpost={this.duplicatecurrentpost} deletethispost={this.deletethispost} Accounts={acc} y={e.y} postedchangedlabel={this.postedchangedlabel} labels={this.state.labels} taskmoreinformation={this.taskmoreinformation} id={e.i} idval={e.idval} data={e.data}/>
      </div>
    }
  })
  }

  searchlocation(rerun){
    /*rerun is for when the sidebar has to rerun for the new tags*/
    this.setState({searchlocation: !this.state.searchlocation, top_title: false, newvalueadded: rerun, updater: false,temp_tags: [], temp_type:[], temp_name: '', temp_searchterm:'', temp_markerlatlng:[] })
  }
  tagnames(e, all_locations){
    this.setState({tagnames: e, all_locations})
  }
  newvaluesetfalse(){
    this.setState({newvalueadded: false})
  }
  hidesidebar(){
    this.setState({sidebarshow: !this.state.sidebarshow})
  }
  currentusers(){
    if(this.state.tagselected)
    window.location.href = "/accountsub?tag="+this.state.tagselected
    else
    window.location.href = "/accountsub"
  }
  async archivelocation(i, bool){
    var token = localStorage.getItem("auth-token");
    var response = null;
    try{
      
      response =  await axios.post('/gettheurl/prospectarchive', {name: i, archived: bool}, {headers:{ "auth-token": token}})
      let res = await response.data;
      let layout = this.state.layout.filter((e) => { if(!e.idval)return false; return e.idval !== i })
      console.log(layout, 'layout value')
      this.setState({newvalueadded: true, layout})
      }catch(response){
        console.log(response, "I error")
        if(!response.response) return;
       if(response.response.status === 401) window.location.replace("/account");
       
       if(response.response.status === 400) 
       {
         localStorage.removeItem("auth-token")
         window.location.replace("/login");
        }
      }
  }
  async deletethispost(id, select){
    let layout = this.state.layout.filter((e) => e.i !== id)
    let labels_list = this.state.labels_list;
    select.map((val) => {
      var labellist = labels_list[val];
      if(!labellist) return;
      labellist.count -= 1;
      if(labels_list[val].count === 0) delete labels_list[val];
    })
    
    var token = localStorage.getItem("auth-token");
    await axios.post('/tasksapi/labels', {labels: labels_list}, {headers:{ "auth-token": token}})

    this.setState({layout,layoutbackup:layout, labels_list})
  }
  async duplicatecurrentpost(id, uuval, data, anotherlocation){
    uuval = (uuval)? uuval:uuid();
    var token = localStorage.getItem("auth-token");
    let layout = this.state.layout
    let lay = layout.filter((e) => e.i === id)[0]
    data = (data)? data:lay.data;
    this.selectedlabels(lay.name, null, true)
    data["idval"]= lay.idval;
    data["id"] = uuval
    data["y"] = 1;
    console.log(lay,'lay duplicate value ')
    if(!data) return false
    let final_lay = {class: "task-box", clicker: false, data: data, y: 1, i: uuval, idval: lay.idval, minH: 6, h: 6, name: lay.name, w:1, type: "Body", x: lay.x}
    layout.push(final_lay)
    if(!anotherlocation)
    var response =  await axios.post('/tasksapi/createvalue', data, {headers:{ "auth-token": token}})
    this.setState({layout})
  }
  async duplicateanotherlocation(val, lay){
    let uuval = uuid()
    var token = localStorage.getItem("auth-token");
    val = val[0].value;
    let layout = this.state.layout.filter((e) => e.i === val)
    console.log(layout)
    if(layout.length > 0) this.duplicatecurrentpost(layout[0].i, uuval, lay, true)
 
      lay.y = 1;
      lay.idval = val;
      lay["id"] = uuval
 
    let response =  await axios.post('/tasksapi/createvalue', lay, {headers:{ "auth-token": token}})
  }
  async deletelocation(i){
    var token = localStorage.getItem("auth-token");
    var value = this.state.all_locations.filter((e) => i === e.name);
    console.log(value[0].coordinates)
    try{
      let coordinates = {lat: value[0].coordinates[0], lng: value[0].coordinates[1] }
      let response =  await axios.post('/gettheurl/prospectdelete', {coordinates, name:value[0].name}, {headers:{ "auth-token": token}})
      let res = await response.data;
      let layout = this.state.layout.filter((e) => e.i !== i )
      this.setState({newvalueadded: true, layout, layoutbackup:layout,})
      }catch(response){
        console.log(response)
       if(response.response.status === 401) window.location.replace("/account");
       
       if(response.response.status === 400) 
       {
         localStorage.removeItem("auth-token")
         window.location.replace("/login");
        }
      }
    
  }
  taskmoreinformation(id, bool, data){
    let layout = this.state.layout
    if(data){
      layout.filter((e) => e.i === data.id)[0].newdata = data;
      
    }
    this.setState({post_temp_id:id, temp_selected:bool, layout, layoutbackup:layout,})
  }
  /** val= name, color = new value color, newvalue added */
  async selectedlabels(val, color, newval, colorchange){
    let labels_list = this.state.labels_list
    /** IF color has changed */
    console.log(val, color, newval, colorchange, 'label config')
    if(colorchange){
      /** IF COLOR DOESN"T EXIST FOR SOME REASON */
      if(!labels_list[val]){
        labels_list[val] = {count:1, color}
      }else{
      labels_list[val].color = color;
      }
    }else{
      /** Else it will either either delete or create/add */
      if(newval){
        if(labels_list[val])
        {
          labels_list[val].count +=1;
        }else{
          labels_list[val] = {count:1, color}
          console.log(labels_list, 'new label added')
        }
      }else{
        if(!labels_list[val]) return;
        labels_list[val].count -= 1;
        if(labels_list[val].count === 0) delete labels_list[val];
      }
    }

    var token = localStorage.getItem("auth-token");
    await axios.post('/tasksapi/labels', {labels: labels_list}, {headers:{ "auth-token": token}})
    this.setState({labels_list})
  }
  calendarshow(id){
    this.setState({post_temp_id:id, temp_selected: true})
  }

  closeTour = async () =>{
    let token = localStorage.getItem("auth-token");
    await axios.post('/gettheurl/tourend', {Task: true}, {headers:{ "auth-token": token}})
    
    this.setState({showTour:false})
  }
  render() {
    var width = (window.innerWidth > 700 && this.state.numbercols === 1)? 650: this.state.numbercols * 390;
      return (
        <div>        
          <Joyride
          run={this.state.showTour}
          showProgress
          continuous
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
          callback={(data) => {
            // You can handle events (like tour end) here if needed
            if (data.status === 'finished' || data.status === 'skipped') {
              this.closeTour();
            }
          }}
        />  
        {/* <Tour steps={steps} isOpen={this.state.showTour} onRequestClose={this.closeTour} /> */}
          {(this.state.temp_selected)?<Task_new_task useraccount={this.state.useraccount} accounts={this.state.accounts} all_locations={this.state.all_locations} id={this.state.post_temp_id} taskmoreinformation={this.taskmoreinformation} selectedlabels={this.selectedlabels} labels_list={this.state.labels_list} duplicateanotherlocation={this.duplicateanotherlocation}   deletethispost={this.deletethispost} />:null}
          <div className="task-total"></div>
          {(this.state.sidebarshow)?<Task_sidebar title={this.state.title} taskcustom={this.taskcustom} currenttype={this.state.currenttype} hidesidebar={this.hidesidebar} newvaluesetfalse={this.newvaluesetfalse} newvalueadded={this.state.newvalueadded} tasktitle={this.tasktitle} tagnames={this.tagnames}/>:<div className="sidebar-open sidebar-task" onClick={()=> this.hidesidebar()}><div className="fa fa-angle-right"></div></div>}
          {this.state.title !=="My Tasks"?
          <div className={(this.state.sidebarshow)?"task-center-filter task-center-filter-left":"task-center-filter"}>
      <button  id="tasks-back" onClick={() => this.tasknextpage(this.state.numbercurrentpage -1)}><div className="fa fa-angle-left"></div></button> {this.state.numberofpages.map((e, index) => {return <div key={e} onClick={() => this.tasknextpage(index)} className={(this.state.numbercurrentpage === index)?"tasks-pages-value":"tasks-pages"}></div>})} <button  id="tasks-next" onClick={() => this.tasknextpage(this.state.numbercurrentpage +1)}><div className="fa fa-angle-right"></div></button>
        </div>:<div className={(this.state.sidebarshow)?"task-center-filter task-center-filter-left":"task-center-filter"}><div className={(this.state.calendarview)?"task-tabvalue task-tabluecolor":"task-tabvalue"} onClick={()=> this.setState({calendarview: true})}>Tasks</div><div className={(this.state.calendarview)?"task-tabvalue":"task-tabvalue task-tabluecolor"} onClick={()=> this.setState({calendarview: false})}>Calendar</div></div>}
        <div className = {this.state.sidebarshow ? "task-sidebarshow":"task-sidebarhide"}>
        <div className="task-top-title">{this.state.title}
        <Task_title_menu account_type={this.state.account_type} searchvaluetotal={this.state.searchval} searchval={this.searchval} searchlocation={this.searchlocation}/>
        </div>
        {(this.state.calendarview)?
        <div>
        {/* <div className={(this.state.onedimension)?"task-gridlayout task-gridlayout-one": "task-gridlayout"}> */}
        <div className={(window.innerWidth > 700 && this.state.numbercols === 1)?"task-gridlayout task-gridlayout-one":"task-gridlayout"}>
        {(this.state.numbercols && this.state.useraccount._id)?<GridLayout 
        draggableHandle=".task-draggable"
        cols={this.state.numbercols}  rowHeight={40} width={width}     layout={this.state.layout}  
        onLayoutChange={this.onLayoutChange}
        verticalCompact={true}
        >
        {this.generatelayout()}
        </GridLayout>:null
        }
        </div>
        </div>
        :<Task_bigcalendar style={{ height: 500,width: '95%' }} calendarshow={this.calendarshow}/>
        }</div>
        {(this.state.searchlocation)?
        <Task_searchlocation   markerlatlng={this.state.temp_markerlatlng} searchterm={this.state.temp_searchterm} updater={this.state.updater}  tagnames={this.state.tagnames} coordinates={this.state.coordinates} searchlocation= {this.searchlocation} type={this.state.temp_type} name={this.state.temp_name} prospecttags={this.state.temp_tags} />:null
        }
        </div>
      )
    }
  }