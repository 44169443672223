import { Bar, Line } from 'react-chartjs-2';
import {Boxdataset} from './boxdataset';
import React, { useMemo, useCallback } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ageGroups = [
  "Under 5", "5 - 9", "10 - 14", "15 - 17", "18 - 19", "20 - 24", "25 - 29", "30 - 34", "35 - 44",
  "45 - 54", "55 - 64", "65 - 74", "75 - 84", "Over 84"
];

const reduceSum = (values) => {
    return values.reduce((acc, item) => acc + item)
}

const calculateSum = (population) => {
    const female = population?.female ? reduceSum(population.female) : 0;
    const male = population?.male ? reduceSum(population.male) : 0;
    return female + male
}

const populationBarChart = (population) => {
    const arrayLength = 14;
    const current_population = new Array(arrayLength).fill(0);
    const prev_population = new Array(arrayLength).fill(0);
    population.population_c.male.forEach((e, index) => current_population[index] = e)
    population.population_c.female.forEach((e, index) => current_population[index] += e)

    population.population_2014.male.forEach((e, index) => prev_population[index] = e)
    population.population_2014.female.forEach((e, index) => prev_population[index] += e)

    const barChart = {
        labels: ageGroups,
        datasets: [
            {
                label: 'Previous Population',
                data: prev_population,
                backgroundColor: 'rgba(255, 153, 51,0.5)',
                borderColor: 'rgba(255, 153, 51,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255, 153, 51,0.7)',
                hoverBorderColor: 'rgba(255, 153, 51,1)',
            },
            {
                label: 'Current Population',
                data: current_population,
                backgroundColor: 'rgba(0,38,62,0.5)',
                borderColor: 'rgba(0,38,62,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(0,38,62,0.7)',
                hoverBorderColor: 'rgba(0,38,62,1)',
            },
        ],
    };
    return {current_population, prev_population, barChart}
}

const incomeSwedenValue = (incomeSweden) => {

    if(!incomeSweden) return;

    console.log('incomeSweden: ', incomeSweden, incomeSweden['current']['male'])
    
    const years = ['2014', 'current'];
    const genders = ['male', 'female'];
    const ageGroups = ['16-19', '20-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60-64', '65-69', '70-74', '75-79', '80-84', '85+']
    const ageGroupsTotal = {'16-19': 0, '20-24': 0, '25-29': 0, '30-34': 0, '35-39': 0, '40-44': 0, '45-49': 0, '50-54': 0, '55-59': 0, '60-64': 0, '65-69': 0, '70-74': 0, '75-79': 0, '80-84': 0, '85+': 0}
    const ageGroupsTotalPrev = {'16-19': 0, '20-24': 0, '25-29': 0, '30-34': 0, '35-39': 0, '40-44': 0, '45-49': 0, '50-54': 0, '55-59': 0, '60-64': 0, '65-69': 0, '70-74': 0, '75-79': 0, '80-84': 0, '85+': 0}

    let avgIncome = { '2014': 0, 'current': 0 };
    
    years.forEach((year) => {
        if (!incomeSweden || !incomeSweden[year]) {
            console.error(`Data for year ${year} is missing`);
            return;
        }
    
        genders.forEach((gender) => {
            if (!incomeSweden[year][gender]) {
                console.error(`Data for ${gender} in year ${year} is missing`);
                return;
            }
    
            ageGroups.forEach((ag) => {
                if (incomeSweden[year][gender][ag] === undefined) {
                    console.warn(`Data for age group ${ag} in year ${year} for ${gender} is missing`);
                    return;
                }
                if(ag !== '16-19'){
                    avgIncome[year] += incomeSweden[year][gender][ag];
                }
                if(year === 'current'){
                    ageGroupsTotal[ag] += Math.round(incomeSweden['current'][gender][ag] / 2);
                }else{
                    ageGroupsTotalPrev[ag] += Math.round(incomeSweden['2014'][gender][ag] / 2);

                }
            });
        });
    });
    const current = Math.round(avgIncome['current'] / 28);
    const past = Math.round(avgIncome['2014'] / 28)
    const diff = Math.round(((current - past) / current) * 10000) / 10000

    const ageGroupsBarChart = {
        labels: ageGroups,
        datasets: [
          {
            label: 'Prev. Avg. Income',
            data: Object.values(ageGroupsTotalPrev),
            backgroundColor: 'rgba(255, 153, 51,0.5)',
            borderColor: 'rgba(255, 153, 51,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 153, 51,0.7)',
            hoverBorderColor: 'rgba(255, 153, 51,1)',
          },
          {
            label: 'Current Avg. Income',
            data: Object.values(ageGroupsTotal),
            backgroundColor: 'rgba(0,38,62,0.5)',
            borderColor: 'rgba(0,38,62,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(0,38,62,0.7)',
            hoverBorderColor: 'rgba(0,38,62,1)',
          },
        ],
      };


    const ageGroupsGender = {
        labels: ageGroups,
        datasets: [
          {
            label: 'Female',
            data: Object.values(incomeSweden['current']['female']),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 99, 132, 0.7)',
            hoverBorderColor: 'rgba(255, 99, 132, 1)',
          },
          {
            label: 'Male',
            data: Object.values(incomeSweden['current']['male']),
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(54, 162, 235, 0.7)',
            hoverBorderColor: 'rgba(54, 162, 235, 1)',
          },
        ],
      };
    return {past, current, diff, ageGroupsBarChart, ageGroupsGender}
    
}
const getColor = (index, alpha = 1) => {
    const colors = [
      `rgba(255, 99, 132, ${alpha})`,   // red
      `rgba(54, 162, 235, ${alpha})`,   // blue
      `rgba(255, 206, 86, ${alpha})`,   // yellow
      `rgba(75, 192, 192, ${alpha})`,   // green
      `rgba(153, 102, 255, ${alpha})`,  // purple
    ];
    return colors[index % colors.length];
}

const incomeNorwayValue = (incomeNorway) => {
    const years = Object.keys(incomeNorway).filter(year => incomeNorway[year].overall !== 0);
    if(years.length === 0) return;
    const current = incomeNorway['2022'].overall
    const past = incomeNorway['2014'].overall
    const diff = Math.round((current - past) / current * 10000) / 10000
    const householdTypes = Object.keys(incomeNorway[years[0]]);
    const options = {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Household Income Trends Norway',
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Income',
            },
          },
        },
    };
    return {
        diff,
        current,
        past,
        options, 
        lineChart: {
            labels: years,
            datasets: householdTypes.map((type, index) => ({
            label: type.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
            data: years.map(year => incomeNorway[year][type]),
            borderColor: getColor(index),
            backgroundColor: getColor(index, 0.5),
            tension: 0.01,
            })),
        }
    }
}

const incomeDenmarkValue = (incomeDenmark) => {
    const years = Object.keys(incomeDenmark).filter(year => incomeDenmark[year].total_income !== 0);
    const current = incomeDenmark['2022'].total_income
    const past = incomeDenmark['2014'].total_income
    const diff = Math.round((current - past) / current * 10000) / 10000
    const householdTypes = ['total_income', 'dispose_income'] //Object.keys(incomeDenmark[years[0]]);
    const options = {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Household Income Trends Denmark',
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Income',
            },
          },
        },
    };
    return {
        diff,
        current,
        past,
        options, 
        lineChart: {
            labels: years,
            datasets: householdTypes.map((type, index) => ({
            label: type.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
            data: years.map(year => incomeDenmark[year][type]),
            borderColor: getColor(index),
            backgroundColor: getColor(index, 0.5),
            tension: 0.01,
            })),
        }
    }
}

const DemographicInternational = ({ municipality_data }) => {
    const {
        population,
        incomeSweden,
        incomeDenmark,
        incomeNorway
      } = municipality_data;
    
      const memoizedData = useMemo(() => {
        const total_population = population?.population_c ? calculateSum(population.population_c) : 0;
        const total_population_past = population?.population_2014 ? calculateSum(population.population_2014) : 0;
        const totalPopulationDiff = Math.round(((total_population - total_population_past) / total_population) * 10000) / 10000;
    
        const totalIncomeSweden = incomeSweden && incomeSwedenValue(incomeSweden);
        const totalIncomeDenmark = incomeDenmark && incomeDenmarkValue(incomeDenmark);
        const totalIncomeNorway = incomeNorway && incomeNorwayValue(incomeNorway);
    
        const populationBar = populationBarChart(population);
    
        return {
          total_population,
          total_population_past,
          totalPopulationDiff,
          totalIncomeSweden,
          totalIncomeDenmark,
          totalIncomeNorway,
          populationBar
        };
      }, [population, incomeSweden, incomeDenmark, incomeNorway]);
    
      const chartData = useMemo(() => ({
        labels: ageGroups,
        datasets: [
          {
            label: 'Male',
            data: population.population_c.male,
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(54, 162, 235, 0.7)',
            hoverBorderColor: 'rgba(54, 162, 235, 1)',
          },
          {
            label: 'Female',
            data: population.population_c.female,
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 99, 132, 0.7)',
            hoverBorderColor: 'rgba(255, 99, 132, 1)',
          },
        ],
      }), [population]);
    
      const optionsBar = useCallback((x_axis, y_axis, plugins_text, stacked = true) => ({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked,
            title: { display: true, text: x_axis },
          },
          y: {
            stacked,
            title: { display: true, text: y_axis },
          },
        },
        plugins: {
          title: { display: true, text: plugins_text },
          legend: { position: 'top' },
        },
      }), []);
//   console.log('totalIncomeSweden: ', totalIncomeSweden)
    return (
    <div className="container-marginleft">
        <div className="boxdata-container">
            <div className="boxdata-header-one boxdata-header">Target Demographic</div>
            <Boxdataset classnameval="box-container2" width={true} margin={154} unsetarrangement={()=>{}} setarrangement={()=>{}} zoom={()=>{}} arrangement={5} arrangestar={true} top_text='Population' mid_text='Target Population'  total_diff={memoizedData.totalPopulationDiff} total_current={memoizedData.total_population} total_past={memoizedData.total_population_past} type={{}} description="Estimate total target population in the region. Previous Period: 2014" filters={{}}/>
        </div>
        {memoizedData.totalIncomeSweden && <div className="boxdata-container">
            <div className="boxdata-header-one boxdata-header">Avg. Income Sweden</div>
            <Boxdataset classnameval="box-container2" width={true} currency={'SEK'} round margin={154} unsetarrangement={()=>{}} setarrangement={()=>{}} zoom={()=>{}} arrangement={6} arrangestar={true} top_text='Average Income' mid_text='Avg. Current Income'  total_diff={memoizedData.totalIncomeSweden.diff} total_current={memoizedData.totalIncomeSweden.current} total_past={memoizedData.totalIncomeSweden.past} stringval={false} type={true} description="Estimate Income of Individuals Sweden. Previous Period: 2014" filters={{}}/>
        </div>}
        {memoizedData.totalIncomeNorway && <div className="boxdata-container">
            <div className="boxdata-header-one boxdata-header">Avg. Income Norway</div>
            <Boxdataset classnameval="box-container2" width={true} currency={'NOK'} round margin={154} unsetarrangement={()=>{}} setarrangement={()=>{}} zoom={()=>{}} arrangement={6} arrangestar={true} top_text='Average Income' mid_text='Avg. Current Income'  total_diff={memoizedData.totalIncomeNorway.diff} total_current={memoizedData.totalIncomeNorway.current} total_past={memoizedData.totalIncomeNorway.past} stringval={false} type={true} description="Estimate Income of Individuals Norway. Previous Period: 2014" filters={{}}/>
        </div>}
        {memoizedData.totalIncomeDenmark && <div className="boxdata-container">
            <div className="boxdata-header-one boxdata-header">Avg. Income Denmark</div>
            <Boxdataset classnameval="box-container2" width={true} currency={'DKK'} round margin={154} unsetarrangement={()=>{}} setarrangement={()=>{}} zoom={()=>{}} arrangement={6} arrangestar={true} top_text='Average Income' mid_text='Avg. Current Income'  total_diff={memoizedData.totalIncomeDenmark.diff} total_current={memoizedData.totalIncomeDenmark.current} total_past={memoizedData.totalIncomeDenmark.past} stringval={false} type={true} description="Estimate Income of Individuals Denmark. Previous Period: 2014" filters={{}}/>
        </div>}
        <div className='pt-20'>
            <div className="barchart-horizontal">
                <div className="h-300px">
                    <Bar data={chartData} options={optionsBar('Age Groups', 'Population', 'Gender Distribution by Age Group')} />
                </div>
            </div>
        </div>
        <div className='pt-20'>
            <div className="barchart-horizontal">
                <div className="h-300px">
                    <Bar data={memoizedData.populationBar.barChart} options={optionsBar('Age Groups', 'Population', 'Gender Distribution by Age Group', false)} />
                </div>
            </div>
        </div>

        {municipality_data.incomeSweden &&
            <div>
                <div className='pt-20'>
                    <div className="barchart-horizontal">
                        <div className="h-300px">
                            <Bar data={memoizedData.totalIncomeSweden.ageGroupsBarChart} options={optionsBar('Age Groups', 'Income', 'Income By Age Groups Sweden', false)} />
                        </div>
                    </div>
                </div>
                <div className='pt-20'>
                    <div className="barchart-horizontal">
                        <div className="h-300px">
                            <Bar data={memoizedData.totalIncomeSweden.ageGroupsGender} options={optionsBar('Age Groups', 'Income', 'Income By Gender Sweden', false)} />
                        </div>
                    </div>
                </div>
            </div>
        }
        {memoizedData.totalIncomeNorway && 
            <div className='pt-20'>
                <div className="barchart-horizontal">
                    <div className="h-300px">
                        <Line data={memoizedData.totalIncomeNorway.lineChart} options={memoizedData.totalIncomeNorway.options} />
                    </div>
                </div>
            </div>
        }
        {memoizedData.totalIncomeDenmark && 
            <div className='pt-20'>
                <div className="barchart-horizontal">
                    <div className="h-300px">
                        <Line data={memoizedData.totalIncomeDenmark.lineChart} options={memoizedData.totalIncomeDenmark.options} />
                    </div>
                </div>
            </div>
        }
    </div>
  );
};

export default DemographicInternational;