import React, {Component} from 'react';
import axios from 'axios';
import maptize from '../Images/maptize_logo_white-01.svg';
import ReCAPTCHA from "react-google-recaptcha";
import Select from 'react-dropdown-select';
import industry_avgs from '../Competitor/industry_avgs.json'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box'; // Import Box
import {PopupRegisterSelection} from './registrationPopUpSelection';
const occupation = industry_avgs.map((av) => {return {value: av.type, label: av.type.replace("_", " ")}}).sort((a, b) => a.value.localeCompare(b.value));



export class PopupRegister extends React.Component{

    constructor(props) {
        super(props);

        this.state= {
            search_competitor: "",
            searched: [],
            name: "",
            handlepass: "",
            handleregemail: "",
            handlemail:"",
            handlepassword: ""

        }
        this.categoryselector = this.categoryselector.bind(this);
        this.valuesetter = this.valuesetter.bind(this);
        this.occupation_setter = this.occupation_setter.bind(this);
    }
    valuesetter(values){
        this.setState({eachcat: values})
    }
    occupation_setter(value){

        this.setState({occupation: value})
    }
    async categoryselector(){

        var token = localStorage.getItem('auth-token');
        var cat_f = [];
        var cat_all = await axios.post('/setup/cattotal');
        let cat_all_final = await cat_all.data;

           this.setState({ resp: cat_all_final})
    }

    componentWillMount(){
        this.categoryselector()
        const params = new URLSearchParams(window.location.search);
        const name = params.get('name');
        const email = params.get('email');

        if (name && email) {
            this.setState({
                name: decodeURIComponent(name),
                handleregemail: decodeURIComponent(email)
            });
        }
    }
    handlenameChange = (e) =>{
        this.setState({classes_name: 'has-val', name: e.target.value})
    }

    handlemail = (e) => this.setState({handlemail: e.target.value})
    handleregemail = (e) => this.setState({handleregemail: e.target.value})
    handlepass = (e) => this.setState({handlepass: e.target.value})
    handlepassword = (e) => this.setState({handlepassword: e.target.value})
    register = async () => {
        if(!this.state.token) return this.setState({error_value: "Please complete the Captcha properly"})
        if(this.state.name.trim().length < 1) return this.setState({error_value:"Please provide your full name"})
        if(this.state.handlepass.length < 8) return this.setState({error_value:"Password must be at least 8 Characters", passwordvalid: false})
        if(!this.state.handlepass.match(/(?=.*[0-9])/)) return this.setState({error_value:"Password must contain at least One Number", passwordvalid: false})
        try{
        let response = await axios.post('/signup/freetier', { token: this.state.token,   
            name: this.state.name.trim(), 
            password: this.state.handlepass, 
            email: this.state.handleregemail, 
            mapRegister: true
        })
        let res = await response.data;
        localStorage.setItem("auth-token",response.headers['auth-token'])
        this.props.changeUnregistered(false)
        }catch(e){
            this.setState({error_value: e.response.data })
        }
    }
    captcha =(e) => this.setState({token: e})
    
    render() {
        return (
        <Dialog open={true} sx={{
            '& .MuiDialog-paper': {
                margin: 0, 
            },
        }}>
                <DialogTitle>Sign Up</DialogTitle>
                <div  >
                <div>  
                    <div >                  
                    <div className="margin-x-10">
                    <input className="popup-register-name width-100"  onChange={this.handlenameChange} type="text" name="name" value={this.state.name} placeholder="Full Name" />
                    <input  className="popup-register-name  width-100"   onChange={this.handleregemail} type="text" name="name" value={this.state.handleregemail} placeholder="Business Email" />
                    <input className="popup-register-name  width-100" type="password" onChange={this.handlepass}  name="name" value={this.state.handlepass} placeholder="Password" />

                    <ReCAPTCHA sitekey="6LdY69UZAAAAAMVP_K9AVLSOOvk6Y6wNl75Q7lO-"     onChange={this.captcha}/>
                    <div className="popup-Registration-agreement">
                    By clicking "Register", you agree to the <a href="https://mapchise.com/terms-and-conditions/">Terms</a> and <a href="https://mapchise.com/privacy">Privacy Policy</a>
                    </div>
                    {(this.state.error_value != null)? <div className= "Competitor-register-error">{this.state.error_value}</div>:null}
                    </div>
                    </div>

                    </div>
                </div>
                <DialogActions>
                <Button className='color-orange' onClick={() => this.props.changeRegisterType(true)}>Login</Button>
                <Button className='color-dark-blue' onClick={this.register}>Sign Up</Button>
                </DialogActions>
        </Dialog>
    )}
}
