import React, {Component} from 'react';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import {Competitor_research_menu} from './Competitor_menu'
import {Competitor_register} from './Competitor_register'
import {Competitor_brand} from './Competitor_brand'
import {Competitor_sidebar} from './Competitor_sidebar'
import {Competitor_financials} from './Competitor_financials';
import Competitor_demographic from './Competitor_demographic';
import {Competitor_demographic_graphs} from './Competitor_demographic_graph'
import OutsideClickHandler from 'react-outside-click-handler';
import Competitor_demographic_local from './Competitor_demographic_local'
import {Competitor_social} from './Competitor_social' 
import DemographicInfo from '../DemographicInfo';
import maptize from '../Images/maptize_logo_white-01.svg';
import turf from '@turf/area'
import State_number from './State_numbers.json'
// import { urlencoded } from 'body-parser';

var map = null;
export class Competitor_search extends React.Component{
    constructor(props) {
        super(props);
        this.state= {
            search_competitor: "",
            searched: [],
            brandLocal: false,
            markers: []
        }
    }
    gettier = async () =>{
        let token = localStorage.getItem("auth-token");
        if(token === "" || !token ) return null;
        try{
            let response = await axios.post('/signup/freetier/type', {}, {headers:{ "auth-token": token}})
            let res = await response.data
            if(res.freetier == null) return;
            this.setState({freetier: res.freetier})
        }catch(e){
            console.log(e)
        }
    }
    componentDidMount(){
        this.gettier()
    }
    competitor_search = async (e) =>{
        let search_competitor = e.target.value 
        this.setState({search_competitor, search_result_show: true})
        var search_res = await axios.post('/gettheurl/competitorapi', {search: search_competitor}, );
        let search = await search_res.data;
        this.setState({searched: search})
        
    }
    search_num(num, Est_revenue, avg_revenue){
        if(num > 0) Est_revenue += (avg_revenue * num)
        return Est_revenue
    }
    regionselected(regionfilter){
        if(!regionfilter) return;

        var regionfilterzoom;
        if(regionfilter.type === "MultiPolygon"){ 
            function indexOfMax(arr) {
                if (arr.length === 0) {
                    return -1;
                } var max = arr[0]; var maxIndex = 0;
                for (var i = 1; i < arr.length; i++) {
                    if (arr[i] > max) {maxIndex = i;max = arr[i];}} return maxIndex;}

            console.log(regionfilter.coordinates)
            let regionsizes = regionfilter.coordinates.map((coor) => turf({type: "Polygon", coordinates: coor}))
            console.log(regionsizes, indexOfMax(regionsizes), "regionssize")
            regionfilterzoom = regionfilter.coordinates[indexOfMax(regionsizes)][0]
        }else{
            regionfilterzoom = regionfilter.coordinates[0];
        } 
        var bounds = regionfilterzoom.reduce(function (bounds, coord) {
            return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(regionfilterzoom[0], regionfilterzoom[0]));
        
        map.fitBounds(bounds, {
            padding: 20
        });
        console.log(regionfilter, 'regionfilter')
        map.addSource('region', {
            'type': 'geojson',
            'data': {
            'type': 'Feature',
            'geometry': regionfilter
            }})
        map.addLayer({
                'id': 'region',
                'type': 'fill',
                'source': "region",
                'layout': {},
                'paint': {
                'fill-color': '#396BF0',
                'fill-opacity': .1,
                }
        });

    }

    loadImageIntoMap = (map, imageName, imageUrl) => {
        return new Promise((resolve, reject) => {
            map.loadImage(imageUrl, (error, image) => {
                if (error) {
                    reject(error);
                } else {
                    map.addImage(imageName, image);
                    resolve();
                }
            });
        });
    }

    addPlaceLayer = (geojson, regionfilter, brandLogo={}) => {
        if (map.getSource('places')) {
            map.removeLayer('places');
            map.removeSource('places');
        }
    
        // console.log(geojson, regionfilter, 'geojson');
        
        this.regionselected(regionfilter);
    
        map.addSource('places', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': geojson
            }
        });

        // Building match expression for dynamically setting the icon image
        let matchExpression = ['match', ['get', 'brand']];
        let iconSizeMatchExpression = ['match', ['get', 'brand']];
        if (Object.keys(brandLogo).length === 0) {
            brandLogo['Dummy'] = 'default-icon';
        }

        Object.keys(brandLogo).forEach(brand => {
            matchExpression.push(brand, brand);
            iconSizeMatchExpression.push(brand, 0.5);
        });
        matchExpression.push('marker-11'); 
        iconSizeMatchExpression.push(1.5);

        map.addLayer({
            'id': 'places',
            'type': 'symbol',
            'source': 'places',
            'layout': {
                'icon-image': matchExpression,
                'icon-size': iconSizeMatchExpression,
                'icon-allow-overlap': true
            }
        });

        this.setupMapInteractions();

    }
    
    setupMapInteractions = () => {
        map.on('mouseenter', 'places', () => {
            map.getCanvas().style.cursor = 'pointer';
        });
        map.on('mouseleave', 'places', () => {
            map.getCanvas().style.cursor = '';
        });
        map.on('click', 'places', (e) => {
            var coordinates = e.features[0].geometry.coordinates.slice();
            e = e.features[0];
            new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(`<div class="Comptitor-popup"><b>${e.properties.title}</b></div>
                          <div class="Comptitor-popup">Est. Revenue: ${this.numberWithCommasPopUp(e.properties.rev, 1000)}</div>
                          <div class="Comptitor-popup">Est. Lease: ${this.numberWithCommasPopUp(e.properties.Lease * 15)}</div>
                          <div class="Comptitor-popup">Est. Location Value: ${this.numberWithCommasPopUp(e.properties.Location)}</div>
                          <div class="Comptitor-popup">Employee Cost: ${this.numberWithCommasPopUp(e.properties.Employee)}</div>`)
                .addTo(map);
        });
    }
    


    showposition = (geojson, regionfilter) => {

        var maploader = false;
        if(!map){
            console.log('map: ', map)
            mapboxgl.accessToken = 'pk.eyJ1Ijoic25pa2FlaW4iLCJhIjoiY2s1eWNpcWxrMDJvbzNtcDdyb3puNTVrMCJ9.t-VFWsbpRo--tRSLgLa9ng';
            map = new mapboxgl.Map({
                container: 'mapbox',
                style: 'mapbox://styles/mapbox/light-v10',
                center: [-95, 38.2],
                zoom: 3.5
            });
            map.scrollZoom.disable();
            map.addControl(new mapboxgl.NavigationControl());
            maploader = true;
        }
        console.log('regionfilter: ', map, regionfilter)
        if(geojson){
        (maploader)? map.on('load', () =>  this.addPlaceLayer(geojson, regionfilter)):  this.addPlaceLayer(geojson, regionfilter);
        
        if(this.state.markers.length > 0) this.state.markers.map((marker) => marker.remove())
        this.setState({places: true, markers: []})
        }else{
            if (map.getSource('places')){
                map.removeLayer('places')
                map.removeSource('places');
            }
            this.regionselected(regionfilter)
            this.state.markers.map((marker) => marker.remove())
            this.setState({markers: []})
        }

    }
    numberWithCommasPopUp(x, number) {
        if(!x) return "No Data"
        x = Math.round(x * number)
        return "$"+ x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    searchvalue = async (search, region) => {
        const temp_search = window.location.href.split('?')[0];
        window.history.pushState({}, null, `${temp_search}?search=${encodeURI(search)}`);
    
        const token = localStorage.getItem("auth-token");
        if (search === "") return null;
    
        document.body.style.overflow = "hidden";
        
        if (region && region.State) {
            const stateEntry = Object.entries(State_number).find(([_, value]) => value.ABR === region.State);
            if (stateEntry) region.State = stateEntry[0];
        }
    
        this.setState({
            searched: [], 
            search_result_show: false, 
            search_competitor: search, 
            loading: true
        });
    
        try {
            const response = await axios.post('/gettheurl/competitorsearch', {search, region}, {headers: {"auth-token": token}});
            const res = response.data;
    
            if (res.nullResult) {
                this.setState({error: 'No Results found'});
                return;
            }
    
            if (res.brandLocal) {
                return this.handleBrandLocalResponse(res);
            }
    
            if (res.freetier) {
                return this.handleFreeTierResponse(res);
            }
    
            return this.handleFullResponse(res);
        } catch (error) {
            this.handleSearchError(error);
        } finally {
            document.body.style.overflow = "visible";
        }
    }
    
    handleBrandLocalResponse = (res) =>  {
        const { business_data, brand, cat_all, brandLogo } = res;
        
        const aggregatedData = business_data.reduce((acc, data) => {
            if (data.data_circle) {
                acc.expense += data.data_circle.expenses2018.mortgage;
                acc.population += data.data_circle.total_age_sex.pop_2018_f;
                acc.income += data.data_circle.family_estincome.estincome_2018;
                acc.number++;
            }
            if(data.revenue){
                acc.revenue += parseInt(data.revenue) || 0; 
                acc.revenueNumber += 1;
            }
            if(data.lease){
                acc.lease += parseInt(data.lease) || 0; 
                acc.leaseNumber += 1;
            }
            if(data.lease){
                acc.operationExpense += parseInt(data.expense) || 0; 
                acc.operationExpenseNumber += 1;
            }
            
            return acc;
        }, { number: 0, revenueNumber: 0, operationExpenseNumber: 0, leaseNumber: 0, expense: 0, population: 0, income: 0, revenue: 0, lease: 0, operationExpense: 0 });
    
        const avgIncome = Math.round(aggregatedData.income / aggregatedData.number);
        const avgExpense = Math.round(aggregatedData.expense / aggregatedData.number);
        const operationExpenseNumber = aggregatedData.operationExpenseNumber || 1 ;
        const revenueNumber = aggregatedData.revenueNumber || 1;
        const leaseNumber = aggregatedData.leaseNumber  || 1;
        const avgExpenseOperation = Math.round(aggregatedData.operationExpense / operationExpenseNumber);
        const avgRevenue = Math.round(aggregatedData.revenue / revenueNumber);
        const avgLease = Math.round(aggregatedData.lease / leaseNumber);
        // console.log('aggregatedData.revenue: ', aggregatedData.revenue)
        const geojson = business_data.map(e => ({geometry: {type: 'Point', coordinates: e.coordinates}, properties: {title: e.name, rev: e.rev, brand: e.brand}}));
        this.showposition()
        if(this.state.markers.length > 0) this.state.markers.map((marker) => marker.remove())
        this.setState({places: true, markers: []})
        
        if(!this.state.imagesLoaded){
            // Collect all promises for image loading
            var logoPromises = Object.entries(brandLogo).map(([brand, logoUrl]) =>
                this.loadImageIntoMap(map, brand, logoUrl)
            );
            this.setState({imagesLoaded: true})
            // Wait for all logos to load before adding the source and layers
            Promise.all(logoPromises).then(() => {
                this.addPlaceLayer(geojson, res.geometry, brandLogo)
            });
        }else{
            this.addPlaceLayer(geojson, res.geometry, brandLogo)
        }


        this.setState({
            loading: false,
            brand,
            search: res.search,
            business_data,
            brandLocal: true,
            expense: avgExpense,
            income: avgIncome,
            population: aggregatedData.population,
            Est_revenue: Math.round(aggregatedData.revenue / 1000),
            bl_cost: aggregatedData.lease,
            Employee_cost_avg: avgExpenseOperation,
            Employee_cost: aggregatedData.operationExpense,
            avg_revenue: Math.round(avgRevenue / 1000),
            bl_cost_avg: avgLease,
            type: brand.type,
            upgradereq: false,
            social_data: brand.social_data,

        });
    }
    
    handleFreeTierResponse = (res) => {
        const { brand, num_revenue, Est_revenue, avg_revenue, population } = res;
        const num = parseInt(brand.final_locations) - num_revenue;
        const updatedEstRevenue = this.search_num(num, Est_revenue, avg_revenue);
    
        this.setState({
            loading: false,
            brand,
            search: res.search,
            num_population: res.num_population,
            Est_revenue: updatedEstRevenue,
            avg_revenue,
            population,
            freetier: true
        });
    }

    handleFullResponse = (res) => {
        var population = 0
        var expense = 0
        var income = 0
        var number = [0, 0, 0, 0, 0, 0]
        var Est_revenue = 0
        var Employee_cost = 0
        var bl_cost = 0
        var race = [0, 0, 0, 0, 0]
        var geojson = []
        var age = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        var degree = [0, 0, 0, 0, 0, 0, 0]
        var gender = [0, 0]
        var layer = (res.competitor.length < 500)
        /** LAYER IF  */
        if(layer) this.showposition(null, res.geometry)
        
        var markers = [] 
        res.competitor.map((e) =>{
            if(e.geometry){
                let coordinates = e.geometry.coordinates
                if(e.geometry.type === "Polygon"){
                    coordinates = coordinates[0][0]
                }
                if(e.geometry.type === "MultiPolygon"){
                    coordinates = coordinates[0][0][0]
                }
                if(layer){
                let marker = new mapboxgl.Marker().setLngLat(coordinates)
                .setPopup(new mapboxgl.Popup().setHTML(`<div class="Comptitor-popup">Est. Revenue: ${this.numberWithCommasPopUp(e.Est_revenue, 1000)}</div>
                <div class="Comptitor-popup">Est. Lease: ${this.numberWithCommasPopUp(e.bl[1] * 15)}</div>
                <div class="Comptitor-popup">Est. Location Value: ${this.numberWithCommasPopUp(e.bl[0])}</div>
                <div class="Comptitor-popup">Employee Cost: ${this.numberWithCommasPopUp(e.Employee_cost)}</div>`
                )) 
                .addTo(map)
                markers.push(marker)
                }else{
                geojson.push({ type: "Feature", geometry: {type: "Point", coordinates: coordinates},
                    properties:{ title: e.Name, rev: e.Est_revenue, Lease: e.bl[1], Location: e.bl[0],Employee: e.Employee_cost}})
                }
           }
            if(e.Est_revenue){
            Est_revenue += e.Est_revenue
            number[0] +=1
            }
            if(e.demo){
            if(e.demo.pop){ population += e.demo.pop; number[1] +=1 }
            if(e.demo.expense[0] > 0){expense += e.demo.expense[0]; number[4] += 1}
            if(e.demo.income > 0){income += e.demo.income; number[5] += 1}
            if(e.demo.degree){ e.demo.degree.map((deg, index) => { if(deg > 0) degree[index] += deg}) }
            if(e.demo.gender){ gender[0] += e.demo.gender.male; gender[1] += e.demo.gender.female; }
            if(e.demo.age){ e.demo.age.map((a, index) => age[index] += a) }
            if(e.demo.race){ race[4]+= e.demo.race["American Indian"]; race[3]+= e.demo.race["Asian"]; race[1]+= e.demo.race["Black/African American"]; race[2] += e.demo.race["Hispanic/Latino"]; race[0]+= e.demo.race["White"] }
            }
            if(e.bl && e.bl[1]){ bl_cost += Math.round(e.bl[1] * 15); number[2] += 1}
            if(e.Employee_cost){ Employee_cost += e.Employee_cost; number[3] += 1}
        
        })
        if(!layer) this.showposition(geojson, res.geometry)
        let gender_total = gender[0] + gender[1]
        var age_sum = age.reduce(function(a, b){return a + b;}, 0);
        age = age.map((a) => Math.round((a / age_sum) * 100))
        var race_sum = race.reduce(function(a, b){return a + b;}, 0);
        race = race.map((r) => Math.round((r / race_sum) * 100))
        gender = [gender[0]/ gender_total, gender[1]/ gender_total] 
        let degree_total = 0 
        degree.map((deg) => degree_total += deg)
        degree = degree.map((deg) => Math.round((deg/ degree_total) *100) /100)
        var num = parseInt(res.brand.final_locations) - number[0]
        var avg_revenue = Est_revenue / number[0]
        var Est_revenue = this.search_num(num, Est_revenue, avg_revenue)
        
        num = parseInt(res.brand.final_locations) - number[1]
        var avg_population = Math.round(population / number[1])
        var population = this.search_num(num, population, avg_population)


        var bl_cost_avg = bl_cost / number[2]
        num = parseInt(res.brand.final_locations) - number[2]
        var bl_cost = this.search_num(num, bl_cost, bl_cost_avg)

        var Employee_cost_avg = Employee_cost / number[3]
        num = parseInt(res.brand.final_locations) - number[3]
        var Employee_cost = this.search_num(num, Employee_cost, Employee_cost_avg)
        expense = Math.round(expense / number[4])

        income = Math.round(income/ number[5])
        this.setState({type:res.brand.type, markers, upgradereq: false, loading:false, race, age, brand: res.brand,search: res.search,gender,social_data: res.brand.social_data, income, Employee_cost_avg,expense, degree, Employee_cost,  bl_cost, bl_cost_avg, competitor: res.competitor, num_population: number[1], Est_revenue,avg_revenue, population})
    }
    
    handleSearchError = (error) => {
        console.log(error, 'response error');
        
        if (error.response) {
            const { status, data } = error.response;
            
            if (status === 401) {
                window.location.href = "/account";
            } else if (status === 400) {
                localStorage.removeItem("auth-token");
                this.setState({register: true, registertype: false});
            } else if (data.Requests) {
                this.setState({errornumber: data.Requests, upgradereq: true});
            } else if (data === "login") {
                this.setState({register: true, registertype: false});
            } else if (data === "register") {
                this.setState({register: true, registertype: true});
            }
        }
    }

    componentWillMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        search = params.get('search');
        let token = localStorage.getItem("auth-token");
        if(!token){
            this.setState({register: true, registertype: true})
        }else{
            if(search){
                this.searchvalue(search)
            }
        }
        if(search)
        this.setState({search_competitor: search})
    }
    numberWithCommas(x) {
        if(!x) return "No Data"
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    closeregisterer = (freetier) => {
        console.log(freetier)
        if(freetier == null){
            this.setState({register: false})

        }
        else{
            this.setState({freetier: freetier.freetier, register: false})
            if(this.state.search_competitor) this.searchvalue(this.state.search_competitor)

        }
    }
    login = () => this.setState({register: true, registertype: false})
    register = () => this.setState({register: true, registertype: true})
    changeregister = () => this.setState({registertype: !this.state.registertype})
    upgradepage = () => {
        if(this.state.freetier) return window.location.href = "https://mapchise.com/pricing/"
        window.location.href = "/account"
    }
    locationsearch = (location) =>{
        if (map.getSource('region')){
            map.removeLayer('region')
            map.removeSource('region');
        }
        map.flyTo({
            center: [-95, 38.2],
            zoom: 3.5
            });
        this.searchvalue(this.state.search_competitor, location)
    }
    render() {
        let searchvalue =this.state.search !== "" && this.state.search;
        return (
          <div id = "Competitor-research-main">
                {this.state.upgradereq? <div className="Competitor-register-total">
                 <div className="Competitor-register-main">
                 <div className="Competitor-upgrade-total">
                 <img src={maptize} className="Competitor-research-menu-logo2"/>
                 <div className="Competitor-search-header2">Upgrade to Continue</div>
                 <div className="Competitor-upgrade-value">You have reached your accounts search limit: {this.state.errornumber} </div>
                 <div className="Competitor-research-started2" onClick={this.upgradepage}>Upgrade</div>
                 </div>
                 </div>
             </div>
             :null}
            {(searchvalue)?<Competitor_sidebar locationsearch={this.locationsearch}/>:null}
              <Competitor_research_menu freetier={this.state.freetier} register={this.register} login={this.login} section_hide={true} />
              {this.state.register? <Competitor_register changeregister={this.changeregister} type={this.state.registertype} closeregisterer={this.closeregisterer}/>:null}
                <div className="Competitor_search-top">
                <div className={(searchvalue)?"Competitor_reasearch-total2": "Competitor_research-total2-none"}>
                <div className="Competitor_reasearch-total3">
                <input className="Competitor_research-input" placeholder="Enter a Retail/Restaurant Brand" value={this.state.search_competitor}  onChange={this.competitor_search}></input>
                <div className="Competitor-search-fields">
                <div className="Competitor-search-divider Competitor-search-divider2">|</div>
                <div className="Competitor-search-divider fa fa-search"></div>
                </div>
                {(this.state.search_result_show)?
                <OutsideClickHandler onOutsideClick={() => this.setState({search_result_show: false})} >
                <div className="Competitor-result-total">
                    {
                        this.state.searched.map((search) => {
                        return <div key={search._id} className="Competitor-result-each" onClick={() =>this.searchvalue(search.Name)}>
                            <div className="Competitor-result-eachpicture">
                                {(search.Image)?
                                <img src={search.Image} onError={(e)=>{e.target.onerror = null; e.target.src="https://i.ibb.co/8NYd9pw/white-background.png"}} className="Competitor-result-image"/>: <div  className="Competitor-result-image"></div>
                                }
                                </div>
                            <div className="Competitor-result-eachname">{search.Name}</div>
                            <div className="Competitor-result-count"><div className="Competitor-result-count-name">Est. Locations</div>
                            <div className="Competitor-result-count-value">{search.final_locations? this.numberWithCommas(search.final_locations): this.numberWithCommas(search.Total)}</div></div>
                        </div>
                        })
                    }
                </div></OutsideClickHandler>:null}
                </div>
                </div>
                </div>
                {(this.state.loading)?
                <div className="Competitor-loading">Loading <div className="Competitor-spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
                </div></div>
                 :null}
                 {/* {(searchvalue)?<div> */}
                <Competitor_brand search={this.state.search} brand={this.state.brand}/>
                <Competitor_financials business_data={this.state.business_data} Employee_cost={this.state.Employee_cost} Employee_cost_avg={this.state.Employee_cost_avg} bl_cost = {this.state.bl_cost} bl_cost_avg={this.state.bl_cost_avg} freetier={this.state.freetier} avg_revenue={this.state.avg_revenue} Est_revenue={this.state.Est_revenue}/>
                <Competitor_demographic freetier={this.state.freetier} income={this.state.income} expense={this.state.expense} population={this.state.population}/>
                {this.state.brandLocal ?
                    <Competitor_demographic_local exportInd state={{zoom: 0, arrangement:[0,1,2,3,4,5,6,7,8,9,10,11,12]}} business_data={this.state.business_data.map((e) => e.data_circle)} industry_hidemore={()=> {}} dem_showmore={()=> {}} unsetarrangement={()=> {}} setarrangement={()=> {}}/>
                :<div>
                    <Competitor_demographic_graphs type={this.state.type} freetier={this.state.freetier} age={this.state.age} race={this.state.race}  degree={this.state.degree} gender={this.state.gender}/>
                </div>
                }

                {/* <Competitor_social freetier={this.state.freetier} social_data={this.state.social_data}/> */}
                {/* </div>:null} */}
              </div>
    )}
}