import React from 'react';
import ReactDOM from 'react-dom';
import '../../Login.css';
import maptize from '../Images/maptize-black.png';
import axios from 'axios';
export class Login extends React.Component{
    constructor(props) {
      super(props);
      this.state= {
          password : '',
          email: '',
          classes_email: '',
          classes_pass: '',
          Errors: null,
          token: localStorage.getItem('auth-token')
      }
       
      
    }
    async validrun()  {
        try{
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let app = params.get('app');
            
            if(app != null){
                var register = await axios.post('/signin/emailconfirm', {
                  app,
                })    
            }
            
            if(this.state.token != null){
            
                var resp = await axios.post('/signin/auth', {
                "token": this.state.token,    
            })
            
            }
            
            window.location.href = resp.data;
        }
        catch(e){
            console.log(e, "failed to get back")
        }
    }

    componentDidMount(){
        this.validrun()
    }
    
   async validationnumber(e){
        e.preventDefault();
        // console.log(this.state.email);
        try{
            let response = await axios.post('/signin/auth', {
                
                "email": this.state.email.toLowerCase(),
                "password": this.state.password,
                "type" : 'verify'
            })
            
            localStorage.setItem("auth-token", response.headers['auth-token']);
            window.location.href = response.data;

            }
            catch(ee){
                
               if(ee.response.data.length > 0) this.setState({Errors: ee.response.data})
                
            }
        
        
    }
    handleEmailChange(e) {
        this.setState({email: e.target.value, classes_email: 'has-val'});
     }
    
     handlePasswordChange(e) {
        this.setState({password: e.target.value, classes_pass: 'has-val'});
     }
    //  toppos(){
    //      document.getElementById("usern").style.top = "-5px"
    //  }
    //  toppos2(){
    //     document.getElementById("passw").style.top = "-5px"
    // }
    render() {

        return (
        <div className="login-page" >
            {/* <img src={background_image} className="login-image"></img> */}
            <div className="login-image"></div>

            <div className="limiter">
		        <div className="container-login100">
                    
			        <div className="wrap-login100 p-t-85 p-b-20">
				    <form className="login100-form validate-form" onSubmit={this.validationnumber.bind(this)}>
					
					<span className="login100-form-avatar">
						<img src={maptize} alt="AVATAR"/>
					</span>
                    <div className="login-logo-login">LOGIN</div>
                    {(this.state.Errors != null)? <div className= "error-logger">{this.state.Errors}</div>:null}
					<div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "Enter email">
						<input className={"input100 "+ this.state.classes_email} type="text" name="username" value={this.state.email} onChange={this.handleEmailChange.bind(this)} ></input>
						<span className="focus-input100" data-placeholder="Username" id="usern"></span>
					</div>
					<div className="wrap-input100 validate-input m-b-50" data-validate="Enter password">
						<input className={"input100 " + this.state.classes_pass} type="password" name="password" value={this.state.password} onChange={this.handlePasswordChange.bind(this)} ></input>
						<span className="focus-input100" data-placeholder="Password" id="passw" ></span>
					</div>
                    <div className="login-forgotpassword">
							<span className="txt1">
								Forgot
							</span>
							<a href="/passwordreset" className="txt2">
								 Email / Password?
							</a></div>
                        <div className="container-login100-form-btn">
						<button className="login100-form-btn">
							LOGIN
						</button>
                        <div className="login-or">OR</div>
                        <a className="login100-form-btn login-register" href="https://maptize.com/pricing">
							SIGN UP
						</a>
					    </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
            )
        }

}