import React, {Component} from 'react';
import {Competitor_box} from './Competitor/Competitor_box'
import {Doughnut, Pie, Bar, Line} from 'react-chartjs-2';

export class Dashboard_sidebar extends React.Component{
    constructor(props) {
      super(props);
    this.state = {
        search: [],
        squareclicked: 'none',
        showresult: 'none',
        industryclicked: false,
        filter: null,
        fss: true,
        cluster: true,
        question: 'none',
        taskmanagement: true,
        percent_diff: null,
        color_percentdiff: null,
        arrangement: [],
        hidesidebar: false,
        founded: ["Analyze your current business locations. Create tasks for all other users. Set territory for prospecting and current locations.", "Customized to your industry and target market. Understand your target demographic by region down to block level."
        ,"Understand interest by region and demographic characteristics.", "Available cost per square footage for leasing/sales and average size of locations by region.", "House average cost to lease and buy including historic trends."],
        zoom: 4,
        number: null,

    }
    

    } 

    

    collapse(){
        document.getElementsByClassName('search-results-total')[0].style.display = 'none';
    }
    notcollapse(){
        document.getElementsByClassName('search-results-total')[0].style.display = 'block';
    }


    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    gender = () =>{
        return {
            labels: ["Male", "Female"],
            datasets: [
              {
                backgroundColor: [
                  'rgba(5, 5, 194,0.6)',
                  'rgba(194, 18, 5,0.6)',
                ],
                hoverBackgroundColor: [
                'rgba(5, 5, 194,1)',
                'rgba(194, 18, 5,1)',
                ],
                data: this.props.gender
              }
            ],

          }
        }

    age = () =>{
      let dataset = [{
        label: 'Population Age distribution',
        fill: false,
        lineTension: 0.5,
        background: '#ffffff',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: this.props.age
      }]
        return {
            labels: ["Under 5", "5 - 9", "10 - 14", "15 - 17", "18 - 19", "20 - 24", "25 - 29", "30 - 34", "35 - 44", "45 - 54", "55 - 64", "65 - 74", "75 - 84", "Over 84"],

            datasets: dataset
        }
    }
    race = () => {
      let dataset = [
        {
          label: 'Race Distribution',
          fill: false,
          lineTension: 0.5,
          borderColor: 'rgba(0,38,62,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(0,38,62,0.7)',
          backgroundColor: 'rgba(0,38,62,0.5)',
          hoverBorderColor: 'rgba(0,38,62,1)',
          data: this.props.race
        }]

        return { 
             labels: ["White", "Black/African American", "Hispanic/Latino", "Asian", "American Indian"],
             datasets: dataset
           }
          
    }
    education = () =>{
        return {
            labels: ["Less than 9th grade","No highschool diploma", "High school graduate", "Some college", "Associates degree", "Bachelors degree",  "Graduate or professional degree"],
            datasets: [
              {
                backgroundColor: [
                  'rgba(74, 119, 3,0.6)',
                  'rgba(3, 119, 48,0.6)',
                  'rgba(3, 74, 119, 0.6)',
                  'rgba(3, 16, 119, 0.6)',
                  'rgba(119, 3, 74,0.6)',
                  'rgba(194, 5, 120, 0.6)',
                  'rgba(106, 3, 119, 0.6)',
                ],
                hoverBackgroundColor: [
                'rgba(74, 119, 3,1)',
                'rgba(3, 119, 48,1)',
                'rgba(3, 74, 119, 1)',
                'rgba(3, 16, 119, 1)',
                'rgba(119, 3, 74,1)',
                'rgba(194, 5, 120, 1)',
                'rgba(106, 3, 119, 1)',
                ],
                data: this.props.degree
              }
            ],

          }
    }
    normal_est(value, dollar){
        if(!value) return "No Data"
        if(value < 1000000) return dollar +(Math.round(value/100)/10).toString() + "K"
        if(value < 1000000000) return dollar + Math.round(value/10000)/100 + "M"
        return Math.round(value/10000000)/100 + "B"
    }
    
    render() {
      
      return (
        <div id="sidebar-top">        
        <div>
          <div className={(!this.state.hidesidebar)?"sidebar-total sidebar-total-dashboard":"sidebar-total sidebar-total-hide"}>
      <div className={!this.state.hidesidebar?"sidebar-open": "sidebar-close"} onClick={() => { this.setState({hidesidebar: !this.state.hidesidebar})}}><div className={!this.state.hidesidebar? "fa fa-angle-right": "fa fa-angle-left" }></div>{/*<div className="sidebar-open-desc">Collapse sidebar</div>*/}</div>
      <div id="sidebar-total-desc" data-tut="reactour__data"><div className="topbar-total-each height60" onMouseEnter={()=> this.setState({number: 0})} onMouseLeave={()=> this.setState({number:null})}>Business Locations <div className="fa fa-lock dashboard-locked-top"></div></div><div className="topbar-total-each height60" onMouseEnter={()=> this.setState({number: 1})} onMouseLeave={()=> this.setState({number:null})}>Demographic Information <div className="fa fa-lock dashboard-locked-top"></div></div><div className="topbar-total-each height60" onMouseEnter={()=> this.setState({number: 2})} onMouseLeave={()=> this.setState({number:null})}>Psychographic Data <div className="fa fa-lock dashboard-locked-top"></div></div><div className="topbar-total-each height60" onMouseEnter={()=> this.setState({number: 3})} onMouseLeave={()=> this.setState({number:null})} >Commercial Real Estate <div className="fa fa-lock dashboard-locked-top"></div></div><div className="topbar-total-each height60"onMouseEnter={()=> this.setState({number: 4})} onMouseLeave={()=> this.setState({number:null})} >Housing Real Estate <div className="fa fa-lock dashboard-locked-top"></div></div></div>
      {(this.state.number !== null)?<div className="Competitor-info">{this.state.founded[this.state.number]}</div>:null}
          <div id="sidebar-scroll"  data-tut='reactour__sidebar'>
          <div className="dashboard-sidebarimage-total">
            <div className="dashboard-sidebarimage-name">DEMO CREATED FOR</div>
            <img className="dashboard-sidebarimage" src={this.props.Image}/>
          </div>
          {(!this.props.population)? <div className="Competitor-box-total dashboard-location-total"><div className="dashboard-sidebarimage-name">Choose a Marker from the map</div></div>:null}
          <div className={(!this.props.population)? "dashboard-blurred": ""}>
          <div className="container-marginleft">
            <div className="dashboard-sidebargrid">
            <Competitor_box description={"Estimated population in proximity to the locations"} title={"Population"} titleMain={"Population"}  value={this.normal_est(this.props.population, "")} />

          <Competitor_box description={"Estimated income of population near locations"} title={"Income"} titleMain={"Income"}  value={this.normal_est(this.props.income, "$")} freetier={false}/>
          <Competitor_box description={"Estimated expenses including mortgage"} title={"Expenses W/ Mortgage"} titleMain={"Expenses"}  value={this.normal_est(this.props.expense, "$")} freetier={false}/>
          <Competitor_box description={"Estimated expenses without mortgage"} title={"Expenses W/O Mortgage"} titleMain={"Expenses"}  value={( "$" + this.props.expense_wo)} freetier={false}/>

          </div>
          <div className="dashboard-sidebargrid2">
          <div className="Competitor-box-total">
                <div className="Competitor-box-title Competitor-box-title2">Education Level</div>
                <div className="Competitor-box-line"></div>
                <div className={this.props.freetier? "Competitor-blurred":""} onClick={this.upgrade}>
                <Doughnut data={this.education()} options={{  responsive:true,   tooltips: {
                callbacks: {label: function (tooltipItem, data) { const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                let label = ' ' + data.labels[tooltipItem.index] || '';
                label += " " + Math.round(value * 100) + '%'; return label }} }}}/>
                </div> 
                </div>
                <div className="Competitor-box-total">
                <div className="Competitor-box-title Competitor-box-title2">Gender Distribution</div>
                <div className="Competitor-box-line"></div>
                <div className={this.props.freetier? "Competitor-blurred":""} onClick={this.upgrade}>
                <Pie data={this.gender()} options={{  responsive:true,   tooltips: {
                callbacks: {label: function (tooltipItem, data) { const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                let label = ' ' + data.labels[tooltipItem.index] || '';
                label += " " + Math.round(value * 100) + '%'; return label }} }}}/>
                </div> 
                </div>
                <div className="Competitor-box-total">
                <div className="Competitor-box-title Competitor-box-title2">Age Distribution</div>
                <div className="Competitor-box-line"></div>
                <div className={this.props.freetier? "Competitor-blurred":""} onClick={this.upgrade}>
                <Line data={this.age()} options={this.state.options}/>
                </div>
                </div>
                <div className="Competitor-box-total">
                <div className="Competitor-box-title Competitor-box-title2">Race Distribution</div>
                <div className="Competitor-box-line"></div>
                <div className={this.props.freetier? "Competitor-blurred":""} onClick={this.upgrade}>
                <Bar data={this.race()} options={this.state.options}/>
                
                </div>                
                </div>
                </div>

            </div>
            </div>
            </div>
          
        </div>
        </div>
        </div>

      )
    }
  }


