import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Tabs, 
  Tab, 
  Box,   
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon, 
  Checkbox, 
  IconButton, 
  Typography, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';

const Legend = (props) => {
  const [legend, setLegend] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [availableTabs, setAvailableTabs] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    const tabs = [];
    if (props.datalayer) tabs.push('datalayer');
    if (props.traffic) tabs.push('traffic');
    if (props.customBrandSearch.length > 0) tabs.push('brand');
    setAvailableTabs(tabs);
    
    // Set the tab index to the first available tab
    if (tabs.length > 0 && !tabs.includes(['datalayer', 'traffic', 'brand'][tabIndex])) {
      setTabIndex(0);
    }
  }, [props.datalayer, props.traffic, props.customBrandSearch]);


  const colors = [
    ['rgba(254, 130, 110)', 'rgba(255, 112, 94)', 'rgba(255, 90, 82)', 'rgba(255, 82, 74)', 'rgba(255, 65, 58)', 'rgba(255, 55, 50)'],
    ['rgb(0,124,6)', 'rgb(0,108,6)', 'rgb(0,100,6)', 'rgb(0,84,6)', 'rgb(0,68,6)', 'rgb(0,60,6)'],
    ['rgb(20, 80, 220, .65)', 'rgb(20, 70, 200, .7)', 'rgb(20, 60, 180, .75)', 'rgb(20, 50, 160, .8)', 'rgb(20, 45, 140, .9)', 'rgb(20, 40, 120, .95)']
  ];
// 'motorway': '#00008B',      // DarkBlue
      // 'trunk': '#0000CD',         // MediumBlue
      // 'primary': '#4169E1',       // RoyalBlue
      // 'secondary': '#6495ED',     // SteelBlue
      // 'tertiary': '#87CEFA',

  const traffic_colors = [
      '#B0E2FF', '#87CEFA', '#6495ED', '#4169E1', '#0000CD', '#00008B'
  ];
    

  const changestatehide = () => {
    setLegend(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // const types = () => {
  //   return (
  //     <div className="legend-opener">
  //       <div
  //         className={props.cluster_type === 0 ? 'legend-opener-eachclick legend-opener-each' : 'legend-opener-each'}
  //         onClick={() => props.layertype(0)}
  //       >
  //         Population Density
  //       </div>
  //       <div
  //         className={props.cluster_type === 1 ? 'legend-opener-eachclick legend-opener-each' : 'legend-opener-each'}
  //         onClick={() => props.freetier ? props.premiumFeatureClicked() : props.layertype(1)}
  //       >
  //         Family Income Distribution {props.freetier ? <div className="fa fa-lock Layer-filter-lock-freetier-legend"></div> : null}
  //       </div>
  //       <div
  //         className={props.cluster_type === 2 ? 'legend-opener-eachclick legend-opener-each' : 'legend-opener-each'}
  //         onClick={() => props.freetier ? props.premiumFeatureClicked() : props.layertype(2)}
  //       >
  //         Recommended Locations {props.freetier ? <div className="fa fa-lock Layer-filter-lock-freetier-legend"></div> : null}
  //       </div>
  //     </div>
  //   );
  // };

  const val = () => {
    if (props.cluster_type === 1) {
      return 'Family Income Dist.';
    }
    if (props.cluster_type === 0) {
      return 'Population Density';
    }
  };

  const p = colors[parseInt(props.cluster_type)];

  return (
    <div>
      {/* DATA LAYER LOCATION */}
      <OutsideClickHandler onOutsideClick={() => { props.datalayer_hide() }}>
        <div className={props.datalayer_show ? 'legend-totalval' : 'legend-totalval legend-totalval-hide'}>
          <div className="Building-title">Data Layer</div>
          <label className="layer-toggle-switch">
            <input type="checkbox" checked={props.datalayer} onChange={() => props.datalayer_switch()} />
            <span className="layer-toggle-slider layer-toggle-round"></span>
          </label>
          <div id="legend-main">
            <div className="legend-opener">
              <div
                className={props.cluster_type == 0 ? 'legend-opener-eachclick legend-opener-each' : 'legend-opener-each'}
                onClick={() => props.layertype(0)}
              >
                Population Density
              </div>
              <div
                className={props.cluster_type == 1 ? 'legend-opener-eachclick legend-opener-each' : 'legend-opener-each'}
                onClick={() => props.freetier ? props.premiumFeatureClicked() : props.layertype(1)}
              >
                Family Income Distribution {props.freetier ? <div className="fa fa-lock Layer-filter-lock-freetier-legend"></div> : null}
              </div>
              <div
                className={props.cluster_type == 2 ? 'legend-opener-eachclick legend-opener-each' : 'legend-opener-each'}
                onClick={() => props.freetier ? props.premiumFeatureClicked() : props.layertype(2)}
              >
                Recommended Locations {props.freetier ? <div className="fa fa-lock Layer-filter-lock-freetier-legend"></div> : null}
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
      
      {/* LEGEND LOCATION */}
      {availableTabs.length > 0 && (
        <div className="legend-values-total" >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="legend tabs"             sx={{minHeight: '30px', marginBottom: '10px'}}>
            { (availableTabs.includes('datalayer')) && <Tab label="Data Layer" style={{ minHeight: '32px', fontSize: '0.75rem' }}/>}
            {availableTabs.includes('traffic') && <Tab label="Traffic Layer" style={{ minHeight: '32px', fontSize: '0.75rem' }}/>}
            {availableTabs.includes('brand') && <Tab label="Brand Layer" style={{ minHeight: '32px', fontSize: '0.75rem' }}/>}

          </Tabs>
        </Box>
        {availableTabs[tabIndex] === 'datalayer' && (
            <div className='justify-content mb-10'>
              <div className="legend-eachfilter">{props.min_val}</div>
            <div style={{ display: 'inline-block' }}>
              {p.map((e) => (
                <div className="legend-values-each" key={e} style={{ background: e }}></div>
              ))}
            </div>
            <div className="legend-eachfilter">{props.max_val}</div>
          </div>
        )}
        {availableTabs[tabIndex] === 'traffic'  && (
            <div className='justify-content mb-10'>
                <div className="legend-eachfilter">Low</div>
                <div style={{ display: 'inline-block' }}>
                  {traffic_colors.map((e) => (
                    <div className="legend-values-each" key={e} style={{ background: e }}></div>
                  ))}
                </div>
                <div className="legend-eachfilter">High</div>
              </div>
        )}

        {availableTabs[tabIndex] === 'brand' && (
          <div style={{
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
            background: 'rgba(0, 0, 0, 0.02)'
          }}>
            <List sx={{ width: '100%' , paddingTop:0, paddingLeft:1, paddingRight: .5, maxHeight: isExpanded ? '250px' : '45px'}}>
              {props.customBrandSearch.map((item, index) => {
                const labelId = `checkbox-list-label-${index}`;

                return (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" onClick={() => props.searchBarGetBrand({title: item.Name_final, subcategory: item.subcategory}, true)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemIcon sx={{minWidth: 0}}>
                      <Checkbox
                        onChange={() => props.searchBarGetBrand({title: item.Name_final, subcategory: item.subcategory, status: !item.status}, false, true)}
                        edge="start"
                        checked={item.status || false}
                        sx={{
                          color: 'grey.500',
                          '&.Mui-checked': {
                            color: 'light-blue',
                          },
                        }}
                      />
                    </ListItemIcon>
                    {item.Image && (
                      <Box
                        component="img"
                        sx={{
                          width: 20,
                          height: 20,
                          marginRight: 1,
                          objectFit: 'contain',
                        }}
                        src={item.Image}
                        alt={item.Name_final}
                      />
                    )}
                    <ListItemText 
                      id={labelId} 
                      primary={
                        <Typography variant="body1" style={{ fontSize: '15px', fontColor: item.status ? 'light-blue' : 'grey.500', fontWeight: item.status ? 'bold' : 'normal' }}>
                          {item.Name_final}
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
            {props.customBrandSearch.length > 1 && <div className='list-expand-icon'>

                <IconButton
                  onClick={toggleExpand}
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                >
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
            }
          </div>
        )}

        </div>
      )}

    </div>
  );
};

export default Legend;
