import React, {Component} from 'react';
import axios from 'axios';
import maptize from '../Images/maptize_logo_white-01.svg';
import ReCAPTCHA from "react-google-recaptcha";
import Select from 'react-dropdown-select';
import industry_avgs from '../Competitor/industry_avgs.json'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box'; // Import Box
import LimitedMultiSelect from '../ui_components/LimitedMultiSelect';

const occupation = industry_avgs.map((av) => {return {value: av.type, label: av.type.replace("_", " ")}}).sort((a, b) => a.value.localeCompare(b.value));



export class PopupRegisterSelection extends React.Component{

    constructor(props) {
        super(props);

        this.state= {
            search_competitor: "",
            search_final: {},
            searched: [],
            eachcat: [], 
            search: [],
            name: "",
            handlepass: "",
            handleregemail: "",
            category_list: [],
            handlemail:"",
            search_set: "",
            handlepassword: "",
            search_results_show: false

        }
        this.categoryselector = this.categoryselector.bind(this);
        this.valuesetter = this.valuesetter.bind(this);
        this.occupation_setter = this.occupation_setter.bind(this);
    }
    valuesetter = (values) => this.setState({ eachcat: values });
    occupation_setter(value){

        this.setState({occupation: value})
    }
    async categoryselector(){

        var token = localStorage.getItem('auth-token');
        var cat_f = [];
        var cat_all = await axios.post('/setup/cattotal');
        let cat_all_final = await cat_all.data;

           this.setState({ category_list: cat_all_final})
    }

    componentWillMount(){
        this.categoryselector()
    }
    handlenameChange = (e) =>{
        this.setState({classes_name: 'has-val', name: e.target.value})
    }


    search_loc = async (e) => {
        // document.getElementsByClassName('search-results-total-setup')[0].style.display = 'block';
        this.setState({search_results_show: true})
        this.setState({search_set: e})
        if(e.length > 1){
            let token = localStorage.getItem("auth-token");
        var search_res = await axios.post('/gettheurl/location', {searcher: true, search:e , bounds:''}, {headers:{ "auth-token": token}});
        let search = await search_res.data;
        this.setState({search: search});
        
        }
    }
    sendingnew(pos, title){
        console.log(pos, title);
        this.setState({search_final: {position: pos, title: title}, search_set: title});
        this.setState({search_results_show: false})
    }

    freetier_category_setup = async () => {
        try{
        let token = localStorage.getItem("auth-token");
        let response = await axios.post('/signup/freetier-category', { starting: this.state.search_final, occupation: this.state.occupation, each_category: this.state.eachcat },  
        {headers:{ "auth-token": token}})
        let res = await response.data;
        console.log(res);
        window.location.reload(false);


        }catch(e){
            this.setState({error_value: e.response.data })
        }
    }
    
    render() {
        return (
            <div>
                <Dialog open={true} sx={{
                    '& .MuiDialog-paper': {
                        margin: 0, 
                    },
                }}>
                <DialogTitle>Complete Registration</DialogTitle>
                <div className="margin-x-10" >
                <div>  
                    <div display="flex" flexDirection="column" alignItems="center"> 
                        <div className="popup-register-form-selector width-100">
                        <div className="Questions_each "><div className='display-inline-block'>Enter a starting location</div> <div className='question_each_sub'>(Select a value from the list)</div></div>
                        <input className="popup-register-name width-100" placeholder="Search" onChange={e => this.search_loc(e.target.value) } value={this.state.search_set}></input> 
                        {(this.state.search_results_show) && <div className="search-results-total-setup" >{(this.state.search.length > 0) &&  this.state.search.map((i) => { if(i.position == null)return false;
                        return <div className="popup-search-results-setup" key={i.id} onClick={() => this.sendingnew(i.position, i.title)}>{i.title} <div className="search-categories">{i.categoryTitle}</div><div className="search-categories" >{i.vicinity}</div></div>
                        })}
                        </div>}
                        </div>
                        <div className="popup-register-form-selector margin-bottom-20 width-100">
                            <div className="Demographic-each"><div className='display-inline-block'>What category is your business considered in</div> <div className='question_each_sub'>(Select a value from the list)</div></div>
                            <Select
                            className="react-dropdown-select2"
                            options={occupation}
                            values={this.state.occupation_final}
                            onChange={(values) => this.occupation_setter(values)}/>
                        </div>
                        <div className="popup-register-form-selector margin-bottom-20 width-100">
                            <div className="Questions_each"><div className='display-inline-block'>Select all niche markets relevant</div></div>
                            {/* <Select
                                className="react-dropdown-select2"
                                multi
                                options={this.state.category_list}
                                values={this.state.eachcat}
                                onChange={(values) => this.valuesetter(values)} 
                                isOptionDisabled={() => this.state.eachcat.length >= 5}
                                create
                            /> */}
                            <LimitedMultiSelect selectedOptions={this.state.eachcat} options={this.state.category_list}  changeOptions={this.valuesetter} limit={5}/>

                        </div>
                        <div className="popup-register-form-selector width-100"><a href='https://mapchise.com/tutorial-page/' target='_blank'>Tutorial Page</a></div>
                        {(this.state.error_value != null)? <div className= "Competitor-register-error">{this.state.error_value}</div>:null}

                    </div>

                    </div>
                    </div>
                    <DialogActions>
                        <Button className='color-dark-blue' onClick={this.freetier_category_setup}>Get Started</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )}
}