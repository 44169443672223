
import React from 'react';
import { PolarArea, Doughnut } from 'react-chartjs-2';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, RadialLinearScale } from 'chart.js';

// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend, RadialLinearScale);

export class Polarchart extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        value: '',
        doughnut_option :
  {
    onClick: (click, elems, chart) => { 
      // console.log('clicked elems: ', click, elems)
      // console.log('this.props.interest_category[elems[0].element.index]: ', elems[0].element.$context.index)
      if(this.state.value === '' && elems.length > 0)  
          this.changevalue(elems[0].element.$context.index)
      },
      maintainAspectRatio: false,    
      onHover: (event, chartElement) => {
        if(event.native.target) event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
      },  
      plugins: {

    legend: {
     onHover: function(e) {
      if(e.native.target)
      e.native.target.style.cursor = 'pointer';

     }
  },
  title: {
      display: true,
      text: 'Interest Categories (Click to drilldown)',
      fontSize: 16,
      fontColor: "#000000"
  }
}
   }
    }
    
    this.piechartgoback = this.piechartgoback.bind(this);
    this.changevalue = this.changevalue.bind(this);
    this.piecharstate = this.piecharstate.bind(this);
  } 
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  changevalue(index){
    console.log('index: ', index)
    let type = this.props.interest_category;
    const value = type[index]
    console.log('value: ', value)
    this.setState({value})
  }
piecharstate(){
        let type = this.props.interest_category;
        // console.log(type,'type value')
        let interest = this.props.interestall
        let value= type.map((e) => {
            var inter = interest.filter((int) => int.cat === e)
            // console.log(inter, 'inter value of piechart')
            return inter.length
        })
        
        return {
            labels: type,
            datasets: [
              {
                
                backgroundColor: [
                    '#FF6384',
                    '#4BC0C0',
                    '#FFCE56',
                    '#E7E9ED',
                    '#36A2EB',
                    "#A2EB36",
                    "rgb(99,57,240)",
                    "#A2EB36",
                    "#47EB36", "#EB3647", "#EB36A2", "#7F36EB", "#F1A572", "#F1E572", "#72F1E5", "#E572F1", "#F172BE", "#B2F6AA"

                ],
                data: value
              }
            ]
          }
 
}
piechartgoback(){
    this.setState({values: ''})
}
polarchart(){
    let value = this.state.value;
    let interest = this.props.interestall.filter((e) => e.cat === value);
    let type = interest.map((e) => e.subcat);
    let val = interest.map((e) => Math.round(e.value))
    return {
        labels: type,
        datasets: [
          {
            
            backgroundColor: [
                '#FF6384',
                '#4BC0C0',
                '#FFCE56',
                '#E7E9ED',
                '#36A2EB',
                "#A2EB36",
                "rgb(99,57,240)",
                "#A2EB36",
                "#47EB36", "#EB3647", "#EB36A2", "#7F36EB", "#F1A572", "#F1E572", "#72F1E5", "#E572F1", "#F172BE", "#B2F6AA"

            ],
            data: val
          }
        ]
      }
}




render() {
  
      
    return (<div>{(this.state.value ==="")?
    <div className="polarchart-total"><Doughnut data={this.piecharstate()} options={this.state.doughnut_option } />   </div>:<div ><div className="box-goback-container2"> <div id="box-goback" onClick={() => this.setState({value:""})}>Back</div></div> 
    <div className="polarchart-total"> <PolarArea 
  data={this.polarchart()}     
  options={{ 
    maintainAspectRatio: false,
    responsive: true,
    legend: { display: false },
    plugins: {

    title: {
      display: true,
      text: this.state.value,
      font: {
        size: 16,
      },
      color: "#000000"
    },
    scale: {
      ticks: { display: false }
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          var labelItem = data.labels[tooltipItem.index];  
          return labelItem;
        }
      }
    },
  }

  }} 
/>

</div>
  
  </div>}
    
    </div>)
}
}