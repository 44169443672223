import React from 'react';
import ReactDOM from 'react-dom';
import '../Login.css';
import '../Register.css';
import maptize from './Images/maptize-black.png';
import axios from 'axios';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
const stripePromise = loadStripe("pk_live_4k96lg3c85qDG4Vt7RNx4B7300sEu13hFu");
const queryString = require('query-string');
// pk_live_4k96lg3c85qDG4Vt7RNx4B7300sEu13hFu
// pk_test_CzbMnwCQDiPyfNGlvP2GOX8w00aoutzlEv

export class Register extends React.Component{
    constructor(props) {
      super(props);
      this.state= {
          password : '',
          email: '',
          name: '',
          classes_name: '',
          classes_email: '',
          classes_pass: '',
          Errors: null,
      }
      
      
      
    }
      
    componentDidMount(){
        
    }
    
    async validationnumber(e){
        e.preventDefault();
        console.log(this.state.email, this.state.password)
        try{
        let response = await axios.post('/signup', {
            "name": this.state.name,
            "email": this.state.email,
            "password": this.state.password
        })
        let res = await response.data;
        localStorage.setItem("auth-token", res)
        window.location.href = "/setup";

        }
        catch(ee){
            console.log(ee.response.data)
            this.setState({Errors: ee.response.data})
            // console.log(response)
        }
    }
    handleEmailChange(e) {
        this.setState({email: e.target.value, classes_email: 'has-val'});
     }
    
     handlePasswordChange(e) {
        this.setState({password: e.target.value, classes_pass: 'has-val'});
     }
     handlenameChange(e) {
        this.setState({name: e.target.value, classes_name: 'has-val'});
     }
    //  toppos(){
    //      document.getElementById("usern").style.top = "-5px"
    //  }
    //  toppos2(){
    //     document.getElementById("passw").style.top = "-5px"
    // }
    
    render() {

        return (
        <div className="login-page" >
            <div className="login-image"></div>
            <div className="limiter">
		        <div className="container-login100 container-loginregister">
			        <div className="wrap-login100 p-t-85 p-b-20">

					<span className="login100-form-avatar">
						<img src={maptize} alt="AVATAR" onClick={()=> {window.location.href ="/login"}} className="login-avatar-register"/>
					</span>

                    <Elements stripe={stripePromise}>
                        <CheckoutForm />
                    </Elements>

                    
					 {/* <ul className="login-more p-t-190">
						<li className="m-b-8">
							<span className="txt1">
								Already Have an account?
							</span>
							<a href="/login" className="txt2">
								 Login
							</a>
						</li>
					</ul>  */}
            
        </div></div></div></div>
            )
        }

}