import React from 'react';
import {ElementsConsumer, CardElement ,Elements} from '@stripe/react-stripe-js';
import maptize from '../Images/maptize-black.png';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Select from 'react-dropdown-select';

export class Account_sub_setting extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            password : '',
            email: '',
            name: '',
            editable: false,
            invoice: null,
            Errors: null,
            Errors_val: null,
            address: null,
            card: null,
            exp: null,
            name_edit: false,
            password_edit: false,
            emailverified: false,
            card_edit: false,
            type: null,
            editpayment: false,
            email_preference_picked: null,//[{label:"Once a Week", value: "week"}],
            email_preference: [{label:"Once a Month", value: "month"}, {label:"Once a Week", value: "week"},{label:"Once a Day", value: "day"}, {label:"No Task Notification", value: "none"}, {label:"No Notification", value: "false"}],
            passtype: false,
            saver: true,
            passwordvalue: '',
            disablesend: false,
            passwordnew: '',
            error_value: null
        }
        this.getvalues = this.getvalues.bind(this)
        this.email_preference = this.email_preference.bind(this);
      }
      componentWillMount(){
        this.getvalues()
      }
      async getvalues(){
        try{
          let token = localStorage.getItem("auth-token");
      
      let response = await axios.post('/accountsubset/accountinfo', {      
        }, {headers:{ "auth-token": token}})
      response= await response.data;
      let emailPreference = (response.emailPreference)? response.emailPreference: "week";
      this.setState({emailverified: response.emailverified,  name: response.name, email: response.email, email_preference_picked: emailPreference})
      }catch(e){
          if(e.response == null || e.response.status == 400){
             
            window.location.href = "/login";
          }
      }
      }
      editpayment(){
        this.setState({editpayment: true})
      }
      namechange(e){
        this.setState({name: e.target.value, saver: false})
      }
      emailchange(e){
        this.setState({email: e.target.value , saver: false})
      }
      logout(){
        localStorage.removeItem("auth-token")
        window.location.href = '/login'
      }
      async savevalues(){
        let token = localStorage.getItem("auth-token");
        try{
        let response = await axios.post('/accountsubset/accountsave', {name: this.state.name, email: this.state.email}, {headers:{ "auth-token": token}});
        let response_val = await response.data.status;
        if(response_val == 1)
          this.setState({saver: true, Errors_val: null})
        }catch(e){
          this.setState({Errors_val: e.response.data})
        }
      }
      passwordvalue(e){
        this.setState({passwordvalue: e.target.value})
      }
      passwordnew(e){
        this.setState({passwordnew: e.target.value})
      }
      changepass(){
        this.setState({passtype: true})
      }
     async savepassword(){
        let token = localStorage.getItem("auth-token");
        try{
        let response = await axios.post('/accountsubset/password', {password: this.state.passwordvalue, passwordnew: this.state.passwordnew}, {headers:{ "auth-token": token}});
        let response_val = await response.data;
        this.setState({passtype: false})
      }catch(e){
        this.setState({Errors: e.response.data})
      }
        
      }
      cancelvalue(){
        this.setState({passtype: false, editpayment: false})
      }
      async email_preference(value){
        let token = localStorage.getItem("auth-token");
        let response = await axios.post('/accountsubset/emailpreference', {emailPreference: value}, {headers:{ "auth-token": token}});
        this.setState({email_preference_picked: value})
      }
      async resendemail(){
        let token = localStorage.getItem("auth-token");
        try{
        let response = await axios.post('/account/sendemail', {}, {headers:{ "auth-token": token}});
        this.setState({disablesend: true})
        }catch(e){
          this.setState({Errors: "Error while trying to send you the Email"})
        }
      }

      render() {
      return (
        <div className="container-account col-xs-1" >
        <img className="image-account" src={maptize} onClick={()=> window.location.href = '/'}></img>
        <div className="sidebar-account-total">
          <div className="sidebar-account-each sidebar-account-selected">Account Settings</div>
          <div className="sidebar-account-each" onClick={() =>window.location.replace('/tasks')}>Task Management</div>
        </div>
        <div className='box-account'>
        <button className="button-account button-top-account" onClick={this.logout}>Logout</button>
        <div className="title-account">Account Details</div>
        {(this.state.Errors != null)? <div className= "error-logger">{this.state.Errors}</div>:null}
        <div className="each-total-account">
        <div className="each-lable-account">Email: </div><input className="each-input-account" value={this.state.email} onChange={this.emailchange.bind(this)}></input>
      </div>{(!this.state.emailverified)?<div><button className="password-account resend-email" onClick={this.resendemail.bind(this)} disabled={this.state.disablesend}>Resend Verification Email</button>{this.state.disablesend?<div  className="error-logger error-logger-values">Email Sent</div>:null}</div>:null}
        <div className="each-total-account">
        <div className="each-lable-account">Name: </div><input className="each-input-account" value={this.state.name} onChange={this.namechange.bind(this)}></input>
        </div>
        
        <button className="button-account" disabled={this.state.saver} onClick={this.savevalues.bind(this)}>Save</button>
        <div className="each-total-account">
        <div className="each-lable-account">Password: </div>
        {(this.state.passtype)? <div style={{"margin-top":"15px"}}> <div className="error-account">{this.state.Errors}</div>
        <div className="each-lable-account">Current Password: </div><input className="newpass-account each-input-account" onChange={this.passwordvalue.bind(this)} type="password" ></input>
        <div className="each-total-account"><div className="each-lable-account">New Password: </div><input className="newpass-account each-input-account" type="password" onChange={this.passwordnew.bind(this)} ></input></div>
        <button className="cancelpass-account password-account" onClick={this.cancelvalue.bind(this)}>Cancel</button>
        <button className="button-account" onClick={this.savepassword.bind(this)} style={{"margin-left": "18px"}}>Save</button> </div>
        :<button className="password-account" onClick={this.changepass.bind(this)}>Change Password</button>}
        </div>
        <div>
        <div className="each-lable-account">Email Preference: </div>
        <div className="task-value-select-email">
        {/* <Select
        options={this.state.email_preference}
        values={this.state.email_preference_picked}
         placeholder=""
         onChange={(values) => this.email_preference(values)}/> */}
         {this.state.email_preference.map((e) => <div className={(this.state.email_preference_picked === e.value)?"task-tabvalue2 task-tabvalue2-color":"task-tabvalue2"} key={e.value} onClick={() => this.email_preference(e.value)}>{e.label}</div>)}
         </div>
        </div>
        </div>
        </div>
      );
    }
}
