
import React, {Component} from 'react';
import {ReactComponent as Financials} from './Images/bar-chart.svg';
import {ReactComponent as Target_market} from './Images/Group.svg';
import {ReactComponent as Social} from './Images/Social_media.svg';
import {ReactComponent as Overview} from './Images/home.svg';
import {ReactComponent as Location_pin} from './Images/location-pin.svg';
import {ReactComponent as Filter} from './Images/filter.svg';
import axios from 'axios';
import OutsideClickHandler from 'react-outside-click-handler';


export class Competitor_sidebar extends React.Component{
    constructor(props) {
        super(props);
        this.state= {
            scroll: 0,
            region: "",
            show: true,
            filterlist: []
        }
    }
    windowpage = (e) =>{
        let page = window.pageYOffset
        let sticky = (page > 100)
        this.setState({scroll: page, sticky})
    }
    componentDidMount(){
    window.addEventListener('scroll', this.windowpage);
    }
    inputsearch = async (e) => {
        let token = localStorage.getItem("auth-token");
        if(!e.target.value) return;
        this.setState({region: e.target.value})
        let response = await axios.post('/gettheurl/competitorregion', {region: e.target.value.trim()}, {headers:{ "auth-token": token}})
        let res = await response.data
        this.setState({filterlist: res.countysearch})
    }
    regions(region){
        if(region.State) return region.Name + " " + region.Region + ", " + region.State
        return region.Name 
    }
    render() {
        return (
            <div className={this.state.sticky? "Competitor-sidebar-total Competitor-sidebar-fixed":"Competitor-sidebar-total"}>
            {!this.state.show?<div className="fa fa-angle-down Competitor-open" onClick={() => this.setState({show:true})}></div>:null}
            <div >
            {(this.state.regionsearch)?
            <OutsideClickHandler onOutsideClick={() => this.setState({regionsearch: false})} >
            <div className="Competitor-search-region-total">
            <input className="Competitor-search-region" value={this.state.region} onChange={(e) => this.inputsearch(e)} placeholder="Search region or state"/>
            <div className="Competitor-result-filter">
                {this.state.filterlist.map((f) => 
                <div className="Competitor-result-each2" key={f._id} onClick={() => {this.setState({region: this.regions(f), regionsearchvalue:this.regions(f), regionsearch: false, filterlist: []}); this.props.locationsearch(f)}}>{this.regions(f)}</div>
                )}
            </div>
            </div>
            </OutsideClickHandler>
            :null}
            <div onClick={() => this.setState({regionsearch: true})}>
            <Filter  className={(this.state.regionsearch || this.state.region)?"Competitor-sidebar-image2":"Competitor-sidebar-image"}/>
            <div className={(this.state.regionsearch || this.state.region)?"Competitor-sidebar-name Competitor-blue-filter":"Competitor-sidebar-name"}>Filter</div>
            {(this.state.regionsearchvalue)?<div className="Competitor-sidebar-name Competitor-blue-filtered" onClick={() => {this.setState({region: "", regionsearchvalue:null}); this.props.locationsearch()}}>{this.state.regionsearchvalue}<i className="Competitor-paddingleft fa fa-times"></i></div>:null}

            </div>
            
            </div>
            {this.state.show? <div className="Competitor-padding20">
            <div onClick={() => window.location.hash="Competitor-brand-total"}>
            <Overview  className={(this.state.scroll < 300)?"Competitor-sidebar-image2":"Competitor-sidebar-image"}/>
            <div className={(this.state.scroll < 300)?"Competitor-sidebar-name Competitor-blue":"Competitor-sidebar-name"}>Overview</div>
            </div>
            <div onClick={() => window.location.hash="Competitor-financial-id"}>
            <Financials  className={(this.state.scroll > 300 && this.state.scroll < 800)?"Competitor-sidebar-image2":"Competitor-sidebar-image"}/>
            <div className={(this.state.scroll > 300 && this.state.scroll < 800)?"Competitor-sidebar-name Competitor-blue":"Competitor-sidebar-name"}>Financials</div>
            </div>
            <div onClick={() => window.location.hash="Competitor-map-id"}>
            <Location_pin  className={(this.state.scroll > 800 && this.state.scroll < 1400)?"Competitor-sidebar-image2":"Competitor-sidebar-image"}/>
            <div className={(this.state.scroll > 800 && this.state.scroll < 1400)?"Competitor-sidebar-name Competitor-blue":"Competitor-sidebar-name"}>Map</div>
            </div>
            <div onClick={() => window.location.hash="Competitor-demographic-id"}>
            <Target_market  className={(this.state.scroll > 1400 && this.state.scroll < 2100)?"Competitor-sidebar-image2":"Competitor-sidebar-image"}/>
            <div className={(this.state.scroll > 1400 && this.state.scroll < 2100)?"Competitor-sidebar-name Competitor-blue":"Competitor-sidebar-name"}>Target Market</div>
            </div>
            {/* <div onClick={() => window.location.hash="Competitor-social-id"}>
            <Social  className={(this.state.scroll > 2100)?"Competitor-sidebar-image2":"Competitor-sidebar-image"}/>
            <div className={(this.state.scroll > 2100)?"Competitor-sidebar-name Competitor-blue":"Competitor-sidebar-name"}>Social Media</div>
            </div> */}
            <div className="fa fa-angle-up Competitor-close" onClick={() => this.setState({show:false})}></div>
            </div>:null}
            </div>

    )}
}