import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Select from 'react-dropdown-select';
import React, {Component, useState, useEffect} from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import { ChromePicker } from 'react-color';
import OutsideClickHandler from 'react-outside-click-handler';
import CircularProgress from '@mui/material/CircularProgress';

const static_status_list = ['Open', 'Prospecting', 'Closed', 'Opening Soon']
const range_distances_guards = {'Meters': [100, 25000], 'Miles': [.1, 25], 'Minutes': [1, 50], 'Circle Radius Miles': [.1, 50], 'Circle Radius Meters': [100, 25000]}
const range_option_const = {Meters: ['1000', '10000', '25000'], Miles: ['1', '5', '10'], Minutes: ['5', '10', '15'], Custom: ['0'], 'Circle Radius Miles': ['1', '5', '10'], 'Circle Radius Meters': ['1000', '10000', '25000']}
const range_types_options = ['Meters', 'Miles', 'Minutes', 'Circle Radius Miles', 'Circle Radius Meters']


export const AccountPreferences = ({ lambdaSheetErrors, lambdaSheetsCall, saveColorChange, status_colors, updateUrlLogo, brandLogo, brandList, setParentRange, range, columns, string_values_static, integer_values_static, brand_static, distance_static, distance_type_static, list_values_static, status_static, name_static, address_static, revenue_static, expense_static, lease_static, coordinates_static}) => {

    const [rangeOptions, setRangeOptions] = useState(range_option_const[range.type]);
    const [rangeTypes, setRangeTypes] = useState(range.type);
    const [rangeDistance, setRangeDistance] = useState(range.distance ? range.distance.toString(): '0');
    const [calculatationError, setCalculationError] = useState('');
    const [calculationChanged, setCalculationChanged] = useState(false);
    const [calculationSearching, setCalculationSearching] = useState(false);
    const [pullingSheet, setPullingSheet] = useState(false);
    const [pullGoogleSheetCompleted, setPullGoogleSheetCompleted] = useState(false);
    const [lambdaSheetErrorsLocal, setLambdaSheetErrorsLocal] = useState(lambdaSheetErrors);

    const [string_values, setString_values] = useState(string_values_static);
    const [list_values, setList_values] = useState(list_values_static);
    const [integer_values, setInteger_values] = useState(integer_values_static)
    const [selectedStatus, setSelectedStatus] = useState(status_static);
    const [selectedName, setSelectedName] = useState(name_static);
    const [selectedAddress, setSelectedAddress] = useState(address_static);
    const [selectedCoordinates, setSelectedCoordinates] = useState(coordinates_static);
    const [selectedBrand, setSelectedBrand] = useState(brand_static);
    const [selectedDistance, setSelectedDistance] = useState(distance_static);
    const [selectedDistanceType, setSelectedDistanceType] = useState(distance_type_static);
    const [selectedRevenue, setSelectedRevenue] = useState(revenue_static);
    const [selectedExpense, setSelectedExpense] = useState(expense_static);
    const [selectedLease, setSelectedLease] = useState(lease_static);

    const [selectedColor, setSelectedColor] = useState();
    const [selectedStatusColor, setSelectedStatusColor] = useState(null);
    const [statusColorShow, setStatusColorShow] = useState(true);

    const [notification, setNotification] = useState('');
    const [initialRun, setInitialRun] = useState(true);
    const [file, setFile] = useState(null);
    const [logoBrand, setLogoBrand] = useState('');

    const [preview, setPreview] = useState(null);
    const [message, setMessage] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    
    useEffect(() => {
        if(lambdaSheetsCall === 'In Progress' && lambdaSheetErrors.length === 0){
            checkGoogleSheetStatus()
        }
        setPullingSheet(lambdaSheetsCall === 'In Progress' ? true : false);
    }, [lambdaSheetsCall])

    useEffect(() => {
        setLambdaSheetErrorsLocal(lambdaSheetErrors)
    }, [lambdaSheetErrors])
    
    useEffect(() => {
        setString_values(string_values_static);
    }, [string_values_static]);

    useEffect(() => {
        setList_values(list_values_static);
    }, [list_values_static]);

    useEffect(() => {
        setInteger_values(integer_values_static);
    }, [integer_values_static]);

    useEffect(() => {
        setSelectedStatus(status_static);
    }, [status_static]);

    useEffect(() => {
        setSelectedName(name_static);
    }, [name_static]);

    useEffect(() => {
        setSelectedAddress(address_static);
    }, [address_static]);

    useEffect(() => {
        setSelectedDistance(distance_static);
    }, [distance_static]);
    useEffect(() => {
        setSelectedDistanceType(distance_type_static);
    }, [distance_type_static]);
    useEffect(() => {
        setSelectedBrand(brand_static);
    }, [brand_static]);

    useEffect(() => {
        setSelectedRevenue(revenue_static);
    }, [revenue_static]);

    useEffect(() => {
        setSelectedExpense(expense_static);
    }, [expense_static]);

    useEffect(() => {
        setSelectedLease(lease_static);
    }, [lease_static]);

    useEffect(() => {
        setSelectedCoordinates(coordinates_static);
    }, [coordinates_static]);


    useEffect(() => {
        setRangeTypes(range.type);
        setRangeDistance(range.distance);
    }, [range]);

    const saveChanges = async () => {
        if(calculatationError != '') return alert('Range must be fixed before saving')
        let token = localStorage.getItem("auth-token");
        const main_req = await axios.post('/account/accountprefrences', 
        {status: selectedStatus, name: selectedName, address: selectedAddress, coordinates: selectedCoordinates, range: {distance: rangeDistance, type: rangeTypes}, distanceType: selectedDistanceType, distance: selectedDistance, brand: selectedBrand, revenue: selectedRevenue, expense: selectedExpense, lease: selectedLease}, 
        {headers:{ "auth-token": token}})
        await setParentRange(range={distance: rangeDistance, type: rangeTypes})
        setNotification('Changes have been saved!')

    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
            //   if (img.width !== 10 || img.height !== 10) {
            //     setMessage('Image must be 10x10 pixels');
            //     setFile(null);
            //     setPreview(null);
            //   } else {
                setFile(file);
                setPreview(reader.result);
                setMessage('');
            //   }
            };
          };
          reader.readAsDataURL(file);
        }
      };
    
      const handleUpload = async () => {
        if (!file || !logoBrand) {
          setMessage('Please provide both a brand and a logo file');
          return;
        }
    
        const formData = new FormData();
        formData.append('logo', file);
        formData.append('brand', logoBrand);
    
        try {
            let token = localStorage.getItem("auth-token");

          const response = await axios.post('/image_upload/logo', formData, {
            headers: { 'Content-Type': 'multipart/form-data',  "auth-token": token}
          });
          updateUrlLogo(logoBrand, response.data.url);
          setMessage('Upload successful!');
        } catch (error) {
          setMessage('Upload failed');
        }
      };
    

    const checkGoogleSheetStatus = async() => {
        let token = localStorage.getItem("auth-token");

        const intervalId = setInterval(async () => {
            const pollResponse = await axios.post('/account/user_metadata', {check: true}, {
                headers: { "auth-token": token }
            });

            // Check response to see if the process is complete
            if (pollResponse.data.lambdaSheetsCall === 'Completed' || pollResponse.data.lambdaSheetErrors.length > 0) {
                clearInterval(intervalId); 
                setPullingSheet(false); // Update state
                setLambdaSheetErrorsLocal(pollResponse.data.lambdaSheetErrors)
                if(pollResponse.data.lambdaSheetsCall === 'Completed') setPullGoogleSheetCompleted(true)
                console.log('Process complete:', pollResponse.data);
                // Optionally, handle the completed state/data here
            } else {
                console.log('Waiting for completion...');
            }
        }, 10000); 
    }
    const pullGoogleSheet = async() => {
        let token = localStorage.getItem("auth-token");
        setPullingSheet(true);
        const lambdaError = lambdaSheetErrorsLocal;
        setLambdaSheetErrorsLocal([])
        setPullGoogleSheetCompleted(false)
        try {
            const response = await axios.post('/account/user_metadata', {}, {
                headers: { "auth-token": token }
            });
            // Check if the initial request was successful
            if (response.status === 200) {
                checkGoogleSheetStatus()

            } else {
                setLambdaSheetErrorsLocal(lambdaError)
                console.log('Initial request failed:', response);
                setPullingSheet(false);
            }
        } catch (error) {
            setLambdaSheetErrorsLocal(lambdaError)
            console.error('Error during the request:', error);
            setPullingSheet(false);
        }
    }
// STATUS COLOR
// RANGE CHANGE
// GOOGLE SHEETS ID, GOOGLE SHEETS NAME, LOCATION NAME, LOCATION ADDRESS, longitude | latitude, STATUS
    return (
        <div className='box-account pr-20'>
            <div className="font-normal mb-20 mt-20">Brand Image Upload</div>
            <Autocomplete
                options={brandList}
                getOptionLabel={(option) => option}
                value={logoBrand}
                onChange={(event, newValue) => setLogoBrand(newValue)}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Select Brand Name"
                    placeholder="Select Brand Name"
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 0.625
                    }}
                    />
                )}
            />
            <div className='center-content'>
            {brandLogo[logoBrand] && <img src={brandLogo[logoBrand]} className='ImageLogoAccount mt-10 mb-10'/>}
            <input type="file" accept="image/*" onChange={handleFileChange} className=' mt-10 mb-10'/>
            <button onClick={handleUpload} className='button-purple mt-10 mb-10'>Upload</button>
            </div>
            {message && <p>{message}</p>}

            <div className="font-normal mb-20 mt-20">Default Status Color Change</div>

            <Autocomplete
                options={Object.keys(status_colors)}
                getOptionLabel={(option) => option}
                value={selectedStatusColor}
                onChange={(event, newValue) => { 
                    setSelectedStatusColor(newValue); 
                    setSelectedColor(status_colors[newValue]);
                    setStatusColorShow(true);
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderOption={(props, option) => (
                    <li {...props}>
                        <Chip
                            label={option}
                            style={{ backgroundColor: status_colors[option], color: '#fff' }}
                        />
                    </li>
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => <Chip
                            color={status_colors[option]}
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                            style={{ backgroundColor: status_colors[option], input: {color: status_colors[option] }}}
                        />
                    )
                }
                renderInput={(params) => (
                    
                    <TextField
                        {...params}
                        variant="standard"
                        label="Change Status Color"
                        placeholder="Change Status Color"
                        sx={{
                            input: { color:  selectedColor},
                            "& .MuiInputLabel-root": { fontSize: '12px' },
                            "& input": { fontSize: '12px' },
                            mt: 0.625,
                            mb: 0.625
                        }}
                    />
                )}
            />
            {selectedStatusColor != '' && statusColorShow && selectedStatusColor && 
            <div className='w-225px'>
            <OutsideClickHandler onOutsideClick={() =>  setStatusColorShow(false)}>
                    <ChromePicker
                        color={selectedColor}
                        onChangeComplete={(color) => setSelectedColor(color.hex)}
                    />
            </OutsideClickHandler>
            </div>}
            {selectedColor != status_colors[selectedStatusColor] &&
            <div>
                    <div className="font-normal mb-20 mt-20">{notification}</div>
                <div className="main-mapping-save mb-20 mt-20" >
                    <div id="box-goback" className={`cursor-pointer`} onClick={(() => saveColorChange(selectedStatusColor, selectedColor))}>Save Color Change</div>
                </div>
            </div>
            }

            <div className="font-normal mb-20 mt-20">Default Range Setup</div>
            <Autocomplete
                options={range_types_options}
                getOptionLabel={(option) => option}
                value={rangeTypes}
                onChange={(event, newValue) => {
                    // console.log('rangeTypes: ', newValue, rangeDistance, range_distances_guards[rangeTypes])
                    if(calculationSearching) alert('Calculating current range')
                    if(range_distances_guards[newValue]){
                        if(rangeDistance < range_distances_guards[newValue][0]){
                            setCalculationError('Range number is too small')  
                        }else if(!rangeDistance){
                            setCalculationError('Enter a range number')    
                        }else if(rangeDistance > range_distances_guards[newValue][1]){
                            setCalculationError('Range number is too large')    
                        }else{
                            setCalculationError('')
                        }
                    }
                    setRangeTypes(newValue)
                    setCalculationChanged(true)
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Distance Type"
                    placeholder="Distance Type"
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 0.625
                    }}
                    />
                )}
            />
            <Autocomplete
                options={rangeOptions}
                freeSolo
                getOptionLabel={(option) => option}
                value={rangeDistance.toString()}
                // onChange={(event, newValue) => {setRangeDistance(newValue)}}
                onInputChange={(event, newInputValue) => {
                    // console.log('rangeDistance: ', newInputValue)
                    if(!newInputValue.match(/^\d*\.?\d*$/)) return setCalculationError('Only numeric values for range')
                    if(calculationSearching) return setCalculationError('Calculating current range')

                    if(range_distances_guards[rangeTypes]){
                        if(newInputValue < range_distances_guards[rangeTypes][0]){
                            setCalculationError('Range number is too small')
                        }else if(!newInputValue){
                            setCalculationError('Enter a range number')    
                        }else if(newInputValue > range_distances_guards[rangeTypes][1]){
                            setCalculationError('Range number is too large')
                        }else{
                            setCalculationError('')
                        }
                    }
                    setRangeDistance(newInputValue);
                    if(!initialRun) setCalculationChanged(true)
                    setInitialRun(false)
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Range Number"
                    placeholder="Range Number"
                    error={calculatationError !== ''}
                    helperText={calculatationError}
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 2
                    }}
                    />
                )}
            />
            {columns.length > 0 && <div>
            <div className="font-normal mb-20 mt-20">Google Sheets Mapping</div>
            {pullingSheet && lambdaSheetErrorsLocal.length === 0 ? <div className='center-content'>
                <CircularProgress />
                <div className='font-normal mb-20 mt-20'>Pulling Sheet</div></div> : <div className="main-mapping-save mb-20 mt-20" >
                <div className='password-account ml-0 pt-3' onClick={pullGoogleSheet}>Pull Google Sheet</div>
            </div> }
            {pullGoogleSheetCompleted && <div className='center-content'>
                <div className='font-normal mb-20 mt-20'>Pull Complete!</div></div>}
            <div className='center-content'>
                {lambdaSheetErrorsLocal.length > 0 && <div>The following records were unable to be retrieved: </div>}
                {lambdaSheetErrorsLocal?.slice(0,10).map((e) => <div key={e} className='error-general'>{e}</div>)}
            </div>
            <Autocomplete
                options={string_values}
                getOptionLabel={(option) => option}
                value={selectedName}
                onChange={(event, newValue) => {setSelectedName(newValue)}}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Select Name Field (Required)"
                    placeholder="Select Name Field (Required)"
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 0.625
                    }}
                    />
                )}
            />

            <Autocomplete
                options={string_values}
                getOptionLabel={(option) => option}
                value={selectedStatus}
                onChange={(event, newValue) => {setSelectedStatus(newValue)}}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Select Status Field (Required)"
                    placeholder="Select Status Field (Required)"
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 0.625
                    }}
                    />
                )}
            />

            <Autocomplete
                options={string_values}
                getOptionLabel={(option) => option}
                value={selectedAddress}
                onChange={(event, newValue) => {setSelectedAddress(newValue)}}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Select Address Field (Required)"
                    placeholder="Select Address Field (Required)"
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 0.625
                    }}
                    />
                )}
            />

            <Autocomplete
                options={integer_values}
                getOptionLabel={(option) => option}
                value={selectedDistance}
                onChange={(event, newValue) => {setSelectedDistance(newValue)}}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Select Distance Field (Optional)"
                    placeholder="Select Distance Field (Optional)"
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 0.625
                    }}
                    />
                )}
            />

            <Autocomplete
                options={string_values}
                getOptionLabel={(option) => option}
                value={selectedDistanceType}
                onChange={(event, newValue) => {setSelectedDistanceType(newValue)}}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Select Distance Type Field (Optional)"
                    placeholder="Select Distance Type Field (Optional)"
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 0.625
                    }}
                    />
                )}
            />

            <Autocomplete
                options={string_values}
                getOptionLabel={(option) => option}
                value={selectedBrand}
                onChange={(event, newValue) => {setSelectedBrand(newValue)}}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Select Brand Field (Optional)"
                    placeholder="Select Brand Field (Optional)"
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 0.625
                    }}
                    />
                )}
            />

            <Autocomplete
                options={string_values}
                getOptionLabel={(option) => option}
                value={selectedRevenue}
                onChange={(event, newValue) => {setSelectedRevenue(newValue)}}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Select Revenue Field (Optional)"
                    placeholder="Select Revenue Field (Optional)"
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 0.625
                    }}
                    />
                )}
            />

            <Autocomplete
                options={string_values}
                getOptionLabel={(option) => option}
                value={selectedExpense}
                onChange={(event, newValue) => {setSelectedExpense(newValue)}}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Select Operational Expenses Field (Optional)"
                    placeholder="Select Operational Expenses Field (Optional)"
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 0.625
                    }}
                    />
                )}
            />

            <Autocomplete
                options={string_values}
                getOptionLabel={(option) => option}
                value={selectedLease}
                onChange={(event, newValue) => {setSelectedLease(newValue)}}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Select Leasing Cost Field (Optional)"
                    placeholder="Select Leasing Cost Field (Optional)"
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 0.625
                    }}
                    />
                )}
            />

            <Autocomplete
                options={list_values}
                getOptionLabel={(option) => option}
                value={selectedCoordinates}
                onChange={(event, newValue) => {setSelectedCoordinates(newValue)}}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Select Coordinates Field (Optional)"
                    placeholder="Select Coordinates Field (Optional)"
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 0.625
                    }}
                    />
                )}
            />

           
            </div>}
            <div className="main-mapping-save mb-20 mt-20">
                <div className="font-normal mb-20 mt-20">{notification}</div>
            </div>
            <div className="main-mapping-save mb-20 mt-20" >
                <div id="box-goback" className={`cursor-pointer`} onClick={saveChanges}>Save</div>
            </div>
        </div>
    )
}