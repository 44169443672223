import React, { useState, useEffect } from 'react';
import { Competitor_box } from './Competitor_box';

export default function Competitor_demographic(props) {
    const [populationEstimate, setPopulationEstimate] = useState("No Data");
    const [incomeEstimate, setIncomeEstimate] = useState("No Data");
    const [expenseEstimate, setExpenseEstimate] = useState("No Data");

    const revenue_est = (value) => {
        if (!value) return "No Data";
        if (value < 1000) return Math.round(value).toString() + "K";
        if (value < 1000000) return Math.round(value / 10) / 100 + "M";
        return Math.round(value / 10000) / 100 + "B";
    };

    const normal_est = (value, dollar) => {
        if (!value) return "No Data";
        if (value < 1000000) return dollar + (Math.round(value / 100) / 10).toString() + "K";
        if (value < 1000000000) return dollar + Math.round(value / 10000) / 100 + "M";
        return dollar + Math.round(value / 10000000) / 100 + "B";
    };

    useEffect(() => {
        setPopulationEstimate(normal_est(props.population, ""));
        setIncomeEstimate(normal_est(props.income, "$"));
        setExpenseEstimate(normal_est(props.expense, "$"));
    }, [props.population, props.income, props.expense]); // dependencies to recompute on changes

    return (
        <div className="Competitor-financials-overall" id="Competitor-demographic-id">
            <div className="Competitor-financials-title">Target Market <div className="Competitor-title-line"></div></div>
            <Competitor_box description={"Estimated population in proximity to the locations"} title={"Population"} titleMain={"Population"} value={populationEstimate} />
            <Competitor_box description={"Estimated income of population near locations"} title={"Income"} titleMain={"Income"} value={incomeEstimate} freetier={props.freetier} />
            <Competitor_box description={"Estimated expenses including mortgage of population near locations"} title={"Expenses"} titleMain={"Expenses"} value={expenseEstimate} freetier={props.freetier} />
        </div>
    );
};
