import React, { useState } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import {FindRange} from './findRange';
import axios from 'axios';

function PopupContent({  defaultBrand, range, statusList, initialTags=[], deleteProspect, prospectnames, getlocationrange, address, markerlatlng, sidebaropenlink }) {
    const [name, setName] = useState('');
    const [status, setStatus] = useState(statusList);
    const [brand, setBrand] = useState(defaultBrand);
    const [tags, setTags] = useState(initialTags);
    const [selectedStatus, setSelectedStatus] = useState("Open");
    const [selectedTags, setSelectedTags] = useState([]);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const selectingTag =(event, newValue) => {
        if(selectedTags.length >= 3) return alert("You can select a maximum of 3 tags.");
        setSelectedTags(newValue)
    }

    const confirmationprospect = () =>{
        if(name == '') return alert('Please enter a name for this location to save.')
        if( name.length >= 150)return alert('Name Entered is too long.')   
        if(prospectnames.includes(name))return alert('Name already exists.')
        senddata()
        deleteProspect()
    }

    const handleBrandChange = (event) => {
        setBrand(event.target.value);
    }

    const senddata = async() => {
        let token = localStorage.getItem("auth-token");
        // try{
        var circle = await FindRange(markerlatlng, range)
        circle = circle.data.geometry
        var data_circle = await getlocationrange(circle.coordinates[0])
        if(data_circle.family)
        data_circle.family.zipcode_color = null
        if(data_circle.total_age_sex)
        data_circle.total_age_sex.zipcode_218 = null;

        var senddata = await axios.post('/gettheurl/setvalue', 
        {  
            updater: false,
            address,
            brand,
            range,
            tags: selectedTags,
            status: selectedStatus,
            coordinates: markerlatlng,
            name_prospect: name, circle, data_circle}, {headers:{ "auth-token": token}});
        // }catch(e){
        //     console.log(e)
        //     if(e.response.status == 400) window.location.href = '/login'
        //     if(e.response.status == 401) alert("You are out of locations to add. Upgrade your account in the settings")
        // }

        sidebaropenlink();
    }


    return (
        <div><div className='droppingcheck'>Save this location?</div> 
            <div className='dropping-prosp-val'>
            <TextField
            onChange={handleNameChange}
            className='mb-5 mt-5 width-200px'
                    variant="standard"
                    label="Location Name"
                    placeholder="Location Name"
                    sx={{
                        "& .MuiInputBase-input": { fontSize: '12px' }, // Sets font size of the input text
                "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' }, // Adjust color as needed
                        mb: 0.625
                    }}
                    />
            </div>
            <div className='dropping-prosp-val'>
            <TextField
            value={brand}
            onChange={handleBrandChange}
            className='mb-5 mt-5 width-200px'
                    variant="standard"
                    label="Brand Name"
                    placeholder="Brand Name"
                    sx={{
                        "& .MuiInputBase-input": { fontSize: '12px' }, // Sets font size of the input text
                "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' }, // Adjust color as needed
                        mb: 0.625
                    }}
                    />
            </div>
            <Autocomplete
                id="status-standard"
                options={status}
                freeSolo
                getOptionLabel={(option) => option}
                value={selectedStatus}
                onInputChange={(event, newValue) => {setSelectedStatus(newValue)}}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                    // style={{  color: 'white' }}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Select Store Status"
                    placeholder="Current Status"
                    sx={{
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 0.625
                    }}
                    />
                )}
            />
            <Autocomplete
                id="tags-standard"
                options={tags}
                multiple
                freeSolo
                getOptionLabel={(option) => option}
                value={selectedTags}
                onChange={selectingTag}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="standard"
                    label="Select Store Tags"
                    placeholder="Current Tags"
                    sx={{ "& input": { fontSize: '12px' },
                    "& .MuiInputLabel-root": { fontSize: '12px' },
                    mt: 0.625,
                    mb: 2
                }}  

                    />
                )}
            />
            <div className="dropping-tot"> 
                <div id='droppingcheck-confirm' onClick={confirmationprospect}>✓ Confirm</div>
                <div id='droppingcheck-delete' onClick={deleteProspect}>X Delete</div>
            </div>
        </div>
    );
}

export default PopupContent;
