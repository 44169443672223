import React from 'react';
import ReactDOM from 'react-dom';
import '../../Login.css';
import maptize from '../Images/maptize-black.png';
import axios from 'axios';
import background_image from '../Images/background_image.png'
import ReCAPTCHA from "react-google-recaptcha";

export class Passwordreset extends React.Component{
    constructor(props) {
      super(props);
      this.state= {
          password : '',
          email: '',
          classes_email: '',
          classes_pass: '',
          token: null,
          Errors: null,
          sent: false,
          loading: false,
          
      }
       
      
    }

    
   async validationnumber(e){
        e.preventDefault();
        // console.log(this.state.email);
        if(this.state.loading) return false;
        if(!this.state.token) return this.setState({Errors: "Please complete Captcha", loading: false});
        try{
            this.setState({loading: true})
            let response = await axios.post('/signin/resetemail', {
                token: this.state.token,
                email: this.state.email,
            })
            if(response.status == 200 || response.status == 201) return this.setState({sent: true, loading: false})
            }
            catch(ee){
                
               if(ee.response.status ==400 || ee.response.status ==401) return this.setState({Errors: ee.response.data, loading: false})
               this.setState({Errors: 'Error while trying to send reset email. Please contact support.', loading: false})
            }
            this.setState({loading: false})
        
    }
    handleEmailChange(e) {
        this.setState({email: e.target.value, classes_email: 'has-val'});
     }
     captcha =(e) => this.setState({token: e})


    //  toppos(){
    //      document.getElementById("usern").style.top = "-5px"
    //  }
    //  toppos2(){
    //     document.getElementById("passw").style.top = "-5px"
    // }
    render() {

        return (
        <div className="login-page" >
            <img src={background_image} className="login-image"></img>
            <div className="limiter">
		        <div className="container-login100">
                    
			        <div className="wrap-login100 p-t-85 p-b-20">
				    <form className="login100-form validate-form" onSubmit={this.validationnumber.bind(this)}>
					
					<span className="login100-form-avatar">
						<img src={maptize} alt="AVATAR"/>
					</span>

                    <div className="login-logo-login">Reset Password</div>
                    {(!this.state.sent)?<div>
                    {(this.state.Errors != null)? <div className= "error-logger">{this.state.Errors}</div>:null}
					<div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "Enter Email">
						<input className={"input100 "+ this.state.classes_email} type="text" name="username" value={this.state.email} onChange={this.handleEmailChange.bind(this)} ></input>
						<span className="focus-input100" data-placeholder="Email" id="usern"></span>
					</div>
                    <div className='mt-10'>
                    <ReCAPTCHA sitekey="6LdY69UZAAAAAMVP_K9AVLSOOvk6Y6wNl75Q7lO-"     onChange={this.captcha}/>
                    </div>
                    <div className="login-forgotpassword">
							<span className="txt1">
								Go back to 
							</span>
							<a href="/login" className="txt2">
								 Login
							</a></div>
                        <div className="container-login100-form-btn">
						<button className="login100-form-btn">
							RESET
						</button>
                        
					    </div>
                        </div>
                        : <div>To reset your password, please follow the instructions in the email sent to you.</div>
                        }   
                        </form>
                    </div>
                </div>
            </div>
        </div>
            )
        }

}