import React, { useState, useEffect, useCallback } from 'react';
import { Doughnut, Pie, Bar, Line } from 'react-chartjs-2';

const Competitor_demographic_local = ({business_data}) => {
    // console.log('props: ', business_data)
    const calculatePositionalAverage = (arrays, sums_ind) => {
      if (!Array.isArray(arrays) || arrays.length === 0) {
          return [];
      }

      // Initialize sums and counts arrays
      let sums = [];
      let counts = [];

      // Loop through each array
      arrays.forEach(array => {
          array.forEach((value, index) => {
              if (sums[index] === undefined) {
                  sums[index] = 0;
                  counts[index] = 0;
              }
              sums[index] += value;
              counts[index]++;
          });
      });
      if(sums_ind) return sums;

      // Calculate average for each position
      const averages = sums.map((sum, index) => Math.round(sum / counts[index]));
      return averages;
  }

    const calculateObjectAverages = (array) => {
      if (array.length === 0) return {};
    
      const result = {};
      const count = {};
    
      for (const obj of array) {
        for (const [key, value] of Object.entries(obj)) {
          if (typeof value === 'number') {
            result[key] = (result[key] || 0) + value;
            count[key] = (count[key] || 0) + 1;
          }
        }
      }
    
      for (const key in result) {
        result[key] = Math.round(result[key] / count[key]);
      }
    
      return result;
    }
    const degree_total = () => {
        let degree = calculatePositionalAverage(business_data.map((e) => Object.values(e.degree)))
        // console.log('degree: ', degree)
        const label = business_data.length > 0 ? Object.keys(business_data[0].degree) : []
        let total = degree.reduce((a, b) => a + b, 0)
        degree = degree.map((e) => Math.round((e * 100) / total)/100)
        return {
          labels: label,
              datasets: [
                {
                  
                  backgroundColor: [
                    'rgba(0,85,139, .5)',
                    'rgba(139,54,0, .5)',
                    'rgba(0,139,54, .5)',
                    'rgba(0,38,62,0.6)',
                    'rgba(255, 153, 51,0.5)',
                    'rgba(216,222,225, 0.5)',
                    'rgba(158,127,146, 0.5)',
                    'rbga(127,158,139, 0.5)',
                    'rgba(185,192,173,.5)',
                    'rgba(159,130,148,.5)',
                    
                  ],
                  hoverBackgroundColor: [
                  'rgba(0,85,139, 1)',
                  'rgba(139,54,0, 1)',
                  'rgba(0,139,54, 1)',
                  'rgba(0,38,62,1)',
                  'rgba(255, 153, 51,1)',
                  'rgba(216,222,225, 1)',
                  'rgba(158,127,146, 1)',
                  'rbga(127,158,139,1)',
                  'rgba(185,192,173,1)',
                  'rgba(159,130,148,1)',
                  
                  ],
                  data: degree
                }
              ]}
    }

    const age_income_overall_data = () => {
        const business_data_filtered = business_data.filter((e) => e?.age_income_overall)
        const age_income_overall_prev = business_data_filtered.length > 0  ? business_data_filtered.map((e) => Object.values(e.age_income_overall[2012])) : [] ;
        const age_income_overall_current = business_data_filtered.length > 0 ? business_data_filtered.map((e) => Object.values(e.age_income_overall[2018])) : [];
        return{
        labels: ['Under 25', '25 - 44', '44 - 64', '64 & Over'],
          datasets: [
            {
              label: "Previous Period",
              
              backgroundColor: 'rgba(255, 153, 51,0.5)',
              borderColor: 'rgba(255, 153, 51,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(255, 153, 51,0.7)',
              hoverBorderColor: 'rgba(255, 153, 51,1)',
              data: calculatePositionalAverage(age_income_overall_prev)
            },
            {
              label: "Current Period",
              
              backgroundColor: 'rgba(0,38,62,0.5)',
              borderColor: 'rgba(0,38,62,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(0,38,62,0.7)',
              hoverBorderColor: 'rgba(0,38,62,1)',
              data: calculatePositionalAverage(age_income_overall_current)
            },
          ]
        };
        
    }
    const age_income_data = () => {
      return {
        labels: ['$0 - 25', '$25 - 45', '$45 - 60', '$60 - 75', '$75 - 100', '$100 - 125', '$125 - 150', '$150 - 200', '$200+'],
        datasets: [
          {
            label: "Previous Period",
            
            backgroundColor: 'rgba(255, 153, 51,0.5)',
            borderColor: 'rgba(255, 153, 51,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 153, 51,0.7)',
            hoverBorderColor: 'rgba(255, 153, 51,1)',
            data: calculatePositionalAverage(business_data.map((e) => e.age_income_prev), true)
          },
          {
            label: 'Current Period',
            
            backgroundColor: 'rgba(0,38,62,0.5)',
            borderColor: 'rgba(0,38,62,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(0,38,62,0.7)',
            hoverBorderColor: 'rgba(0,38,62,1)',
            data: calculatePositionalAverage(business_data.map((e) => e.age_income), true)
          },
        ]
      };
    }

    const family_income_barchart = () => {
      var income_past = [];
      var income_current = [];
      const business_data_filtered = business_data.filter((e) => e?.family_income)

      if(business_data.length > 0)
      {
        income_current = calculatePositionalAverage(business_data_filtered.map((e) => Object.values(e.family_income[2018])))
        income_past = calculatePositionalAverage(business_data_filtered.map((e) => Object.values(e.family_income[2012])))
        
      }
      return {
        labels: ['$0 - 25', '$25 - 45', '$45 - 60', '$60 - 75', '$75 - 100', '$100 - 125', '$125 - 150', '$150 - 200', '$200+'],
        datasets: [
          {
            label: "Previous Period",
            
            backgroundColor: 'rgba(255, 153, 51,0.5)',
            borderColor: 'rgba(255, 153, 51,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 153, 51,0.7)',
            hoverBorderColor: 'rgba(255, 153, 51,1)',
            data: income_past
          },
          {
            label: 'Current Period',
            backgroundColor: 'rgba(0,38,62,0.5)',
            borderColor: 'rgba(0,38,62,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(0,38,62,0.7)',
            hoverBorderColor: 'rgba(0,38,62,1)',
            data: income_current
          },
        ]
      };
    }
    const bar_options = () => {
      return {
        scales: {
          x: {
            title: {
              display: true,
              text: '$1000 Increments'
            }
          }
        }
      }
    }
    
    const Horizontalbar_options = (val) => {
      const options = {
        scales: {
          y: {        
            ticks: {
            callback: function(value) {
              // Multiply by 1000 and format with commas for thousands
              return '$' + (value).toLocaleString();
            }
          },
            title: {
              display: true,
              text: val
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                // Assuming context.parsed.y is the correct value for the y-axis
                if (context.parsed.y !== null) {
                  label += '$' + context.parsed.y.toLocaleString();
                }
                return label;
              }
            }
          }
        }
      };

    
      return options;
    }

    const Horizontalbar_options_targeted = (val, tr) => {
      const options = {
        scales: {
          y: {
            title: {
              display: true,
              text: val
            }
          },
          x: {
            title: {
              display: true,
              text: "$1000 Increments"
            }
          }
        },
        
      };
    
      return options;
    }

    const piecharstate = () => {
      const business_data_filtered = business_data.filter((e) => e.occupation)
      let type = business_data_filtered.length > 0 ? business_data_filtered[0].occupation.map((e) => e.type) : [];
      let value = business_data_filtered.length > 0 ? business_data_filtered.map((b) => b.occupation.map((e) => e.value)) : [];
      // console.log('piecharstatevalue: ', value)
      value = calculatePositionalAverage(value)
      // console.log('piecharstate: ', value)
      let total = value.reduce((a, b) => a + b, 0)
      value = value.map((e) => Math.round((e * 100) / total)/100)

      return {
          labels: type,
          datasets: [
            {
              
              backgroundColor: [
                'rgba(0,38,62,0.6)',
                'rgba(255, 153, 51,0.5)',
                'rgba(216,222,225, 0.5)',
                'rgba(158,127,146, 0.5)',
                'rbga(127,158,139, 0.5)',
                'rgba(185,192,173,.5)',
                'rgba(159,130,148,.5)'
              ],
              hoverBackgroundColor: [
              'rgba(0,38,62,1)',
              'rgba(255, 153, 51,1)',
              'rgba(216,222,225, 1)',
              'rgba(158,127,146, 1)',
              'rbga(127,158,139,1)',
              'rgba(185,192,173,1)',
              'rgba(159,130,148,1)'
              ],
              data: value
            }
          ]
        }
    }
    const options = {
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              const value = context.parsed || 0;
              const total = context.dataset.data.reduce((acc, data) => acc + data, 0);
              const percentage = Math.round((value / total) * 100) + '%';
              return `${percentage}`;
            }
          }
        }
      }
    };


    return (
            <div className="Competitor-financials-overall2">
                <div className="Competitor-box-total height-500">
                <div className="Competitor-box-title Competitor-box-title2">Family Income</div>
                <div className="Competitor-box-line"></div>
                    <div className='ps-10'>
                      <Bar id="barchart-familyincome" data={family_income_barchart()} options={bar_options()} />
                    </div>
                </div>
                <div className="Competitor-box-total height-500">
                <div className="Competitor-box-title Competitor-box-title2">Income for Target Age Group</div>
                <div className="Competitor-box-line"></div>
                    <div className='ps-10'>
                      <Bar id="barchart-targetincomeage" data={age_income_data()}  options={Horizontalbar_options_targeted('Total target Age Group income')}/>
                    </div>
                </div>
                <div className="Competitor-box-total height-500">
                <div className="Competitor-box-title Competitor-box-title2">Income by Age Group</div>
                <div className="Competitor-box-line"></div>
                    <div className='ps-10'>
                    <Bar id="barchart-incomeage" data={age_income_overall_data()}  options={Horizontalbar_options('Avg. income by Age Group')}/>
                    </div>
                </div>
                <div className="Competitor-box-total height-500">
                <div className="Competitor-box-title Competitor-box-title2">Occupation Distribution</div>
                <div className="Competitor-box-line"></div>
                <div className='center-content polarchart-container-competitor'>
                  <Doughnut id="barchart-employee" data={piecharstate()} 
                    options={options} />
                  </div>
                </div>
                <div className="Competitor-box-total height-500">
                <div className="Competitor-box-title Competitor-box-title2">Education Distribution</div>
                <div className="Competitor-box-line"></div>
                    <div className='center-content polarchart-container-competitor'>
                      <Pie id="barchart-degree" data={degree_total()} options={options} />
                    </div>
                </div>

            </div>
    )
}
export default Competitor_demographic_local;