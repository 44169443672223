import React, {Component} from 'react';
import {Competitor_box} from './Competitor_box'
export class Competitor_financials extends React.Component{
    constructor(props) {
        super(props);
        this.state= {

        }
    }
    revenue_est(value){
        if(!value) return "No Data"
        if(value < 1000) return Math.round(value).toString() + "K"
        if(value < 1000000) return Math.round(value/10)/100 + "M"
        return Math.round(value/10000)/100 + "B"
    }
    normal_est(value){
        if(!value) return "No Data"
        if(value < 1000000) return Math.round(value/1000).toString() + "K"
        if(value < 1000000000) return Math.round(value/10000)/100 + "M"
        return Math.round(value/10000000)/100 + "B"
    }
    upgrade = ()=>{
        if(this.props.freetier)  window.location.href = "https://mapchise.com/pricing"
    }
    render() {
        return (
        <div className="Competitor-financials-overall" id="Competitor-financial-id">
            <div className="Competitor-financials-title">Estimate Overview <div className="Competitor-title-line"></div></div>
            <Competitor_box titleMain={"Revenue"}  title={"Revenue"} value={this.revenue_est(this.props.Est_revenue)}/>
            <Competitor_box titleMain={"Lease Cost"}  title={"Lease Cost"} value={this.normal_est(this.props.bl_cost)} freetier={this.props.freetier}/>
            <Competitor_box titleMain={(this.props.business_data) ? "Operating Expenses" : "Employee Cost"  }  title={(this.props.business_data) ? "Operating Expenses" :"Employee Cost"} value={this.normal_est(this.props.Employee_cost)} freetier={this.props.freetier}/>
            <div className="Competitor-financials-title">Estimate Per Store <div className="Competitor-title-line"></div></div>
            <Competitor_box  titleMain={"Avg. Revenue"} title={"Average Revenue"} value={this.revenue_est(this.props.avg_revenue)}/>
            <Competitor_box titleMain={"Avg. Lease Cost"} title={"Average Lease Cost"} value={this.normal_est(this.props.bl_cost_avg)} freetier={this.props.freetier}/>
            <Competitor_box titleMain={"Avg. Employee Cost"} title={"Average Employee Cost"} value={this.normal_est(this.props.Employee_cost_avg)} freetier={this.props.freetier}/>
            <div className="Competitor-Break" id="Competitor-map-id"></div>
            <div className="Competitor-financials-title">Available Locations <div className="Competitor-title-line"></div></div>
            <div id ={this.props.freetier?"mapboxlocked":"mapbox"}>
            {(this.props.freetier)?<div className="Competitor-box-lock2" onClick={this.upgrade}><div className="Competitor-box-locked fa fa-lock"></div></div>:null}

            </div>
        </div>
            )}
}