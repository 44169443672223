
import React from 'react';
import {Loader_sidebar} from './loader_sidebar'
import star from './Images/star.png';
import goldstar from './Images/goldstar.png';
import {Doughnut, Pie} from 'react-chartjs-2';
import { Infoeach } from './infoeach';

export class Piecharttotal extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        values: ''
    }
  this.arrangement_image =  this.arrangement_image.bind(this);
  this.piecharstate = this.piecharstate.bind(this);
  } 
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
arrangement_image(){
  if(this.props.zoom == 2){
    if(this.props.arrangestar)
      return(<img src={goldstar} onClick={() => this.props.unsetarrangement(this.props.arrangement)} className="starredvalues-long starredvalues"></img> )
        
    return(<img src={star} onClick={() => this.props.setarrangement(this.props.arrangement)} className="starredvalues-long starredvalues"></img> )
  }
}
piecharstate(){
    if(this.state.values == ''){
        let type = this.props.occupation.map((e) => e.type);
        let value = this.props.occupation.map((e) => e.value);
        let total = value.reduce((a, b) => a + b, 0)
        value = value.map((e) => Math.round((e * 100) / total)/100)
        return {
            labels: type,
            datasets: [
              {
                
                backgroundColor: [
                  'rgba(0,38,62,0.6)',
                  'rgba(255, 153, 51,0.5)',
                  'rgba(216,222,225, 0.5)',
                  'rgba(158,127,146, 0.5)',
                  'rbga(127,158,139, 0.5)',
                  'rgba(185,192,173,.5)',
                  'rgba(159,130,148,.5)'
                ],
                hoverBackgroundColor: [
                'rgba(0,38,62,1)',
                'rgba(255, 153, 51,1)',
                'rgba(216,222,225, 1)',
                'rgba(158,127,146, 1)',
                'rbga(127,158,139,1)',
                'rgba(185,192,173,1)',
                'rgba(159,130,148,1)'
                ],
                data: value
              }
            ]
          }
    }else{
        let temp = this.props.occupation.filter((e) => {
            if(e.type == this.state.values)
            return e
        })
        let type = temp[0].subs.map((e) => e.type)
        let value = temp[0].subs.map((e) => e.value)
        let total = value.reduce((a, b) => a + b, 0)
        value = value.map((e) => Math.round((e * 100) / total)/100)
        return {
            labels: type,
            datasets: [
              {
                
                backgroundColor: [
                  'rgba(0,38,62,0.6)',
                  'rgba(255, 153, 51,0.6)',
                  'rgba(216,222,225, 0.6)',
                  'rgba(158,127,146, 0.6)',
                  'rbga(127,158,139, 0.6)'
                ],
                hoverBackgroundColor: [
                'rgba(0,38,62,1)',
                'rgba(255, 153, 51,1)',
                'rgba(216,222,225, 1)',
                'rgba(158,127,146, 1)',
                'rbga(127,158,139,1)'
                ],
                data: value
              }
            ]
          }
    }
}
piechartgoback(){
    this.setState({values: ''})
}


render() {
  const     options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce((acc, data) => acc + data, 0);
            const percentage = Math.round((value / total) * 100) + '%';
            return `${percentage}`;
          }
        }
      }
    }
  }

  const options_doughnut = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.label || '';
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce((acc, data) => acc + data, 0);
            const percentage = Math.round((value / total) * 100) + '%';
            return `${percentage}`;
          }
        }
      },
      legend: {
        onHover: function(e) {
          if(e.target)
          e.target.style.cursor = 'pointer';
        }
      }
    },
    onHover: (event, chartElement) => {
      if (event.native) {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
      }
    },
    onClick: (event, elements) => {
      if (elements && elements.length > 0) {
        const index = elements[0].index;
        const label = this.piecharstate().labels[index];
        this.setState({values: label});
      }
    }
  };
      
    return (<div className="barchart-horizontal piechart-horizontal"><Infoeach margin={this.props.margin} description={this.props.description} filters={this.props.filters}/>
   <div className="boxdata-header">{this.props.top_text}</div>{this.arrangement_image()}
    {(this.props.type)?  
    <div>
    {(this.state.values != '')? <div className="box-price box-showmore" onClick={this.piechartgoback.bind(this)}><div id="box-goback">Back</div></div>: null}
    <div className="box-industry-piechart box-industry-type-pie box-industry-type">{(this.state.values == "")? <div>Occupation Distribution</div>: this.state.values}</div>
    {(this.state.values == '')?<Doughnut id="barchart-employee" data={this.piecharstate()} 
    options={options_doughnut} 
   
    />: <Pie id="barchart-employee" data={this.piecharstate()} options={options}/>}
    </div>
    : <Loader_sidebar/> }
    </div>);
      }
}