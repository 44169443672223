import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import mapboxgl, { Point } from 'mapbox-gl';
import axios from 'axios';
 import MainMappingComponent from './ui_components/MainMappingComponent';
 import {Sidebar_left} from './sidebar_leftreal';
 import Legend from './legend';
 import SettingsRightSide from './MainMapping/SettingsRightSide'
 import ReactDOMServer from "react-dom/server";
 import uuid from 'react-uuid';
 import './index.css';
 import Joyride from 'react-joyride';
 import steps from './category_selector/Tour_map.json';
 import turf from '@turf/area'
import turfpolygon from '@turf/boolean-contains'
import turfintersect from '@turf/intersect'
import clustersDbscan from '@turf/clusters-dbscan'
import centroid from '@turf/centroid';
import { featureCollection } from '@turf/helpers';
import {Psychographic_map} from './Psychographic_map';
import {PopUpMessages} from './PopUp_Survey/PopUpMessages';
import Places from './Places';
import CompetitorSidebar from './ui_components/CompetitorsSidebar';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { confirmAlert } from 'react-confirm-alert';
import PopupContent from './ui_components/popupContent';
import PlaceClusterChart from './ui_components/PlaceClusterChart'
import MarkerPinPopUp from './ui_components/MarkerPinPopUp'
import emptyResponse from './json/empty_response.json'
import pako from 'pako';

 const CancelToken = axios.CancelToken;
//  const MapboxTraffic = require('@mapbox/mapbox-gl-traffic');

 var map;
 var draw;
 var WAIT_INTERVAL = 3000;
 const tract_threshold = 5010000000;
export class MainMapping extends React.Component{

    // mapRef = React.createRef()
    
    constructor(props) {
      super(props);

        this.state= {
            map: null,
            locationFilters: [],
            locationMarkers: [],
            showLocationMarkers: true,
            checkStatusExpanded: {},
            getlocations: [],
            cluster_type: 0,
            turf_area: 0,
            marker_range: [],
            navigationPrimary: [],
            psychograph: {},
            housing_buy: {},
            family: {},
            family_estincome: {},
            expenses2012: {},
            expenses2018: {},
            expenses_diff: {},
            housing_rent: [],
            total_age_sex: {},
            family_income: {},
            age_income_overall: {},
            degree: {},
            age_income_prev: {},
            age_income: {},
            occupation: [],
            showsearching: 'none',
            searchingfor: [],
            prospectnames: [],
            shoppingcenter_data: [],
            shoppingcenter_county: [],
            shop_array: [],
            local_id: [],
            Tract_id: [],
            Municipality_id: [],
            municipality: [],
            building_each: [],
            competitor_list: [],
            competitor_list_fss: [],
            Competitor_type: [],
            showsearch_value: true,
            Tract_polygon: [],
            showTour: false,
            building_model: true,
            commercial_data: {loading: true},
            setgeo: true,
            competitor_list: [],
            State_id: [],
            State_polygon: [],
            getfssfind_id: [],
            sidebarshow: true,
            sidebar_changed: false,
            circle_range_geojson: [],
            zoom: 15,
            feedback: {},
            County_id: [],
            SourceCancel: null,
            places_category: {}, 
            places_subCategory: {},
            places_image_list: [],
            places_custom_records: {},
            competitorCategory: {},
            placeSearch: true,
            customBrandSearch: [],
            streetView: false

        }
        
        
      }

    componentDidMount(){
    this.getstarted();
    this.setgeo("State")
    }
    errorinresponse(response){
      let token = localStorage.getItem("auth-token");
      if(response?.response?.data === '/setup') return window.location.href = "/setup";
      if(response.response.status === 401) return window.location.href = "/account";
       
       if(response.response.status === 400) 
       {
         localStorage.removeItem("auth-token")
          return true
         //  window.location.replace("/login");
        }
    }
  searchingfordata = (value, state) => {
    // console.log('searchingfordata: ', value, state, this.state.searchingfor);

    this.setState(prevState => {
      let searchingfor;

      if (state) {
        // Add the value if it's not already in the array
        if (!prevState.searchingfor.includes(value)) {
          searchingfor = [...prevState.searchingfor, value];
          return { searchingfor, showsearching: "block", showsearch: true };
        }
      } else {
        // Remove the value
        searchingfor = prevState.searchingfor.filter(e => e !== value);
        // console.log('searchingfor: ', searchingfor, prevState.searchingfor);

        if (searchingfor.length === 0) {
          return { searchingfor: [], showsearching: "none", showsearch: false };
        } else {
          return { searchingfor };
        }
      }

      // If there's no change, return null to indicate no update is needed
      return null;
    });
  };

    // TO FIND PERCENT OF OVERLAP
  getGEOJSON =  async(bbox, geometry) => {
      geometry = geometry.map((value) => {
          let geo = value.geometry
          let geoarea = Math.round(turf(geo))
          let inter = turfintersect(bbox, geo )
          if(!inter) return ({GEOID: value.GEOID, multiplier: .01})
          let interarea = Math.round(turf(inter))
          if(interarea === geoarea){
              
              return ({GEOID: value.GEOID, multiplier: 1})
          }
          let multiplier = Math.round((interarea / geoarea) * 100) / 100
          multiplier = multiplier === 0 ? .01 : multiplier;
          return ({GEOID: value.GEOID, multiplier})
        })
      return geometry
  } 
  // FINDING OVERLAPPING BOUNDING BOX AND STATE / COUNTY / TRACT data
  setgeo_filterresponse(responsedata, bbox){
      if(!bbox.coordinates[0]) return [];
      return  responsedata.filter((e) => { 
        let geo = e.geometry
        /** TURF JS REQUIRES SAME TYPE *POLYGON **/
        if(geo.type ==="MultiPolygon"){
          for(let g in geo.coordinates){
            let temp_geo = {type: "Polygon", coordinates: geo.coordinates[g]}
            if(turfintersect(temp_geo, bbox)) return true
            if(turfpolygon(temp_geo, bbox)) return true
          }
          return false
        }

        if(turfpolygon(geo, bbox)) return true
        if(turfintersect(geo, bbox)) return true
        return false
      })
  }

  setgeo_tractfind = (responsedata, County_id, GEO_multiplyer) =>{
    // let bbox = {type: "Polygon", coordinates: [this.state.boundingbox]}
    // let Tract = this.setgeo_filterresponse(responsedata, bbox)
    let Tract = responsedata
    let Tract_id = Tract.map((t) => t.properties.GEOID)
    let center = map.getCenter();

    if(this.state.places) this.places(Tract_id, this.state.turf_area)

    if(!Tract_id.every(element => this.state.Tract_id.includes(element))){

      if(this.state.turf_area < tract_threshold / 2){
        if(this.state.cluster) this.circles(Tract_id, true)
        if(this.state.building) this.getbuildings(Tract_id)
      }
      if(this.state.fss) this.getfssfind(Tract_id)

      if(this.state.zipcode_tabulation) this.zipcode_tabulation({Tract: Tract_id, County_id})


      if(this.state.fss_main) this.getfss(center.lat, center.lng)
    }
    if(this.state.traffic) this.getTrafficLayer({Tract_id, County_id}, 'Tract')
      // if(this.state.datalayer && Tract_id.length < 20) this.circles(Tract_id, false)
      // let cent = map.getCenter()

    this.setState({ Tract_id, setgeo: true}, async () =>{
      if(this.state.sidebarshow){ 
        if(this.state.datalayer) await this.age_income_total_req({Tract: Tract_id, County_id, municipality: []}, GEO_multiplyer)

        this.loadindata(center.lat, center.lng, true);
      }else{ 
        if(this.state.datalayer) this.age_income_total_req({Tract: Tract_id, County_id, municipality: []}, GEO_multiplyer)
        this.setState({sidebar_changed: true}) 
      }
    })
  }

  setgeo_tract = async(County_id) =>{
      let token = localStorage.getItem("auth-token");
      County_id.sort()
      if(!map) return;
      let center =  map.getCenter()
      let bound = map.getBounds();
      var bounds  = [
        [ bound.getNorthEast().lng, bound.getNorthEast().lat], 
        [ bound.getNorthWest().lng, bound.getNorthWest().lat], 
        [ bound.getSouthWest().lng, bound.getSouthWest().lat],
        [ bound.getSouthEast().lng, bound.getSouthEast().lat], 
        [ bound.getNorthEast().lng, bound.getNorthEast().lat],  ]
      let response = await axios.post('/gettheurl/frontend', {type: "Tract", center:  [center.lat, center.lng], state_county: County_id, bounds}, {headers:{ "auth-token": token}})
      let responsedata = await response.data;
      let GEO_multiplyer = await this.getGEOJSON({type: "Polygon", coordinates: [bounds]}, responsedata.map((e) => { return{GEOID: e.properties.GEOID, geometry: e.geometry}}))
      this.setState({Tract_polygon: responsedata, County_id, GEO_multiplyer}, () =>       this.setgeo_tractfind(responsedata, County_id, GEO_multiplyer))
    }

    setgeo_countyfind = async (responsedata) =>{
      let bbox = {type: "Polygon", coordinates: [this.state.boundingbox]}
      // console.log('setgeo_countyfind: ', responsedata)
      let County = this.setgeo_filterresponse(responsedata, bbox)
      let County_id  = County.map((Count) => Count.properties.STATE + Count.properties.COUNTY)
      if(this.state.shoppingcenter)this.shoppingcenter(County_id)
      if(this.state.customBrandSearch.length) this.getCustomBrandSearch(this.state.customBrandSearch, County_id)

      if(County.length < 5 || this.state.turf_area < tract_threshold){
        this.setgeo_tract(County_id)
        this.setState({County_polygon: responsedata, County, County_id})

      }else{
        const County_id_geoid = County.map((e) => e.properties.GEO_ID)
        if(this.state.traffic) this.getTrafficLayer({County_id: County_id_geoid}, 'County')

        let GEO_multiplyer = await this.getGEOJSON(bbox, County.map((e) => { return{GEOID: e.properties.GEO_ID, geometry: e.geometry}}))
        if(this.state.datalayer) this.age_income_total_req({Tract: [], County_id, municipality: []}, GEO_multiplyer)
        if(this.state.zipcode_tabulation && County_id.length < 6) this.zipcode_tabulation({County_id, Tract: []})
        let center = map.getCenter();
        this.setState({ Tract_polygon: [], Tract_id: [], setgeo: true, GEO_multiplyer, County_polygon: responsedata, County, County_id}, () =>
        {
          if(this.state.sidebarshow){ 
          this.loadindata(center.lat, center.lng, true);
          }else{ 
            this.setState({sidebar_changed: true}) 
          }
        })
      }
    }
  setgeo_county = async(State) => {
    let token = localStorage.getItem("auth-token");

    let response = await axios.post('/gettheurl/frontend', {type: "County", State}, {headers:{ "auth-token": token}})
    let responsedata = await response.data;
    this.setgeo_countyfind(responsedata)
  }
  setgeo_state = async (responsedata) => {
    this.setState({State_polygon: responsedata}, ()=>{
      let bbox = {type: "Polygon", coordinates: [this.state.boundingbox]}
      if(!this.state.boundingbox) return this.setState({setgeo: true})
      let State = this.setgeo_filterresponse(responsedata, bbox)
      // console.log('State: ', State)
      if(State.length === 0){
        // IF INTERNATIONAL THEN CALCULATE INTERNALTIONAL
        this.getMunicipality()
        return this.setState({setgeo: true})
      }else{
        this.setState({municipality_data: null, municipality: [], Municipality_id: []})
      } 

      function arraysEqual(a1,a2) {
        return JSON.stringify(a1)==JSON.stringify(a2);
      }
      let State_id = State.map((e) => e.properties.STATE)
      State_id.sort()
      // IF zoom isn't state level
      if(this.state.zoom > 6){
        // If state has changed then use the state array to make a request to find counties
        if(arraysEqual(State_id, this.state.State_id) && this.state.County_polygon){
          this.setgeo_countyfind(this.state.County_polygon)
        }else{
          this.setgeo_county(State_id)
        }
      }else{
        this.setState({setgeo: true})
      }
      this.setState({State, State_id})
    })
  }

  getMunicipality = async () => {
    let token = localStorage.getItem("auth-token");
    let bound = map.getBounds();
    var bounds  = [
      [ bound.getNorthEast().lng, bound.getNorthEast().lat], 
      [ bound.getNorthWest().lng, bound.getNorthWest().lat], 
      [ bound.getSouthWest().lng, bound.getSouthWest().lat],
      [ bound.getSouthEast().lng, bound.getSouthEast().lat], 
      [ bound.getNorthEast().lng, bound.getNorthEast().lat],  ]

    let response = await axios.post('/gettheurl/frontend', {type: "Municipality", bounds}, {headers:{ "auth-token": token}})
    let responsedata = await response.data;
    const Municipality_id = responsedata.map((e) => e.properties.GEOID)

    const Municipality_values = responsedata.map((e) => { return{GEOID: e.properties.GEOID, geometry: e.geometry}});
    if(this.state.traffic) this.getTrafficLayer({Municipality_id}, 'Municipality')

    let GEO_multiplyer = await this.getGEOJSON({type: "Polygon", coordinates: [bounds]}, Municipality_values)

    if(this.state.datalayer) this.age_income_total_req({Tract: [], County_id: [], municipality: Municipality_id}, GEO_multiplyer)

    if(!Municipality_id.every(element => this.state.Municipality_id.includes(element))){
      
      if(this.state.shoppingcenter)this.shoppingcenter(Municipality_id)
      
      if(this.state.customBrandSearch.length) this.getCustomBrandSearch(this.state.customBrandSearch, Municipality_id)
      if(this.state.turf_area < tract_threshold / 2){
        if(this.state.cluster) this.circles(Municipality_id, true)
        if(this.state.building) this.getbuildings(Municipality_id)
        if(this.state.places) this.places(Municipality_id, this.state.turf_area)

      }
      // if(this.state.zipcode_tabulation) this.zipcode_tabulation({Tract: Tract_id, County_id})
    }
    let center = map.getCenter();
    const municipality = {}
    responsedata.map((e) => municipality[e.properties.GEOID] = e.geometry)
    this.setState({ Tract_polygon: [], Tract_id: [], setgeo: true, GEO_multiplyer, municipality, Municipality_id}, () =>
    {
      if(this.state.sidebarshow){ 
        this.loadindata(center.lat, center.lng, true);
      }else{ 
        this.setState({sidebar_changed: true}) 
      }
    })
  }

  setgeo = async (type, State, County) => {
    let token = localStorage.getItem("auth-token");
    /** Loading_layer so it doesn't change view
     * setgeo =  it doesn't calculate while it is calculating
     * segeo_changedposition = if it is calculating and the screen moved so in loadindata it doesn't use old tract
     */
      if(this.state.loading_layer || !this.state.setgeo) return this.setState({ setgeo_changedposition: true})
      this.setState({setgeo: false, setgeo_changedposition: false})
      // console.log('this.state.State_polygon: ', this.state.State_polygon)
      if(type === "State" && this.state.State_polygon && this.state.State_polygon.length > 0) return this.setgeo_state(this.state.State_polygon)
      // If initial request then getting the state level data to get started
      let response = await axios.post('/gettheurl/frontend', {type, State, County}, {headers:{ "auth-token": token}})
      let responsedata = await response.data;
      // console.log('setgeo_responsedata: ', responsedata)
      if(type === "State") return this.setgeo_state(responsedata)

    
      
  }
  mapcenters_hide(turf_area){
    if(this.state.cluster){
      if(turf_area > tract_threshold){ 
        let temp_lay = map.getLayoutProperty("population-circles", 'visibility')
        if(!temp_lay || temp_lay === "visible")  map.setLayoutProperty("population-circles", 'visibility', 'none');
      }else{
        let temp_lay = map.getLayoutProperty("population-circles", 'visibility')
        if(!temp_lay || temp_lay === "none") map.setLayoutProperty("population-circles", 'visibility', 'visible');
      }
    }
  }
  resetlatlong = () =>{
    let numbs = {12: .01,  13: .0005, 14: .0009, 15: .0009, other: .00015}
    let zoom = map.getZoom();
    let center = map.getCenter();
    var zoomlevel = 0;
    let dis = true;
    let bound = map.getBounds();
    var bounds2  = [
      [ bound.getNorthEast().lng, bound.getNorthEast().lat], 
      [ bound.getNorthWest().lng, bound.getNorthWest().lat], 
      [ bound.getSouthWest().lng, bound.getSouthWest().lat],
      [ bound.getSouthEast().lng, bound.getSouthEast().lat], 
      [ bound.getNorthEast().lng, bound.getNorthEast().lat],  ]
    let turf_area = turf({type: "Polygon", coordinates: [bounds2]})
    turf_area = Math.round(turf_area)
    const placeSearch = turf_area <= tract_threshold
    // GETTING TO HIDE LAYER IF BIGGER THAN 21MILLION LENGTH
    if(map.getSource("population-circles")) this.mapcenters_hide(turf_area)
    // REMOVING TIMEOUT FOR REQUEST
    clearTimeout(this.geotimer);
    let showsearch = false;

    if(zoom > 6){
      if(this.state.refreshing_auto){
      clearTimeout(this.timer);
      zoomlevel = numbs.other
      // this.timer = setTimeout(() => this.handleClick(), WAIT_INTERVAL);
      if(this.state.showsearching === "block") showsearch = true
      }
    }else{
      dis = false
      this.setState({showresult_red:false, showsearch: true})
    }
    
    // if(!dis)
      this.setState({zoom, turf_area, center: [center.lat, center.lng], boundingbox: bounds2, showsearch, showsearch_value: dis, noresults: false, placeSearch})
    // else
      // this.setState({zoom, showsearch: true,turf_area, boundingbox: bounds2, showsearch_value: true, noresults: false})
      this.geotimer = setTimeout(() => this.setgeo("State"), 1300);

  }
  startlatlong = () =>{
    clearTimeout(this.geotimer);
    if(this.state.SourceCancel)
    this.state.SourceCancel.cancel('Operation canceled by the user.');

    let zoom = map.getZoom();
    if(zoom > 6){
      clearTimeout(this.timer);
    }
  }

  getCurrentImages = () => {
    if(this.state.mapImagesLoaded) return;
    const base_url = '/map_images/'
    const image_list ={'shopping_center.png': 'shopping-center-icon', 'marker-automotive.png': 'places_automotive', 
                      'marker-business.png': 'places_business', 'marker-education.png': 'places_education',
                      'marker-entertainment.png': 'places_entertainment', 'marker-finance.png': 'places_finance',
                      'marker-food.png': 'places_food', 'marker-hotel.png': 'places_hotel',
                      'marker-Medical.png': 'places_medical', 'marker-Rental.png': 'places_rental',
                      'marker-Service.png': 'places_Service', 'marker-Shop.png': 'places_shop',
                      'marker-Supermarket.png': 'places_supermarket', 'marker-other.png': 'places-other', 
                      'marker-Competitor.png': 'places-competitor'
    }
    Object.entries(image_list).forEach(([link, image_name]) => {
      if (!map.hasImage(image_name)) {
        map.loadImage(`${base_url}${link}`, (error, image) => {
            if (error) throw error;
            map.addImage(image_name, image);
        });
      }
    })
    this.setState({mapImagesLoaded: true})
  }

  async getstarted(){
      let token = localStorage.getItem("auth-token");

      let responsedata = null;
      let unregistered = false;
      let customBrandSearch = [];
      try{
      let response = await axios.post('/map/getstarted', {}, {headers:{ "auth-token": token}})
      responsedata = await response.data;
      }catch(response){
        unregistered = this.errorinresponse(response)
      }
      try{
        const placescustom = localStorage.getItem('placescustom');
        if(placescustom){
          const placescustomJSON = JSON.parse(placescustom);
          if(placescustomJSON.length > 0){
            customBrandSearch = placescustomJSON
          }
        }
      }catch(e){
        console.log('placesCustom Error: ', e)
      }
      // if(responsedata == null) 
      // window.location.replace("/setup");
      
      let responsedata_co = responsedata.coordinates;
      let zoom = 15;
      if(responsedata_co){
      responsedata_co = [responsedata_co[1], responsedata_co[0]]
      }else{
        responsedata_co = [-94.5786, 39.0997]
        zoom = 4
      }
      let freetier = responsedata.freetier;
      let feedback = responsedata.feedback;
      let cluster_type = localStorage.getItem("cluster_type");
      cluster_type = (cluster_type != null && !freetier)? cluster_type: 0;
      let feedback_show = false;
      if(!responsedata.unregistered)
      Object.keys(feedback).map((e) => feedback[e] ? feedback_show = true: null);
      let gender = (responsedata.cat_call.gender.length != 0 &&  responsedata.cat_call.gender[0].label != "No Preference")? responsedata.cat_call.gender[0].label: null;
      let race = (responsedata.cat_call.race.length != 0 )? responsedata.cat_call.race.map((e) => e.label): [];
      race = race.join(', ');
      let age = (responsedata.cat_call.age_group.length != 0 )? responsedata.cat_call.age_group.map((e) => e.label): [];
      age = age.join(', ');
      let income = (responsedata.cat_call.income.length != 0 )? responsedata.cat_call.income.map((e) => e.label): [];
      income = income.join(', ');
      let Competitor_type = responsedata.cat_call.occupation
      const brand = responsedata.cat_call.brand ? responsedata.cat_call.brand : '';
      let res_total = {income, age, race, gender, brand}
      let showTour = (responsedata.showTour)?true:false;
      let refreshing_auto = (responsedata.refreshing_auto)?true:false;
      let competitorCategory = []

      // console.log('cats: ', responsedata.cat_call.subcategories)
      responsedata.cat_call?.subcategories.forEach((e) => {
        if(!e.value) return;
        let value = e.value.split(' > ')
        
        if(!competitorCategory[value[0]]){
          competitorCategory[value[0]] = [value[value.length -1]]
        }else{
          competitorCategory[value[0]].push(value[value.length -1])
        }
      })
      let competitorSubcategory = Object.values(competitorCategory).flat();
      const competitorChangeInd = competitorSubcategory.find((e) => this.state.places_subCategory[e])
      const competitorSearch = competitorChangeInd ? true : false;
      console.log('cats: ', competitorCategory)
      mapboxgl.accessToken = 'pk.eyJ1Ijoic25pa2FlaW4iLCJhIjoiY2s1eWNpcWxrMDJvbzNtcDdyb3puNTVrMCJ9.t-VFWsbpRo--tRSLgLa9ng';
        map = new mapboxgl.Map({
          container: 'mapbox-main',
          style: 'mapbox://styles/mapbox/streets-v11',
        // style:"mapbox://styles/snikaein/ck5yl4xsd0q2i1iqknl746gvh",
          center: responsedata_co,
          antialias: true,
          pitch: (this.state.building_model) ? 45:0,

          zoom
      });
      map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    this.getCurrentLocations()
    this.getCurrentImages();
    let mapcenter = map.getCenter()
    let bound = map.getBounds();
    var bounds2  = [
      [ bound.getNorthEast().lng, bound.getNorthEast().lat], 
      [ bound.getNorthWest().lng, bound.getNorthWest().lat], 
      [ bound.getSouthWest().lng, bound.getSouthWest().lat],
      [ bound.getSouthEast().lng, bound.getSouthEast().lat], 
      [ bound.getNorthEast().lng, bound.getNorthEast().lat],  ]
    if(responsedata.freeTrialOneTime) this.emailconfirmation()
    this.setState({ customBrandSearch, competitorSearch, competitorSubcategory, competitorCategory, unregistered: responsedata.unregistered, Competitor_type, feedback, boundingbox: bounds2, center: [mapcenter.lat, mapcenter.lng], refreshing_auto, showTour,cluster_type, properties_number: responsedata.number,  cat_all: res_total, freetier});
    map.on('moveend', this.resetlatlong);
    map.on('movestart', this.startlatlong);
    this.circle_rance_onclick()
  
    }


  sidebarUpdateLocationArea = async (name, geometry=null) => {
    // NAME AND ADDRESS CHANGES ONLY SO CALLING A REFRESH ON LOCATIONS
    if(!geometry) return this.getCurrentLocations()

    this.searchingfordata("sidebarupdate", true)
    
    await this.getCurrentLocations(name)
    this.searchingfordata("sidebarupdate", false)
    
  }
  customUpdateLocationArea = async (e) =>{
      console.log('e: ', e)
      if(e?.features.length > 0){
        let geo = e.features[0].geometry
        var data_circle = await this.getlocationrange(geo.coordinates[0])
        var token = localStorage.getItem("auth-token");
        let reval = await axios.post('/gettheurl/distance/new', {va: this.state.prospect_clicked, data_circle: data_circle, circle: geo, bounds:[]}, {headers:{ "auth-token": token}});
        let name = this.state.prospect_clicked;
        let circle_range_geojson = this.state.circle_range_geojson
        circle_range_geojson = circle_range_geojson.filter((e) => e.properties.name !== name)
        circle_range_geojson.push({"type": "Feature", geometry: geo, properties:{name: name, data_circle: data_circle, color: this.state.circle_range[name].color}})
        this.circle_range_geojson_func(circle_range_geojson)
        if(map.getSource("selected_circle")){
          map.removeLayer('selected_circle')
          map.removeSource('selected_circle');
        }
        this.getCurrentLocations()
      }
      if (this.state.draw) {
        try{
          map.removeControl(this.state.draw)
          
        }catch(e){
          console.log('error: ', e)
        }
        // map.removeControl(this.state.draw);
        this.setState({ draw: null, drawtooltip: false });
      }
  }
  
  getCurrentLocationsVal = (type) => {
      var draw = null
      if(!this.state.drawtooltip){
      draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
        polygon: true,
        point: true,
        trash: true
        }
        });
        map.addControl(draw, 'top-left');
        map.on('draw.create', this.customUpdateLocationArea);
        map.on('draw.delete', this.customUpdateLocationArea);
        map.on('draw.update', this.customUpdateLocationArea);

        this.setState({ draw, drawtooltip: true})
        document.querySelector(".mapbox-gl-draw_trash").onclick =  this.customUpdateLocationArea
      }else{
        draw = this.state.draw
      }

      type = (type === "Point")? "draw_point": "draw_polygon";
      const currentMode = this.state?.draw?.getMode();

      if(currentMode !== type){
        draw.changeMode(type)
      }

  }

  async getfss(lati, lon, loadingdata){
    let token = localStorage.getItem("auth-token");
    let response = await axios.post('/gettheurl/fss', {
      Latitude: lati,
      Longitude: lon,
      bounds: this.state.bounds,
      fss: true
      
    }, {headers:{ "auth-token": token}})
    response = await response.data;
    let loc_id = this.state.local_id;
    if( response.length > 0 ){ 
      var res_data = response.filter((e) => {
        if(!loc_id.includes(e.id))
        { 
          loc_id.push(e.id)
          return e
        }
      });
      var all_markers = []
    for(let bus in res_data) {
      let businesses = res_data[bus] 
      let temp_marker = await this.createmarkerhere(businesses, "#0000CD")
      all_markers.push(temp_marker)
      }
      if(loadingdata) this.setState({loadindata: false})
      let competitor_list_fss = this.state.competitor_list_fss.concat(all_markers)
      this.setState({competitor_list_fss, local_id: loc_id}); 

      
    }
  }

  async  createmarkerhere(businesses, color){
    let log = [];
    try{
    var logo = await axios.get('https://autocomplete.clearbit.com/v1/companies/suggest?query='+ encodeURI(businesses.title));
    log = await logo.data;
    }catch(e){
      console.log(e)
    }
    var logo_final_val = null;
    let openinghours = (businesses.openinghours)? businesses.openinghours.text : '';
    let tags = (businesses.tags)? businesses.tags.map((e) =>e.title).join(", "): '';

    if( log.length > 0 ){for(var l in log){if(log[l].name == businesses.title ){ logo_final_val =  log[l].logo; break;}}}
    let image = (logo_final_val)? "<img class='business-checker-image' src="+ logo_final_val + " />": "<div class='business-checker-image business-checker-single'></div>";

    let el = document.createElement('div');
    let logo_final = `<img src= ${logo_final_val} onerror= "this.style.display='none'" class='building-mapbox'></img>`;

    el.className = 'custom-div-icon';
    el.innerHTML += "<div style='background-color:"+ color +";' class='marker-pin'></div>" + logo_final;

    var popup = new mapboxgl.Popup().setHTML(image + "<div class='business-checker-name'>" + businesses.title + "</div>" +"<div class='business-checker-vicinity'>Vicinity: <div class='business-checker-vicinity-text'>" + businesses.vicinity + "</div><div class='business-checker-vicinity'>Category: <div class='business-checker-vicinity-text'>" + businesses.category + "</div></div><div class='business-checker-vicinity'>Open hours: <div class='business-checker-vicinity-text'>" + openinghours + "</div></div>"+"<div class='business-checker-vicinity' >Tags: <div class='business-checker-vicinity-text'>" + tags + "</div></div>")
    var marker = new mapboxgl.Marker(el).setLngLat([businesses.position[1], businesses.position[0]])
    .setPopup(popup) 
    .addTo(map);
    marker.id = businesses;
    return marker
  }
  
  createGeoJSONCircle = (center, radiusInKm, points) => {
      if(!points) points = 64;
      var coords = {
          latitude: center[1],
          longitude: center[0]
      };
      var km = radiusInKm;
      var ret = [];
      var distanceX = km/(111.320*Math.cos(coords.latitude*Math.PI/180));
      var distanceY = km/110.574;
      var theta, x, y;
      for(var i=0; i<points; i++) {
          theta = (i/points)*(2*Math.PI);
          x = distanceX*Math.cos(theta);
          y = distanceY*Math.sin(theta);
          ret.push([coords.longitude+x, coords.latitude+y]);
      }    
      ret.push(ret[0]);
      return {
          "type": "geojson",
          "data": {
              "type": "Feature",
                  "geometry": {
                      "type": "Polygon",
                      "coordinates": [ret]
                  }
          }
      };
  };
  places_switch = () =>{
    if(this.state.places){
      localStorage.setItem("places", "false")
      if(map.getSource("places")){
        // places-cluster-labels places-unclustered-point
        map.removeLayer("places-unclustered-point")
        map.removeLayer("places-cluster-labels")
        map.removeLayer("places-cluster-circles")
        map.removeLayer("places_each")
        map.removeSource("places_each")
        map.removeSource("places")
      }
      this.setState({places: false, cluster_records: {}, places_records: {}})
    }else{
      // console.log('places_switch_tracts: ', this.state.turf_area, this.state.Tract_id)
      if(this.state.turf_area <= tract_threshold && this.state.Tract_id.length > 0) this.places(this.state.Tract_id, this.state.turf_area)
      localStorage.setItem("places", "true")
      // map.setPaintProperty("places", 'fill-color', "#5a5a5a");

      this.setState({places: true})
    }
  }
  datalayer_switch = () => {
    if(this.state.datalayer){
      localStorage.setItem("datalayer", "false")
      if(map.getSource("polygon-color")){
        map.removeLayer("polygon-color")
        map.removeSource("polygon-color")
      }

      return this.setState({datalayer: false})
    }else{
      localStorage.setItem("datalayer", "true")
      let cent = map.getCenter()
      return this.setState({datalayer: true}, () => {     this.loadindata(cent.lat, cent.lng, true) })
    }
  }


  getTrafficLayer = async (list_values) => {
    this.searchingfordata("traffic", true)
    // console.log('list_values: ', list_values)
    const County_id = list_values.County_id ? list_values.County_id.map((e) => e.replace('0500000US', '')): [];
    const Tract_id = list_values.Tract_id ? list_values.Tract_id : [];
    const Municipality_id = list_values.Municipality_id ? list_values.Municipality_id : [];

    if(this.state.turf_area  > 20000000000) {
      this.searchingfordata("traffic", false)
      return this.setState({showresult_red: true})
    }

    let traffic_data = {...this.state.traffic_data}
    let county_list = County_id;
    let tract_list = Tract_id;
    let municipality_list = Municipality_id;
    // GETTING ONLY TRACTS/COUNTY IN VIEW
    if(traffic_data){
      county_list = County_id.filter((e) => !traffic_data[e])
      tract_list =  Tract_id.filter((e) => !traffic_data[e]);
      municipality_list =  Municipality_id.filter((e) => !traffic_data[e]);

      if(county_list.length == 0 && tract_list.length == 0 && municipality_list.length == 0) return this.searchingfordata("traffic", false);
      
    }

    // REMOVING TRACT/COUNTY THAT ARE NOT IN VIEW
    Object.keys(traffic_data).map((e) => {
      if(!County_id.includes(e) && !Tract_id.includes(e) && !Municipality_id.includes(e)){
        delete traffic_data[e]
      }
    })


    let token = localStorage.getItem("auth-token");
    if(this.state.SourceCancelTraffic) this.state.SourceCancelTraffic.cancel('Operation canceled by the user.');
    
    try{
      const SourceCancelTraffic = CancelToken.source();
      this.setState({SourceCancelTraffic})
      const getTrafficData = await axios.post('/gettheurl/traffic', 
      { list_values: {County_id: county_list, Tract_id: tract_list, Municipality_id: municipality_list}}, {headers:{ "auth-token": token}, cancelToken: SourceCancelTraffic.token}
      );
      const colors = {
        'motorway': '#00008B',
        'motorway_link': '#00008B',
        'trunk': '#0000CD',
        'trunk_link': '#0000CD',
        'primary': '#4169E1',   
        'primary_link': '#4169E1',       
        'secondary': '#6495ED', 
        'secondary_link': '#6495ED',     // SteelBlue
        'tertiary': '#87CEFA',
        'tertiary_link': '#87CEFA',
        // 'residential': '#ADD8E6',   // LightBlue
      }

    
      const widths = {
        'motorway': 4,
        'motorway_link': 4,
        'trunk': 3,
        'trunk_link': 3,
        'primary': 3,
        'primary_link': 3,
        'secondary': 2,
        'secondary_link': 2,
        'tertiary': 1,
        'tertiary_link': 1
        // 'residential': 1,
      }      
      const zipFiles = await getTrafficData.data;
      // console.log('zipFiles: ', zipFiles)
      zipFiles.map((value) => {
        // console.log('[key, value]: ', value)
        const compressed = new Uint8Array(atob(value.data).split('').map(char => char.charCodeAt(0)));
        const decompressed = pako.inflate(compressed, { to: 'string' });
        // console.log('decompressed: ', decompressed)
        let allTrafficData = JSON.parse(decompressed);
        const traffic_data_new = allTrafficData.map((e) => ({...e, properties: {color: colors[e.properties.highway], width: widths[e.properties.highway]}}))
        const key = value.Tract_id ? value.Tract_id : value.County_id ? value.County_id : value.m_id ;
        traffic_data[key] = traffic_data_new;
      })

      let traffic_data_source = []
      Object.keys(traffic_data).map((e) => traffic_data_source= [...traffic_data_source, ...traffic_data[e]])
      this.setState({traffic_data})
      // console.log('traffic_data: ', traffic_data)
      if(!this.state.traffic) return     this.searchingfordata("traffic", false);
      // console.log('traffic: ', traffic_data)
      if(map.getSource("traffic_range_geojson")){
        map.getSource("traffic_range_geojson").setData({
          'type': 'FeatureCollection',
          'features': traffic_data_source
        })

      }else{

        map.addLayer({
          id: 'traffic_range_geojson',
          type: 'line',
          source: {
            type: 'geojson',
            data: {
              'type': 'FeatureCollection',
              'features': traffic_data_source
            },
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': [
              'case',
              ['has', 'color'], 
              ['get', 'color'],
              'red' 
            ],
            'line-width': [
              'case',
              ['has', 'width'],
              ['get', 'width'],
              2  
            ]
          },
        });

    }
    }catch(e){
      console.log('getTrafficLayer_error: ', e)
    }
    this.searchingfordata("traffic", false)
  }

  places_hide = () => {

    if(this.state.places_show){
      console.log('places_hide')
      if(!Object.keys(this.state.places_subCategory).length){
        this.places_switch()
      }else{
        // if(this.state.turf_area <= tract_threshold && this.state.Tract_id.length > 0 && this.state.placeSubcategoryChanged) this.places(this.state.Tract_id, this.state.turf_area, true)
      }
      this.setState({places_show: false}) 

      // console.log(this.state.turf_area <= tract_threshold, this.state.Tract_id.length > 0, this.state.placeSubcategoryChanged)

    }

  }

  competitor_hide = () => {
    if(this.state.competitor_show){
      console.log('competitor_hide')
      if(!Object.keys(this.state.places_subCategory).length){
        this.places_switch()
      }else{
        // if(this.state.turf_area <= tract_threshold && this.state.Tract_id.length > 0 && this.state.placeSubcategoryChanged) this.places(this.state.Tract_id, this.state.turf_area, true)
      }
      this.setState({competitor_show: false})
    }
  }

  datalayer_hide = () => this.setState({datalayer_show: false}) 
  
  turnthison = (e) => {
    if(e === 'traffic'){
      localStorage.setItem(e, (!this.state.traffic).toString());

      if(this.state.traffic){
        this.setState({traffic: false, traffic_data: {}})
        if(map.getLayer("traffic_range_geojson")) map.removeLayer("traffic_range_geojson");

      }else{
        this.setState({traffic: true})
        const list_values = this.state.Municipality_id.length > 0 ? {Municipality_id: this.state.Municipality_id} : this.state.Tract_id.length > 0 ? {Tract_id: this.state.Tract_id, County_id: this.state.County_id} : {County_id: this.state.County_id};
        this.getTrafficLayer(list_values)
      }
      return;
    }
    if(e === 'building_model'){
      const isBuildingModel = this.state.building_model;
      map.setPitch(isBuildingModel ? 0 : 45);
      // console.log('isBuildingModel: ', isBuildingModel, this.state.building)
      localStorage.setItem(e, (!isBuildingModel).toString());
      if (this.state.building) {
        if(map.getLayer("buildingvalues")) map.removeLayer("buildingvalues");
        // console.log('(!isBuildingModel).toString(): ', (!isBuildingModel).toString())
        this.setState({ building_model: !isBuildingModel }, () => this.building_mapping_geo());
      } else {
        this.setState({ building_model: !isBuildingModel }, () => this.building_mapping_geo());
      }
      return;

    }
    if(e === "places"){
      if(this.state.freetier === true) return;
      if(!this.state.places){
        this.places_switch()
        return this.setState({places_show: true})
      }
      if(!this.state.places_show)  
         {return this.setState({places_show: true})
      }
    }

      if(e === "cluster"){
        if(this.state.freetier === true) return;
        if(this.state.cluster){
          if(map.getSource("population-circles")){
            map.removeLayer("population-circles")
            map.removeSource("population-circles")
          }
          localStorage.setItem(e, "false")          
          return this.setState({cluster: false})
        }else{
          localStorage.setItem(e, "true")

          return this.setState({cluster: true})
        }
      }
      if(e === "fss"){
        this.setState({competitor_show: true})
      }
      if(e === "datalayer"){
        if(!this.state.datalayer){
          this.datalayer_switch()
          return this.setState({datalayer_show: true})
        }
       if(!this.state.datalayer_show)  return this.setState({datalayer_show: true})
      }
      if(e === "shoppingcenter"){
        if(this.state.freetier) return;
        if(this.state.shoppingcenter){
          localStorage.setItem(e, "false")
          if(map.getLayer("shoppingcenter-cluster-count")) map.removeLayer("shoppingcenter-cluster-count")
          if(map.getLayer("shoppingcenter-unclustered-point")) map.removeLayer("shoppingcenter-unclustered-point")
          if(map.getSource("shoppingcenter")) map.removeSource("shoppingcenter")
          return this.setState({shoppingcenter: false, shoppingcenter_county: {}})
        }
        localStorage.setItem(e, "true")
        const ids = this.state.Municipality_id.length > 0 ? this.state.Municipality_id : this.state.County_id;
        this.shoppingcenter(ids)
        return this.setState({shoppingcenter: true})
      }
      if(e === "building"){
        if(this.state.freetier) return;
        if(this.state.building){
          localStorage.setItem(e, "false")
          if(map.getLayer("building_names")){
          map.removeLayer("building_names")
          map.removeSource("building_names")
          }
          if(map.getLayer('buildingvalues')){
            map.removeLayer("buildingvalues")
            map.removeSource("buildingvalues")
          }
          return this.setState({building: false})

        }else{
          localStorage.setItem(e, "true")
          return this.setState({building: true})
        }
      }
      if(e === "zipcode_tabulation"){
        if(this.state.zipcode_tabulation){
          localStorage.setItem(e, "false")
          map.removeLayer("zipcode_names")
          map.removeSource("zipcode_names")
          map.removeLayer("zipcodes")
          map.removeSource("zipcodes")
          return this.setState({zipcode_tabulation: false})
        }
        localStorage.setItem(e, "true")
        let cent = map.getCenter()
        return this.setState({zipcode_tabulation: true}, () => {     this.loadindata(cent.lat, cent.lng, true) })

      }

    
  }

  // IF TOGGLE IN POPUP IS CLICKED
    getCurrentRange = async() => {
       let circle_range_clicked = this.state.circle_range_clicked
       if(map.getSource("selected_circle")){
        map.removeLayer('selected_circle')
        map.removeSource('selected_circle');
        }
        let circle_range_geojson = this.state.circle_range_geojson
        let filter_circle_range = circle_range_geojson.filter((e) => e.properties.name !== circle_range_clicked)
        if(filter_circle_range.length < circle_range_geojson.length)         this.circle_range_geojson_func(filter_circle_range)
        // IF SEARCH IS NEEDED THEN CLEAR OUT CURRENT DATA AND CALL LOADINDATA
        if(this.state.circle_range_search){
          return this.setState({circle_range_clicked: null, circle_range_search: false}, () => this.loadindata(this.state.center[0], this.state.center[1], true))
        }else{
          return this.setState({circle_range_clicked: null, ...this.state.circle_range_backup})

        }
    }

    constructGoogleStreetViewUrl = (longitude, latitude, heading = 90, pitch = 0) => {
      this.setState({streetViewCoordinates: [longitude, latitude]})
      // return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${latitude},${longitude}&heading=${heading}&pitch=${pitch}`;
    }

    handleStreetView = () => {
        if(this.state.streetView){
          this.setState({streetView: false, streetViewCoordinates: null})
          map.getCanvas().style.cursor = '';
  
        }else{
          this.setState({streetView: true})
          map.getCanvas().style.cursor = 'crosshair';
  
        }
    }

    handleNavigation = (distanceType) => {
      this.setState({navigation: !this.state.navigation, distanceType}); 
      this.getNavigation();
    }
    setStateAsync = (state) => {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
    handleSettingsChanged = async (type) => {
      if(type === 'showRadiusAll'){
        if(!this.state.showRadiusAll){
          let locationValues = this.state.all_locations.filter((e) => !e.hidden)
          locationValues = this.state.all_locations.map((e) => ({"type": "Feature", geometry: e.circle, properties: {name: e.name, data_circle: e.data_circle, color: this.state.circle_range[e.name].color}}));
          localStorage.setItem('showRadiusAll', 'true')
          this.circle_range_geojson_func(locationValues)
          
        }else{
          if(map.getLayer('circle_range_geojson')) map.removeLayer('circle_range_geojson')
          if(map.getSource('circle_range_geojson')) map.removeSource("circle_range_geojson")
          localStorage.setItem('showRadiusAll', 'false')
          this.setState({circle_range_geojson: []})

        }
        this.setState({showRadiusAll: !this.state.showRadiusAll})
      }
      if(['showLocationMarkers', 'showLocationNames'].includes(type)){
        this.state.locationMarkers.forEach(marker => marker && marker.remove());

        await this.setStateAsync({ [type]: !this.state[type] });
        localStorage.setItem([type], this.state[type])

        const locationMarkersFiltered = this.state.getlocations.business_data.filter((data) =>this.passesAllFilters(data, this.state.locationFilters, true))
        if(map.getSource("markers")){
          map.removeLayer("markers-layer")
          map.removeSource("markers")
        }
        // console.log('locationMarkersFiltered: ', locationMarkersFiltered)
        this.createMarkerLayer(locationMarkersFiltered)
        const locationMarkers = locationMarkersFiltered.map(data => {
          const color = this.state?.status_colors[data.status] ? this.state?.status_colors[data.status] : 'grey';
          const { marker, data: dataItem, popup } = this.createMarker(data, 'marker-prospect-business', color, this.state.brandLogo);
          return marker
        });
        this.setState({locationMarkers})

      }
    }
    passesAllFilters = (data, locationFilters, mapFilter) => {
      if(locationFilters.length === 0 ) return true;
      if(mapFilter && !this.state.filterMapLocations) return true;
      const statusConditions = locationFilters.filter(filter => filter.field === "Status");
      const brandCondition = locationFilters.filter(filter => filter.field === "Brand");
    
      const statusPasses = statusConditions.length > 0 ? statusConditions.some(filter => {
        const field = filter.field.toLowerCase();
        const value = filter.value?.toLowerCase();
        switch (filter.operator) {
          case '=':
            return data[field]?.toLowerCase() === value;
          case '!=':
            return data[field]?.toLowerCase() !== value;
          default:
            return false;
        }
      }) : true;
    
      const brandPasses = brandCondition.length > 0 ? brandCondition.some(filter => {
        const field = filter.field.toLowerCase();
        const value = filter.value?.toLowerCase();
        switch (filter.operator) {
          case '=':
            return data[field]?.toLowerCase() === value;
          case '!=':
            return data[field]?.toLowerCase() !== value;
          default:
            return false;
        }
      }) : true;
      // console.log('data: ', data, statusPasses, brandPasses)
      return statusPasses && brandPasses;
    };
    

    handleUpdateLocationFilters = (locationFilters, locationMarkers) => {
      locationMarkers.forEach(marker => marker && marker.remove());
      const locationMarkersFiltered = this.state.getlocations.business_data.filter((data) =>this.passesAllFilters(data, locationFilters, true))
      this.createMarkerLayer(locationMarkersFiltered)
      locationMarkers = locationMarkersFiltered.map(data => {
        const color = this.state?.status_colors[data.status] ? this.state?.status_colors[data.status] : 'grey';
        const { marker, data: dataItem, popup } = this.createMarker(data, 'marker-prospect-business', color, this.state.brandLogo);
        return marker
      });
      this.setState({locationFilters, locationMarkers})
    }
    
    filterMapLocationsFunc = () => {
      if(this.state.filterMapLocations){
        
        this.setState({filterMapLocations: false}, () => this.handleUpdateLocationFilters(this.state.locationFilters, this.state.locationMarkers))
      }else{
        
        this.setState({filterMapLocations: true}, () => this.handleUpdateLocationFilters(this.state.locationFilters, this.state.locationMarkers))
      }
    }


    sendFilters = async (locationFilters) => {
      let token = localStorage.getItem("auth-token");
      await axios.post('/gettheurl/user_metadata', {locationFilters}, {headers:{ "auth-token": token}})
    }
    
    handleAddFilter = (selectedField, filterValue, selectedOperation) => {
      let locationFilters = [...this.state.locationFilters, { field: selectedField, value: filterValue, operator: selectedOperation }]
      this.handleUpdateLocationFilters(locationFilters, this.state.locationMarkers)
      this.sendFilters(locationFilters)
    }

    handleRemoveFilter = (index) => {
      let locationFilters = this.state.locationFilters
      locationFilters = locationFilters.filter((_, i) => i !== index)
      this.handleUpdateLocationFilters(locationFilters, this.state.locationMarkers)
      this.sendFilters(locationFilters)
    };

    handleDrawCreate = async (coordinates) => {
      let navigationPrimary = this.state.navigationPrimary;
      const distanceType = this.state.distanceType;

      navigationPrimary.push(coordinates)
      if (navigationPrimary.length === 2) {
        if(this.state.draw){
          const start = this.state.navigationPrimary[0];
          const end = this.state.navigationPrimary[1];
          // map.off('draw.create', this.handleDrawCreate);
          if(distanceType === 'shortest'){
            await this.getDirectionsShortest(start, end);
          }else if(distanceType === 'fastest'){
            await this.getDirectionsFastest(start, end);
          }

          this.state.draw.deleteAll()
          this.state.draw.changeMode('simple_select')
          map.removeControl(this.state.draw);
        }
        this.setState({draw:null, navigationPrimary: [], navigation: false})
      }else{
        this.setState({navigationPrimary})
      }

    };

    decodePolyline(str, precision) {
      var index = 0,
          lat = 0,
          lng = 0,
          coordinates = [],
          shift = 0,
          result = 0,
          byte = null,
          latitude_change,
          longitude_change,
          factor = Math.pow(10, precision || 5);
    
      while (index < str.length) {
        byte = null;
        shift = 0;
        result = 0;
    
        do {
          byte = str.charCodeAt(index++) - 63;
          result |= (byte & 0x1f) << shift;
          shift += 5;
        } while (byte >= 0x20);
    
        latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));
        shift = result = 0;
    
        do {
          byte = str.charCodeAt(index++) - 63;
          result |= (byte & 0x1f) << shift;
          shift += 5;
        } while (byte >= 0x20);
    
        longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));
    
        lat += latitude_change;
        lng += longitude_change;
    
        coordinates.push([lng / factor, lat / factor]);
      }
    
      return coordinates;
    }

    getDirectionsFastest = async (start, end) => {
      const query = await axios.get(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?steps=true&geometries=geojson&access_token=pk.eyJ1Ijoic25pa2FlaW4iLCJhIjoiY2s1eWNpcWxrMDJvbzNtcDdyb3puNTVrMCJ9.t-VFWsbpRo--tRSLgLa9ng`,
      );
      const json = await query.data;
      const data = json.routes[0];
      const route = data.geometry.coordinates;
      const geojson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: route,
        },
      };

      if (map.getSource('route')) {
        map.getSource('route').setData(geojson);
      } else {
        map.addLayer({
          id: 'route',
          type: 'line',
          source: {
            type: 'geojson',
            data: geojson,
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#3887be',
            'line-width': 5,
            'line-opacity': 0.75,
          },
        });
      }
      const distance_kilometer = data.distance / 1000; 
      const distance_miles = data.distance / 1609
      const duration = data.duration / 60;
      const steps = data.legs[0].steps.map((step, index) => `${index}. `  + step.maneuver.instruction).join('<br>');

      const popup = new mapboxgl.Popup()
      .setLngLat(end)
      .setHTML(`
        <h3>Route Information</h3>
        <p><strong>Distance Kilometers:</strong> ${distance_kilometer.toFixed(2)}</p>
        <p><strong>Distance Miles:</strong> ${distance_miles.toFixed(2)}</p>
        <p><strong>Duration:</strong> ${duration.toFixed(2)} mins</p>
        <h4>Directions:</h4>
        <p>${steps}</p>
      `)
      .addTo(map);
    };

    getDirectionsShortest = async (start, end) => {
        const url = `https://router.project-osrm.org/route/v1/driving/${start[0]},${start[1]};${end[0]},${end[1]}?overview=full`;
      
        try {
          const response = await axios.get(url);
          const data = response.data;
      
          if (data.code !== 'Ok') {
            throw new Error('Unable to find route');
          }
      
          const route = data.routes[0];
          const distance_meter = route.distance;
          const distance_kilometer = distance_meter / 1000;
          const distance_miles = distance_meter / 1609.34;
          const duration_minutes = route.duration / 60;
      
          // Decode the polyline to get coordinates for the route
          const coordinates = this.decodePolyline(route.geometry);
          const geojson = {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: coordinates
            }
          };
      
          // Update or add the route layer on the map
          if (map.getSource('route')) {
            map.getSource('route').setData(geojson);
          } else {
            map.addLayer({
              id: 'route',
              type: 'line',
              source: {
                type: 'geojson',
                data: geojson,
              },
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': '#3887be',
                'line-width': 5,
                'line-opacity': 0.75,
              },
            });
          }
      
          // Create a popup with the distance information
          const popup = new mapboxgl.Popup()
            .setLngLat(end)
            .setHTML(`
              <h3>Shortest Road Distance</h3>
              <p><strong>Distance (km):</strong> ${distance_kilometer.toFixed(2)}</p>
              <p><strong>Distance (miles):</strong> ${distance_miles.toFixed(2)}</p>
              <p><strong>Estimated Duration:</strong> ${duration_minutes.toFixed(2)} mins</p>
            `)
            .addTo(map);
      
          return { distance_kilometer, distance_miles, duration_minutes, geojson };
        } catch (error) {
          console.error('Error fetching shortest road distance:', error);
          throw error;
        }
    };
    
    getNavigation = () => {
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
        point: true,
        trash: true
        },
        styles: [
          // Style for the line that is actively being drawn
          {
            "id": "gl-draw-line",
            "type": "line",
            "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
            "layout": {
              "line-cap": "round",
              "line-join": "round"
            },
            "paint": {
              "line-color": "#3887be", // Dark color for the line
              "line-width": 4, // Thicker line width
              "line-opacity": 0.85
            }
          },
          // Style for the line when it is not actively being drawn (static mode)
          {
            "id": "gl-draw-line-static",
            "type": "line",
            "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
            "layout": {
              "line-cap": "round",
              "line-join": "round"
            },
            "paint": {
              "line-color": "#3887be", // Maintain a dark color for clarity
              "line-width": 4, // Maintain a thicker line for visibility
              "line-opacity": 0.5
            }
          }
        ]
        });
      map.addControl(draw, 'top-left');

      if(this.state.draw){
        this.state.draw.deleteAll()
      }
      this.setState({draw})

      // map.on('draw.click', this.handleDrawCreate);
      draw.changeMode('draw_polygon')


    }

    circle_rance_onclick = () =>{
      map.on('click',  (e) =>  {
        // console.log('CLICK_E: ', e)
        // IF STREETVIEW IS CLICKED
        // console.log('this.state.exportInProgress: ', this.state.exportInProgress)
        if(this.state.exportInProgress) return alert('Export In Progress');
        if(this.state.navigation){
          this.handleDrawCreate([e.lngLat.lng, e.lngLat.lat])
        }

        if(this.state.dropThePointClicked){
          this.droppingcheck({type: 'Point', coordinates: [e.lngLat.lng, e.lngLat.lat]})
          map.getCanvas().style.cursor = '';
          this.setState({dropThePointClicked: false})
        }

        if(this.state.streetView){
          if(e.lngLat){
            this.constructGoogleStreetViewUrl(e.lngLat.lng, e.lngLat.lat)
          }
          this.setState({streetView: false})
          map.getCanvas().style.cursor = ''
        }
        // console.log('map.getSource("markers"): ', map.getSource("markers"))
        if(map.getSource('places')){
          var features_places = map.queryRenderedFeatures(e.point, {layers: ['places-cluster-circles']});
          if(features_places.length > 0){
            return this.places_cluster_click(features_places)
          }
        }
        if(map.getSource("markers")){
          var features_markers_clicked = map.queryRenderedFeatures(e.point, {layers: ['markers-layer']});
          // console.log('features_markers_clicked: ', features_markers_clicked)
          if(features_markers_clicked.length > 0){
            const name = features_markers_clicked[0].properties.name
            // console.log('features_markers_clicked: ', name, features_markers_clicked[0], features_markers_clicked[0].source)
            return this.onclick_pros(name)
          }
        }
        /** CIRCLE RANGE IF CLICKED INSIDE OR OUTSIDE SWITCH BETWEEN THE TWO */
        if(map.getSource("circle_range_geojson")){

          var circle_range_clicked = this.state.circle_range_clicked
          // console.log('circle_rance_onclick, circle_range_clicked: ', circle_range_clicked)
          var features = map.queryRenderedFeatures(e.point, {layers: ['circle_range_geojson']});
          if(features.length === 0 && circle_range_clicked) {
            if(map.getSource("selected_circle")){
              map.removeLayer('selected_circle')
              map.removeSource('selected_circle');
            }
            if(this.state.circle_range_search){
              return this.setState({mapLocationClicked: null, circle_range_clicked: null, circle_range_search: false}, () => this.loadindata(this.state.center[0], this.state.center[1], true))
            }           
            return this.setState({mapLocationClicked: null, circle_range_clicked: null, ...this.state.circle_range_backup})
          } 
          if(features.length > 0)
          this.getcirclerange(features[0].properties.name)
        }
      })
    }

    circle_range_create_func = (circle_range_geojson) => {
      if(map.getSource("circle_range_geojson")){
        return map.getSource("circle_range_geojson").setData({
          'type': 'FeatureCollection',
          'features': circle_range_geojson
        })

      }else{

        map.addSource('circle_range_geojson', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': circle_range_geojson
          }
        });
        map.addLayer({
          'id': "circle_range_geojson",
          'type': 'fill',
          'source': "circle_range_geojson",
          'layout': {},
          'paint': {
          'fill-color': [
            'case',
            ['has', 'color'], 
            ['get', 'color'],
            'red' 
          ],
          'fill-opacity': 0.15,
          }
          });
          map.on('mouseenter', 'circle_range_geojson',  () => {
            if(!this.state.navigation  && !this.state.streetView)
              map.getCanvas().style.cursor = 'pointer';

          });
            
          map.on('mouseleave', 'circle_range_geojson',  () => {
            if(!this.state.navigation  && !this.state.streetView)
            map.getCanvas().style.cursor = '';
          });
      }
    }

    circle_range_geojson_func = (circle_range_geojson) => {

      circle_range_geojson = circle_range_geojson.filter((e) => e.geometry.coordinates.length > 0)
      this.setState({circle_range_geojson})
      const checkMapLoaded = () => {
        if (map.loaded()) {
          this.circle_range_create_func(circle_range_geojson)
        } else {
          setTimeout(checkMapLoaded, 200) // Check again after 100ms
        }
      }
    
      checkMapLoaded()

    }
    // SHOW FOCUSED ON CIRCLE
    getcirclerange = (name) =>{

      let circle_range = this.state.circle_range;
      let circle_range_geojson = this.state.circle_range_geojson
      let filter_circle_range = circle_range_geojson.filter((e) => e.properties.name !== name)
      
      if(circle_range[name].circle){
        filter_circle_range.push({"type": "Feature", geometry: circle_range[name].circle, properties:{name: name, data_circle: circle_range[name].data_circle, color: circle_range[name].color}})
        this.circle_range_geojson_func(filter_circle_range)
      }
      console.log('circle_range[name].data_circle.family,: ', circle_range[name].data_circle.family)

      if(!this.state.circle_range_clicked){
        let temp_object = Object.keys(circle_range[name].data_circle)
        // BACKUP IS FOR WHEN A RANGE IS CLICKED OUTSIDE BUT MAP ISN'T MOVED
        let circle_range_backup = {}
        temp_object.map((e) => circle_range_backup[e]= this.state[e])
        this.setState({...circle_range[name].data_circle, circle_range_backup})
      }else{
        this.setState({...circle_range[name].data_circle})

      }
      // JUST INCASE CIRCLE RANGE ISN'T LOADED
      if(!circle_range) return;
      if(!circle_range[name].circle) return;
      
      if(map.getSource("selected_circle")){
        map.removeLayer('selected_circle')
        map.removeSource('selected_circle');
      }
      if(circle_range[name].circle.coordinates.length > 0){
        // console.log('name: ', name, circle_range[name].circle.coordinates, circle_range[name])

        map.addSource("selected_circle", {
          'type': 'geojson',
          'data': {
            type: "Feature", geometry: circle_range[name].circle, properties: {color: circle_range[name].color}
          }
        });

        map.addLayer({
          'id': "selected_circle",
          'type': 'fill',
          'source': "selected_circle",
          'layout': {},
          'paint': {
          'fill-color': [
            'case',
            ['has', 'color'], 
            ['get', 'color'], 
            'red' 
          ],
          'fill-opacity': 0.4,
          }
          });
      }
      const mapLocationClicked = this.state.getlocations.business_data.find(e => e.name === name)
      setTimeout(() => this.setState({circle_range_clicked: name, mapLocationClicked}), 300);
    }
    onclick_pros = (name) =>{
      console.log('e: ', name, )
       // Retrieve custom name attribute
      // const id = e.currentTarget.dataset.id;
      this.setState({prospect_clicked: name, propsect_address: ''})
      this.getcirclerange(name)

    }
    
    deleteLocationConfirm = () => {
      confirmAlert({
        title: 'Confirm to Delete',
        message: 'Are you sure you want to Delete this location?',
        buttons: [
          {
            label: 'Confirm',
            onClick: () => this.droppingcheck_d()
          },
          {
            label: 'Cancel',
            
          }
        ]
      })
    }
    droppingcheck_d = async() => {
      let token = localStorage.getItem("auth-token");
      let va = this.state.prospect_clicked//this.state.prospect_clicked.lng.toString() +this.state.prospect_clicked.lat.toString()
      let response = await axios.post('/gettheurl/prospectdelete', {name: this.state.prospect_clicked}, {headers:{ "auth-token": token}});
      if (map.getLayer(va)){
        map.removeLayer(va)
        // map.removeSource(va);
    }
      let circle_range_geojson = this.state.circle_range_geojson
      let filter_circle_range = circle_range_geojson.filter((e) => e.properties.name !== this.state.prospect_clicked)
      if(filter_circle_range.length !== circle_range_geojson.length){
        this.circle_range_geojson_func(filter_circle_range)

      }
      if(map.getSource("selected_circle")){
        map.removeLayer('selected_circle')
        map.removeSource('selected_circle');
      }
      this.setState({taskmanager:true, circle_range_clicked: null});
      this.getCurrentLocations()
    }

    creatMarkerLayerSource = (geojsonSource) => {
      if(!map.getSource("markers")){
        map.addSource('markers', {
          'type': 'geojson',
          'data': geojsonSource
        });
        map.addLayer({
          'id': 'markers-layer',
          'type': 'symbol',
          'source': 'markers',
          'minzoom': 0, // Minimum zoom level at which the layer will be visible
          'maxzoom': 20,
          'layout': {
            'icon-image': 'status-icon-transparent',
            'icon-size': .85,
            'text-field': this.state.showLocationNames ? ['get', 'name'] : '',
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': 14,
            'text-offset': [0, 2],
            'text-anchor': 'top',
            'icon-allow-overlap': true,  // Allow icons to overlap
            'icon-ignore-placement': true,  // Ignore placement rules for icons
          
          },
          paint: {
            'icon-opacity': 0,
            'text-color': '#000',
            'text-halo-color': '#fff',
            'text-halo-width': 1
          }
        });  
        map.on('mouseenter', 'markers-layer', () => {
          if(!this.state.navigation  && !this.state.streetView)
          map.getCanvas().style.cursor = 'pointer';
        });
      
        map.on('mouseleave', 'markers-layer', () => {
          if(!this.state.navigation  && !this.state.streetView)
          map.getCanvas().style.cursor = '';
        });    
      }else{
        map.getSource("markers").setData(geojsonSource)

      }
    }

    createMarkerLayer = (data) => {
      // return;
      const geojsonSource = { 'type': 'FeatureCollection',
      'features':  data.map((e) => ({properties: {name: e.name}, geometry: {type: 'Point', coordinates: e.coordinates, color: e.color, brand: e.brand}}))
      }
      
      if (map.isStyleLoaded()) {
        this.creatMarkerLayerSource(geojsonSource); 
      } else {
        map.on('load', () => this.creatMarkerLayerSource(geojsonSource)); 
      }
    }


    // Function to create a marker
    createMarker = (data, className, color, brandLogo) => {
      let el = document.createElement('div');
      el.className = className;
      el.id = data._id;
      el.name = data.name;
      el.dataset.name = data.name;
      el.dataset.id = data._id;
    
      const showMarkers = this.state.showLocationMarkers;
      // const showNames = this.state.showLocationNames;
    
      let innerHTML = '';
      if (data.brand && brandLogo[data.brand]) {
        el.style.width = '40px';
        el.style.height = '50px';
        if (showMarkers) {
          innerHTML = `<div style="height: 100%; width: 100%; background-image: url(${brandLogo[data.brand]}); background-size: contain; background-repeat: no-repeat; background-position: center;"></div>`;
        }
        // if (showNames) {
        //   innerHTML += `<div style="font-weight: 600; position: absolute; top: 50px; left: 50%; transform: translateX(-50%); padding: 2px 5px; border-radius: 3px; font-size: 16px;">${data.name}</div>`;
        // }
      } else {
        if (showMarkers) {
          innerHTML = `<svg viewBox="0 0 24 24" width="45" height="55" fill="${color}" name="${data.name}" id="${data._id}">
            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9 13.38 11.5 12 11.5z"/>
          </svg>`;

        }
        // if (showNames) {
        //   // innerHTML += `<div style="font-weight: 600; position: absolute; top: 40px; left: 50%; transform: translateX(-50%); padding: 2px 5px; border-radius: 3px; font-size: 14px;">${data.name}</div>`;
        // }
      }
      el.innerHTML = innerHTML

      const popupNode = document.createElement('div');
      const root = createRoot(popupNode);
      root.render(<MarkerPinPopUp data={data} getCurrentRange={this.getCurrentRange} getCurrentLocationsVal={this.getCurrentLocationsVal} deleteLocationConfirm={this.deleteLocationConfirm} />);

      const popup = new mapboxgl.Popup().setDOMContent(popupNode)
        
      let marker = new mapboxgl.Marker(el).setLngLat([data.coordinates[0], data.coordinates[1]])
        .setPopup(popup)
        .addTo(map);
  
      
      return { marker, data, popup };
    };


    async getCurrentLocations(name) {
      // Fetch token and data
      const token = localStorage.getItem("auth-token");
      const response = await axios.post('/gettheurl/prospect', {}, { headers: { "auth-token": token } });
      const { business_data, brandLogo, defaultRange, locationFilters, checkStatusExpanded } = response.data;
      
      // Clear existing markers
      this.state.locationMarkers.forEach(marker => marker.remove());
      const defaultRangeClean = defaultRange ? defaultRange : {distance: 12, type: 'Minutes'}
      // Initialize variables
      let tempArr = [];
      let tempTag = [];
      let allLocations = [];
      let popups = {};
      let circleRange = {};
      let uniqueStatus = ['Open', 'Prospecting', 'Closed', 'Opening Soon'];
      let checkStatusExpandedLocal = checkStatusExpanded ? checkStatusExpanded: {};
      let uniqueBrand = []
      
      // Process prospects and businesses
      const status_colors = response.data.status_colors;
      // FILTER BASED ON LOCATION FILTERS FOR MARKERS
      const locationMarkersFiltered = business_data.filter((data) =>this.passesAllFilters(data, locationFilters, true))
      const url = '/transparent.jpeg'
      const image_name = 'status-icon-transparent'
      if (!map.hasImage(image_name)) {
        map.loadImage(url, (error, image) => {
            if (error) throw error;
            map.addImage(image_name, image);
        });
      }
        
      this.createMarkerLayer(locationMarkersFiltered);

      const locationMarkers = locationMarkersFiltered.map(data => {
        const color = status_colors[data.status] ? status_colors[data.status] : 'grey';
        const { marker, popup } = this.createMarker(data, 'marker-prospect-business', color, brandLogo);
        popups[data.name] = popup;
        return marker
      });
      business_data.map(data => {
        const color = status_colors[data.status] ? status_colors[data.status] : 'grey';
        tempArr.push(data.name);
        allLocations.push(data);
        tempTag.push(...data.tags);
        if(data.status && !uniqueStatus.includes(data.status)) uniqueStatus.push(data.status)
        if(data.brand && !uniqueBrand.includes(data.brand)) uniqueBrand.push(data.brand)

        circleRange[data.name] = { circle: data.circle, data_circle: data.data_circle, color };
      });
      uniqueStatus.map((e) => {
        if (checkStatusExpandedLocal[e] === undefined) {
          checkStatusExpandedLocal[e] = true;  
        }
      });
      console.log('this.state.showRadiusAll: ', this.state.showRadiusAll)
      if(this.state.showRadiusAll){
        const locationValues = allLocations.map((e) => ({"type": "Feature", geometry: e.circle, properties: {name: e.name, data_circle: e.data_circle, color: circleRange[e.name].color}}));
        console.log('locationValues: ', locationValues)
        this.circle_range_geojson_func(locationValues)
      }
    
      // Process tags and determine propnum
      const uniqueTags = Array.from(new Set(tempTag));
      const propnum = (business_data.length < this.state.properties_number);
      // Set state
      this.setState({
        locationFilters,
        brandLogo,
        status_colors,
        circle_range: circleRange,
        checkStatusExpanded: checkStatusExpandedLocal,
        popup: popups,
        prospect_business: business_data.length,
        all_locations: allLocations,
        getlocations: response.data,
        locationMarkers,
        prospectnames: tempArr,
        status: uniqueStatus,
        tags: uniqueTags,
        brand: uniqueBrand,
        defaultRange: defaultRangeClean,
        prospect_prospect: propnum
      }, () => {      if(name) this.getcirclerange(name)    });
    }
    
    
    async family(fam , fam_tabulation){
        this.setState({family: fam, family_income: fam.values_final, family_estincome: fam.estincome, fam_type: true, loading_layer: false})
        if(fam_tabulation)
        this.getzipcode(true, fam.zipcode_color, false, true)
    }
    getprospectsidebar(coordinates){
      map.flyTo({center: coordinates, zoom: 16})
    }
    // ADDING LAYERS TO THE MAP
    getzipcode(singlerun, res_test, clus, family) {
      if (!res_test || res_test.length === 0) return;
    
      let min_val = Infinity;
      let max_val = -Infinity;
      const geometry = [];
      const res_geo = res_test[0].properties.TRACTCE ? this.state.Tract_polygon : this.state.County;
      const res_geo_id = res_test[0].properties.TRACTCE ? "GEOID" : "GEO_ID";
    
      if (!this.state.datalayer) return;
    
      res_test.forEach((e) => {
        if (e.properties.value.zipcode_data_area <= 0) return;
    
        const color = e.properties.value ? e.properties.value.color : "rgba(255,130,110,.2)";
        const feature = {
          type: 'Feature',
          properties: { color },
          geometry: res_geo
            ? res_geo.find(item => item.properties[res_geo_id] === e.properties[res_geo_id])?.geometry
            : e.geometry
        };
    
        if (feature.geometry) {
          geometry.push(feature);
        }
    
        if (this.state.cluster_type === 0) {
          const val_temp = Math.round(e.properties.value.zipcode_data_area * e.area);
          min_val = Math.min(min_val, val_temp);
          max_val = Math.max(max_val, val_temp);
        } else if (family) {
          const val_temp = e.properties.value.val;
          min_val = Math.min(min_val, val_temp);
          max_val = Math.max(max_val, val_temp);
        }
      });
    
      const geojsonData = {
        type: 'FeatureCollection',
        features: geometry
      };
    
      if (map.getSource('polygon-color')) {
        map.getSource("polygon-color").setData(geojsonData);
      } else {
        map.addSource("polygon-color", { type: 'geojson', data: geojsonData });
        map.addLayer({
          id: "polygon-color",
          type: 'fill',
          source: "polygon-color",
          maxzoom: 14.5,
          paint: {
            'fill-color': { type: 'identity', property: 'color' },
            'fill-opacity': 0.75,
          }
        });
      }
    
      if (this.state.cluster_type === 1) {
        min_val = `${Math.round(min_val / 1000)}k`;
        max_val = `${Math.round(max_val / 1000)}k`;
      } else if (this.state.cluster_type === 2) {
        max_val = "High";
        min_val = "Low";
      }
    
        this.setState({ min_val, max_val });
    }
    industryestate = async(bounds2, parent, geoset, returnvalue)=> {
      let token = localStorage.getItem("auth-token");
      this.setState({industry_loading: false})
      let response = await axios.post('/gettheurl/industryestate', {
        bounds2,
        Tract: this.state.Tract_id,
        County_id: this.state.County_id,
        geoset,
        point:[parent.lng, parent.lat],
        bounds: ""
        }, {headers:{ "auth-token": token}})
    
        let response_data = await response.data;
        let housing_rent = (response_data.housing_rent)? response_data.housing_rent: {min:0, max:0};
        // let industry_total = response_data.industry //.map((e) => {return {...e, id: uuid()}})
        // if(industry_total) Object.keys(industry_total).map((e) => {if(industry_total[e].rent) industry_total[e].rent *= 12})
        if(returnvalue) return { industry_loading: true, housing_seasonality: response_data.housing_seasonality, housing_buy: response_data.housing_buy, housing_rent: housing_rent,  industrygroup: response_data.industrygroup, type_industry: true}
      if(response_data.industry){
        this.setState({ industry_loading: true, housing_seasonality: response_data.housing_seasonality, housing_buy: response_data.housing_buy, housing_rent: housing_rent, industrygroup: response_data.industrygroup, type_industry: true})
      }else
        this.setState({industry_loading: true, industry: {}, industrygroup: response_data.industrygroup })
    }

    commercialEstate = async({geoset, bounds2, returnvalue}) => {
      let token = localStorage.getItem("auth-token");
      let response = await axios.post('/gettheurl/commercialestate', {
        bounds2,
        Tract: this.state.Tract_id,
        County_id: this.state.County_id,
        geoset,
        bounds: ""
        }, {headers:{ "auth-token": token}})
      let response_data = await response.data
      if(returnvalue) return {...response_data}
      this.setState({commercial_data: {...response_data}})
    }

    async traveltime(bounds){
      let token = localStorage.getItem("auth-token");
      let travel = await axios.post('/gettheurl/traveltime', { bounds: bounds,   }, {headers:{ "auth-token": token}})
      let travel_data = await travel.data;
      this.setState({traveltime_each_2012: travel_data.traveltime.each_2012, traveltime_each_2018: travel_data.traveltime.each_2018, traveltime_each_dif: travel_data.traveltime.dif})
    }
    async degree(bounds, returnvalue){
      let token = localStorage.getItem("auth-token");
      let degree = await axios.post('/gettheurl/degree', {  bounds:  bounds}, {headers:{ "auth-token": token}})
      let degree_total = await degree.data;
      if(returnvalue) return {degree: degree_total}
      this.setState({degree: degree_total})
    }

  async occupation(bounds, bounds_id, returnvalue){
    
    let token = localStorage.getItem("auth-token");
    let occupation = await axios.post('/gettheurl/occupation', { bounds_id, bounds:  bounds}, {headers:{ "auth-token": token}})
    let occupation_data = await occupation.data;
    if(returnvalue) return {occupation: occupation_data}
    this.setState({occupation: occupation_data})
  }


  componentWillMount() {
    let showLocationNames = (localStorage.getItem("showLocationNames")  === "false")? false: true;
    let showRadiusAll = (localStorage.getItem("showRadiusAll")  === "false")? false: true;
    let showLocationMarkers = (localStorage.getItem("showLocationMarkers")  === "false")? false : true;
    // let fss = (localStorage.getItem("fss")  === "true")? true: false;
    // let fss_main = (localStorage.getItem("fss_main")  === "true")? true: false;
    let fss = false;
    let fss_main = false;
    let traffic = (localStorage.getItem("traffic") === "true")? true: false;
    let datalayer = (localStorage.getItem("datalayer") === "true")? true: false;
    let cluster = (localStorage.getItem("cluster") === "true")? true: false;
    let shoppingcenter = (localStorage.getItem("shoppingcenter") === "true")? true: false;
    let zipcode_tabulation = (localStorage.getItem("zipcode_tabulation") === "true")? true: false;
    let places = (localStorage.getItem("places")  === "true")? true: false;
    let building = (localStorage.getItem("building")  === "true")? true: false;
    let building_model = (localStorage.getItem("building_model")  === "true")? true: false;
    let places_cat = localStorage.getItem("places_category")
    let places_sub = localStorage.getItem("places_subCategory")
    let places_category = {}
    let places_subCategory = {}

    places_cat = places_cat ? places_cat.split(',').map((e) => places_category[e] = true) : {};
    places_sub = places_sub ? places_sub.split(',').map((e) => places_subCategory[e] = true) : {};

    this.setState({showLocationMarkers, showRadiusAll, showLocationNames, building_model, fss_main, fss,places, traffic, building, datalayer, cluster, shoppingcenter, zipcode_tabulation, places_category, places_subCategory})
  }

  getColorForPopulation(populationData, baseColor = [255, 130, 110]) {
    // Extract population values and filter out invalid values
    const populations = Object.values(populationData).filter(val => val > 0);
  
    // If there's no valid data, return a default color for all
    if (populations.length === 0) {
      return Object.fromEntries(
        Object.keys(populationData).map(key => [key, `rgba(${baseColor.join(',')},0.2)`])
      );
    }
  
    // Calculate log values
    const logPopulations = populations.map(val => Math.log(val));
    const minLog = Math.min(...logPopulations);
    const maxLog = Math.max(...logPopulations);
  
    function getColorForValue(value) {
      if (value <= 0) {
        return `rgba(${baseColor.join(',')},0.2)`;
      }
  
      const logValue = Math.log(value);
      // Normalize the log value to be between 0 and 1
      const normalizedValue = (logValue - minLog) / (maxLog - minLog);
  
      // Adjust lightness: 0 means darkest (original color), 1 means lightest
      const lightnessAdjustment = 1 - normalizedValue; // Invert so higher values are darker
  
      // Adjust the color's lightness
      const adjustedColor = baseColor.map(channel => 
        Math.round(channel + (255 - channel) * lightnessAdjustment)
      );
  
      // Adjust opacity: from 0.2 (lightest) to 0.77 (darkest)
      const opacity = 0.2 + normalizedValue * (0.77 - 0.2);
  
      return `rgba(${adjustedColor.join(',')},${opacity.toFixed(2)})`;
    }
  
    const result = {};
    for (const [geoid, population] of Object.entries(populationData)) {
      result[geoid] = getColorForValue(population);
    }
  
    return result;
  }


  createGeometry = async(geometry) => {
    if (!map.isStyleLoaded()) {
      // If the style isn't loaded, wait for it
      await new Promise(resolve => map.once('style.load', resolve));
    }
  
    const geojsonData = {
      type: 'FeatureCollection',
      features: geometry
    };
  
    if (map.getSource('polygon-color')) {
      map.getSource("polygon-color").setData(geojsonData);
    } else {
      map.addSource("polygon-color", { type: 'geojson', data: geojsonData });
      map.addLayer({
        id: "polygon-color",
        type: 'fill',
        source: "polygon-color",
        maxzoom: 14.5,
        paint: {
          'fill-color': { type: 'identity', property: 'color' },
          // 'fill-opacity': 0.85,
        }
      });
    }
  }

  createCombinedView = (GEO_INCOME, GEO_POP) => {
    const allKeys = new Set([...Object.keys(GEO_INCOME), ...Object.keys(GEO_POP)]);
    const combinedData = {}

    if(allKeys.length < 2){
      return allKeys.forEach((e) => combinedData[e] = 0)
    }
    const logIncome = Object.values(GEO_INCOME).filter(val => val ).map(val => Math.log(val));
    const minLogIncome = Math.min(...logIncome);
    const maxLogIncome = Math.max(...logIncome);

    const logPop = Object.values(GEO_POP).filter(val => val ).map(val => Math.log(val));
    const minLogPop = Math.min(...logPop);
    const maxLogPop = Math.max(...logPop);
    
    // console.log(minLogPop, maxLogPop, minLogIncome, maxLogIncome)
    // console.log(logIncome, logPop)

    allKeys.forEach((e) => {
      const POP = GEO_POP[e] ? this.calculateLog(GEO_POP[e], minLogPop, maxLogPop) : 0;
      const INCOME = GEO_INCOME[e] ? this.calculateLog(GEO_INCOME[e], minLogIncome, maxLogIncome) : 0
      combinedData[e] = POP + INCOME
    })
    return combinedData
  }

  calculateLog = (value, minLog, maxLog) => {
    const logValue = Math.log(value);
    // Normalize the log value to be between 0 and 1
    const normalizedValue = (logValue - minLog) / (maxLog - minLog);
    return normalizedValue
  }

   municipalityCalculate = async (municipality_data, cluster_type) => {
    console.log('municipality_data: ', municipality_data)
    if(Object.keys(municipality_data.GEOID_totalPopulation).length === 0) return     this.setState({ min_val: 0, max_val: 0 });

    let min_val = Infinity;
    let max_val = -Infinity;
    const geometry_static = municipality_data.geometry
    ? municipality_data.geometry.reduce((acc, feature) => {
        acc[feature.properties.GEOID] = feature.geometry;
        return acc;
      }, {})
    : this.state.municipality;

    if(cluster_type == 0){
      const GEO_POP = municipality_data.GEOID_totalPopulation;
      const colors = this.getColorForPopulation(GEO_POP)
      Object.values(GEO_POP).map((e) => {
        if(e < min_val) min_val = e
        if(e > max_val) max_val = e
      })

      const geometry = Object.keys(GEO_POP).map((e) => ({properties: {color: colors[e] ? colors[e] : null, }, geometry: geometry_static[e] }))

      this.createGeometry(geometry)

    } else if (cluster_type == 1) {
      const GEO_INCOME = municipality_data.GEOID_averageIncome;
      const colors = this.getColorForPopulation(GEO_INCOME, [0, 130, 6])
      Object.values(GEO_INCOME).map((e) => {
        if(e < min_val) min_val = e
        if(e > max_val) max_val = e
      })
      
      const geometry = Object.keys(GEO_INCOME).map((e) => ({properties: {color: colors[e] ? colors[e] : null, }, geometry: geometry_static[e] }))

      this.createGeometry(geometry)

      min_val = `${Math.round(min_val / 1000)}k`;
      max_val = `${Math.round(max_val / 1000)}k`;
    } else if (cluster_type == 2) {
      const combinedValues = this.createCombinedView(municipality_data.GEOID_averageIncome, municipality_data.GEOID_totalPopulation)
      console.log('combinedValues: ', combinedValues)
      const colors = this.getColorForPopulation(combinedValues, [20, 74, 205])
      const geometry = Object.keys(combinedValues).map((e) => ({properties: {color: colors[e] ? colors[e] : null, }, geometry: geometry_static[e] }))
      this.createGeometry(geometry)

      // console.log('GEO_INCOME: ', GEO_INCOME, GEO_POP)
      max_val = "High";
      min_val = "Low";
    }

    this.setState({ min_val, max_val });
    
  }

  async age_income_total_req(County_Tract, GEO_multiplyer){
    let token = localStorage.getItem("auth-token");
    let zoom = map.getZoom();
    this.searchingfordata("layer", true)
    if(zoom <= 6) return this.setState({showresult_red: true})
      let bound = map.getBounds();
      /** age_income_loadingdata IS SO THAT THE total_age_sex isn't passed in /sidebar value without being current source */
    this.setState({age_income_loadingdata: true})
    var bounds2  = [
      [ bound.getNorthEast().lng, bound.getNorthEast().lat], 
      [ bound.getNorthWest().lng, bound.getNorthWest().lat], 
      [ bound.getSouthWest().lng, bound.getSouthWest().lat],
      [ bound.getSouthEast().lng, bound.getSouthEast().lat], 
      [ bound.getNorthEast().lng, bound.getNorthEast().lat],  ]
      
    let response = await axios.post('/gettheurl/layer', {
      bounds2: bounds2,
      geoset_data: County_Tract,
      center: map.getCenter(),
      tabulate: true,
      GEO_multiplyer,
      zip: this.state.datalayer,
      turf_area: this.state.turf_area,
      layerRunValue: {layer: parseInt(this.state.cluster_type), run: true },
      }, {headers:{ "auth-token": token}})
    let response_data = await response.data
    this.setState({age_income_loadingdata: false})
    if(response_data.municipality_data){
      
      if(this.state.datalayer) this.municipalityCalculate(response_data.municipality_data, parseInt(this.state.cluster_type))
      this.setState({municipality_data: response_data.municipality_data})

    }else{
      this.setState({municipality_data: null})
      switch(parseInt(this.state.cluster_type)){
        case 0: 
          this.getzipcode(true, response_data.zipcodes, null)
          this.setState({total_age_sex: response_data.total_age_sex})
          break;
        case 1:
          this.family(response_data.total_family_income, true)
          break;
        case 2:
          this.getzipcode(true, response_data.combination, false);
          this.setState({total_age_sex: response_data.total_age_sex})
          this.family(response_data.total_family_income, false)
          break;
      }
    }

    // console.log('searchingfordata: ', this.state.searchingfor)
    this.searchingfordata("layer", false)

  }
  async age_income_total(bounds, bounds2, age_income_data){

      let dif = {postmortgage: (age_income_data.get2018.postmortgage - age_income_data.get2012.postmortgage)/age_income_data.get2012.postmortgage, mortgage: (age_income_data.get2018.mortgage - age_income_data.get2012.mortgage)/age_income_data.get2012.mortgage }
      this.setState({age_income_overall: age_income_data.age_income_total.age_grouping, age_income: Object.values(age_income_data.age_income_total.values_final["2018"]),
      age_income_prev: Object.values(age_income_data.age_income_total.values_final["2012"]), age_income_header: age_income_data.age_income_total.name, expenses2018: age_income_data.get2018,expenses2012: age_income_data.get2012, expenses_diff: dif, type_income: true})
    }
  checkifnew(tract, name){
    if(!name) return false;
    for(let circle_num in tract){
      if(!name.includes(tract[circle_num])) return false;
    }
    return true;
  }
  numberWithCommas(x) {
    if(!x) return "";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  getCenter(arr)
  {
    var x = arr.map (x => x[0]);
    var y = arr.map (x => x[1]);
    var cx = (Math.min (...x) + Math.max (...x)) / 2;
    var cy = (Math.min (...y) + Math.max (...y)) / 2;
    return [cx, cy];
  }

  combineNearbyRecords = (records, turf_area, distance=300) => {
    
    const features = records.map(record => ({
      type: 'Feature',
      geometry: record.geometry,
      properties: {
        ...record.properties,
        _id: record._id,
        tract: record.tract,
        county: record.county
      }
    }));
    // console.log('turf_area: ', turf_area)
    // var distance = Math.round(turf_area / 1000000)
    // distance = distance > 10000 ? 10000 : distance;
    // distance = distance < 200 ? 200 : distance;

    // Create a FeatureCollection and run DBSCAN
    const points = featureCollection(features);
    const clustered = clustersDbscan(points, distance, { minPoints: 1, units: 'meters' });
  
    // Group features by cluster
    const clusters = clustered.features.reduce((acc, feature) => {
      const { cluster, count, subcategories } = feature.properties;
      if (cluster === undefined) return acc;
  
      if (!acc[cluster]) {
        acc[cluster] = {
          type: 'Feature',
          geometry: { type: 'MultiPoint', coordinates: [] },
          properties: { subcategoriesCount: {} }
        };
      }
  
      acc[cluster].geometry.coordinates.push(feature.geometry.coordinates);
      acc[cluster].properties.subcategoriesCount[subcategories] = 
      (acc[cluster].properties.subcategoriesCount[subcategories] || 0) + count;
      
      acc[cluster].properties.count = (acc[cluster].properties.count || 0) + count;

      return acc;
    }, {});
  
    // Process clusters and calculate centroids
    return Object.values(clusters).map(cluster => {
      const centerPoint = centroid(cluster.geometry);
      const subcategoriesCount = Object.entries(cluster.properties.subcategoriesCount)
        .map(([key, value]) => `+${value} ${key}`)
        .join('\n');

      let properties = { subcategoriesCount };
      if(cluster.properties.count > 1)
        properties['count'] = cluster.properties.count
      return {
        type: 'Feature',
        geometry: centerPoint.geometry,
        properties
      };
    });
  };

  places_cluster_visibility = (search_places) => {
    if (map.getSource('places')) {
      map.setLayoutProperty('places-cluster-labels', 'visibility',
      search_places ? 'none': 'visible' );

      map.setLayoutProperty('places-cluster-circles', 'visibility',
      search_places ? 'none': 'visible' );

      map.setLayoutProperty('places-unclustered-point', 'visibility',
      search_places ? 'visible' : 'none' );
    }
  }

  places_cluster_click = (features) => {
    if (!features.length) {
      return;
    }
  
    const feature = features[0];
    // console.log('places_cluster_click: ', feature)
    
    // Parse the subcategoriesCount string into an object
    const subcategoriesData = feature.properties.subcategoriesCount.split('\n').reduce((acc, curr) => {
      const curr_values = curr.split(' ');
      const count = curr_values.shift();
      const category = curr_values.join(' ')
      acc[category] = parseInt(count.replace('+', ''));
      return acc;
    }, {});

    const popupNode = document.createElement('div');
    const root = createRoot(popupNode);
    root.render(<PlaceClusterChart data={subcategoriesData} />);
  
    new mapboxgl.Popup()
      .setLngLat(feature.geometry.coordinates)
      .setDOMContent(popupNode)
      .addTo(map);
  }

  places_layout = (places, name_places_uncluster, name_places_source, name_places_each) => {
    

    map.addLayer({
      id: name_places_uncluster,
      type: 'symbol',
      source: name_places_source,
      filter: ['!', ['has', 'count']],
      layout: {
        'icon-image': ['get', 'icon'],
        'icon-size': 0.5,
        'icon-allow-overlap': true,
        'text-field': ['get', 'title'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 15,
        'text-offset': [0, 1.5],
        'text-anchor': 'top'
      },
      paint: {
        'text-color': ['get', 'textColor'],
      }
    });
    map.addSource(name_places_each, {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': places
      }
    });
    map.addLayer({
      'id': name_places_each,
      'type': 'fill',
      'source': name_places_each,
      'layout': {},
      'paint': {
      'fill-color': 'rgba(0,0,0,.1)',
      'fill-opacity': 1,
      'fill-outline-color': 'black'
      }
    });

    map.on('mouseenter', name_places_uncluster, () => { 
      if(!this.state.navigation  && !this.state.streetView)
        map.getCanvas().style.cursor = 'pointer';
    });
    
    map.on('mouseleave', name_places_uncluster,  () =>  { 
      if(!this.state.navigation  && !this.state.streetView)
      map.getCanvas().style.cursor = '';
    });

    map.on('click', name_places_uncluster, (e) => {
      var coordinates = e.features[0].geometry.coordinates.slice();
      e = e.features[0]
      let subcategory = (e.properties.subcategory)? e.properties.subcategory: e.properties.category ? e.properties.category : "No Data";
      let lease = (e.properties.lease !== "null" && e.properties.lease)? "$" + this.numberWithCommas(e.properties.lease): "No Data";
      let buy = (e.properties.buy)? "$" + this.numberWithCommas(e.properties.buy): "No Data";
      let Est = e.properties.Est ? "Real Estate estimation is high": "";
      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(`Name: ${e.properties.title} <br/>Address: ${e.properties.address} <br/>Type: ${subcategory} <br/>Est. Lease: ${lease} <br/>Est. Cost: ${buy} <br/>${Est} `)
        .addTo(map);
    })
  }

  places_transform = (places_records) => {
    const places_icons = { 'Automotive': 'places_automotive', 
    'Business': 'places_business', 'Education': 'places_education',
    'Entertainment': 'places_entertainment', 'Finance': 'places_finance',
    'Food & Drink': 'places_food', 'Hotel': 'places_hotel',
    'Medical Institution': 'places_medical', 'Rental Shop': 'places_rental',
    'Services': 'places_Service', 'Shop': 'places_shop',
    'Supermarket': 'places_supermarket', 'marker-other.png': 'places-other', 'places-competitor': 'places-competitor'
    }

    let places = []
    let places_image_list = this.state.places_image_list;

    places_records.forEach((e) => {
      if(!places_image_list.includes(e.Img) && e.Img){
        places_image_list.push(e.Img)
      }
    })

    places_image_list.forEach((imageUrl) => {
      map.loadImage(imageUrl, (error, image) => {
        if (error) {
          console.error(`Failed to load image: ${imageUrl}`, error);
          return; 
        }
        if (!image || !image.width) {
          console.error(`Invalid image data for: ${imageUrl}`);
          return; 
        }
        try {
          if (!map.hasImage(imageUrl)) {
            const standardWidth = 80; 
            const standardHeight = 80;
            const canvas = document.createElement('canvas');
            canvas.width = standardWidth;
            canvas.height = standardHeight;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = '#FFFFFF';  
            ctx.fillRect(0, 0, standardWidth, standardHeight); 

            // Calculate aspect ratio
            const aspectRatio = image.width / image.height;

            // Calculate new dimensions
            let newWidth, newHeight;
            if (aspectRatio > 1) {
              newWidth = standardWidth;
              newHeight = standardWidth / aspectRatio;
            } else {
              newHeight = standardHeight;
              newWidth = standardHeight * aspectRatio;
            }

            // Calculate positioning to center the image
            const x = (standardWidth - newWidth) / 2;
            const y = (standardHeight - newHeight) / 2;

            // Draw the image maintaining aspect ratio
            ctx.drawImage(image, x, y, newWidth, newHeight);

            // Get the image data from the canvas
            const resizedImage = ctx.getImageData(0, 0, standardWidth, standardHeight);

            map.addImage(imageUrl, resizedImage);
            console.log(`Successfully added image: ${imageUrl}`);
          }
        } catch (addError) {
          console.error(`Error adding image to map: ${imageUrl}`, addError);
        }
        });
    })
    let points = places_records.map((e) => {
        let coordinates = e.geometry.coordinates;
        if (e.geometry.type === "Polygon") {
          coordinates = this.getCenter(coordinates[0]);
          places.push({type: "Feature", geometry: e.geometry});
        } else if (e.geometry.type === "MultiPolygon") {
          coordinates = coordinates[0][0][0];
          places.push({type: "Feature", geometry: e.geometry});
        }

        const image_logic = this.state.competitorSubcategory.includes(e.subcategory) ? 'places-competitor' : places_icons[e.category] || 'places-other';
        const textColor = this.state.competitorSubcategory.includes(e.subcategory) ? '#F99600' : '#000000';

        return {
          type: "Feature",
          geometry: {type: "Point", coordinates},
          properties: {
          icon: e.Img ? e.Img : image_logic,
          title: e.Name_final,
          address: e.address?.label,
          type: e.type,
          buy: e.bl ? e.bl[0] : "No Data",
          lease: e.bl ? e.bl[1] * 12 : "No Data",
          id: e._id,
          Est: e.Est,
          category: e.category,
          subcategory: e.subcategory,
          textColor
        }
        };
    });
    return {points, places}
  }

  places_map = (places_records, cluster_records, search_places, turf_area)=>{
    if(!cluster_records) return;
    const {points, places} = this.places_transform(places_records)

    cluster_records = this.combineNearbyRecords(cluster_records, turf_area)
    // console.log('cluster_records: ', cluster_records)
    cluster_records.forEach((cluster) => points.push(cluster))
    
    if (map.getSource('places')) {
      map.getSource('places').setData({
        type: 'FeatureCollection',
        features: points
      });
      map.getSource('places_each').setData({
        'type': 'FeatureCollection',
        'features': places
        });
    } else {

      map.addSource('places', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: points
        },
        cluster: false
      });
      // Add cluster count layer
      map.addLayer({
        id: 'places-cluster-labels',
        type: 'symbol',
        source: 'places',
        filter: ['!', ['has', 'count']],
        layout: {
          'text-field': ['get', 'subcategoriesCount'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 14,
          'text-anchor': 'center',
          'text-justify': 'center',
          'text-offset': [0, 0],
          'text-max-width': 20,
          'text-allow-overlap': true
        },
        paint: {
          'text-color': '#000000',
          'text-halo-color': '#ffffff',
          'text-halo-width': 1
        },
      });

      map.addLayer({
        id: 'places-cluster-circles',
        type: 'circle',
        source: 'places',
        filter: ['has', 'count'],
        paint: {
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['get', 'count'],
            0, 10,  // Minimum size
            100, 30 // Maximum size
          ],
          'circle-color': 'rgba(0, 100, 255, 0.3)', // Semi-transparent blue
          'circle-stroke-width': 2,
          'circle-stroke-color': 'rgba(0, 100, 255, 0.8)'
        }
      });
      
      map.on('mouseenter', 'places-cluster-circles', () => {
        if(!this.state.navigation && !this.state.streetView)
        map.getCanvas().style.cursor = 'pointer';
      });
      
      map.on('mouseleave', 'places-cluster-circles', () => {
        if(!this.state.navigation  && !this.state.streetView)
        map.getCanvas().style.cursor = '';
      });
      this.places_layout(places, 'places-unclustered-point', 'places', 'places_each')
    }

    this.places_cluster_visibility(search_places)
  }

  getCustomBrandSearch = async (customBrandSearch, County_id, force_refresh=false) => {
    this.searchingfordata("placesCustom", true)
    let county_list = County_id;
    let places_custom_records = {...this.state.places_custom_records}
    if(force_refresh){
      places_custom_records = {}
    }else{
      county_list = County_id.filter((e) => !places_custom_records[e])
    }
    if(county_list.length === 0) return     this.searchingfordata("placesCustom", false)    ;
    const customBrandSearchStatus = {}
    customBrandSearch.forEach((e) => customBrandSearchStatus[e.subcategory + e.Name_final] = e.status)
    const getCustomValues = await axios.post('/gettheurl/placescustom', {customBrandSearch, county_list });
    const places_records = getCustomValues.data.places_records.map((e) => ({...e, status: customBrandSearchStatus[e.subcategory + e.Name_final]}));
    
    Object.keys(places_custom_records).map((e) => {
      if(!County_id.includes(e)){
        delete places_custom_records[e]
      }
    })

    places_records.map((e) => {
      const GEOID = e.County;
      if(places_custom_records[GEOID]){
        places_custom_records[GEOID] = [...places_custom_records[GEOID], {...e}]
      }else{
        places_custom_records[GEOID] = [{...e}]
      }
    })

    county_list.map((e) => {
        if(!places_custom_records[e]) places_custom_records[e] = []
    })

    const places_records_flat = Object.values(places_custom_records).flat();
    this.getCustomBrandAddLayer(places_records_flat)
    this.setState({places_custom_records})
  }

  getCustomBrandAddLayer = async (places_records_flat) => {
    places_records_flat = places_records_flat.filter((e) => e.status)
    const {points, places} = this.places_transform(places_records_flat);

    if (map.getSource('placesCustom')) {
      map.getSource('placesCustom').setData({
        type: 'FeatureCollection',
        features: points
      });
      map.getSource('places_each_custom').setData({
        'type': 'FeatureCollection',
        'features': places
        });
    } else {

      map.addSource('placesCustom', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: points
        },
        cluster: false
      });
      this.places_layout(places, 'places-unclustered-custom-point', 'placesCustom', 'places_each_custom')

    }
    this.searchingfordata("placesCustom", false)
  }
  
  searchBarGetBrand = async ({title, subcategory, Image, status}, deleteInd=false, updateInd=false) => {
    var customBrandSearch = [...this.state.customBrandSearch];
    // IF NAME_FINAL IS THE SAME AS VALUE IN customBrandSearch
    // console.log('searchBarGetBrand: ', {title, subcategory, Image, status}, deleteInd, updateInd)
    if(deleteInd){
      var places_custom_records = {...this.state.places_custom_records}
      // console.log('places_custom_records: ', places_custom_records, Object.entries(places_custom_records))
      Object.entries(places_custom_records).map(([key, value]) => {
        // console.log('object_delete: ', key, value.filter((e) => !(e.Name_final === title && e.subcategory === subcategory)))
        places_custom_records[key] = value.filter((e) => !(e.Name_final === title && e.subcategory === subcategory))
      })
      customBrandSearch = customBrandSearch.filter((e) => !(e.Name_final === title && e.subcategory === subcategory))
      // console.log('delete: ', places_custom_records)
      // console.log('delete: ', customBrandSearch)
      const places_records_flat = Object.values(places_custom_records).flat();
      this.getCustomBrandAddLayer(places_records_flat)
      this.setState({customBrandSearch, places_custom_records})

    }else if(updateInd){
      var places_custom_records = {...this.state.places_custom_records}
      Object.entries(places_custom_records).map(([key, value]) => {
        places_custom_records[key] = value.map((e) => {
          if(e.Name_final === title && e.subcategory === subcategory){
            return {...e, status}
          }
          return e
        })
      })
      // console.log('updateInd: ', places_custom_records)

      customBrandSearch = customBrandSearch.map((e) => {
        if(e.Name_final === title && e.subcategory === subcategory){
          return {...e, status}
        }
        return e
      })
      const places_records_flat = Object.values(places_custom_records).flat();
      this.getCustomBrandAddLayer(places_records_flat)
      this.setState({customBrandSearch, places_custom_records})

    }else{
      const searchCustom = customBrandSearch.find((e) => e.Name_final === title && e.subcategory === subcategory)
      if(searchCustom || (this.state.County_id.length === 0 && this.state.Municipality_id.length === 0)) return;
      customBrandSearch.push({Name_final: title, subcategory, Image, status})
      if(customBrandSearch.length > 5) customBrandSearch.shift()
      const ids = this.state.Municipality_id ? this.state.Municipality_id : this.state.County_id;
      this.getCustomBrandSearch(customBrandSearch, ids, true)
      this.setState({customBrandSearch})
    }
    localStorage.setItem('placescustom', JSON.stringify(customBrandSearch))
  }

  building_mapping_geo = () => {
    if(this.state.building_model){
      map.addLayer({
        'id': 'buildingvalues',
        'type': 'fill-extrusion',
        'source': 'buildingvalues',
        'paint': {
            'fill-extrusion-height': ['get', 'height'], // This is the extrusion height
            'fill-extrusion-base': 0, // This is the base height of the extrusion
            'fill-extrusion-color': ['get', 'color'], // Color of the extrusion
            'fill-extrusion-opacity': 0.6 // Opacity of the extrusion
        }
      });
    }else{
      if(map.getLayer("buildingvalues")) 
      map.addLayer({
        'id': 'buildingvalues',
        'type': 'fill',
        'source': "buildingvalues",
        'layout': {},
        'paint': {
        'fill-color': {
            type: 'identity',
            property: 'color',
        },
        'fill-opacity': 1,
        'fill-outline-color': 'black'
        }
        });
    }

  }

  building_map = (response) => {
    function colorchangevalue(building){
      let color = { Commercial: "rgba(255,20,20, .25)", Retail: "rgba(35,101,96, .25)", School: "rgba(249,222,15, .25)", Office: "rgba(105,15,249, .25)", Industrial: "rgba(249,105,15, .25)", Government: "rgba(15,159,249, .25)" }
      let value =  color[building]
      if(value) return value
      return "rgba(0, 0,0, .25)"
    }
    let building_names = response.map((e) => {
      let coordinates = e.geometry.coordinates;
      if(e.geometry.type === "Polygon") coordinates = this.getCenter(coordinates[0])
      if(e.geometry.type === "MultiPolygon") coordinates = this.getCenter(coordinates[0][0][0])          
      
      return {properties:{description: e.building}, geometry: {type: "Point", coordinates}}
    })
    response = response.filter((e) => e.geometry.type != 'Point')
    let res_value = response.map((e) => {
      let colorchange = colorchangevalue(e.building)
      return {type: "Feature", geometry: e.geometry, properties: {color: colorchange, height: e.height ? parseFloat(e.height) : 5}}
    })


    if(map.getSource("building_names")){
      map.getSource('building_names').setData({
        'type': 'FeatureCollection',
        'features': building_names
        });

    }else{
      map.addSource('building_names', {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': building_names
      }
      });
      map.addLayer({
        'id': 'building_names',
        'type': 'symbol',
        'source': 'building_names',
        'layout': {
        'text-field': ['get', 'description'],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
        "text-size": 10

        }
      });
    }
    if(map.getSource('buildingvalues')){
      map.getSource('buildingvalues').setData({
        'type': 'FeatureCollection',
        'features': res_value
        });
    }else{
      map.addSource('buildingvalues', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': res_value
        }
        });
      this.building_mapping_geo()
    }
  }
  getbuildings = async(Tract) => {
    let token = localStorage.getItem("auth-token");
    if(this.checkifnew(Tract, this.state.building_each)) return;
    this.searchingfordata("buildings", true)

    let response = await axios.post('/gettheurl/buildings', {
      Tract
      }, {headers:{ "auth-token": token}})
    let response_data = await response.data;
    if(response_data) this.building_map(response_data)
    this.setState({building_each: Tract})
    this.searchingfordata("buildings", false)

  }
  places = async(tract, turf_area, force_refresh=false) => {

    let token = localStorage.getItem("auth-token");
    // IF TURF LESS THAN NUM > CLUSTER IT
    const search_places = turf_area < tract_threshold / 20;
    // console.log('search_places: ', tract, turf_area,force_refresh)
    if (!tract || tract.length === 0) {
      // console.log('places_tract: ', tract)
      this.searchingfordata("places", false)
      return this.setState({ places_records: {},  cluster_records: {}});
    }
    let places_records = {...this.state.places_records}
    let cluster_records = {...this.state.cluster_records}
    let tract_list = tract;
    // IF THE FUNCTION IS CALLED WITH NEW PARAMS TO REFRESH
    if(force_refresh){
      places_records = {}
      cluster_records = {}
    }else{
      if(search_places){
        tract_list = tract.filter((e) => !places_records[e])
      }else{
        tract_list = tract.filter((e) => !cluster_records[e])
      }
    }
    // console.log('tract_list: ', tract_list)
    if(tract_list.length === 0){ 
      this.places_cluster_visibility(search_places)
      return this.searchingfordata("places", false);
    }
    const places_subCategory = {...this.state.places_subCategory};
    // console.log('places_subCategory: ', places_subCategory)
    this.searchingfordata("places", true)
    if(!places_subCategory) return this.searchingfordata("places", false);
    const subcategory = Object.keys(places_subCategory)
    let response = await axios.post('/gettheurl/places', {
      tract: tract_list,
      subcategory,
      search_places
      }, {headers:{ "auth-token": token}})
    let response_data = await response.data;
    response_data = (response_data && this.state.places)? response_data: {places_records: [], cluster_records: []};

    Object.keys(places_records).map((e) => {
      if(!tract.includes(e)){
        delete places_records[e]
      }
    })

    Object.keys(cluster_records).map((e) => {
      if(!tract.includes(e)){
        delete cluster_records[e]
      }
    })

    response_data.places_records.map((e) => {
      const GEOID = e.GEOID;
      if(places_records[GEOID]){
        places_records[GEOID] = [...places_records[GEOID], {...e}]
      }else{
        places_records[GEOID] = [{...e}]
      }
    })

    response_data.cluster_records.map((e) => {
      const GEOID = e.GEOID;
      if(cluster_records[GEOID]){
        cluster_records[GEOID] = [...cluster_records[GEOID], {...e}]
      }else{
        cluster_records[GEOID] = [{...e}]
      }
    })

    tract_list.map((e) => {
      if(search_places){
        if(!places_records[e]) places_records[e] = []
      }else{
        if(!cluster_records[e]) cluster_records[e] = []
      }
    })

    // console.log('places_records: ', places_records)
    // console.log('cluster_records: ', cluster_records)

    const cluster_records_flat = Object.values(cluster_records).flat();
    const places_records_flat = Object.values(places_records).flat();
    // console.log('places_records_flat: ', places_records_flat)
    this.places_map(places_records_flat, cluster_records_flat, search_places, turf_area)
    this.setState({places_records, cluster_records})
    this.searchingfordata("places", false)

  }
  getfssvalues(businesses){
    let image = (businesses.Img)? `<img class='business-checker-image' src=${businesses.Img} />`: "<div class='business-checker-image business-checker-single'></div>";

    let el = document.createElement('div');
    let logo_final = `<img src= ${businesses.Img} onerror= "this.style.display='none'" class='building-mapbox'></img>`;
    el.className = 'custom-div-icon';
    el.innerHTML += "<div style='background-color:#0000CD;' class='marker-pin'></div>" + logo_final;
    let est_income = (businesses.Est_revenue)? `<div class='business-checker-vicinity'>Est. Revenue: <div class='business-checker-vicinity-text'>$${this.numberWithCommas(businesses.Est_revenue * 1000)}</div></div>`: "";
    let address = (businesses.add)? `<div class='business-checker-vicinity'>Vicinity: <div class='business-checker-vicinity-text'> ${businesses.add} </div></div>`: "";
    let hours = (businesses.hours && businesses.hours.length > 0) ? `<div class='business-checker-vicinity'>Open hours: <div class='business-checker-vicinity-text'> ${businesses.hours.join("<br>")} </div></div>`: "";
    let Employee_cost = (businesses.Employee_cost)? `<div class='business-checker-vicinity'>Est. Employee Salary Cost: <div class='business-checker-vicinity-text'> $${this.numberWithCommas(businesses.Employee_cost)} </div></div>`: "";
    let lease = businesses.bl && businesses.bl.length > 0 ? `<div class='business-checker-vicinity'>Est. Lease Cost: <div class='business-checker-vicinity-text'> $${this.numberWithCommas(businesses.bl[1] * 12)} </div></div>`: "";
    let buy = businesses.bl  && businesses.bl.length > 0  ? `<div class='business-checker-vicinity'>Est. Buying Cost: <div class='business-checker-vicinity-text'> $${this.numberWithCommas(businesses.bl[0])} </div></div>`: "";
    let type = (businesses.type)? `<div class='business-checker-vicinity'>Category: <div class='business-checker-vicinity-text'> ${businesses.type} </div></div>`: "";
    let brandurl = "/competitor-research?search=" + encodeURI(businesses.Name_final);
    let search = (businesses.Search) ? `<div class="business-link"><a  target="_blank" href=${brandurl}>Explore Brand ${businesses.Name_final}</a></div>`: "";
    var popup = new mapboxgl.Popup().setHTML(`${image}<div class='business-checker-name'> ${businesses.Name_final} </div>${address}${type}${est_income}${hours}${Employee_cost}${lease}${buy}${search}`)
    let coordinates = businesses.geometry.coordinates
    if(businesses.geometry.type === "Point") coordinates = [coordinates[0], coordinates[1]]
    if(businesses.geometry.type === "Polygon"){
        coordinates = this.getCenter(coordinates[0])
    }
    if(businesses.geometry.type === "MultiPolygon"){
        coordinates = coordinates[0][0][0]
    }
    var marker = new mapboxgl.Marker(el).setLngLat(coordinates)
    .setPopup(popup) 
    .addTo(map);
    marker.id = businesses._id;
    return marker
  }
  getfssfind_names = (names) =>{
    if(map.getSource("getfssfind_names")){
      map.getSource('getfssfind_names').setData({
        'type': 'FeatureCollection',
        'features': names
        })
    }else{
      var layers = map.getStyle().layers;
      var firstSymbolId;
      for (var i = layers.length - 1; i >= 0; i--) {
          firstSymbolId = layers[i].id;
          break;
      }
    
    map.addSource('getfssfind_names', {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': names
      }
      });
      map.addLayer({
        'id': 'getfssfind_names',
        'type': 'symbol',
        'source': 'getfssfind_names',
        'layout': {
        'text-field': ['get', 'description'],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 1.5,
        'text-justify': 'auto',
        }
        }, firstSymbolId);
      }
  }

  getfssfind = async (Tract) =>{
    let token = localStorage.getItem("auth-token");
    if(this.checkifnew(Tract, this.state.fssfind)) return;
    this.searchingfordata("fssfind", true)

    let response = await axios.post('/gettheurl/fssfind', {Tract, Competitor_type: this.state.Competitor_type}, {headers:{ "auth-token": token}})
    let response_data = await response.data;
    let getfssfind_id = this.state.getfssfind_id;
    let competitor_list = this.state.competitor_list;
    let response_id = response_data.map((e) => e._id);
    let response_names = response_data.map((e) => {return {type:"Features", geometry: e.geometry,  properties: { description:e.Name_final}}})
    this.getfssfind_names(response_names)
    let getfssfind_id_new = []
    let remove_competitor = getfssfind_id.filter((e) => { 
      let res_id = response_id.indexOf(e); 
      if(res_id !== -1){ 
        response_data.splice(res_id, 1);
        /** ^^^ SPLICE FROM RESPONSE_DATA SO IT DOESN"T DUPLICATE THE MARKER TWICE */
        getfssfind_id_new.push(e)
        return false; 
      }
      /** IT IS NOT IN THE RESPONSE_DATA SO IT RETURN TRUE TO REMOVE IT IN THE NEXT STEP */
      return true; 
    })

    
    competitor_list = competitor_list.filter((e) => {
      if(remove_competitor.includes(e.id)){
        e.remove()
        return false;
      }

      return true
    })
    let competitor_list2 = response_data.map((e) => { getfssfind_id_new.push(e._id); return this.getfssvalues(e)})
    competitor_list = competitor_list2.concat(competitor_list)
    this.setState({fssfind: Tract, competitor_list, getfssfind_id: getfssfind_id_new })
    this.searchingfordata("fssfind", false)

  }


  circles = async (circles, zoomed) =>{
    let token = localStorage.getItem("auth-token");
    if(this.checkifnew(circles, this.state.circles)) return;
    this.searchingfordata("circle", true)
    let center = map.getCenter()
    let population = this.state.total_age_sex ? this.state.total_age_sex.population_total : null;
    let response = await axios.post('/gettheurl/circles', {
      Tract: circles,
      center,
      population
      }, {headers:{ "auth-token": token}})
    let response_data = await response.data;
    if(!this.state.cluster) return this.searchingfordata("circle", false)

    let points = []
    this.setState({circles})
    response_data.map((res) => { res.v.map((variable) => { 
      points.push({type: "Feature", geometry: {type: "Point", coordinates: variable}})}) })

    if (map.getSource('population-circles')){
    map.getSource('population-circles').setData({
      'type': 'FeatureCollection',
      'features': points
      });

    }else{
      var layers = map.getStyle().layers;
      var firstSymbolId;
      for (var i = 0; i < layers.length; i++) {
      if (layers[i].type === 'symbol') {
      firstSymbolId = layers[i].id;
      break;
      }
      }
      map.addSource('population-circles', {
        'type': 'geojson',
        'data': {
        'type': 'FeatureCollection',
        'features': points
        }
        });
      map.addLayer({
        'id': 'population-circles',
        'type': 'circle',
        'source': 'population-circles',
        'paint': {
        'circle-radius': {
        'base': 1.75,
        // 'stops': [[10, 0], [12, 1.5], [17, 1]]
        'stops': [[12, 1.5], [15, 2], [17, 1]]
        },
        'circle-color': "red"
        }
        }, firstSymbolId);

      }
      this.searchingfordata("circle", false)

  }
    async loadindata(lati, lon, hide, run_fss) {
      let token = localStorage.getItem("auth-token");
      let zoom = map.getZoom();
      if(this.state.circle_range_clicked) return this.setState({circle_range_search: true})
      if(this.state.loading_layer) return;

      if(zoom <= 6) return this.setState({showresult_red: true})
      this.searchingfordata("loadin", true)
      let bound = map.getBounds();
      var bounds2  = [
        [ bound.getNorthEast().lng, bound.getNorthEast().lat], 
        [ bound.getNorthWest().lng, bound.getNorthWest().lat], 
        [ bound.getSouthWest().lng, bound.getSouthWest().lat],
        [ bound.getSouthEast().lng, bound.getSouthEast().lat], 
        [ bound.getNorthEast().lng, bound.getNorthEast().lat],  ]
        
        // let tract_length = this.state.Tract_id.length !== this.state.Tract_polygon.length || this.state.Tract_id.length === 0;
      let geoset_logic = ((this.state.County_id.length > 0 || this.state.Municipality_id.length > 0) && this.state.setgeo && !this.state.setgeo_changedposition)
      var geoset_data = (geoset_logic)? {County_id: this.state.County_id, Tract: this.state.Tract_id, municipality: this.state.Municipality_id}: {County_id: [], Tract: [], municipality: []}
      var fam_type = (this.state.datalayer && this.state.cluster_type != 1)? false: this.state.fam_type;
      this.setState({bounds2, loading_layer: true, type_income: false, fam_type, type: false, traveltime_each_2018: null, commercial_data: {loading: true}})
  
        // let sidebar_tabulation = (this.state.cluster_type == 0)? true: false;
        let response_data = null;
        var responseempty = false;
        // var fam_tabulation = (this.state.cluster_type == 1 && this.state.datalayer)? true: false;
        try{
          /**It tabulates both views */
          // var fam_tab = (this.state.cluster_type == 2)? true: fam_tabulation;
          // var combinationView = (this.state.cluster_type == 2);
          const SourceCancel = CancelToken.source();
          this.setState({SourceCancel})
          let layerRunValue = (this.state.datalayer)? {run: false, layer: parseInt(this.state.cluster_type)}:null;
          /**IF IT LOADING IN DATA FOR LAYERS IT SHOULDN"T SEND THE WRONG DATA */
          let total_age_sex = (!this.state.age_income_loadingdata) ? this.state.total_age_sex : null;
          let response = await axios.post('/gettheurl/sidebar', {
          bounds2: bounds2,
          geoset_data,
          center: map.getCenter(),
          turf_value: Math.round(this.state.turf_value),
          GEO_multiplyer: this.state.GEO_multiplyer,
          zip: this.state.datalayer,
          total_age_sex,
          layerRunValue: layerRunValue,
          tabulate: true,
          turf_area: this.state.turf_area,
          }, {headers:{ "auth-token": token}, 
          cancelToken: SourceCancel.token
          })

          if(response.status == 204) return this.setState({noresults: true, loading_layer: false, showsearching: 'none', showresult_red: true, SourceCancel: null})
          response_data = await response.data;
          
          if(this.state.Municipality_id.length > 0 ){
            this.searchingfordata("loadin", false)
            if(this.state.datalayer) this.municipalityCalculate(response_data.municipality_data, this.state.cluster_type)
            return this.setState({ municipality_data: response_data.municipality_data, loading_layer: false, showsearching: 'none', SourceCancel: null});
          }else{
            this.setState({municipality_data: null})
          }
          /** IF A CIRCLE IS CLICKED BUT THE DATA IS LOADING FOR THE SIDEBAR */
          if(this.state.circle_range_clicked) return this.setState({circle_range_search: true, loading_layer: false, SourceCancel: null}, () =>       this.searchingfordata("loadin", false))

          let temp_tracts = (geoset_logic)? {County_id: this.state.County_id.map((e) => "0500000US"+e), Tract: this.state.Tract_id}: response_data.bounds_ids
          this.industryestate(bounds2, map.getCenter(), temp_tracts)
          this.commercialEstate({bounds2, geoset: {...temp_tracts}})
          if(response_data.total_family_income) this.family(response_data.total_family_income, false);
        }catch(response){
        // if response is sent back with an error
        console.log(response, 'response loadindata error')
        this.searchingfordata("loadin", false)
        this.setState({loading_layer: false})
        if(response.response == null) return responseempty= true;
       if(response.response.status === 401) window.location.href = "/account";
       
       if(response.response.status === 400) 
       {
         localStorage.removeItem("auth-token")
         window.location.href = "/login";
        }
      }
      
        if(response_data == null) return this.setState({noresults: true, loading_layer: false, showsearching: 'none', showresult_red: true, SourceCancel: null});
        var bounds = response_data.bounds;
        /** COMBINATIONVIEW IS SET HERE IN THIS FUNCTION BELOW */
        // if(this.state.cluster_type == 2 && this.state.datalayer) this.getzipcode(true, response_data.combination, false);
        this.age_income_total(bounds,bounds2, response_data)
        let psycho = response_data.psychograph
        psycho.id = uuid();
        this.setState({bounds: bounds, psychograph: psycho, degree: response_data.degree,  traveltime_each_2012: response_data.traveltime.each_2012, traveltime_each_2018: response_data.traveltime.each_2018, traveltime_each_dif: response_data.traveltime.dif, SourceCancel: null})

      let occ_bounds = (geoset_logic)? {County_id: this.state.County_id, Tract: this.state.Tract_id}: response_data.bounds_ids
      this.occupation(bounds, occ_bounds)
     
    //  this.degree(bounds)
     
    //  this.traveltime(bounds)
      if(response_data.total_age_sex)
      this.setState({ total_age_sex: response_data.total_age_sex, type: true, loading_layer: false });

      this.searchingfordata("loadin", false)
      
  }

  async shoppingcenter(County_id){
    this.searchingfordata("shoppingcenter", true)

    if(this.state.turf_area  > 20000000000) {
      this.searchingfordata("shoppingcenter", false)
      return this.setState({showresult_red: true})
    }
    if (!County_id || County_id.length === 0) {
      return this.setState({ shoppingcenter_county: {} });
    }
    let shoppingcenter_county = {...this.state.shoppingcenter_county}
    // console.log('shoppingcenter_county: ', shoppingcenter_county)
    let county_list = County_id.filter((e) => !shoppingcenter_county[e])
    
    // console.log('county_list: ', county_list, shoppingcenter_county)
    if(county_list.length === 0) return this.searchingfordata("shoppingcenter", false);
    let token = localStorage.getItem("auth-token");
    let bounds = this.state.bounds ? this.state.bounds : [];
    let response = await axios.post('/gettheurl/shoppingcenter', {
        County_id: county_list,
        bounds,
        fss: true
        
    }, {headers:{ "auth-token": token}})
    let shop_array = await response.data;
    if(shop_array.length == 0 || !this.state.shoppingcenter) return this.searchingfordata("shoppingcenter", false);
    Object.keys(shoppingcenter_county).map((e) => {
      if(!County_id.includes(e)){
        delete shoppingcenter_county[e]
      }
    })
    shop_array.map((e) => {
      const countyId = e.properties.County_id;
      if(shoppingcenter_county[countyId]){
        shoppingcenter_county[countyId] = [...shoppingcenter_county[countyId], {...e}]
      }else{
        shoppingcenter_county[countyId] = [{...e}]
      }
    })
    // console.log('shoppingcenter_county_keys: ', Object.keys(shoppingcenter_county))
    // let shop = this.state.shoppingcenter_data;
    // let shop_array =  this.state.shop_array
    let shops = []
    Object.keys(shoppingcenter_county).map((e) => shops= [...shops, ...shoppingcenter_county[e]])
    // console.log('shops: ', shops)
    this.setState({shoppingcenter_county})
    this.searchingfordata("shoppingcenter", false);

    if(map.getSource("shoppingcenter")){
      map.getSource('shoppingcenter').setData({
        'type': 'FeatureCollection',
        'features': shops
        });

    }else{
      map.addSource('shoppingcenter', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': shops
        },
        'cluster': true,
        // 'clusterMaxZoom': 10,
        'clusterRadius': 20
      });


        // Cluster count layer
        map.addLayer({
          'id': 'shoppingcenter-cluster-count',
          'type': 'symbol',
          'source': 'shoppingcenter',
          'filter': ['has', 'point_count'],
          'layout': {
            'text-field': `+{point_count_abbreviated}`,
            'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
            'text-size': 20,
            // 'text-offset': [-1, -1],  // Adjust this to position the text
            // 'text-anchor': 'top'
          },
          'paint': {
            'text-color': '#ffffff',
            'text-halo-color': '#000000',
            'text-halo-width': 1
          }
        });

        // Unclustered point layer
        map.addLayer({
          'id': 'shoppingcenter-unclustered-point',
          'type': 'symbol',
          'source': 'shoppingcenter',
          'filter': ['!', ['has', 'point_count']],
          'layout': {
            'icon-image': 'shopping-center-icon',
            'icon-size': 1
          }
        });

        function handleClusterClick(e) {
          const features = e.features[0]
          
          // console.log('handleClusterClick: ', features.properties, e)

          const clusterId = features.properties.cluster_id;

          map.getSource('shoppingcenter').getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
              if (err) return;
        
              map.easeTo({
                center: features.geometry.coordinates,
                zoom: zoom
              });
            }
          );
        }

        // Mouse events for cluster count
        map.on('mouseenter', 'shoppingcenter-cluster-count', () => {
          if (!this.state.navigation  && !this.state.streetView) map.getCanvas().style.cursor = 'pointer';
        });
        
        map.on('mouseleave', 'shoppingcenter-cluster-count', () => {
          if(!this.state.navigation  && !this.state.streetView)
          map.getCanvas().style.cursor = '';
        });

        map.on('click', 'shoppingcenter-cluster-count', handleClusterClick);
      
        
        map.on('mouseenter', 'shoppingcenter-unclustered-point', () => {
          if (!this.state.navigation  && !this.state.streetView) map.getCanvas().style.cursor = 'pointer';
        });
        
        map.on('mouseleave', 'shoppingcenter-unclustered-point', () => {
          if(!this.state.navigation  && !this.state.streetView)
          map.getCanvas().style.cursor = '';
        });
        
        map.on('click', 'shoppingcenter-unclustered-point', (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const { title } = e.features[0].properties;
        
          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(`Name: ${title}`)
            .addTo(map);
        });
      }
    }

    getlocationrange = async (bounds) => {
      // console.log('getlocationrange: ', bounds)
      if(!bounds || bounds.length === 0) return emptyResponse
      let token = localStorage.getItem("auth-token");    
      this.searchingfordata("setting", true)
      // this.setState({ loading_layer: true, type_income: false,  type: false, traveltime_each_2018: null})
        let response_data = null;
        try{
          let response = await axios.post('/gettheurl/sidebar', {
          bounds2: bounds,
          geoset_data: {County_id: [], Tract: [], municipality: []},
          center: map.getCenter(),
          turf_value: null,
          GEO_multiplyer: null,
          zip: false,
          total_age_sex: null,
          layerRunValue: null,
          tabulate: true,
          turf_area: null,
          }, {headers:{ "auth-token": token}})
          
          if(response.status == 204) return this.setState({noresults: true, loading_layer: false, showsearching: 'none', showresult_red: true})
          response_data = await response.data;
      }catch(response){
        if(response.response.status === 401) window.location.href = "/account";
       if(response.response.status === 400) 
       {localStorage.removeItem("auth-token"); window.location.href = "/login";}
      }
      if(response_data.municipality_data){
        this.searchingfordata("setting", false)
        return {municipality_data: response_data.municipality_data}
      } 
      // if(response_data == null) return this.setState({noresults: true, loading_layer: false, showsearching: 'none', showresult_red: true});
      let industry = await this.industryestate(bounds, map.getCenter(), response_data.bounds_ids, true)
      let commercial_data = await this.commercialEstate({geoset: {center: map.getCenter()}, bounds2: bounds, returnvalue: true})
      console.log('commerical_data: ', commercial_data)
      let families = {}
      if(response_data.total_family_income) families = {family: response_data.total_family_income, family_income: response_data.total_family_income.values_final, family_estincome: response_data.total_family_income.estincome, fam_type: true, loading_layer: false}
      var bounds = response_data.bounds;
      let dif = response_data.get2018 ? {postmortgage: (response_data.get2018.postmortgage - response_data.get2012.postmortgage)/response_data.get2012.postmortgage, mortgage: (response_data.get2018.mortgage - response_data.get2012.mortgage)/response_data.get2012.mortgage } : null;
      let age_data = response_data.age_income_total ? {age_income_overall: response_data.age_income_total.age_grouping, age_income: Object.values(response_data.age_income_total.values_final["2018"]), 
      age_income_prev: Object.values(response_data.age_income_total.values_final["2012"]), age_income_header: response_data.age_income_total.name, expenses2018: response_data.get2018,expenses2012: response_data.get2012, expenses_diff: dif, type_income: true} : null;
      let psycho = response_data.psychograph ? response_data.psychograph : {};
      // console.log('age_data: ', age_data, psycho)
      psycho.id = uuid();
      
      let occ = (response_data.bounds_ids) && await this.occupation(bounds, response_data.bounds_ids, true)
      // let deg = await this.degree(bounds, true)
      // let travel = await this.traveltime(bounds, true)
      let total_age_sex = { total_age_sex: response_data.total_age_sex, type: true, loading_layer: false };
      const traveltime_each_dif = response_data.traveltime ? response_data.traveltime.dif : null;
      const traveltime_each_2012 = response_data.traveltime ? response_data.traveltime.each_2012 : null;
      const traveltime_each_2018 = response_data.traveltime ? response_data.traveltime.each_2018 : null;
      let final_value = {...total_age_sex,  ...occ, ...age_data, psychograph: psycho, commercial_data, degree: response_data.degree, traveltime_each_2012, traveltime_each_2018, traveltime_each_dif, ...industry, ...families, municipality_data: null}
      this.searchingfordata("setting", false)
      return final_value
    }


    sidebaropenlink = () => {
      this.getCurrentLocations()
      this.setState({sidebaropen: 0, location_presetvalues: []})
    }
    droppingcheck = (geo, address)=>{
      map.flyTo({
        center: geo.coordinates,
        zoom: 15
        });
      if(this.state.prospect_business >= this.state.properties_number) return null;

      var marker = new mapboxgl.Marker().setLngLat(geo.coordinates)//.setPopup(popup)
      .addTo(map);

      setTimeout(() => {
          var popupElement = document.createElement('div');
          var popup = new mapboxgl.Popup()
              .setLngLat(geo.coordinates)
              .setDOMContent(popupElement)
              .addTo(map);
  

          const root = createRoot(popupElement); // create a root instance
          root.render(
              <PopupContent 
                  defaultBrand={this.state.cat_all.brand}
                  initialTags={this.state.tags}
                  statusList={this.state.status}
                  range={this.state.defaultRange}
                  confirmationprospect={this.confirmationprospect}
                  deleteProspect={this.deleteProspect}
                  prospectnames={this.state.prospectnames}
                  getlocationrange={this.getlocationrange}
                  address={address}
                  markerlatlng={geo.coordinates}
                  sidebaropenlink={this.sidebaropenlink}
              />
          );
       
          popup.on('close', ()=> {
            marker.remove();
            root.unmount();
          }) 
          this.setState({ temp_marker: {marker, popup, root}})

        }, 30);

        this.setState({markertitle: address, markerlatlng: geo.coordinates})  

    }
    deleteProspect = () => {
      this.state.temp_marker.marker.remove(); 
      this.state.temp_marker.root.unmount();
      this.state.temp_marker.popup.remove(); 
      this.setState({temp_marker: {}})
    }

    dropthepin = ()=> {

      map.getCanvas().style.cursor = 'crosshair';
      this.setState({dropThePointClicked: true})
    }


    async zipcode_tabulation(Tract){
        
      let token = localStorage.getItem("auth-token");
      let zipcode_tab = await axios.post('/gettheurl/zipstabulation', { bounds: [], geoset: Tract,  }, {headers:{ "auth-token": token}})
      let zipcode_tab_data = await zipcode_tab.data;


      let zipcode_names = zipcode_tab_data.zip_val.map((e)=> {
        return { type: 'Feature', geometry:e.geometry, properties: { description:e.properties.ZCTA5CE10 }}
      // temp_zipcodelayer.push({type: 'Feature', geometry:e.geometry,})
      });
      if(map.getSource("zipcode_names")){
        map.getSource('zipcode_names').setData({
          'type': 'FeatureCollection',
          'features': zipcode_names
          });
        map.getSource('zipcodes').setData({
          'type': 'FeatureCollection',
          'features': zipcode_names
          });
      }else{
        map.addSource('zipcode_names', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': zipcode_names
          }
          });
          map.addLayer({
            'id': 'zipcode_names',
            'type': 'symbol',
            'source': 'zipcode_names',
            'layout': {
            'text-field': ['get', 'description'],
            'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
            'text-radial-offset': 0.5,
            'text-size': 25,
            'text-justify': 'auto',
            }
            });
            map.addSource('zipcodes', {
              'type': 'geojson',
              'data': {
                'type': 'FeatureCollection',
                'features': zipcode_names
              }
              });
              map.addLayer({
                'id': 'zipcodes',
                'type': 'fill',
                'source': "zipcodes",
                'layout': {},
                'paint': {
                'fill-color': 'rgba(0,0,0,.1)',
                'fill-opacity': 1,
                'fill-outline-color': 'black'
                }
                });
      }
    }
      maptilelayer(val){
        map.setStyle('mapbox://styles/mapbox/' + val);
      }
    layertype = (i) =>{
      localStorage.setItem('cluster_type', i);
      this.setState({cluster_type: i, min_val: "-", max_val:"-"}, () => {
        this.age_income_total_req({Tract: this.state.Tract_id, County_id: this.state.County_id, municipality: this.state.Municipality_id}, this.state.GEO_multiplyer)
      })
      
    }
    rezoombackin(){
      map.setZoom(12)
    }
    resettaskmanager = () => {this.setState({taskmanager: null})}
    closeTour = async () =>{
      let token = localStorage.getItem("auth-token");
      await axios.post('/gettheurl/tourend', {}, {headers:{ "auth-token": token}})
      
      this.setState({showTour:false})
    }
    showTourAgain = () => {
      this.setState({showTour:true})
    }
    refreshing_auto = async() => {
      let token = localStorage.getItem("auth-token");
      await axios.post('/gettheurl/refreshing', {refreshing: !this.state.refreshing_auto}, {headers:{ "auth-token": token}})
      this.setState({refreshing_auto: !this.state.refreshing_auto})
    }
    Psychographic_parent = (Psychographic) => {
      // console.log('Psychographic: ', Psychographic)
      this.setState({Psychographic_parent: Psychographic})
    }
    Psychographic_close =() => this.setState({Psychographic_parent: null})
    
    timeoutId = null;

    places_change = (category, subCategories, competitorInd) =>{
      Object.keys(category).map((e) => {
        if(!category[e]) delete category[e]
      })
      Object.keys(subCategories).map((e) => {
        if(!subCategories[e]) delete subCategories[e]
      })
      const competitorChangeInd = this.state.competitorSubcategory.find((e) => subCategories[e])
      const competitorSearch = competitorChangeInd ? true : false;
      // console.log('(category, subCategories): ', category, subCategories)
      localStorage.setItem("places_category", Object.keys(category).join(','))
      localStorage.setItem("places_subCategory", Object.keys(subCategories).join(','))
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      this.timeoutId = setTimeout(() => {
        if(this.state.turf_area <= tract_threshold && this.state.Tract_id.length > 0) this.places(this.state.Tract_id, this.state.turf_area, true)
      }, 2000);
      this.setState({placeseach: [], places_category: category, places_subCategory: subCategories, competitorSearch})
    }

    places_change_deselect = () =>{
      localStorage.removeItem("places_category")
      localStorage.removeItem("places_subCategory")
      this.setState({placeseach: [], places_category: {}, places_subCategory: {}, places_response: []})
    }

    competitor_change_deselect = () => {
      const competitorSubcategory = this.state.competitorSubcategory;
      const places_category = this.state.places_category.filter((e) => !this.state.competitorCategory[e])
      const places_subCategory = this.state.places_subCategory.filter((e) => !competitorSubcategory[e])
      localStorage.setItem("places_category", places_category)
      localStorage.setItem("places_subCategory", places_subCategory)
      this.setState({placeseach: [], places_category, places_subCategory, places_response: []})
    }

    hidesidebar = (sidebarshow) => {
      if(this.state.sidebarshow){
        this.setState({sidebarshow: false})
      }else{
        if(this.state.sidebar_changed){
          let center = map.getCenter();
          this.loadindata(center.lat, center.lng, true);
        
        }
        this.setState({sidebar_changed: false, sidebarshow: true})
      }
    }
    closePopUp = () => {
      console.log('closePopUp', this.state.feedback_show)
      this.setState({feedback_show: false})
    }
    changeUnregistered = (unregistered) => {
      this.setState({unregistered})
    }

    emailconfirmation = () => {
      confirmAlert({
          title: 'Please confirm your email',
          message: 'Please confirm your email address to start your free trial.',
          buttons: [
            {
              label: 'Continue',
              // onClick: () => this.deletesubscription()
            },
            {
              label: 'Settings',
              onClick: () => {window.location.href = "/account"}
              
            }
          ]
        })
    }

    openCalendly = () => {
      window.open('https://calendly.com/snikaein/30min','_blank');
    }

    premiumFeatureClicked = () => {
      return this.openCalendly();
      let feedback_show = false;
      if(!this.state.unregistered)
      Object.keys(this.state.feedback).map((e) => this.state.feedback[e] ? feedback_show = true: null);
      if(feedback_show){
        this.setState({feedback_show})
      }else{
        window.location.href = 'https://mapchise.com/pricing/'
      }
    }

    parentExportInProgress = (status) => {
      this.setState({exportInProgress: status})
    }

    hideStreetView = () => {
      this.setState({streetViewCoordinates: null, streetView: false})
    }


    render(){
      // var showsearchExport = (!this.state.loading_layer && !this.state.showsearch);

        return(
            <div>
                {/* {(this.state.feedback_show)? {(this.state.reranner)? (<Categoryselect reran={this.reran.bind(this)}/> ): null} */}
              <PopUpMessages changeUnregistered={this.changeUnregistered} Competitor_type={this.state.Competitor_type} unregistered={this.state.unregistered} feedback_show={this.state.feedback_show} InitialFeedbackCompleted={this.state.feedback.InitialFeedbackCompleted} freetrialendsurvey={this.state.feedback.freetrialendsurvey} closePopUp={this.closePopUp} />
              <Joyride
              run={this.state.showTour}
              showProgress
              continuous
              steps={steps}
              styles={{
                options: {
                  zIndex: 10000,
                },
              }}
              callback={(data) => {
                // You can handle events (like tour end) here if needed
                if (data.status === 'finished' || data.status === 'skipped') {
                  this.closeTour();
                }
              }}
            />
            <SettingsRightSide showLocationMarkers={this.state.showLocationMarkers} showRadiusAll={this.state.showRadiusAll} showLocationNames={this.state.showLocationNames} handleStreetView={this.handleStreetView} handleNavigation={this.handleNavigation} handleSettingsChanged={this.handleSettingsChanged}/>

              {/* <Tour
              steps={steps}
              isOpen={this.state.showTour}
              onRequestClose={this.closeTour} /> */}
              <Sidebar_left municipality_data={this.state.municipality_data} searchBarGetBrand={this.searchBarGetBrand} competitorSearch={this.state.competitorSearch} placeSearch={this.state.placeSearch} parentExportInProgress={this.parentExportInProgress} checkStatusExpanded={this.state.checkStatusExpanded} filterMapLocationsFunc={this.filterMapLocationsFunc} filterMapLocations={this.state.filterMapLocations} passesAllFilters={this.passesAllFilters} brand={this.state.brand} handleRemoveFilter={this.handleRemoveFilter} handleAddFilter={this.handleAddFilter} locationFilters={this.state.locationFilters} mapLocationClicked={this.state.mapLocationClicked} getcirclerange={this.getcirclerange} premiumFeatureClicked={this.premiumFeatureClicked} building_model={this.state.building_model} circle_range_clicked= {this.state.circle_range_clicked} hidesidebar={this.hidesidebar} building={this.state.building} Competitor_type={this.state.Competitor_type}  datalayer_show={this.state.datalayer_show} turf_area={this.state.turf_area} places_show={this.state.places_show} competitor_show={this.state.competitor_show}  places={this.state.places} Psychographic_parent={this.Psychographic_parent} industry_loading={this.state.industry_loading} refreshing_auto={this.refreshing_auto} refreshing_auto_value={this.state.refreshing_auto} showTourAgain={this.showTourAgain} bounds2={this.state.bounds2}  showTour={this.state.showTour} psychograph={this.state.psychograph} resettaskmanager={this.resettaskmanager} taskmanager={this.state.taskmanager} all_locations={this.state.all_locations} prospect_prospect={this.state.prospect_prospect} createmarkerhere={this.createmarkerhere} maptilelayer={this.maptilelayer} 
              shoppingcenter={this.state.shoppingcenter} getprospectsidebar={this.getprospectsidebar} getlocations={this.state.getlocations} traffic={this.state.traffic} housing_seasonality={this.state.housing_seasonality} housing_rent={this.state.housing_rent} housing_buy={this.state.housing_buy} cat_all={this.state.cat_all} traveltime_each_dif={this.state.traveltime_each_dif} traveltime_each_2018={this.state.traveltime_each_2018} traveltime_each_2012={this.state.traveltime_each_2012} type_industry={this.state.type_industry} dropthepin={this.dropthepin} degree={this.state.degree} fam_type={this.state.fam_type} occupation={this.state.occupation} expenses_diff={this.state.expenses_diff} expenses2012={this.state.expenses2012} expenses2018={this.state.expenses2018} family_estincome={this.state.family_estincome} 
              family_income= {this.state.family_income} age_income_prev={this.state.age_income_prev} age_income_header={this.state.age_income_header} age_income={this.state.age_income} age_income_overall={this.state.age_income_overall} fss={this.state.fss} cluster={this.state.cluster} type={this.state.type} type_income={this.state.type_income} id='search-leftside' droppingcheck={this.droppingcheck} center={this.state.center} commercial_data= {this.state.commercial_data} 
              industrygroup={this.state.industrygroup} total_age_sex={this.state.total_age_sex} turnthison= {this.turnthison} zipcode_tabulation={this.state.zipcode_tabulation} datalayer ={this.state.datalayer} family={this.state.family} freetier={this.state.freetier} tags={this.state.tags} status={this.state.status} defaultRange={this.state.defaultRange} sidebarUpdateLocationArea={this.sidebarUpdateLocationArea} getlocationrange={this.getlocationrange}/>
              
              <div id = "search-bar" ></div>
              <div id="search-here" data-tut="reactour__calculate" className={`${this.state.showsearch || this.state.showTour ? "display-block" : "display-none"}`}>
                <div className={this.state.showsearch_value || !this.state.showresult_red ? "" : "search-here-red"}>
                  <div id="search-leftside-loader" style={{ display: this.state.showsearching }}>
                    {this.state.showsearching && (
                      <div className="sk-fading-circle">
                        {Array.from({ length: 12 }).map((_, i) => (
                          <div key={i} className={`sk-circle${i + 1} sk-circle`}></div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="search-here-value">
                    {this.state.noresults ? "No Results" : this.state.showsearch_value ? <div className="padding-left5">Searching</div> : "Zoom back in"}
                  </div>
                </div>
              </div>

              <Legend searchBarGetBrand={this.searchBarGetBrand} customBrandSearch={this.state.customBrandSearch} traffic={this.state.traffic} premiumFeatureClicked={this.premiumFeatureClicked} freetier={this.state.freetier} datalayer_show={this.state.datalayer_show} datalayer_hide={this.datalayer_hide} datalayer_switch={this.datalayer_switch} min_val={this.state.min_val} max_val={this.state.max_val} cluster_type={this.state.cluster_type} maptilelayer={this.maptilelayer} datalayer={this.state.datalayer} layertype={this.layertype}/>
              {/* {(this.state.loading_layer)? <div className="spinner-setter spinner-center"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div>: null}                 */}
              <MainMappingComponent hideStreetView={this.hideStreetView} streetViewCoordinates_parent={this.state.streetViewCoordinates} streetView={this.state.streetView} map={map}/>
              {(this.state.Psychographic_parent)?<Psychographic_map Psychographic_close={this.Psychographic_close} eachvalue={this.state.Psychographic_parent.eachvalue} spent={this.state.Psychographic_parent.spent} age_name={this.state.Psychographic_parent.age_name} agegroup={this.state.Psychographic_parent.agegroup} avg_income={this.state.Psychographic_parent.avg_income} interest_category={this.state.Psychographic_parent.interest_category} interestall={this.state.Psychographic_parent.interestall}/>:null}
              {/* <CompetitorSidebar categories={this.state.competitorCategory} competitor_hide={this.competitor_hide} Places_zoom={this.state.turf_area > tract_threshold} competitor_show={this.state.competitor_show} places_change_deselect={this.competitor_change_deselect} places_change={this.places_change} places_category={this.state.places_category} places_subCategory={this.state.places_subCategory}/> */}
              <Places competitorCategory={this.state.competitorCategory} places={this.state.places} places_switch={this.places_switch} places_hide={this.places_hide} Places_zoom={this.state.turf_area > tract_threshold} places_show={this.state.places_show} places_change_deselect={this.places_change_deselect} places_change={this.places_change} places_category={this.state.places_category} places_subCategory={this.state.places_subCategory}/>
          </div>
            )};
}
