import React from 'react';
import {ElementsConsumer, CardElement, Elements} from '@stripe/react-stripe-js';
import maptize from './Images/maptize-black.png';
import axios from 'axios';
import * as EmailValidator from 'email-validator';
import OutsideClickHandler from 'react-outside-click-handler';

// import UserProfile from '../../public/userProfile.png';


class CheckoutForm extends React.Component {

    constructor(props) {
        super(props);
        this.state= {
            password : '',
            email: '',
            name: '',
            address: '',
            classes_name: '',
            classes_email: '',
            classes_pass: '',
            classes_address: '',
            classes_state: '',
            classes_city: '',
            passwordvalid: true,
            classes_zip: '',
            city: '',
            address:'',
            Errors: null,
            error_value: null,
            firststep: true,
            stateval: '',
            stripeval: true,
            postal_code: '',
            value: window.location.href,
            plan_type: ["basic", "professional", "enterprise"],
            token: localStorage.getItem('auth-token'),
            freetier: false

        }
        
      }
      getAuthentication = async () => {
        try{
          if(this.state.token != null){
          
              var resp = await axios.post('/signin/auth', {
              "token": this.state.token,    "registration": true
          })
            if(resp.data.freetier) this.setState({freetier: true, firststep: false, passwordvalid: true, email: resp.data.email, name: resp.data.name})
          }
          
      }
      catch(e){
          console.log(e, "failed to get back")
      }
      }

      componentWillReceiveProps(){
        
      }
      componentDidMount(){
        this.getAuthentication()
      }
      handleacityChange(e){
        this.setState({ classes_city: 'has-val', city: e.target.value});
      }
      handleEmailChange(e) {
        let email = e.target.value
        this.setState({ classes_email: 'has-val', email: email.toLowerCase()});
     }
     handleaddressChange(e) {
        this.setState({ classes_address: 'has-val', address: e.target.value});
     }
     handlenameChange(e) {
        this.setState({ classes_name: 'has-val'});
     }
     handlepasswordChange(e){
      var password = e.target.value; 
      var passwordvalid= true;
      if(!this.state.passwordvalid){
        passwordvalid = (password.match(/(?=.*[0-9])/) && password.length > 7)? true: false;
        
      }
         this.setState({classes_pass: 'has-val', password, passwordvalid})
     }
     handlenameChange(e){
        this.setState({classes_name: 'has-val', name: e.target.value})
    }
    handlestateChange(e){
      console.log(e.target.value)
      if(!isNaN(e.target.value))         this.setState({classes_state: 'has-val has-val2' , stateval: ""})
      if(isNaN(e.target.value) && e.target.value.length <= 2)
        this.setState({classes_state: 'has-val has-val2' , stateval: e.target.value})
    }
    handlezipChange(e){
      console.log(e.target.value)
      if(!isNaN(e.target.value) && e.target.value.length <=9)
        this.setState({classes_zip: 'has-val has-val2', postal_code: e.target.value})
    }
    handleSubmit = async (event) => {

      event.preventDefault();

      const {stripe, elements} = this.props
  
      if (!stripe || !elements || !this.state.stripeval) {
        // Stripe.js has not yet loaded.
        // Make  sure to disable form submission until Stripe.js has loaded.
        return;
      }
      if(!event.target.address.value)
        return this.setState({error_value: 'Please fill out address'})
      if(!event.target.state.value)
        return this.setState({error_value: 'Please fill out State'})
      if(!event.target.zip.value)
        return this.setState({error_value: 'Please fill out Postal Code'})
      if(!event.target.city.value)
        return this.setState({error_value: 'Please fill out City'})
      this.setState({stripeval:false})
      
      const  billing_details= {
        email: this.state.email,
        name: this.state.name,
        address:{
            line1: event.target.address.value,
            state: this.state.stateval,
            postal_code: this.state.postal_code,
            city: event.target.city.value,
        }
        
      }

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details
      });
      if(result.error)
        return this.setState({ error_value: result.error.message, stripeval: true})
    
    this.stripePaymentMethodHandler(result, this.state.password);

    };

    async stripePaymentMethodHandler(result, password){
    const {stripe, elements} = this.props
    let stripe_data = {}
    try{  
    let st = await  axios.post('/signup', 
      {  freetier: this.state.freetier,
        token: this.state.token,
        password,
        payment_method: result.paymentMethod.id,
        billing_details: result.paymentMethod.billing_details,
        value: this.state.value
      });
      stripe_data = await st.data;
    }catch(ee){
        
        return this.setState({error_value: ee.response.data, stripeval: true})
    }
      
      
      if(stripe_data.status == 'requires_action'){
        let resultcard = await stripe.confirmCardPayment(stripe_data.client_secret);
        if(resultcard.error){
            this.setState({error_value: resultcard.error.message, stripeval: true})
        }else{

            try{
                let stsub = await  axios.post('/signup/verify', 
                {  freetier: this.state.freetier,
                  token: this.state.token,
                  password,
                  name: result.paymentMethod.billing_details.name,
                  email: result.paymentMethod.billing_details.email,
                  customer: stripe_data.customer,
                  value: this.state.value,
                });
                stsub = await stsub.data;
                localStorage.setItem("auth-token", stsub.token)
                 window.location.href = "/setup";
                
                
            }catch(e){
                console.log(e)
                this.setState({error_value: e.response.data, stripeval: true })
            }
        }
      }else{
        if(!this.state.freetier) localStorage.setItem("auth-token", stripe_data.token)
        window.location.href = "/setup";
      }
    }
    nextstep = ()=>{
      if(EmailValidator.validate(this.state.email)){
        if(this.state.name.trim().length < 1) return this.setState({error_value:"Please provide your full name"})
        if(this.state.password.length < 8) return this.setState({error_value:"Password must be at least 8 Characters", passwordvalid: false})
        if(!this.state.password.match(/(?=.*[0-9])/)) return this.setState({error_value:"Password must contain at least One Number", passwordvalid: false})
        this.setState({firststep: false, passwordvalid: true, error_value: null})
      }else{
        this.setState({error_value:"Please enter a valid Email", passwordvalid: false})
      }
      
    }
    render() {
        const CARD_ELEMENT_OPTIONS = {
            style: {
              base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
              },
              
            },
            hidePostalCode:true,
          };
      const {stripe} = this.props;
  
      return (
        <div>
        <div className="login-logo-register">REGISTER FOR {this.state.plan_type.filter((e) => this.state.value.includes(e))[0].toUpperCase()} PLAN</div>      
        <form onSubmit={this.handleSubmit}>
          {(this.state.freetier)?<div className="login-logo-fremium-total"><img className="login-logo-fremium-image" src='/userProfile.png' onClick={() => this.setState({freetierOptions: true})}/>
          {(this.state.freetierOptions)? <div >
            <OutsideClickHandler onOutsideClick={() => this.setState({freetierOptions: false})} >

            <div className="login-logo-register-free-menu">
              <div className="task-options-each" onClick={() => window.location.href='/account'}>Account</div>
              <div className="task-options-each" onClick={() => window.location.href='https://mapchise.com/pricing/'}>Pricing</div>
              <div className="task-options-each" onClick={() => window.location.href='/'}>Map Research</div>
              <div className="task-options-each" onClick={() => window.location.href='/competitor'}>Competitor Research</div>
            </div>
            </OutsideClickHandler>
          </div>:null}
          </div>:null}
        {(this.state.error_value != null)? <div className= "error-logger error-logger-blue">{this.state.error_value}</div>:null}
       {(this.state.firststep)?<div>
        <div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "Full Name">
			<input className={"input100 "+ this.state.classes_name} onChange={this.handlenameChange.bind(this)} type="text" name="name" value={this.state.name} ></input>
			<span className="focus-input100" data-placeholder="Full Name" id="name"></span>
		</div>
        <div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "Email">
			<input className={"input100 "+ this.state.classes_email} onChange={this.handleEmailChange.bind(this)} type="text" name="email" value={this.state.email} ></input>
			<span className="focus-input100" data-placeholder="Email" id="usern"></span>
		</div>
        <div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "password">
			<input className={"input100 "+ this.state.classes_pass} type="password" onChange={this.handlepasswordChange.bind(this)} name="password" value={this.state.password} ></input>
			<span className={(this.state.passwordvalid)?"focus-input100":"focus-input100 focus-input-red"} data-placeholder="Password" id="password"></span>
		</div>
    <div onClick={this.nextstep} className="login100-form-btn">Next Step</div>
    </div>:<div>

        <div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "Address" id="address">
			<input className={"input100 "+ this.state.classes_address} onChange={this.handleaddressChange.bind(this)} type="text" name="address" value={this.state.address}  ></input>
			<span className="focus-input100" data-placeholder="Address" ></span>
		</div>
        <div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "City" id="city">
			<input className={"input100 "+ this.state.classes_city} onChange={this.handleacityChange.bind(this)} type="text" name="city" value={this.state.city} ></input>
			<span className="focus-input100" data-placeholder="City" ></span>
		</div>
        <div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "State" id="state">
			<input className={"input100 "+ this.state.classes_state} onChange={this.handlestateChange.bind(this)} type="text" name="state" value={this.state.stateval} ></input>
			<span className="focus-input100" data-placeholder="State" ></span>
		</div>
        <div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "Zip" id="zip">
			<input className={"input100 "+ this.state.classes_zip} onChange={this.handlezipChange.bind(this)} type="text" name="zip" value= {this.state.postal_code} ></input>
			<span className="focus-input100" data-placeholder="Postal Code" ></span>
		</div>
    
            <label>
                Card details
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            </label>
            <button className="login100-form-btn login100-40-back" onClick={() => this.setState({firststep: true})} disabled={!stripe}>
            Back
          </button>

          <button className="login100-form-btn login100-40-btn" type="submit" disabled={!stripe}>
            Register
          </button>
          </div>}
        </form>
        </div>
      );
    }
  }
  export default function InjectedCheckoutForm() {
    return (
    <div>

      <ElementsConsumer>
        {({stripe, elements}) => (
          <CheckoutForm  stripe={stripe} elements={elements} />
        )}
      </ElementsConsumer>
      
      </div>
    );
  }