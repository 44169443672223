import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const MarkerPinPopUp = ({ data, getCurrentRange, getCurrentLocationsVal, deleteLocationConfirm }) => {

  return (
    <div id='droppingcheck-preset'>
        <div>Name: {data.name}  </div>
        <div className='prospect-address'>Address: {data.address} </div>
        <div className='prospect-address'>Status: {data.status} </div>
        <div id='droppingcheck-showmore' onClick={getCurrentLocationsVal}>Edit Boundary</div>
        <div id='droppingcheck-getrange' onClick={getCurrentRange}>Toggle Boundary</div>
        <div id='droppingcheck-d' onClick={deleteLocationConfirm}>X Delete</div>
    </div>
  );
};

export default MarkerPinPopUp;