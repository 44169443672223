import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Responsive, { WidthProvider } from "react-grid-layout";
import axios from 'axios';
import OutsideClickHandler from 'react-outside-click-handler';
import { confirmAlert } from 'react-confirm-alert';



export class Task_name_menu extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    top_title: false,
  }
  this.delete = this.delete.bind(this)
  this.fullydelete = this.fullydelete.bind(this)
  }

  currentusers(){
    if(this.state.tagselected)
    window.location.href = "/accountsub?tag="+this.state.tagselected
    else
    window.location.href = "/accountsub"
  }
  async delete(i, bool){
    var un = (bool)? "": "un";
    confirmAlert({
    title: 'Confirm to '+ un + 'archive location',
    message: 'Are you sure you want to '+ un + 'archive '+i,
    buttons: [
      {
        label: 'Confirm',
        onClick: () => this.props.archivelocation(i, bool)
      },
      {
        label: 'Cancel',
        
      }
    ]
  })
}

async fullydelete(i){
  confirmAlert({
  title: 'Confirm to delete location',
  message: 'Are you sure you want to delete '+i,
  buttons: [
    {
      label: 'Confirm',
      onClick: () => this.props.deletelocation(i)
    },
    {
      label: 'Cancel',
      
    }
  ]
})
}
  render() {
      // layout is an array of objects, see the demo for more complete usage

      return (
        

        <div className="task-top-threedots task-middle-threedots" onClick={() => this.setState({top_title: true})}>...
        {(this.state.top_title)? <OutsideClickHandler onOutsideClick={() => {this.setState({top_title:false})}} > 
        {(this.props.statearchived && this.props.title !== "My Tasks") ?
        <div className="task-options-total tasks-opstions-total-next">
        <div className="task-options-each" onClick={() => this.fullydelete(this.props.id, false)}>Delete Location</div>
        <div className="task-options-each" onClick={() => this.delete(this.props.id, false)}>Unarchive Location</div>
        </div> 
        :
        <div className="task-options-total tasks-opstions-total-next">
        {( this.props.title !== "My Tasks")?<div className="task-options-each" onClick={() => this.props.indexadd(null, this.props.id)}>Add New Task</div>:null}
        {this.props.account_type? <div className="task-options-each" onClick={() => window.location.href = "/accountsub"}>Add New User</div>:null}
          {(!this.props.showeditionalinfo)?<div><div className="task-options-each" onClick={() => this.props.editlocation(this.props.id)}>Edit Location</div>
      {this.props.account_type? <div className="task-options-each" onClick={() => this.delete(this.props.id, true)}>Archive Location</div>:null}</div>:null}
        </div>}
        </OutsideClickHandler>:null
        }
        </div>
      )
    }
  }