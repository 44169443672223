import React, {Component} from 'react';
import Calendar from 'react-calendar';
import Select from 'react-dropdown-select';

export class Task_Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            calendar_type: [{value:"1", label:"One Time"}, {value:"0", label:"Recurring"}],
            calendar_type_selected:[{value:"1", label:"One Time"}],
            datetime: [],
            datarecurring: [],
            recurringmonthly: false,
            recurringdaily: false,
            dateformat: null,
            duedate: null,
            time: [{value:"12AM", label:"12:00 AM", num:24}, {value:"1AM", label:"1:00 AM", num:1}, {value:"2AM", label:"2:00 AM", num:2}, {value:"3AM", label:"3:00 AM", num:3}, {value:"4AM", label:"4:00 AM", num:4}, {value:"5AM", label:"5:00 AM", num:5},
            {value:"6AM", label:"6:00 AM", num:6}, {value:"7AM", label:"7:00 AM", num:7}, {value:"8AM", label:"8:00 AM", num:8}, {value:"9AM", label:"9:00 AM", num:9}, {value:"10AM", label:"10:00 AM", num:10}, {value:"11AM", label:"11:00 AM", num:11}, {value:"12PM", label:"12:00 PM", num:12},
            {value:"1PM", label:"1:00 PM", num:13}, {value:"2PM", label:"2:00 PM", num:14}, {value:"3PM", label:"3:00 PM", num:15}, {value:"4PM", label:"4:00 PM", num:16}, {value:"5PM", label:"5:00 PM" , num:17}, {value:"6PM", label:"6:00 PM", num:18}, {value:"7PM", label:"7:00 PM", num:19}, {value:"8PM", label:"8:00 PM", num:20},
            {value:"9PM", label:"9:00 PM", num:21}, {value:"10PM", label:"10:00 PM", num:22}, {value:"11PM", label:"11:00 PM", num:23 },],
            dataofweek: [{value:"0", label:"Sunday"}, {value:"1", label:"Monday"}, {value:"2", label:"Tuesday"}, {value:"3", label:"Wednesday"}, {value:"4", label:"Thursday"}, {value:"5", label:"Friday"}, {value:"6", label:"Saturday"}],
            dataofweekpicked: [],
        }
        this.calendar_time = this.calendar_time.bind(this);
        this.duedate = this.duedate.bind(this);
        this.calendar_type= this.calendar_type.bind(this);
        this.dayofweek = this.dayofweek.bind(this);
    }
    componentWillMount(){
        var format = this.props.dateformat
        /**dateformat is the full object of the type, date, week (if recurring), what type of recurring, and hour */
        if(format){
            
            let calendar_type_selected = (format.type)? format.type: this.state.calendar_type_selected;
            let dateformat = (format.dateformat)? new Date(format.dateformat): this.state.dateformat;
            console.log(dateformat, 'date formated')
            let dataofweekpicked = (format.dataofweekpicked)? format.dataofweekpicked: this.state.dataofweekpicked;
            
            let datetime = (format.datetime)? format.datetime : this.state.datetime;
            let recurringdaily = false;
            let datarecurring = [];
            let recurringmonthly = false;
            if(format.datarecurring.length > 0){
            
            
            recurringdaily = (format.datarecurring[0].value ==="Daily")? true: false;
            datarecurring = format.datarecurring;
            recurringmonthly = (format.datarecurring[0].value !=="Monthly")? true: false;
            
            }
            this.setState({datetime, dataofweekpicked,dateformat,  calendar_type_selected, datarecurring, recurringdaily, recurringmonthly})
        }
    }
    calendar_time(values){
        
        this.setState({datetime: values}, () => {
            if(this.state.calendar_type_selected[0].value === "0" && this.state.recurringdaily) this.duedate()
        })
    }
    dayofweek(values){
        this.setState({dataofweekpicked: values}, () => {
            this.duedate()
        })
    }
    calendar_type(values){
    this.setState({calendar_type_selected: values})
    }
    duedate(date){
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        console.log(date, 'date inside duedate')
        var duedate = null;
        var day = null;
        var datetime = null;
        if(this.state.calendar_type_selected[0].value ==="1"){
         var timerval = "";
         day = monthNames[date.getMonth()]  
         
        if(this.state.datetime[0]){ 
            datetime = this.state.datetime[0].num;
            date.setHours(datetime, 0, 0)
            timerval = ", "+  this.state.datetime[0].value;       
        }
        var dateday = date.getDate()
        
         duedate = day+" "+ dateday  + timerval;
        }else{
            if(!this.state.datarecurring[0]) return alert("Please pick a Recurring Period")
            let datareq = this.state.datarecurring[0].value
            if(datareq ==="Monthly"){
                day = monthNames[date.getMonth()]
                console.log(this.state.datetime[0], 'date time here')
                if(this.state.datetime[0]){ 
                    datetime = this.state.datetime[0].num;
                    date.setHours(datetime, 0, 0)
                }
                var dateday = date.getDate()
                var datetimemonthly =  (this.state.datetime[0])? ", "+  this.state.datetime[0].value: "";
                duedate = day+" "+  dateday + datetimemonthly;
            }else if(datareq === "Weekly"){
                day = this.state.dataofweekpicked[0].label
                if(this.state.datetime[0]){ 
                    datetime = this.state.datetime[0].value;
                }
                duedate = day.slice(0, 3) +" "+ datetime
            }else{
                duedate = this.state.datetime[0].label +" Daily";
            }
        }
        this.setState({duedate: duedate,  dateformat: date	}, () => {
            let state = this.state;
            this.props.updatedate(state.duedate, {datarecurring: state.datarecurring, type: state.calendar_type_selected, dateformat: state.dateformat, datetime: state.datetime, dataofweekpicked: state.dataofweekpicked})
        })
    }
    calendar_recurring(values){
        let recurringmonthly = (values[0].value !== "Monthly")? true: false;
        let recurringdaily = (values[0].value === "Daily")? true: false;
        this.setState({datarecurring: values, recurringmonthly, recurringdaily})
    }
    render() {
    return (
        <div className="task-calendar-type">
        <div className="task-calendar-totaleach">
        <Select
        options={this.state.calendar_type}
        values={this.state.calendar_type_selected}
        onChange={(values) => this.calendar_type(values)}
        />
        {(this.state.calendar_type_selected[0].value === "0")?
        <Select
        options={[{value:"Daily", label:"Daily"}, {value:"Weekly", label:"Weekly"}, {value:"Monthly", label:"Monthly"}]}
        placeholder="Recurring Period"
        values={this.state.datarecurring}
        onChange={(values) => this.calendar_recurring(values)}
        />:null}
        <Select
        options={this.state.time}
        placeholder="Select A Time"
        values={this.state.datetime}
        onChange={(values) => this.calendar_time(values)}
        />
        {(this.state.calendar_type_selected[0].value === "1" || (this.state.calendar_type_selected[0].value === "0" && !this.state.recurringmonthly) )?null:
        <Select
        options={this.state.dataofweek}
        placeholder="Select A Day"
        values={this.state.dataofweekpicked}
        onChange={(values) => this.dayofweek(values)}
        />}
        </div>
        {(this.state.calendar_type_selected[0].value === "1" || !this.state.recurringdaily)?
        <div className="task-calendar-total">{(this.state.calendar_type_selected[0].value === "1" || (this.state.calendar_type_selected[0].value === "0" && !this.state.recurringmonthly) )?
        <Calendar
        onChange={this.duedate}
        value={this.state.dateformat}
        />:  null}</div>:null}
        </div>
    )}
}