import React from 'react';
import maptize from './Images/maptize-black.png';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

export class RegisterFreetier extends React.Component{
    constructor(props) {
      super(props);
      this.state= {
          password : '',
          email: '',
          name: '',
          classes_name: '',
          classes_email: '',
          classes_pass: '',
          Errors: null,
      }
      
    }
    handlepasswordChange = (e) => {
        var password = e.target.value; 
        var passwordvalid= true;
        if(!this.state.passwordvalid){
          passwordvalid = (password.match(/(?=.*[0-9])/) && password.length > 7)? true: false;
          
        }
           this.setState({classes_pass: 'has-val', password, passwordvalid})
    }

    handlenameChange = (e) => {
        this.setState({classes_name: 'has-val', name: e.target.value})
    }
    handleEmailChange = (e) => {
        let email = e.target.value
        this.setState({ classes_email: 'has-val', email: email.toLowerCase()});
    }

    captcha =(e) => this.setState({token: e})

    handleSubmit = async (event) => {
        event.preventDefault();
        const urlParams = new URLSearchParams(window.location.search);
        const trialToken = urlParams.get('token');
        if(!this.state.token) return this.setState({error_value: "Please complete the Captcha properly"})
        if(this.state.name.trim().length < 1) return this.setState({error_value:"Please provide your full name"})
        if(this.state.password.length < 8) return this.setState({error_value:"Password must be at least 8 Characters", passwordvalid: false})
        if(!this.state.password.match(/(?=.*[0-9])/)) return this.setState({error_value:"Password must contain at least One Number", passwordvalid: false})
        try{

            let response = await axios.post('/signup/freetier', { token: this.state.token,   
                name: this.state.name.trim(), 
                password: this.state.password, 
                email: this.state.email, 
                trialToken,
                mapRegister: true
            })
            let res = await response.data;
            localStorage.setItem("auth-token",response.headers['auth-token'])
            window.location.href = "/setup";

        }catch(e){
            if(e.response.data.includes('One time token already used.')){
                urlParams.delete('token');
                window.history.pushState({}, '', window.location.pathname);
            }
            this.setState({error_value: e.response.data })
        }
    }
    render() {

        return (
        <div className="login-page" >
            <div className="login-image"></div>
            <div className="limiter">
		        <div className="container-login100 container-loginregister">
			        <div className="wrap-login100 p-t-85 p-b-20">

                        <span className="login100-form-avatar">
                            <img src={maptize} alt="AVATAR" onClick={()=> {window.location.href ="/login"}} className="login-avatar-register"/>
                        </span>
                        <div className="login-logo-register">REGISTER FOR A FREE ACCOUNT</div>      
                        <form onSubmit={this.handleSubmit}>
                            {(this.state.error_value != null)? <div className= "error-logger error-logger-blue">{this.state.error_value}</div>:null}
                            <div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "Full Name">
                                <input className={"input100 "+ this.state.classes_name} onChange={this.handlenameChange} type="text" name="name" value={this.state.name} ></input>
                                <span className="focus-input100" data-placeholder="Full Name" id="name"></span>
                            </div>
                            <div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "Email">
                                <input className={"input100 "+ this.state.classes_email} onChange={this.handleEmailChange} type="text" name="email" value={this.state.email} ></input>
                                <span className="focus-input100" data-placeholder="Email" id="usern"></span>
                            </div>
                            <div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "password">
                                <input className={"input100 "+ this.state.classes_pass} type="password" onChange={this.handlepasswordChange} name="password" value={this.state.password} ></input>
                                <span className={(this.state.passwordvalid)?"focus-input100":"focus-input100 focus-input-red"} data-placeholder="Password" id="password"></span>
                            </div>
                            <div className="register-captcha">
                            <ReCAPTCHA sitekey="6LdY69UZAAAAAMVP_K9AVLSOOvk6Y6wNl75Q7lO-"     onChange={this.captcha}/>
                            </div>
                            <div className="input100">
                                By clicking "Register", you agree to the <a href="https://mapchise.com/terms-and-conditions/">Terms</a> and <a href="https://mapchise.com/privacy">Privacy Policy</a>
                            </div>
                            <button className="login100-form-btn login100-40-btn" type="submit" >Register</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        )
        
    }
}