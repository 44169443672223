import React from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';

export default function LimitedMultiSelect({ options, changeOptions, selectedOptions, limit }) {
  // const [selectedOptions, setSelectedOptions] = React.useState([]);

  const handleOnChange = (event, newValue) => {
    // newValue contains all currently selected values
    if (newValue.length <= limit) {
      console.log('newValue: ', newValue)
      changeOptions(newValue);
    } else {
      // Optionally, show a message to the user indicating the limit
      alert("You can only select up to 5 items.");
    }
  };
  console.log('selectedOptions: ', selectedOptions)

  return (
    <Autocomplete
      multiple
      id="tags-standard"
      options={options}
      getOptionLabel={(option) => option.label}
      value={selectedOptions}
      onChange={handleOnChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Select up to 5 options"
          placeholder="Favorites"
        />
      )}
    />
  );
}
