import React from 'react';
import ReactDOM from 'react-dom';
import '../../Login.css';
import maptize from '../Images/maptize-black.png';
import axios from 'axios';
import background_image from '../Images/background_image.png'

export class Passwordconfirm extends React.Component{
    constructor(props) {
      super(props);
      this.state= {
          password : '',
          confirmpassword : '',
          email: '',
          app: null,
          classes_email: '',
          classes_pass: '',
          Errors: null,
          sent: false,
          app: null,
          
      }
       
      
    }
    componentWillMount(){
        // this.validationnumber()
        this.confirmthis()
        // this.setState(app)
    }

    componentDidMount(){
        
    }
    async confirmthis(){
        try{
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let app = params.get('app');
        let email = params.get('email');
        this.setState({email: email, app: app})
        let response = await axios.post('/signin/passwordconfirm', {
             "email": email,
            "app": app,
        })
        
        }
        catch(ee){
            if(ee.response.status ==400) this.setState({Errors: "Link has expired"})
        }
    }
   async validationnumber(e){
        e.preventDefault();
        if(this.state.loadin) return false;
        if(this.state.password !== this.state.confirmpassword)
            return this.setState({Errors: "Password does not match"})
        if(this.state.password.length == 0)
            return this.setState({Errors: "Please enter in a password"})
        try{
            this.setState({loadin: true})
            let response = await axios.post('/signin/resetpassword', {
                "app": this.state.app,
                "email": this.state.email,
                "password": this.state.password,
                "confirmpassword": this.state.confirmpassword
            })
            
            if(response.status == 200) window.location.href = "/login";
            }
            catch(ee){
               if(ee.response.status ==400) this.setState({Errors: "An error has occured", loading: false})
               if(ee.response.status ==401) this.setState({Errors: ee.response.data, loading: false})
            }
        this.setState({loadin: false})
        
    }
    password(e) {
        this.setState({password: e.target.value, classes_email: 'has-val'});
     }
     confirmpassword(e) {
        this.setState({confirmpassword: e.target.value, classes_email: 'has-val'});
     }


    render() {

        return (
        <div className="login-page" >
            <img src={background_image} className="login-image"></img>
            <div className="limiter">
		        <div className="container-login100">
                    
			        <div className="wrap-login100 p-t-85 p-b-20">
				    <form className="login100-form validate-form" onSubmit={this.validationnumber.bind(this)}>
					
					<span className="login100-form-avatar">
						<img src={maptize} alt="AVATAR"/>
					</span>
                    <div className="login-logo-login">Reset Password</div>
                    {(!this.state.sent)?<div>
                    {(this.state.Errors != null)? <div className= "error-logger">{this.state.Errors}</div>:null}
					<div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "Enter Password">
						<input className={"input100 "+ this.state.classes_email} type="password" name="username" value={this.state.password} onChange={this.password.bind(this)} ></input>
						<span className="focus-input100" data-placeholder="New Password" id="usern"></span>
					</div>
					<div className="wrap-input100 validate-input m-t-85 m-b-35" data-validate = "Enter Password">
						<input className={"input100 "+ this.state.classes_email} type="password" name="username" value={this.state.confirmpassword} onChange={this.confirmpassword.bind(this)} ></input>
						<span className="focus-input100" data-placeholder="Confirm Password" id="usern"></span>
					</div>
                    <div className="login-forgotpassword">
							<span className="txt1">
								Go back to 
							</span>
							<a href="/login" className="txt2">
								 Login
							</a></div>
                        <div className="container-login100-form-btn">
						<button className="login100-form-btn">
							RESET
						</button>
                        
					    </div>
                        </div>
                        : <div>To reset your password, please follow the instructions in the email sent to you.</div>
                        }   
                        </form>
                    </div>
                </div>
            </div>
        </div>
            )
        }

}