import React, {Component} from 'react';
import maptize from '../Images/maptize_logo_white-01.svg';
import axios from 'axios';


export class Competitor_research_menu extends React.Component{
    constructor(props) {
        super(props);
        this.state= {
            width: false
        }
    }
    updateDimensions = () =>{
        
        let width = ( window.innerWidth > 1000)
        this.setState({width})
    }
    windowpage = () => {
        let page = window.pageYOffset
        let pages = false
        if (page > 0)
            pages = true
        this.setState({page: pages})
    }
    componentWillMount() {
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('scroll', this.windowpage);

        let width = ( window.innerWidth > 1000)
            this.setState({width})
        
    }
    getstarted = (freetier) => {
        if(freetier == null){
            this.props.register(); 
            return this.setState({submenue: false})
        } 
        if(freetier)return window.location.href = "/pricing"
        window.location.href = "/"
    }
    getstarted_name = (freetier) => {
        if(freetier == null) return "Get Started"
        if(freetier)return "Upgrade"
        return "Map"

    }
    account = (freetier) => {
        if(freetier == null){
             this.props.login(); 
             return this.setState({submenue: false})
        } 
        window.location.href = "/account"
    }
    account_name = (freetier) => {
        if(freetier == null) return "Login"
        return "Account"

    }
    render() {
        return (
            <div>
                {(this.state.submenue)?
                <div className="Competitor-menu-mobile">
                <div className="Competitor-menu-mobile-exit fa fa-times" onClick={() => this.setState({submenue: false})}></div>
                <div className="Competitor-research-menu-each-mobile" onClick={() => this.setState({industry: !this.state.industry})}>Industries</div>
                {(this.state.industry)?<div className="Competitor-submenu-mobile">
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://mapchise.com/franchise"} >Franchise</div>
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://mapchise.com/Retail"}>Retail</div>
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://mapchise.com/Restaurant"}>Restaurant</div>
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://mapchise.com/Hospitality"}>Hospitality</div>
                </div>: null}
                <div className="Competitor-research-menu-each-mobile" onClick={() => this.setState({solutions: !this.state.solutions})}>Solutions</div>
                {this.state.solutions? <div className="Competitor-submenu-mobile">
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://mapchise.com/business-location-intelligence"}>Business Location Intelligence</div>
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://app.mapchise.com/competitor"}>Competitor Analysis</div>
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://mapchise.com/task-management"}>Task Management Software</div>
                </div>: null}
                <div className="Competitor-research-menu-each-mobile" onClick={() => window.location.href = "https://mapchise.com/pricing"} >Pricing</div>
            <div className="Competitor-research-menu-each-mobile" onClick={() => window.location.href = "https://mapchise.com/hub"}>Content</div>
            <div className="Competitor-research-menu-each-mobile" onClick={() => window.location.href = "https://mapchise.com/contact"}>Contact Us</div>
            {<div className="Competitor-research-menu-each-mobile" onClick={() => this.account(this.props.freetier)}>{this.account_name(this.props.freetier)}</div>}
            {<div className="Competitor-research-menu-each-mobile"  onClick={() => this.getstarted(this.props.freetier)}>{this.getstarted_name(this.props.freetier)}</div>}    
                </div>:null}




            <div className= {(this.state.page || this.props.section_hide)?"Competitor-research-menu":"Competitor-research-menu2"}>
            <img className="Competitor-research-menu-logo" onClick={() => window.location.href = "https://app.mapchise.com/competitor"} src={maptize} />
            {(this.state.width)?
            <div className="Competitor-computer">
            <div className="Competitor-menu-tabs">
            <div className="Competitor-research-dropdown" onMouseEnter={() => this.setState({industry: true})} onMouseLeave={() => this.setState({industry: false})}>
            <div className="Competitor-research-menu-each" >Industries</div>
            {(this.state.industry)?<div className="Competitor-submenu">
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://mapchise.com/franchise"} >Franchise</div>
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://mapchise.com/Retail"}>Retail</div>
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://mapchise.com/Restaurant"}>Restaurant</div>
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://mapchise.com/Hospitality"}>Hospitality</div>
            </div>: null}
            </div>

            <div className="Competitor-research-dropdown" onMouseEnter={() => this.setState({solutions: true})} onMouseLeave={() => this.setState({solutions: false})}>
            <div className="Competitor-research-menu-each">Solutions</div>
            {this.state.solutions? <div className="Competitor-submenu">
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://mapchise.com/business-location-intelligence"}>Business Location Intelligence</div>
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://app.mapchise.com/competitor"}>Competitor Analysis</div>
                <div className="Competitor-submenu-each" onClick={() => window.location.href = "https://mapchise.com/task-management"}>Task Management Software</div>
            </div>: null}
            </div>

            <div className="Competitor-research-menu-each" onClick={() => window.location.href = "https://mapchise.com/pricing"} >Pricing</div>
            <div className="Competitor-research-menu-each" onClick={() => window.location.href = "https://mapchise.com/hub"}>Content</div>
            <div className="Competitor-research-menu-each" onClick={() => window.location.href = "https://mapchise.com/contact"}>Contact Us</div>
            </div>
            <div className="Competitor-research-logintotal">
            <div className="Competitor-research-login" onClick={() => this.account(this.props.freetier)}>{this.account_name(this.props.freetier)}</div>
            <div className="Competitor-research-started" onClick={() => this.getstarted(this.props.freetier)}>{this.getstarted_name(this.props.freetier)}</div>
            </div>
            </div>:
            <div>
                <div className="Competitor-research-hamburger fa fa-bars" onClick={() => this.setState({submenue: true})}></div>

            </div>
            }
      </div> 
      </div>           
      )}
}