import React, {Component} from 'react';
import {PopUpInitialFeedback} from './PopUpInitialFeedback'
import {PopUpFreeTrialEnded} from './PopUpFreeTrialEnded'
import {PopupRegister} from '../Registration/registrationPopUp'
import {PopUpLogin} from '../Registration/loginPopUp'
import {PopupRegisterSelection} from '../Registration/registrationPopUpSelection'

export class PopUpMessages extends React.Component{

    constructor(props) {
        super(props);

        this.state= {
            login: false
        }

    }
    
    changeRegisterType = (changeType) => {
        this.setState({login: changeType})
    }

    closePopUp = () => this.props.closePopUp();
    render() {
        return (
                <div>
                        {(this.props.unregistered && !this.state.login) && <PopupRegister changeUnregistered={this.props.changeUnregistered} changeRegisterType={this.changeRegisterType} />}
                        {(this.props.unregistered && this.state.login) && <PopUpLogin changeRegisterType={this.changeRegisterType} />}
                        {(!this.props.unregistered && this.props.Competitor_type.length > 0 && this.props.Competitor_type[0] === 'N/A') && <PopupRegisterSelection changeRegisterType={this.changeRegisterType} />}
                        {/* {(this.props.InitialFeedbackCompleted && this.props.feedback_show) && <PopUpInitialFeedback closePopUp={this.closePopUp}/>} */}
                        {(this.props.freetrialendsurvey && this.props.feedback_show) && <PopUpFreeTrialEnded closePopUp={this.closePopUp}/>}
                </div>
            )
    }
}