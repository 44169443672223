import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes, // Updated import
  Route,
  Link
} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import 'font-awesome/css/font-awesome.min.css';


// import {Home} from './components/Home';
import {Competitor_research} from './components/Competitor/Competitor_research'
import {MainMapping} from './components/MainMapping';
import {Login} from './components/Authentication/Login';
import {Register} from './components/Register';
import {Setup} from './components/Setup';
import {Account_details} from './components/Authentication/Account_details';
import {Taskmanager} from './components/Task/task_manager.js';
import {Passwordreset} from './components/Authentication/Passwordreset.js';
import {Passwordconfirm} from './components/Authentication/Passwordconfirm.js';
import {Account_sub} from './components/Authentication/Account_sub.js';
import {Passwordset} from './components/Authentication/Passwordset.js';
import {Account_sub_setting} from './components/Authentication/Account_sub_setting.js';
import {Competitor_search} from './components/Competitor/Competitor_research_search'
import TagManager from 'react-gtm-module'
import { Dashboard } from './components/dashboard';
import {RegisterFreetier} from './components/RegisterFreetier'
import {PopUpMessages} from './components/PopUp_Survey/PopUpMessages';

class App extends React.Component{

  
  render() {
    return (
      <StrictMode>

      <Router>
        <div>
          <Routes> 
            <Route path="/login" element={<Login />} />
            <Route path="/register/basic" element={<Register />} />
            <Route path="/register/professional" element={<Register />} />
            <Route path="/register/enterprise" element={<Register />} />
            <Route path="/register/freetier" element={<RegisterFreetier />} />
            <Route path="/" element={<MainMapping />} />
            <Route path="/setup" element={<Setup />} />
            <Route path="/account" element={<Account_details />} />
            <Route path="/tasks" element={<Taskmanager />} />
            <Route path="/passwordreset" element={<Passwordreset />} />
            <Route path="/passwordconfirm" element={<Passwordconfirm />} />
            <Route path="/accountsub" element={<Account_sub />} />
            <Route path="/passwordset" element={<Passwordset />} />
            <Route path="/competitor" element={<Competitor_research />} />
            <Route path="/competitor-research" element={<Competitor_search />} />
            <Route path="/accountsubsetting" element={<Account_sub_setting />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </div>
      </Router>
        </StrictMode>
    );
    }
}
const tagManagerArgs = {
  gtmId: "GTM-MCTF9B9"
}
 
TagManager.initialize(tagManagerArgs)  

const container = document.getElementById('root');
const root = createRoot(container); // Use createRoot
root.render(<App />);
