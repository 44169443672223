import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export function UI_Table({rows, titles}) {
    // const rows = [
    //   { Name_final: "Northwood Town Center", loc_overlap_count: 9, _id: "65d3cf9db8331464b115ecd9" }
    // ];
    const headerStyle = {
        fontFamily: '"Source Sans Pro", Sans-serif',
        fontSize: '15px',
        // fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.27,
        letterSpacing: 'normal',
        color: '#000000',
      };
    return (
      <TableContainer component={Paper} style={{ maxHeight: 250 }}>
        <Table sx={{}} aria-label="simple table" stickyHeader >
          <TableHead >
            <TableRow>
              {(titles).map((e) => <TableCell style={{...headerStyle,  fontWeight: 600, backgroundColor: '#f8f8f9' }} key={e.title} >{e.title}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {(titles).map((e) => <TableCell style={headerStyle} key={e.key} >{row[e.key]}</TableCell>)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  