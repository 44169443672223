import React from 'react';
import ReactDOM from 'react-dom';
import '../Setup.css';
import maptize from './Images/maptize-black.png';
import axios from 'axios';
import {Categoryselect} from './category_selector.js';
import Select from 'react-dropdown-select';

import College from '../svgfiles/college.png';
import Entertainment from '../svgfiles/Entertainment.png';
import Events from '../svgfiles/event.png';
import Food from '../svgfiles/food.png';
import Nightlife from '../svgfiles/nightlife.png';
import Shop from '../svgfiles/shop.png';
import Shop_color from '../svgfiles/shop_color.png';
import Food_color from '../svgfiles/food_color.png';
import Nightlife_color from '../svgfiles/nightlife_color.png';
import Event_color from '../svgfiles/event_color.png';
import College_color from '../svgfiles/college_color.png';
import Entertainment_color from '../svgfiles/Entertainment_color.png';
import { confirmAlert } from 'react-confirm-alert';
import industry_avgs from './Competitor/industry_avgs.json'
import LimitedMultiSelect from './ui_components/LimitedMultiSelect';


const industry_occupations = industry_avgs.map((av) => {return {value: av.type, label: av.type.replace("_", " ")}}).sort((a, b) => a.value.localeCompare(b.value));

export class Setup extends React.Component{


    constructor(props) {
        super(props);
        this.state= {
            
            classes_email: '',
            classes_pass: '',
            Errors: null,
            brandname: '',
            resp: [],
            previndex: null,
            search: [],
            search_final: {title: '', position: []},
            eachcat: [],
            industry_type: [{ value: "Flex", label: "Industrial Flex"}, { value: "Industrial",label: "Industrial"}, { value:  "Industrial Manufacturing", label:  "Industrial Manufacturing"}, { value: "Land", label: "Land"}, { value: "Medical", label: "Medical"}, { value: "Medical Office", label: "Medical Office"},  { value: "Multifamily", label: "Multifamily"}, 
            {value: "Office", label: "Office"}, {value: "Retail", label: "Retail"}, {value: "Retail Office", label: "Retail Office"}, {value: "Specialty", label: "Specialty"}, {value: "Warehouse", label: "Warehouse"}],
            eachimage: [Shop, Food, Entertainment, Events, College, Nightlife],
            eachimage_stag: [Shop, Food, Entertainment, Events, College, Nightlife],
            eachimage_color: [Shop_color, Food_color, Entertainment_color, Event_color, College_color, Nightlife_color],
            each_id: ["4d4b7105d754a06378d81259","4d4b7105d754a06374d81259", "4d4b7104d754a06370d81259", "4d4b7105d754a06373d81259", "4d4b7105d754a06372d81259", "4d4b7105d754a06376d81259" ],
            each_name: ["Shop", "Food", "Entertainment", "Events", "College", "Nightlife"],
            age_group: [{label:"1 - 4", value: "1"},  {label:"5 - 9", value:"5"}, {label:"10 - 14", value:"10"}, {label:"15 - 17", value:"15"}, {label:"18 - 19", value:"18"}, {label:"20 - 24", value:"20"}, {label:"25 - 29", value:"25"},
            {label:"30 - 34", value:"30"}, {label:"35 - 44", value:"35"}, {label:"45 - 54", value:"45"}, {label:"55 - 64",value:"55"}, {label:"65 - 74",value:"65"}, {label:"75 - 84", value:"75"}, {label:"85+" ,value:"85"}],
            education: [{label:"Less than high school degree", value: "lesshs"}, {label:"High school graduate", value:"hs"}, {label:"Some college or associates", value:'as'}, {label: "Bachelor's degree", value:'bs'}, {label: "Graduate or professional degree", value:'professional'}],
            income: [{value: "0", label:"$1 to $9,999"}, {value: '10000', label:'$10,000 to $14,999'}, {value:"15000", label:"$15,000 to $19,999"}, {value:"20000", label:"$20,000 to $24,999"}, {value:"25000", label:"$25,000 to $29,999"}, {value:"30000", label:"$30,000 to $34,999"}, 
            {value:"35000", label:"$35,000 to $39,999"}, {value:"40000", label:"$40,000 to $44,999"}, {value:"45000", label:"$45,000 to $49,999" }, {value:"50000", label:"$50,000 to $59,999" }, {value:"60000", label:"$60,000 to $74,999" }, {value:"75000", label:"$75,000 to $99,999" }, 
            {value:"100000", label:"$100,000 to $124,999" }, {value:"125000", label:"$125,000 to $149,999" }, {value:"150000", label:"$150,000 to $199,999" }, {value:"200000", label:"$200,000 or more" }],
            gender: [{value:"male", label: "Male"}, {value:"female", label:"Female"}, {value:"gender", label:"No Preference"}],
            
            race: [{value:"White", label: "White"}, {value:"Black/African American", label:"Black or African American"}, {value:"indian", label:"American Indian"}, 
            {value:"Asian", label:"Asian"}, {value:"Hispanic/Latino", label:"Hispanic Or Latino"}],
            occupation: industry_occupations,
            // [{value:"Business, Health Care, Arts", label:"Business, Health Care, Arts"}, {value:"Food & Services", label:"Food & Services"}, {value:"Sales & Office", label:"Sales & Office"}, {value:"Construction & Maintenance", label:"Construction & Maintenance"}, {value:"Production & Transportation", label:"Production & Transportation"}],
            search_set: '',
            location: [],
            submit_error: false,
            industry_type_final: [],
            age_group_final: [],
            education_final: [],
            income_final: [],
            gender_final: [],
            occupation_final: [],
            race_final: [],
            Errors: null,
            pager: true,
            }
        this.categoryselector = this.categoryselector.bind(this);  
        this.age_group_setter = this.age_group_setter.bind(this); 
        this.education_setter = this.education_setter.bind(this);  
        this.income_setter = this.income_setter.bind(this);  
        this.industry_type = this.industry_type.bind(this);  
        this.gender_setter = this.gender_setter.bind(this); 
        this.occupation_setter = this.occupation_setter.bind(this); 
        this.race_setter = this.race_setter.bind(this); 
        this.sendit = this.sendit.bind(this);
        this.search_loc = this.search_loc.bind(this);
        this.ipsetter = this.ipsetter.bind(this);
        this.getpreset = this.getpreset.bind(this);
    }
    async getpreset(){
        let token = localStorage.getItem("auth-token");
        let res = null
        var emailverified = null
        let freetier = false;
        try{
        let response = await axios.post('/setup/preset', {}, {headers:{ "auth-token": token}})
        
        let restemp = await response.data;
        console.log(restemp)
        res = restemp.cat_all;    
        emailverified  = restemp.emailverified
        // freetier = restemp.freetier
        }catch(response){
            // if response is sent back with an error
        //    if(response.response == null)
        //    window.location.replace("/login");
           if(response.response.status === 401) window.location.href = "/account";
           
           if(response.response.status === 400) 
           {
             localStorage.removeItem("auth-token")
             window.location.href = "/login";
            }
          }
        if(!emailverified)
          this.emailconfirmation();
        console.log(res)
        if(!res){
            this.setState({freetier})
            return false;
        }

        this.setState({brandname: res.brandname, age_group_final: res.age_group, race_final: res.race, eachcat: res.subcategories, freetier,
            occupation_final: res.occupation, gender_final: res.gender, income_final: res.income, education_final: res.education, search_final: res.starting[0], search_set: res.starting[0].title, industry_type_final: res.industrygroup})
        
        let index = parseInt(res.index)
        
        
            
    }
    componentWillMount(){
        this.getpreset()
        this.categoryselector()
    }
    industry_type(value){
        this.setState({industry_type_final: value});
    }
    brandname_setter(e){
        this.setState({brandname: e.target.value});
    }
    education_setter(value){
        this.setState({education_final: value});
    }
    income_setter(value){
        this.setState({income_final: value});
    }
    gender_setter(value){
        this.setState({gender_final: value});
    }

    occupation_setter(value){
        this.setState({occupation_final: value});
    }
    race_setter(value){
        this.setState({race_final: value});
    }
    valuesetter = (value) => {
        // if(this.state.eachcat.length > 5) return this.setState({Errors: 'Maximum 5 values selected for niche.'})
        value = value.map((e) => {
            let find = this.state.resp.filter((res) => res.value === e.value)
            if(find.length === 0) return {...e, other: true}
            return e
        })
        this.setState({eachcat: value});
    }
    
    age_group_setter(value){
        this.setState({age_group_final: value})
    }
    //validation final setp
    componentDidMount(){
        this.ipsetter()
    }

    async ipsetter(){
        // let response_ip = await axios.get('http://cors-anywhere.herokuapp.com/https://tools.keycdn.com/geo.json?host='+ ip )
        // let response_ip_final = await response_ip.data;
        // if(response_ip_final == null){
            this.setState({location:[ 33, -117]})    
            return false;
        // }
        // console.log(response_ip_final);
        // let lati = response_ip_final.data.geo.latitude;
        // let lon = response_ip_final.data.geo.longitude;
        // this.setState({location:[lati, lon]})
    }

    async sendit(){

        
        if(this.state.brandname == "" ||  this.state.eachcat.length > 5 ||  this.state.eachcat.length == 0 || this.state.search_final.position == 0 || this.state.occupation_final.length == 0)
        {
            this.setState({submit_error: true});
            return false;
        }
        // Not allowing to go to next page for free accounts, removed filter
        // && !this.state.freetier
        if(this.state.pager) return this.setState({pager:false})
        var all_values = {brandname: this.state.brandname,  categories: this.state.eachcat, 
        age_group: this.state.age_group_final, race: this.state.race_final, occupation: this.state.occupation_final, gender: this.state.gender_final, income: this.state.income_final, education: this.state.education_final, industrygroup: this.state.industry_type_final, starting: this.state.search_final }
        console.log(all_values)
        var token = localStorage.getItem("auth-token")
        try{
        var cat_return = await axios.post('/setup/send', {
            total: all_values
        }, {headers:{
            'auth-token': token
        }});
        
        let cat_return_final = await cat_return.data;
        window.location.href = '/';
        }catch(ee){
            console.log(ee.response.data)
            this.setState({Errors: ee.response.data})
        }

    }
    // after selecting the index number for category
    async categoryselector(){

        var token = localStorage.getItem('auth-token');
        var cat_f = [];
        var cat_all = await axios.post('/setup/cattotal', {}, {headers:{
            'auth-token': token
        }});
        let cat_all_final = await cat_all.data.sort((a, b) => a.label.localeCompare(b.label));

           this.setState({ resp: cat_all_final})
    }
    //location finder

    async search_loc(e) {
        document.getElementsByClassName('search-results-total-setup')[0].style.display = 'block';
        this.setState({search_set: e})
        if(e.length > 1){
            let token = localStorage.getItem("auth-token");
        var search_res = await axios.post('/gettheurl/location', {searcher: true, search:e ,center: this.state.location, bounds:''}, {headers:{ "auth-token": token}});
        let search = await search_res.data;
        this.setState({search: search});
        
        }
      }
      notcollapse(){
        // document.getElementsByClassName('search-results-total')[0].style.display = 'block';
    }
    sendingnew(pos, title){
        // map.setView(new L.LatLng(lati, lon), 13);
        console.log(pos, title);
        this.setState({search_final: {position: pos, title: title}, search_set: title});
        document.getElementsByClassName('search-results-total-setup')[0].style.display = 'none';
        console.log(this.state.search_final.title, 'search final')
        // this.props.latilon(pos)
    }
    emailconfirmation(){
        confirmAlert({
            title: 'Please confirm your email',
            message: 'Please confirm your email address provided by clicking the link sent to your email',
            buttons: [
              {
                label: 'Continue',
                // onClick: () => this.deletesubscription()
              },
              {
                label: 'Settings',
                onClick: () => {window.location.href = "/account"}
                
              }
            ]
          })
    }
    render() {

        return (
            <div> <div className="setup-total"></div>       <div className="sidebar-account-total">
            <div className="sidebar-account-each sidebar-account-selected">Setup Filters</div>
            <div className="sidebar-account-each" onClick={() =>window.location.href = '/account'}>Account Settings</div>

          </div>
            <div className="form-total">
            <div className="form-inner" >
            <div className="container-setup">
            <span className="login100-form-avatar login100-image">
            <img src={maptize} alt="AVATAR"></img>
            </span>
            {(this.state.Errors != null)? <div className= "error-logger">{this.state.Errors}</div>:null}

            {(this.state.pager)?<div>
            <div className="Questions_each">Please enter your brand name below</div>
                <input className="answer_each" id="brand_name" value={this.state.brandname} onChange={this.brandname_setter.bind(this)}></input>
                
                {(this.state.brandname == '' && this.state.submit_error)?
                <div className="required-field">Required Field*</div>: null
                }
            <div className="Demographic-industry">
            <div className="Demographic-each">What category is your business considered in?</div>
            <Select
                  className="react-dropdown-select2"
                  multi
                  options={this.state.occupation}
                  values={this.state.occupation_final}
                   onChange={(values) => this.occupation_setter(values)}/>
            </div>
            {(this.state.occupation_final.length == 0 && this.state.submit_error)?
                <div className="required-field">Required Field*</div>:null
            }
            
            {
            // IF PART OF THE FREETIER THEN THEY WILL NOT BE ABLE TO SETUP A COMMERCIAL REAL ESTATE VALUE
                // (!this.state.freetier)? 
                <div>
                    <div className="Demographic-each">What type of Commerical real estate are you interested in?</div>
                    <div className="Demographic-industry">
                        <Select
                        className="react-dropdown-select2"
                        multi
                        options={this.state.industry_type}
                        values={this.state.industry_type_final}
                        onChange={(values) => this.industry_type(values)}/>
                    </div>
                    {(this.state.industry_type_final.length == 0 && this.state.submit_error)?
                    <div className="required-field">Required Field*</div>:null
                    }
                </div>
                // :null
            }


            {/* position name here */}
            <div id="search-location">
            <div className="Demographic-each">Please enter a starting location. (Select a value from the list)</div>
            <input id="search-heres" placeholder="Search" onChange={e => this.search_loc(e.target.value) } onClick={this.notcollapse.bind(this)} value={this.state.search_set}></input> 

            <div className="search-results-total-setup" >{(this.state.search.length > 0)?  this.state.search.map((i) => { if(i.position == null)return false;
            return <div className="search-results-setup" key={i.id} onClick={() => this.sendingnew(i.position, i.title)}>{i.title} <div className="search-categories">{i.categoryTitle}</div><div className="search-categories" >{i.vicinity}</div></div>
            }): null}
            </div>
            {(this.state.search_final.position == 0 && this.state.submit_error)?
                <div className="required-field">Required Field*</div>:null
                }
            </div>
            
            <div className="container-bottom container-setup" >
            
            <div id="selector">
            
            <div className="Questions-each-container-setup">
            <div className="Questions_each">Please select all niche markets relevant to your brand?</div>
            {/* <Select
                  className="react-dropdown-select2"
                  multi
                  options={this.state.resp}
                  values={this.state.eachcat}
                onChange={(values) => this.valuesetter(values)} 
                create
            /> */}
            <LimitedMultiSelect selectedOptions={this.state.eachcat} options={this.state.resp}  changeOptions={this.valuesetter} limit={5}/>
            </div>
            
            {((this.state.eachcat.length == 0 || this.state.eachcat.length > 5) && this.state.submit_error)?
            <div className="required-field required-field-values">Required field* (Max 5 values)</div>: null}
            </div>
            </div>
            </div>: <div>
            <div className="Demographic-total">
            <div className="Demographic">Target Demographic Questions</div>
            <div className="Demographic-warning">Demographic questions are NOT required to complete.</div>
            <div className="Demographic-warning">By Default all values are included</div>
            <div className="Demographic-each">What is your target Age-Group?</div>
            <Select
                  className="react-dropdown-select2"
                  multi
                  options={this.state.age_group}
                  values={this.state.age_group_final}
                   onChange={(values) => this.age_group_setter(values)}/>
            
            <div className="Demographic-each">What is your target Education Level?</div>
            <Select
                  className="react-dropdown-select2"
                  multi
                  options={this.state.education}
                  values={this.state.education_final}
                   onChange={(values) => this.education_setter(values)}/>
            <div className="Demographic-each">What is your target Income level?</div>
            <Select
                  className="react-dropdown-select2"
                  multi
                  options={this.state.income}
                  values={this.state.income_final}
                   onChange={(values) => this.income_setter(values)}/>

            <div className="Demographic-each">What is your target Gender?</div>
            <Select
                  className="react-dropdown-select2"
                  options={this.state.gender}
                  values={this.state.gender_final}
                   onChange={(values) => this.gender_setter(values)}/>
            <div className="Demographic-each">What is your target Race?</div>
            <Select
                  className="react-dropdown-select2"
                  multi
                  options={this.state.race}
                  values={this.state.race_final}
                   onChange={(values) => this.race_setter(values)}/>

            </div></div>}
            {(this.state.submit_error)? <div className="required-field-all required-field">Please complete the required fields.</div>:null}

            {/* {(this.state.freetier)? <div className="login100-form-btn setup-save-button" onClick={this.sendit}>Submit</div>: */}
        {(this.state.pager)?<div>
            <div className="login100-form-btn setup-save-button" onClick={this.sendit}>Next</div>
        </div> :<div>
        <div className="login100-form-btn setup-save-button-back" onClick={() => this.setState({pager:true})}>Back</div>
        <div className="login100-form-btn setup-save-button-submit" onClick={this.sendit}>Submit</div>
        </div>}
                </div>
            </div>
            </div>
            </div>
            )
        }


}
