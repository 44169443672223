import React, {Component} from 'react';
import {Container} from './industry_container';
import {BoxdatasetPrimary} from './ui_components/boxdatasetPrimary';
import {UI_Table} from './ui_components/ui-table'

export function Commercial_estate(props){
  var commercial_data = props.commercial_data

  var temp_delete = []  
  return (
    
    <div className='container-marginleft'>
      <div id='pdf-export-container-commercial' className='pt-20'>
        {props.exportInd && <h2 className='ml-10 mb-20 mt-100 font-weight600'>Commercial Real Estate</h2>}
        <div className='boxdata-container'>
        <BoxdatasetPrimary classnameval="box-container2" margin={167}  top_text='Average Leasing' mid_text="Monthly Sqft. Cost" loading={props.commercial_data.loading} total_current={props.commercial_data.averageRentSqFtMonthly} description="Average lease cost of locations within the region." filters={{}}/>
        </div>
        <BoxdatasetPrimary classnameval="box-container2" margin={167}  top_text='Average Buying' mid_text="Sqft. Cost" loading={props.commercial_data.loading} total_current={props.commercial_data.averageBuySqFt} description="Average lease cost of locations within the region." filters={{}}/>
      </div>
      <div id='pdf-export-container-commercial-second' className='pt-20'>
      {(!props.commercial_data.loading) && <div className='margin-top-10 width-408px'>
        {(commercial_data.location_total_agg.length > 0) && <UI_Table rows={commercial_data.location_total_agg} titles={[{title: 'Competitor Name', key:'Name_final'}, {title: 'Est. Total Locations', key: 'loc_count'}, {title: 'Locations On Map', key: 'count'}]}/>}
      </div>}
      {(!props.commercial_data.loading) && <div className='margin-top-10 width-408px'>
        {(commercial_data.shops_near.length > 0) && <UI_Table rows={commercial_data.shops_near} titles={[{title: 'Shopping Center', key:'Name_final'}, {title: 'Est. Number Stores', key: 'loc_overlap_count'}, {title: 'Est. Monthly Rent Sqft.', key: 'average_rent_sqft'}]}/>}
      </div>}
      </div>
        {/* {props.industrygroup.map((e) =>{
            if(industry[e.value]){
              temp_delete.push(e.value); 
              return <Container industry_type={e.value} values={industry[e.value]} key={e.value}/>
            }
        })}
        {Object.keys(industry).map((e) =>{
          if(!temp_delete.includes(e))
           return <Container industry_type={e} values={industry[e]} key={e}/>
        })} */}



                      {/* {this.props.industry.map((e) => {
                if(this.state.filter != null)
                  if(this.state.filter != e.status)
                    return false;
                let price = e.price;
                let buildingsize = this.numberWithCommas(e.buildingsize);
                let lotsize = this.numberWithCommas(e.lotsize);

                if(e.status == "For Sale" && e.buildingsize != 0){
                  price = (e.price / e.buildingsize);
                  price = price.toFixed(2)
                }
                if(e.status == "For Sale" && e.buildingsize == 0)
                  return false
                if(!this.state.industryclicked)
                {
                  let value = this.props.industrygroup.map((e) => e.value)
                  if( value.includes(e.industry_type))
                  return <Container  key={e.id} id={e.id} price= {price} buildingsize= {buildingsize} lotsize ={lotsize} status={e.status} industry_type={e.industry_type}/>
                  else
                  return false  
                }
                else{
                  return <Container  key={e.id} id={e.id} price= {price} buildingsize= {buildingsize} lotsize ={lotsize} status={e.status} industry_type={e.industry_type}/>
                }
              })}
              {(!this.state.industryclicked)? <div className="box-price box-showmore" onClick={this.industry_clicked.bind(this)}>Show More...</div>: null}
               */}
    </div>
        )
              } 