import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import OutsideClickHandler from 'react-outside-click-handler';




const WAIT_INTERVAL = 1500;
export class Task_title_menu extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    top_title: false,
    searchvalue: false,
    searched: ""
  }
  this.searchval = this.searchval.bind(this);
  }
  componentWillMount(){
    this.timer = null;
  }
  componentWillReceiveProps(newProps){
    this.setState({searched: newProps.searchvaluetotal})
  }
  searchval(e){
    clearTimeout(this.timer);
    this.setState({searched:e}, () => {
      this.timer = setTimeout(() => this.props.searchval(this.state.searched), WAIT_INTERVAL);
    })
    
  }
  currentusers(){
    if(this.state.tagselected)
    window.location.href = "/accountsub?tag="+this.state.tagselected
    else
    window.location.href = "/accountsub"
  }
  logout(){
    localStorage.removeItem("auth-token")
    window.location.href = '/login'
  }
  render() {
      // layout is an array of objects, see the demo for more complete usage

      return (
        
        <div>
        
        {(this.state.top_title)? <OutsideClickHandler onOutsideClick={() => {this.setState({top_title:false})}} > 
        {(this.props.account_type)?
        <div className="task-options-total2">
          <div className="task-options-each" onClick={this.props.searchlocation}>Add New Location</div>
          <div className="task-options-each" onClick={ this.currentusers.bind(this)}>Edit Current Users</div>
          <div className="task-options-each" onClick={() => window.location.href = "/accountsub"}>Add New User</div>
          <div className="task-options-each" onClick={() => window.location.href = "/account"}>Account Settings</div>
          <div className="task-options-each" onClick={this.logout}>Logout</div>
        </div>
        :
        <div className="task-options-total2">
          <div className="task-options-each" onClick={() => window.location.href = "/accountsubsetting"}>Account Settings</div>
          <div className="task-options-each" onClick={this.logout}>Logout</div>
        </div>
        }
        </OutsideClickHandler>:null
        }
        <div className="task-top-threedots2" data-tut="reactour__newlocation" onClick={() => this.setState({top_title: true})}><div className="margin-left10"><div className="container-layers-dots2"></div ><div className="container-layers-dots2"></div><div className="container-layers-dots2"></div></div></div>
        {(this.state.searchvalue)?<div className="task-searchvalue-container"><input placeholder="Search" className="task-searchvalue" value={this.state.searched} onChange={(e) =>this.searchval(e.target.value)}/><div className="fa fa-times task-searchvalue-close" onClick={()=> {this.setState({searchvalue: false}); this.searchval("")}}></div></div>
        :<div className="container2" onClick={() => this.setState({searchvalue: true})} >
            <div className="search2"></div>
            </div>}
        </div>
      )
    }
  }