import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Select from 'react-dropdown-select';
import OutsideClickHandler from 'react-outside-click-handler';

export class Task_searchlocation extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    tag_prospect: [],
    tag_business: [],
    current_business_locations: false,
    prospecting_business_locations: false,
    prospecttags: [],
    prospect: [],
    business: [],
    tag_names: [],
    searchterm: '',
    markerlatlng: [],
    name: '',
    states: true,
    updater: false,
    setname: true,
    search: [],
    type: [],
    tags: false,
    error: false,
  }
  this.search_loc = this.search_loc.bind(this);
  this.searchtype = this.searchtype.bind(this);
  this.prospecttype = this.prospecttype.bind(this)
  this.senddata = this.senddata.bind(this)
  this.getvalues = this.getvalues.bind(this)
  }
getvalues(){
  if(this.props.updater){
    let prospect=  []
    if(this.props.prospecttags.length > 0) prospect = this.props.prospecttags.map((e) => {return{value:e, label:e}})
    this.setState({address: this.props.searchterm, searchterm:this.props.searchterm, markerlatlng: this.props.markerlatlng, type: this.props.type, prospecttags: prospect, updater: true, name:this.props.name ,setname:false})
  }
}
async search_loc(e){
    let token = localStorage.getItem("auth-token");
    this.setState({searchterm: e})
    if(e.length > 2){
    
    let token = localStorage.getItem("auth-token");
    var search_res = await axios.post('/gettheurl/location', {searcher:true, search:e ,center: this.props.coordinates, bounds:''}, {headers:{ "auth-token": token}});
    let search = await search_res.data;
    
    
    search = search.filter((e) => {
        if(e.position != null)
            return e
    })
    this.setState({search: search});
       
    }
  }
  sendingnew(pos, title, vicinity){
    this.setState({markerlatlng: pos, address: title + " " +vicinity, states:false})
  }
  async senddata(){
    let token = localStorage.getItem("auth-token");
    if(this.state.type.length === 0 || this.state.name == '')
    return this.setState({error: true})
    let prospecttags = this.state.prospecttags.map((e) => e.value);
    try{
      var senddata = await axios.post('/gettheurl/setvalue', 
      { type: this.state.type[0].value, 
        updater: this.state.updater,
        address: this.state.address,
        tags: prospecttags,
        coordinates: [this.state.markerlatlng[1] , this.state.markerlatlng[0]],
        name_prospect: this.state.name, }, {headers:{ "auth-token": token}});
        this.setState({name_prospect: '', type: [], address: '', prospecttags: [], markerlatlng:[]})
        this.props.searchlocation(true)
      }catch(e){
        console.log(e)
        if(e.response.status == 400) window.location.replace('/login')
        if(e.response.status == 401) alert(e.response.data)
      }
      
  }
  searchtype(e){
    // let val = e.map((e) => e.value)
    this.setState({prospecttags: e})
  }
  prospecttype(e){
    // let val = e.map((e) => e.value)
    this.setState({type:e})
  }
  componentWillMount(){
    this.getvalues()
  }
  render() {

    return (
      <div className="task-search-background"><OutsideClickHandler onOutsideClick={() => this.props.searchlocation(false)} >
        
           <div className="task-options-input"><div className="task-exittotal" onClick={() => this.props.searchlocation(false)}><i className="fa fa-times"></i></div>{(this.state.states)? <div className="tags-searchvalue"><div className="tasks-business-locations">Search business location</div>
           <div className="task-results-search-total">
           <input  type="text" className="search-results-total" placeholder="Search..." onChange={e => this.search_loc(e.target.value) } value={this.state.searchterm} ></input>
           </div> 
            <div className="search-results-values"> {(this.state.search.length > 0)?  this.state.search.map((i) => {
            return <div className="search-results" key={i.id} onMouseDown={() => this.sendingnew(i.position, i.title, i.vicinity)}>{i.title}  <div className="search-categories">{i.categoryTitle}</div><div className="search-categories" dangerouslySetInnerHTML={{__html:i.vicinity}}></div></div>}):null}
           </div>
           <button className="login100-form-btn setup-save-button-submit" id="task-createbutton-next" onClick={()=> this.setState({states: false})} disabled={(this.state.markerlatlng.length > 0)? false: true}>Next</button>
           </div>:
           
           <div className="task-options-creating-total">
             <div className="task-options-name-label">Enter name here</div>  {(this.state.error)?
                <div className="required-field">Required Field*</div>:null
            }
             {(this.state.setname)?<input className="task-options-name" onChange={(e) => this.setState({name: e.target.value})} value={this.state.name}></input>:<div className="task-options-name" >{this.state.name}</div>}
             <div className="task-options-name-label">Enter Tags</div>  
             <Select multi
                  options={this.props.tagnames}
                  values={this.state.prospecttags}
                  create
                   onChange={(values) => this.searchtype(values)} />
            <div className="task-options-name-label">Business Type</div>{(this.state.error)?
                <div className="required-field">Required Field*</div>:null
            }
            <Select 
                  options={[{value:"business", label:"Current Business"}, {value:"prospect", label:"Prospecting Business"}]}
                  values={this.state.type}
                  create
                   onChange={(values) => this.prospecttype(values)} />
              <button className="login100-form-btn setup-save-button-submit" id="task-createbutton" onClick={this.senddata}>{(this.state.updater)?"Update":"Create"}</button>
           </div>}</div>
            </OutsideClickHandler>
      </div>

    )
  }

}