import React from 'react';
import {ElementsConsumer, CardElement ,Elements} from '@stripe/react-stripe-js';
import maptize from '../Images/maptize-black.png';
import axios from 'axios';
import {loadStripe} from '@stripe/stripe-js';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Select from 'react-dropdown-select';
import {AccountPreferences} from './Account_preferences'
const stripePromise = loadStripe("pk_live_4k96lg3c85qDG4Vt7RNx4B7300sEu13hFu");
// pk_test_CzbMnwCQDiPyfNGlvP2GOX8w00aoutzlEv
export class Account_details extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            password : '',
            range: {distance: 12, type: 'Minutes'},
            columns: [],
            lambdaSheetsCall: {},
            lambdaSheetErrors: [],
            status_static: '',
            status_colors: {},
            name_static: '',
            address_static: '',
            distance: '',
            distance_type: '',
            distance_static: '',
            distance_type_static: '',
            brand_static: '',
            revenue_static: '',
            expense_static: '',
            lease_static: '',
            brand: '',
            coordinates_static: '',
            string_values_static: [],
            list_values_static: [],
            integer_values_static: [],
            brand: [],
            brandLogo: {},
            email: '',
            name: '',
            address: '',
            sub: '',
            editable: false,
            invoice: null,
            Errors: null,
            Errors_val: null,
            card: null,
            exp: null,
            name_edit: false,
            password_edit: false,
            card_edit: false,
            type: null,
            editpayment: false,
            subscriptiontypes: [ {cost: "99", label:"Basic", value:"Basic"}, {cost: "149",label:"Professional", value:"Professional"}, {cost: "199",label:"Enterprise", value:"Enterprise"}],
            email_preference: [{label:"Once a Month", value: "month"}, {label:"Once a Week", value: "week"},{label:"Once a Day", value: "day"}, {label:"No Task Notification", value: "none"}, {label:"No Notification", value: "false"}],
            passtype: false,
            saver: true,
            passwordvalue: '',
            passwordnew: '',
            cancel: '',
            prorate: null,
            proratecost: null,
            proratevalue: null,
            prorationdate: null,
            current_period_start: '',
            disablesend: false,
            emailverified: false,
            freetier: true,
            error_value: null
        }
        this.getvalues = this.getvalues.bind(this)
      }
      componentWillMount(){
        this.getvalues()
      }

      updateUrlLogo = (brand, url) => {
        this.setState({brandLogo: {...this.state.brandLogo, [brand]: url}})
      }
      async getvalues(){
        try{
          let token = localStorage.getItem("auth-token");
      
          let response = await axios.post('/account/accountinfo', {      
            }, {headers:{ "auth-token": token}})
          response= await response.data;
          console.log(response)
          let temp_currentsub = this.state.subscriptiontypes.filter((e) => e.value === response.type)
          let emailPreference = (response.emailPreference)? response.emailPreference: "week";
          const status_colors = response.status_colors 
          const columns = response.columns
          const brand = response.brand.filter((e) => e != '');
          const brandLogo = response.brandLogo;
          const range =   response.range ? response.range : {distance: 12, type: 'Minutes'}
          const string_values_static = columns.filter((e) => e.type === 'String').map((e) => e.column);
          const list_values_static = columns.filter((e) => e.type === 'List').map((e) => e.column);
          const integer_values_static = columns.filter((e) => e.type === 'Integer').map((e) => e.column);
          const status_static = columns.find((e) => e.associated === 'status')?.column;
          const name_static =  columns.find((e) => e.associated === 'name')?.column;
          const address_static = columns.find((e) => e.associated === 'address')?.column;
          const distance_static = columns.find((e) => e.associated === 'distance')?.column;
          const distance_type_static = columns.find((e) => e.associated === 'distanceType')?.column;
          const brand_static = columns.find((e) => e.associated === 'brand')?.column;
          const revenue_static = columns.find((e) => e.associated === 'revenue')?.column;
          const expense_static = columns.find((e) => e.associated === 'expense')?.column;
          const lease_static = columns.find((e) => e.associated === 'lease')?.column;
          const lambdaSheetsCall = response.lambdaSheetsCall;
          const lambdaSheetErrors = response.lambdaSheetErrors;
          const coordinates_static = columns.find((e) => e.associated === 'coordinates')?.column;
          this.setState({lambdaSheetErrors, lambdaSheetsCall, integer_values_static, brandLogo, brand, status_static: status_static ? status_static : '', name_static: name_static ? name_static: '', address_static: address_static ? address_static : '', distance_static: distance_static ? distance_static : '', distance_type_static: distance_type_static ? distance_type_static : '', brand_static: brand_static ? brand_static : '',
          coordinates_static: coordinates_static ? coordinates_static : '', revenue_static: revenue_static? revenue_static : '', expense_static: expense_static ? expense_static : '', lease_static: lease_static ? lease_static : '',
          string_values_static, list_values_static, range, columns, status_colors, emailverified: response.emailverified, email_preference_picked: emailPreference,freetier: response.freetier, current_sub: temp_currentsub, current_period_start: response.current_period_start, cancel: response.cancel, editable: true, type: response.type, exp: response.exp, card: response.card, name: response.name, email: response.email, sub: response.sub, invoice: response.invoice, address: response.address})
        }catch(e){
            if(e.response == null || e.response.status == 400){
              
              window.location.href = "/login";
            }
        }
      }
      setParentRange = (range) => {
       this.setState({range}) 
      }
      editpayment(){
        this.setState({editpayment: true})
      }
      namechange(e){
        this.setState({name: e.target.value, saver: false})
      }
      emailchange(e){
        this.setState({email: e.target.value , saver: false})
      }
      logout(){
        localStorage.removeItem("auth-token")
        window.location.href = '/login'
      }
      async savevalues(){
        let token = localStorage.getItem("auth-token");
        try{
        let response = await axios.post('/account/accountsave', {name: this.state.name, email: this.state.email}, {headers:{ "auth-token": token}});
        let response_val = await response.data.status;
        if(response_val == 1)
          this.setState({saver: true, Errors_val: null})
        }catch(e){
          this.setState({Errors_val: e.response.data})
        }
      }
      passwordvalue(e){
        this.setState({passwordvalue: e.target.value})
      }
      passwordnew(e){
        this.setState({passwordnew: e.target.value})
      }
      changepass(){
        this.setState({passtype: true})
      }
     async savepassword(){
        let token = localStorage.getItem("auth-token");
        try{
        let response = await axios.post('/account/password', {password: this.state.passwordvalue, passwordnew: this.state.passwordnew}, {headers:{ "auth-token": token}});
        let response_val = await response.data;
        this.setState({passtype: false})
      }catch(e){
        this.setState({Errors: e.response.data})
      }
        
      }
      cancelvalue(){
        this.setState({passtype: false, editpayment: false})
      }
     async deletesubscription(){
        
        
        let token = localStorage.getItem("auth-token");
        let response = await axios.post('/account/cancel', {}, {headers:{ "auth-token": token}});
        this.getvalues()
        console.log(response)
      }
     async cancel(){
        confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure you want to cancel your membership?',
        buttons: [
          {
            label: 'Confirm',
            onClick: () => this.deletesubscription()
          },
          {
            label: 'Cancel',
            
          }
        ]
      })
        
      }
      async resubscribe(){
        let token = localStorage.getItem("auth-token");
        let response = await axios.post('/account/reactivate', {}, {headers:{ "auth-token": token}});
        this.getvalues()
      }
      addresszip(e){
        let address = this.state.address;
        address.zip = e.target.value
        this.setState({address})
      }

      async subscriptionchange(e){
        if(this.state.current_sub ===  e[0].value) return this.setState({prorate: false})
        return this.setState({prorate: true, proratevalue: e[0].value})
        let token = localStorage.getItem("auth-token");
        
        let response = await axios.post('/account/changeplan', {value: e[0].value}, {headers:{ "auth-token": token}});
        let res = response.data;
        this.setState({invoice: res.invoice, prorate: true, proratecost: res.newPlanAmount, proratevalue: res.new_plan, prorationdate: res.proration_date})
      }
      async changeplan(){
        let token = localStorage.getItem("auth-token");
        
        let response = await axios.post('/account/changeplanfinal', {plan: this.state.proratevalue, date: this.state.prorationdate}, {headers:{ "auth-token": token}});
        this.setState({proratevalue: null, prorationdate: null, proratecost: null, prorate: null})
        
      }
      async resendemail(){
        let token = localStorage.getItem("auth-token");
        try{
        let response = await axios.post('/account/sendemail', {}, {headers:{ "auth-token": token}});
        this.setState({disablesend: true})
        }catch(e){
          this.setState({Errors: "Error while trying to send you the Email"})
        }
      }
      async email_preference(value){
        let token = localStorage.getItem("auth-token");
        let response = await axios.post('/account/emailpreference', {emailPreference: value}, {headers:{ "auth-token": token}});
        this.setState({email_preference_picked: value})
      }

      saveColorChange = async (selectedStatusColor, selectedColor) => {
        let token = localStorage.getItem("auth-token");

        await axios.post('/account/accountprefrences', {selectedStatusColor, selectedColor},
        {headers:{ "auth-token": token}})
        let status_colors = this.state.status_colors
        status_colors[selectedStatusColor] = selectedColor
        this.setState({status_colors})
      }

      render() {
      return (
        <div className="container-account col-xs-1" >
        <img className="image-account" src={maptize} onClick={()=> window.location.href = '/'}></img>
        {/* {!this.state.freetier? */}
        <div className="sidebar-account-total">
          <div className="sidebar-account-each sidebar-account-selected">Account Settings</div>
          {/* <div className="sidebar-account-each" onClick={() =>window.location.replace('/tasks')}>Task Management</div> */}
          <div className="sidebar-account-each" onClick={() =>window.location.href = '/'}>Map View</div>
          <div className="sidebar-account-each" onClick={() =>window.location.href = '/setup'}>Setup Filters</div>
          <div className="sidebar-account-each" onClick={() =>window.location.href = '/competitor'}>Competitor Research</div>
          <div className="sidebar-account-each" onClick={() =>window.location.href = 'https://mapchise.com/tutorial-page/'}>Tutorial</div>
          {/* <div className="sidebar-account-each" onClick={() =>window.location.replace('/accountsub')}>Sub Accounts</div> */}
        </div>
        <div className='box-account'>
        <button className="button-account button-top-account" onClick={this.logout}>Logout</button>
        <div className="title-account">Account Details</div>
        {(this.state.Errors != null)? <div className= "error-logger">{this.state.Errors}</div>:null}
        <div className="each-total-account">
        <div className="each-lable-account">Looking to Upgrade your Account? </div><button className="button-account button-upgrade-account" onClick={() => window.location.href='https://mapchise.com/pricing/'}>See Plans</button>
        </div>
        <div className="each-total-account">
        <div className="each-lable-account">Plan type: </div><div className="each-value-account">{(this.state.freetier && this.state.type != 'Free Trial')? 'Free': this.state.type}</div>
        </div>
        <div className="each-total-account">
        <div className="each-lable-account">Status: </div><div className="each-value-account">{this.state.sub}</div>
        </div>

        <div className="error-account">{this.state.Errors_val}</div>
        <div className="each-total-account">
        <div className="each-lable-account">Email: </div><input className="each-input-account" value={this.state.email} onChange={this.emailchange.bind(this)}></input>
        </div>
        {(!this.state.emailverified)?<button className="password-account resend-email" onClick={this.resendemail.bind(this)} disabled={this.state.disablesend}>Resend Verification Email</button>:null}
        <div className="each-total-account">
        <div className="each-lable-account">Name: </div><input className="each-input-account" value={this.state.name} onChange={this.namechange.bind(this)}></input>
        </div>
        
        <button className="button-account" disabled={this.state.saver} onClick={this.savevalues.bind(this)}>Save</button>
        <div className="each-total-account">
        <div className="each-lable-account">Password: </div>
        {(this.state.passtype)? <div style={{"margin-top":"15px"}}> <div className="error-account">{this.state.Errors}</div>
        <div className="each-lable-account">Current Password: </div><input className="newpass-account each-input-account" onChange={this.passwordvalue.bind(this)} type="password" ></input>
        <div className="each-total-account"><div className="each-lable-account">New Password: </div><input className="newpass-account each-input-account" type="password" onChange={this.passwordnew.bind(this)} ></input></div>
        <button className="cancelpass-account password-account" onClick={this.cancelvalue.bind(this)}>Cancel</button>
        <button className="button-account" onClick={this.savepassword.bind(this)} style={{"margin-left": "18px"}}>Save</button> </div>
        :<button className="password-account" onClick={this.changepass.bind(this)}>Change Password</button>}
        </div>

        {!this.state.freetier? <div>
        <div className="each-lable-account">Email Preference: </div>
        <div className="task-value-select-email">
        {this.state.email_preference.map((e) => <div className={(this.state.email_preference_picked === e.value)?"task-tabvalue2 task-tabvalue2-color":"task-tabvalue2"} key={e.value} onClick={() => this.email_preference(e.value)}>{e.label}</div>)}
        </div></div>: null}
        </div>
        
        {!this.state.freetier?
        <div>
        <div className='box-account'>
        {(!this.state.editpayment || !this.state.editable)?<button className="button-account button-top-account" onClick={this.editpayment.bind(this)}>Edit</button>:
        <button className="button-top-account cancelpayment-account password-account" onClick={this.cancelvalue.bind(this)}>Cancel</button>
        }
        
        <div className="title-account">Payment Information</div> <div className="edit-payment-account" ></div>
        {(!this.state.editpayment || !this.state.editable)?
        <div>
        <div className="each-total-account">
        <div className="each-lable-account">Address: </div> <div className="each-value-account">{(this.state.address && this.state.address.line1)?this.state.address.line1 +' ' + this.state.address.city + ' ' + this.state.address.state+ ' ' +this.state.address.postal_code:null}</div>
        </div>
        <div className="each-total-account">
        <div className="each-lable-account">Last 4 digits: </div><div className="each-value-account">{this.state.card}</div>
        </div>
        <div className="each-total-account">
        <div className="each-lable-account">Expiration: </div><div className="each-value-account">{this.state.exp}</div>
        </div>
        </div>
        :
        <Elements stripe={stripePromise}>
        <Cardpayment address={this.state.address} name={this.state.name} email={this.state.email}/>
        </Elements>
        }
        </div>
        <div className='box-account'>
        <div className="title-account">Subscription</div> <div className="edit-payment-account" ></div>
        <div className="each-total-account">
        <div className="each-lable-account">Change Subscription: </div>
        <div className="each-select-account">
        {(this.state.current_sub)?
        <Select
                  options={this.state.subscriptiontypes}
                  values={this.state.current_sub}
                   onChange={(values) => this.subscriptionchange(values)}/>
                  :null}
        </div>
        {(this.state.prorate)? <div>
          {/* <div className="each-total-account">  <div className="prorate-account">Prorate Current Billing Cycle: <div className="prorate-account" style={{"margin-left": "65px"}}>${this.state.prorate / 100}</div></div></div> */}
          {/* <div className="prorate-account">Regular Billing Cycle: <div className="prorate-account" style={{"margin-left": "101px", "margin-top": "0px"}}>+ ${this.state.proratecost}</div></div> */}
          {/* <div className="each-total-account" style={{ "margin-top": "0px"}}><div className="prorate-account final-account" >Total Next Billing Cycle: <div className="prorate-account" style={{"margin-left": "99px", "margin-top": "0px"}}>${Math.round((parseInt(this.state.proratecost) + this.state.prorate / 100) * 100)/100}</div></div></div> */}
          <button className="button-change-account button-account" onClick={this.changeplan.bind(this)} style={{"margin-left": "150px", "width": "110px"}}>Change Plan</button></div>: null}
        
        </div>
        <div className="each-total-account">
        <div className="each-lable-account">Recent Invoice Cycle: </div><div className="each-value-account">{this.state.current_period_start}</div>
        </div>
        <div className="each-lable-account">Current Subscription: </div>{(!this.state.cancel)?<button className="button-account" onClick={this.cancel.bind(this)} style={{"margin-left": "18px", "width": "120px"}}>Cancel Subscription</button>:<button className="password-account" onClick={this.resubscribe.bind(this)} style={{"margin-left": "18px", "width": "110px", "margin-top": "20px"}}>Subscribe</button>}
        <div className="each-total-account">
        <div className="each-lable-account">Cancel Period: </div><div className="each-value-account">{this.state.cancel}</div>
        </div>
        </div>
        </div>:null}
        <AccountPreferences lambdaSheetErrors={this.state.lambdaSheetErrors} lambdaSheetsCall={this.state.lambdaSheetsCall} saveColorChange={this.saveColorChange} updateUrlLogo={this.updateUrlLogo} brandLogo={this.state.brandLogo} brandList={this.state.brand} setParentRange={this.setParentRange} string_values_static={this.state.string_values_static} list_values_static={this.state.list_values_static} integer_values_static={this.state.integer_values_static} status_static={this.state.status_static} name_static={this.state.name_static} address_static={this.state.address_static} distance_static={this.state.distance_static} distance_type_static={this.state.distance_type_static} brand_static={this.state.brand_static} revenue_static={this.state.revenue_static} expense_static={this.state.expense_static} lease_static={this.state.lease_static} coordinates_static={this.state.coordinates_static} range={this.state.range} status_colors={this.state.status_colors} columns={this.state.columns}/>
        </div>
      );
    }
}
function Cardpayment(props){
  return (
  <div>
  <ElementsConsumer>
  {({stripe, elements}) => (
          <Formpayment name={props.name} email={props.email} address={props.address} stripe={stripe} elements={elements} />
        )}
  </ElementsConsumer>
  </div>
  )
}

class Formpayment extends React.Component{
  constructor(props) {
    super(props);
    this.state= {
      line1: "",
      city: "",
      postal_code: "",
      state: "",
      error_value: null,
    }
    
  }

  handleSubmit = async (event) => {

    event.preventDefault();
  
    const {stripe, elements} = this.props
    
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }
    if(event.target.address.value == null)
      return this.setState({error_value: 'Please fill out address'})
    if(event.target.state.value == null)
      return this.setState({error_value: 'Please fill out State'})
    if(event.target.zip.value == null)
      return this.setState({error_value: 'Please fill out Postal Code'})
    if(event.target.city.value == null)
      return this.setState({error_value: 'Please fill out City'})
    const  billing_details= {
      email: this.props.email,
      name: this.props.name,
      address:{
          line1: event.target.address.value,
          state: event.target.state.value,
          postal_code: event.target.zip.value,
          city: event.target.city.value,
      }
      
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details
    });
    console.log(result, 'result value here', result.error)
    if(result.error)
      return this.setState({ error_value: result.error.message})
    console.log("after error method here,", result.error.message)
      let token = localStorage.getItem("auth-token");
    let response = await axios.post('/account/paymentinfo', {
      payment_method: result.paymentMethod.id,
        billing_details: result.paymentMethod.billing_details
    }, {headers:{ "auth-token": token}})
    let res_data = response.data;
    console.log(res_data)
  // this.stripePaymentMethodHandler(result, this.state.password);

  };
  
  componentWillReceiveProps(nextProps) {
    this.setState({ 
      line1: nextProps.address.line1,
      city: nextProps.address.city,
      postal_code: nextProps.address.postal_code,
      state: nextProps.address.state
     });  
  }
  addressline1(e){
    this.setState({line1: e.target.value})
  }
  addresscity(e){
    this.setState({city: e.target.value})
  }
  addressstate(e){
    this.setState({state: e.target.value})
  }
  addresspostal_code(e){
    this.setState({postal_code: e.target.value})
  }
  render() {
    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
        
      },
      hidePostalCode:true,
    };
const {stripe} = this.props;
  return(
    
    <form className="form-account" onSubmit={this.handleSubmit.bind(this)}>
    {(this.state.error_value != null)? <div className= "error-logger">{this.state.error_value}</div>:null}
     <div className="each-total-account">
      <div className="each-lable-account">Address: </div><input className="each-bottom-account each-input-account" value={this.state.line1} onChange={this.addressline1.bind(this)} name="address"></input>
      </div>
      <div className="each-total-account">
      <div className="each-lable-account">City: </div><input className="each-bottom-account each-input-account" value={this.state.city} onChange={this.addresscity.bind(this)} name="city"></input>
      </div>
      <div className="each-total-account">
      <div className="each-lable-account">State: </div><input className="each-bottom-account each-input-account" value={this.state.state} onChange={this.addressstate.bind(this)} name="state"></input>
      </div>
      <div className="each-total-account">
      <div className="each-lable-account">Postal Code: </div><input className="each-bottom-account each-input-account" value={this.state.postal_code} onChange={this.addresspostal_code.bind(this)} name="zip"></input>
      </div>
    
    <label >
    <div className="label-account">
            Card details
            </div>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        </label>
        
      <button className="login100-form-btn" type="submit" disabled={!stripe}>
        Change
      </button>
    </form>
  )
}
}