import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import OutsideClickHandler from 'react-outside-click-handler';
import Calendarimg from '../Images/calendar2.png';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-dropdown-select';
import Status from '../Images/status.png';
import Priority from '../Images/priority.png';
import { GithubPicker } from 'react-color';
import menupost from '../Images/menupost.png'
import {Task_Calendar} from './task_calendar';
import {Task_label} from './task_label';

const WAIT_INTERVAL = 1500;

export class Task_posted extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    addeduser: {},
    top_title: false,
    accounts: [],
    Title: "",
    temp_selected: false,
    calendarclicked: false,
    status: [],
    statuscurrent: [],
    labelsearch: "",
    accountnamesselected: [],
    datarecurring: [],
    prioritycurrent: [],
    labels: [],
    name: '',
    duedate: null,
    status_color: "",
    Description: "",
    priority_color: "",
    selectedaccounts: [],
    postsettings: false,
    dateformat: null,
    selectedlabels: [],
    tasknotification: {Tasknote: false, selectedaccounts: []},
    labelobject: [],
    showlabel: false,
    priority_type: [{value:"Type > Create", label:"Type to Create", disabled: 'yes'}, {value:"Low", label:"Low", color: "#008B02", }, {value: "Medium", label:"Medium" , color: "#FCCB00"},  {value:"High", label:"High", color: "#B80000"}],
    status_type: [{value:"Type > Create", label:"Type to Create", disabled: 'yes'}, {value:"Beginning", label:"Beginning", color: "#1273DE"}, {value: "In Progress", label:"In Progress" , color: "#FCCB00"},{value:"Backburner", label:"Backburner",color: "black"}, {value: "Problem", label:"Problem", color:"red"}, {value:"Finished", label:"Finished", color: "#008B02"}]
  }
  
  this.priority_type = this.priority_type.bind(this)
  this.status_type = this.status_type.bind(this);
  this.addnewaccount = this.addnewaccount.bind(this);
  this.labeledited = this.labeledited.bind(this)
  this.triggersend = this.triggersend.bind(this);
  this.inputsetter = this.inputsetter.bind(this);
  this.taskmoreinformation = this.taskmoreinformation.bind(this)
  this.updatedate = this.updatedate.bind(this);
  this.updatefromcomponents = this.updatefromcomponents.bind(this);
  }
  updatefromcomponents(data){
    
    var priority_color = (data.priority[0])? data.priority[0].color: "";
    var status_color = (data.status[0])? data.status[0].color: "";
    var selectedlabels = (data.selectedlabels)? data.selectedlabels: [];
    var accountnamesselected= data.selectedaccounts.map((e) => e.email);
    var description = data.description? data.description: "";
    var tasknotification = data.tasknotification? data.tasknotification:  {Tasknote: false, selectedaccounts: []};
    var Title = data.title? data.title: '';
    
    var accounts = this.props.Accounts.filter((e) => {    
      if(this.props.useraccount){
      if(this.props.useraccount.email !==e.email){ return !accountnamesselected.includes(e.email) }else{ return false }
      
      }else{ return !accountnamesselected.includes(e.email) }
    });
    // console.log(accountnamesselected, "selectedaccount")
    // console.log(this.props.Accounts.map((e) => e.email),"props account")
    // console.log(accounts.map((e) => e.email), 'accounts each value', Title)
    // debugger;
    if(this.props.useraccount)
    if(!accountnamesselected.includes(this.props.useraccount.email)) accounts.push(this.props.useraccount)
    var addeduser = (data.addeduser)? data.addeduser : {added: false, account:[]};
    this.setState({accounts, dateformat:data.dateformat,addeduser,  accountnamesselected,tasknotification, Title: Title, Description: description, statuscurrent: data.status, prioritycurrent: data.priority, duedate: data.duedate, selectedaccounts: data.selectedaccounts, status_color, priority_color, selectedlabels})
  }
  componentDidMount() {
    this.timer = null;

    var data = this.props.data
    
    if(data) this.updatefromcomponents(data);
  }
  
  async newprops(y, idval, id){
    console.log(y, idval, id)
    var token = localStorage.getItem("auth-token");
    try{
    let update = await axios.post('/tasksapi/newsetup', {y, idval, id}, {headers:{ "auth-token": token}});
    }catch(e){
        console.log(e.response.status)
    }
  }
  componentWillReceiveProps(nextProps){
    // Gets the key for all labels and as you type it filters 
    let labels = (nextProps.labels_list)? Object.keys(nextProps.labels_list): [];
    this.setState({labelobject: labels })
    var data = nextProps.newdata
    
    if(data) this.updatefromcomponents(data);
    
  }
  componentDidUpdate(prevProps) {
    
  if(this.props.title !== "My Tasks"){
    if(this.props.newval !== "" && this.props.newval != null){
      if(prevProps.idval !== this.props.idval){
        this.newprops(null, this.props.idval, this.props.id)
      }
    }else{
      
    if(prevProps.y !== this.props.y || prevProps.idval !== this.props.idval){
      if(prevProps.idval !== "My Tasks")
    this.newprops(this.props.y, this.props.idval, this.props.id)
    }    
    }
  }

  }

  status_type(value){
      
    this.setState({statuscurrent: value, status_color: value[0].color}, () => {
        this.triggersend()
    })
  }
  priority_type(value){
    this.setState({prioritycurrent: value, priority_color: value[0].color}, () => {
        this.triggersend()
    })
  }
  labeledited(label){
    this.setState({selectedlabels:label}, ()=> { this.triggersend(); })
  }


  async triggersend(){
    try{  
        var token = localStorage.getItem("auth-token");
        let save = await axios.post('/tasksapi/save', { id: this.props.id, title: this.state.Title, description: this.state.Description, duedate: this.state.duedate, dateformat: this.state.dateformat, status: this.state.statuscurrent, priority: this.state.prioritycurrent, labels: this.state.selectedlabels,  selectedaccounts: this.state.selectedaccounts, addeduser: this.state.addeduser }, {headers:{ "auth-token": token}})
    }catch(e){
        alert(e)
    }
  }
  
  async addnewaccount(e, type){
    clearTimeout(this.timer);
    let selectedaccounts = this.state.selectedaccounts;
    let accountnamesselected = this.state.accountnamesselected;
    let addeduser = this.state.addeduser;
    // if(!accountnamesselected.includes(this.props.useraccount.email)) accounts.push(this.props.useraccount)
    if(type ==="Add"){
      selectedaccounts.push(e)
      accountnamesselected.push(e.email)
      console.log(addeduser, 'added users')
      var token = localStorage.getItem("auth-token");
    await axios.post('/tasksapi/notify', {_id: e._id, id:this.props.id}, {headers:{ "auth-token": token}});
      addeduser = (addeduser.added)? addeduser.account.push(e): {added:true, account:[e]};
    }else{
      addeduser = (addeduser.account.length > 0)? {added:false, account:addeduser.account.filter(add=> add._id !==e._id)}: {added:false, account:[]};
      selectedaccounts = selectedaccounts.filter((s) => s._id !== e._id)
      accountnamesselected = accountnamesselected.filter((a) => a !== e.email)
    }
    var accounts = this.props.Accounts.filter((e) => !accountnamesselected.includes(e.email));

    /** accountnamesselected is for when mapping to exclude that value */
    this.setState({selectedaccounts,accountnamesselected ,accounts,addeduser, shownewuser: false,}, () => {
        this.timer = setTimeout(this.triggersend, WAIT_INTERVAL);
    })
  }
  inputsetter(e){
    
    if(e.target.value.length < 40) {   
        clearTimeout(this.timer); 
        this.setState({Title: e.target.value}, ()=> {
            this.timer = setTimeout(this.triggersend, WAIT_INTERVAL);
        })
    }else alert("Title is too long")
  }
  textarea(e){
    if(e.target.value.length < 280) {
        clearTimeout(this.timer);
        this.setState({Description: e.target.value}, () =>{
            this.timer = setTimeout(this.triggersend, WAIT_INTERVAL);
        })
  }else alert("Description is over 280 characters")
  }

  async deletecurrentpost(){
    try{
    var token = localStorage.getItem("auth-token");
    let deletes = await axios.post('/tasksapi/delete', {id: this.props.id }, {headers:{ "auth-token": token}});
    this.props.deletethispost(this.props.id, this.state.selectedlabels)
    }catch(e){
        alert("Could not delete this post")
    }

  }
  updatedate(duedate, dateformat){
    this.setState({duedate, dateformat, calendarclicked: false}, () => { this.triggersend() })
  }
  async taskmoreinformation(){
    await this.triggersend()
    this.setState({tasknotification: {Tasknote: false, selectedaccounts: []}})
    this.props.taskmoreinformation(this.props.id, true)
  }
  render() {
      return (
        
    <div className="task-body-name" > 
        <div className="task-draggable">
            
        </div>
        <input className="task-value-input" placeholder="Title" value={this.state.Title} onChange={(e) => this.inputsetter(e)}/>
        <img src={menupost} className="task-menupost" onClick={() => this.setState({postsettings:true})}></img>
        {(this.state.postsettings)?
        <OutsideClickHandler  onOutsideClick={() => this.setState({postsettings: false, searchduplicate: false})} > 
        <div className="task-options-total tasks-opstions-total-next2">
            {(!this.state.searchduplicate)?
            <div>
            <div className="task-options-each" onClick={this.deletecurrentpost.bind(this)}>Complete/Remove</div>
            <div className="task-options-each" onClick={() => {this.props.duplicatecurrentpost(this.props.id); this.setState({postsettings:false, searchduplicate: false})}}>Duplicate</div>
            <div className="task-options-each" onClick={() => this.setState({searchduplicate: true})}>Duplicate > location</div>
            {(!this.props.mapview_hideall)?<div className="task-options-each" onClick={this.taskmoreinformation}>More Details</div>:null}
            </div>:
                <Select         className="task-value-select3"
        options={this.props.allaccounts}
        dropdownHandle={false}
        onChange={(values) => {this.setState({postsettings: false, searchduplicate: false}); this.props.duplicateanotherlocation(values, {y: this.props.y, id: this.props.id, title: this.state.Title, description: this.state.Description, duedate: this.state.duedate, dateformat: this.state.dateformat, status: this.state.statuscurrent, priority: this.state.prioritycurrent, selectedlabels: this.state.selectedlabels, idval: this.props.idval, selectedaccounts: this.state.selectedaccounts })}}
        values={[]}/>
            
            }
        </div>
        </OutsideClickHandler> :null
        }
         <div onClick={() => this.setState({calendarclicked: true})} className="task-value-total">
         <img className="task-value-calendar" src={Calendarimg}></img>
         <div className="task-value-text">Due Date</div>
         <div className="task-value-text-due">{this.state.duedate}</div>
         </div>
        {(this.state.calendarclicked)?
        <div className="task-calendar"><OutsideClickHandler onOutsideClick={() => this.setState({calendarclicked: false})} > 
          <Task_Calendar updatedate={this.updatedate} dateformat={this.state.dateformat} duedate={this.state.duedate} />
          </OutsideClickHandler> </div>
          : null
        }       
        <div className="task-value-total">
        <img className="task-value-calendar" src={Status}></img>    
        <div className="task-value-text">Status</div>    
        <div style={{color: this.state.status_color}}>
        <Select
        className="task-value-select"
        options={this.state.status_type}
        dropdownHandle={false}
        values={this.state.statuscurrent}
         placeholder=""
         isOptionDisabled={(option) => option.disabled === 'yes'}
         create
         onChange={(values) => this.status_type(values)}/></div>
         </div>
         <div className="task-value-total">
        <img className="task-value-calendar" src={Priority}></img>    
        <div className="task-value-text">Priority</div>    
        <div style={{color: this.state.priority_color}}>
        <Select
        className="task-value-select4"
        options={this.state.priority_type}
        dropdownHandle={false}
        values={this.state.prioritycurrent}
        isOptionDisabled={(option) => option.disabled === 'yes'}
         placeholder=""
         create
         onChange={(values) => this.priority_type(values)}/></div>
         </div>
         <textarea value={this.state.Description} className="task-value-textarea" placeholder="Description" onChange={(e) => this.textarea(e)}/>
        {/* Labels starts here */}
        <Task_label labeledited={this.labeledited} labels_list={this.props.labels_list} selectedlabelsprops={this.props.selectedlabels} selectedlabels={this.state.selectedlabels} labelobject={this.state.labelobject} />
         <div className="task-add-user-total">
             {this.state.selectedaccounts.map((e) => <div className="task-add-user-plus" key={e._id} style={{background:e.color, paddingTop:"2px"}}><div  className="task-add-img" title={e.name} onClick={() => this.addnewaccount(e, "Remove")}>{e.name.split(" ")[0][0].toUpperCase()}{e.name.split(" ")[1]?e.name.split(" ")[1][0].toUpperCase():null}</div></div>)}
             <div className="task-add-user-plus" onClick={()=> this.setState({shownewuser:true})}><i className="fa fa-plus"></i></div>
              { (this.state.shownewuser)? <OutsideClickHandler onOutsideClick={() => this.setState({shownewuser: false})} > 
              <div className="task-results-values task-results-absolute">
                {/* {(!this.state.accountnamesselected.includes(this.props.useraccount.email))?
                  <div className="search-result-each" onClick={() => this.addnewaccount(this.props.useraccount, "Add")}>{this.props.useraccount.name} {this.props.useraccount.email}</div>:null}  */}
                  {this.state.accounts.map((e) => {//if(!this.state.accountnamesselected.includes(e.email)) 
                    return <div key={e._id} className="search-result-each" onClick={() => this.addnewaccount(e, "Add")}>{e.name}, {e.email}</div>})}
                    {(this.state.accounts.length === 0)?<div className="search-result-each" onClick={() => window.location.href="/account"}>No Users Found. Click to Add New Users</div>:null}
              </div>
              </OutsideClickHandler> :null}
         </div>
        {(!this.props.mapview_hideall)?<div onClick={this.taskmoreinformation} className="task-value-more">
        {/*Notifications for new message or checklist here */}
        {(this.state.tasknotification.Tasknote && this.props.useraccount)?<div >{this.state.tasknotification.selectedaccounts.map((e) => {if(e.id === this.props.useraccount._id) return <div key={e.id} className="task-rednotification task-rednotification-total">{e.message + e.checklist}</div> })}</div>:null}
          <div className="fa fa-angle-down"></div></div>:null}
    </div>
      )
    }
  }



