import React, {Component} from 'react';
import axios from 'axios';

import {ReactComponent as Financials} from './Images/bar-chart.svg';
import {ReactComponent as Target_market} from './Images/Group.svg';
import {ReactComponent as Social} from './Images/Social_media.svg';
import {ReactComponent as Overview} from './Images/home.svg';
import {ReactComponent as Location_pin} from './Images/location-pin.svg';
import Social_image from './Images/social.svg';
import Store from './Images/Store.svg'
import Demographic from './Images/demographic.svg'
import Location from './Images/location.svg'
import Financial from './Images/financial.svg'
import uuid from 'react-uuid';

var images = [Store, Financial, Location, Demographic, Social_image]
export class Competitor_research_brand extends React.Component{
    constructor(props) {
        super(props);
        this.state= {
            search_competitor: "",
            view: 0,
            gettext: ["Brand analysis", "Get proprietary financial data", "All known competitor store locations", "Target market by stores geo-territory", "Social media growth"],
            getbrand: [["Research over 20,000 Retail and Restaurant Brands", "Get up-to-date data for companies financial & market analysis", "Get detailed information of a brands location growth"]
            ,[
                "Accurate revenue, rent and employees cost", "Proprietary financial data that cannot be found anywhere else", "Powered by the latest Machine Learning technology"
             ],
             [
                "Get every known store location of a competitor on a map, including their financials",  "Get updates on your competitors location growth month-over-month"
             ],
             [
                "Get detailed competitor market by store locations", "Predominant demographic by store location"
             ],
             [
                "Historic growth of a brands social media", "Includes data for Facebook, Instagram, and Twitter"
             ]
            ]
        }
    }
    gettext = () =>{
        return this.state.gettext[this.state.view]
    }
    gettier = async () =>{
        let token = localStorage.getItem("auth-token");
        if(token === "" || !token ) return null;
        try{
            let response = await axios.post('/signup/freetier/type', {}, {headers:{ "auth-token": token}})
            let res = await response.data
            if(res.freetier == null) return;
            this.setState({freetier: res.freetier})
        }catch(e){
            localStorage.removeItem("auth-token")
            console.log(e)
        }
    }
    componentDidMount(){
        this.gettier()
    }
    render() {
        return (
            <div className="Competitor_reasearch-brand-total">
            <div className="Competitor_reasearch-total-brand">
                <div onClick={() => this.setState({view: 0})} className="Competitor-reasearch-brand">
                <Overview  className={(this.state.view === 0)?"Competitor-sidebar-image2 Competitor-height38":"Competitor-sidebar-image Competitor-height38"}/>
                <div className={(this.state.view === 0)?"Competitor-sidebar-name2 Competitor-blue":"Competitor-sidebar-name2"}>Brands</div>
                </div>
                <div onClick={() => this.setState({view: 1})} className="Competitor-reasearch-brand">
                <Financials  className={(this.state.view === 1)?"Competitor-sidebar-image2 Competitor-height38":"Competitor-sidebar-image Competitor-height38"}/>
                <div className={(this.state.view === 1)?"Competitor-sidebar-name2 Competitor-blue":"Competitor-sidebar-name2"}>Financials</div>
                </div>
                <div onClick={() => this.setState({view: 2})} className="Competitor-reasearch-brand">
                <Location_pin  className={(this.state.view === 2)?"Competitor-sidebar-image2 Competitor-height38":"Competitor-sidebar-image Competitor-height38"}/>
                <div className={(this.state.view === 2)?"Competitor-sidebar-name2 Competitor-blue":"Competitor-sidebar-name2"}>Locations</div>
                </div>
                <div onClick={() => this.setState({view: 3})} className="Competitor-reasearch-brand">
                <Target_market  className={(this.state.view === 3)?"Competitor-sidebar-image2 Competitor-height38":"Competitor-sidebar-image Competitor-height38"}/>
                <div className={(this.state.view === 3)?"Competitor-sidebar-name2 Competitor-blue":"Competitor-sidebar-name2"}>Target Market</div>
                </div>
                {/* <div onClick={() => this.setState({view: 4})} className="Competitor-reasearch-brand">
                <Social  className={(this.state.view === 4)?"Competitor-sidebar-image2 Competitor-height38":"Competitor-sidebar-image Competitor-height38"}/>
                <div className={(this.state.view === 4)?"Competitor-sidebar-name2 Competitor-blue":"Competitor-sidebar-name2"}>Social Media</div>
                </div> */}
        <div className="Competitor-branding-box">
            <div className="Competitor-financials-title Competitor-branding-title">{this.gettext()}
            <div className="Competitor-title-line"></div></div>
        <ul className="Competitor-details-total">{this.state.getbrand[this.state.view].map((e) =><li key={uuid()} className="Competitor-details">{e}</li>)}</ul>
            <img className= "Competitor-branding-image" src={images[this.state.view]}/>
            </div>
            </div>
            </div>
    )}
}