import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ImageIcon from '@mui/icons-material/Image';

const MainSearchBar = ({ search, search_loc, searchterm, sendingnew }) => {
    return (
    <>
        <div id="status-search-total"  > 
            <input data-tut="reactour__search"  type="text" className="search-results-total" placeholder="Search..." onChange={e => search_loc(e.target.value, true) } value={searchterm} />
            {/* <TextField
            data-tut="reactour__search"
            fullWidth
            variant="outlined"
            placeholder="Search..."
            onChange={(e) => search_loc(e.target.value)}
            value={searchterm}
            InputProps={{
                endAdornment: <SearchIcon />,
            }}
            sx={{ m: 1 }}
            /> */}
            {search.length > 0 && <List sx={{  width: '100%', 
                maxHeight: 300, 
                overflow: 'auto',
                backgroundColor: 'white', // Make background white
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                 }}>
                {search.map((item) => (
                    <ListItem
                        key={item.id}
                        alignItems="flex-start"
                        onClick={() => sendingnew(item.position, item.title, item.subcategory, item.image)}
                        sx={{ 
                            cursor: 'pointer', 
                            '&:hover': { 
                                bgcolor: 'rgba(0, 0, 0, 0.04)', 
                                cursor: 'pointer'
                            },
                        }}
                    >
                        {item.image && <Box
                            sx={{
                                width: 32,
                                height: 32,
                                marginRight: 1,
                                marginTop: '10px',
                                overflow: 'hidden',
                            }}
                        >
                            <img 
                                src={item.image} 
                                alt={item.title}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </Box>}
                        <ListItemText
                            primary={item.title}
                            secondary={
                                <>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {item.subcategory}
                                    </Typography>
                                </>
                            }
                        />
                    </ListItem>
                ))}
            </List>}
            </div>
    </>
    );
};




export default MainSearchBar;