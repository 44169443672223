import React from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment';
import axios from 'axios';

const localizer = momentLocalizer(moment)

export class Task_bigcalendar extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    viewdate: [new Date().getFullYear(), new Date().getMonth()],
    events:[],
    currentdata: [],

    }    
    this.editlayout= this.editlayout.bind(this);
    this.datechange = this.datechange.bind(this);
    }
    getthevalue(datall){
        let data = datall.map((e) => {
            let date = null;
            if(e.dateformat) {
                let dateformat = e.dateformat;
                let type = dateformat.type[0].value;
                
                if(type === "0"){
                    /** IF TYPE RECURRING */
                    let rec = dateformat.datarecurring[0].value;
                    var hour = (dateformat.datetime.length >0)? dateformat.datetime[0].num: 0;
                    
                    switch (rec) {
                        case "Weekly":
                            let day = dateformat.dataofweekpicked[0].label;
                            let view = this.state.viewdate[1]+ 1;
                            var dateval = moment(view +"-" + "01"+ "-" +this.state.viewdate[0] + ' 00:00:00').day(day);
                            
                            if (dateval.date() > 7) dateval.add(7,'d');
                            var month = dateval.month();
                            
                            var datetotal = [];
                            while(month === dateval.month()){
                                // console.log(dateval.date(),dateval,day, dateval.set({hour,minute:0,second:0,millisecond:0}), "momomnday value was hereifjeifje")
                                date = dateval.set({hour,minute:0,second:0,millisecond:0}).toDate()
                                let dateend = dateval //.set({hour: hour+1,minute:0,second:0,millisecond:0}).toDate();
                                datetotal.push({id: e.id, title:e.title, start:dateval.toDate(), end: dateval.toDate(),  description: e.description})
                                dateval.add(7,'d');
                            }
                            return datetotal

                        case "Daily":
                                let viewdata = this.state.viewdate[1]+1;
                                date = new Date(this.state.viewdate[0] +"-"+ viewdata+"-" + 1);
                                var days = [];
                                
                                while (date.getMonth() +1 === viewdata) {
                                
                                let tempdate = new Date(date);
                                let tempstart = tempdate.setHours(tempdate.getHours() + 1);
                                days.push({id: e.id, title:e.title, start:new Date(tempstart), end: new Date(tempstart),  description: e.description});
                                date.setDate(date.getDate() + 1);
                                }
                            return days;
                        case "Monthly":
                            let senddate = new Date(dateformat.dateformat)
                            let viewdata1 = this.state.viewdate[1]+1;
                            let currentday = senddate.getDate();
                            while(!moment(this.state.viewdate[0] +"-"+ viewdata1+"-" + currentday).isValid()){
                                currentday -=1;
                            }
                            let tempdate = new Date(this.state.viewdate[0] +"-"+ viewdata1+"-" + currentday);
                            let tempend= tempdate.setHours(tempdate.getHours() +1);
                            tempend = new Date(tempend);
                            return [{id: e.id, title:e.title, start: tempdate, end: tempend,  description: e.description}]
                    }
                }else{
                    /** IF TYPE ONE TIME */
                    let tempdate = new Date(dateformat.dateformat);
                    console.log(tempdate, 'date temp_date here')
                    tempdate.setHours(tempdate.getHours() +1);
                    return [{id: e.id, title:e.title, start: tempdate, end: tempdate,  description: e.description}]
                }
            }else{
                /** IF NO DATE PICKED */
                date = new Date(e.createddate);
                return[{id: e.id, title:e.title, start:date, end: date, allDay: true,  description: e.description}]
            }

        })
        var merged = [].concat.apply([], data); console.log(merged, 'merged')
        return merged
    }
    async editlayout(){
        var token = localStorage.getItem("auth-token");
        let getall = await axios.post('/tasksapi/getallcalendar', {}, {headers:{ "auth-token": token}})
        let datall = await getall.data.task;
        var merged = this.getthevalue(datall)
        
        this.setState({alldata: datall, currentdata: merged})
    }
    componentWillMount(){
        this.editlayout();
    }
    timeslotclicked(val){
        console.log(val, 'value here')
        this.props.calendarshow(val.id);
    }
    datechange(date, view){
        if(date.getFullYear() !== this.state.viewdate[0] || date.getMonth() !== this.state.viewdate[1]){
            this.setState({viewdate: [date.getFullYear(), date.getMonth()]}, () =>{
                var merged = this.getthevalue(this.state.alldata);
                this.setState({currentdata: merged})
            })
        }
        console.log(date.getMonth(), view)
        
    }
    render() {
        return (
            <div className="task-big-calendar-total">
            <Calendar
            events={this.state.currentdata}
            selectable
            step={30}
            timeslots={1}
            localizer={localizer}
            eventTimeRangeFormat={()=> null}
            defaultView={Views.WEEK}
            onSelectEvent={(e) => this.timeslotclicked(e)}
            onNavigate={(date, view) => this.datechange(date, view)}
          />
          </div>
        )}
} 