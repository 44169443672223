import React from 'react';
import {ElementsConsumer, CardElement ,Elements} from '@stripe/react-stripe-js';
import maptize from '../Images/maptize-black.png';
import axios from 'axios';
import {loadStripe} from '@stripe/stripe-js';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Select from 'react-dropdown-select';

export class Account_sub extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            password : '',
            email: '',
            name: '',
            address: '',
            num: 0,
            tags: [],
            adding: false,
            temp_tags: [],
            filter_tags: [],
            accounts: [],
            app_final: [],
            accounts_filter: [],
            newuser: true,
            id: null,
            Errors: null,
        }
        this.getvalues = this.getvalues.bind(this)
        this.deletevalue = this.deletevalue.bind(this)
        this.updatevalue = this.updatevalue.bind(this)
        this.tags_filter = this.tags_filter.bind(this)
      }
      componentWillMount(){
        this.getvalues(true)
      }
      async getvalues(firsttime){
        let token = localStorage.getItem("auth-token");
        var response = null;
        var business = null;
        try{
        response =  await axios.post('/accountsub/accountinfo', {}, {headers:{ "auth-token": token}});
        business =  await axios.post("/gettheurl/prospect", {}, {headers:{ "auth-token": token}});
        }catch(e){
            if(e.response.status == 400) return window.location.replace('/login')
        }
        let bus  = await business.data;
        let resp = await response.data;
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let app = params.get('tag');
        var app_final = [];
        var temp_accounts =  resp.children
        if(app && firsttime){
        temp_accounts = resp.children.filter((v) => {
          for(let i in v.tags){            
            if(app.toLowerCase() === v.tags[i].value.toLowerCase()){
              return true;
            }
          }
        })
        app_final = [{value: app, label: app}]
        }
        
        var temp_tag = [];
        bus.business_data.map((e) => temp_tag.push(e.tags))
        bus.prospect_data.map((e) => temp_tag.push(e.tags))
        var newuser= true;
        if(resp.children.length >= resp.number)
            newuser= false;
        var merged = [].concat.apply([], temp_tag);
        var merg = merged.filter((item, i, ar) => ar.indexOf(item) === i);
        merg = merg.map((e) => {return {value:e, label: e}})
        console.log(app_final)
        this.setState({tags: merg, num: resp.number, newuser, accounts: resp.children, accounts_filter: temp_accounts, filter_tags: app_final})
    
    }
      addaccount(){
          if(this.state.newuser )
          this.setState({adding: true, name: '', email:'', temp_tags:[], id:null})
          
      }
      tags_setter(val){
        
        this.setState({temp_tags: val})
      }
      async senddata(){
        let token = localStorage.getItem("auth-token");
        let val = this.state.name
        if(val == '') return alert('Please enter a name.')
        if( val.length >= 150)return alert('Name Entered is too long.')  
        var response = null;
        try{
        response =  await axios.post('/accountsub/createaccount', {name:val, email: this.state.email, tags: this.state.temp_tags, id: this.state.id, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,  }, {headers:{ "auth-token": token}});
        this.setState({adding: false, name: '', email:'', temp_tags:'', id:null, Errors: null})
        }catch(e){
            // if(e.response.status == 400) window.location.replace('/login')
            if(e.response.status == 401) return this.setState({Errors: e.response.data})
        }
        this.getvalues();

      }
      async updatevalue(e){
          console.log(e)
        this.setState({name: e.name, email: e.email, temp_tags: e.tags, id: e._id, adding: true})
      }
      cancelsend(){
        this.setState({adding: false, name: '', email:'', temp_tags:[], id:null , Errors: null})
      }
      async deletevalue(e){
          if(this.state.id === e)
          this.setState({adding: false, name: '', email:'', temp_tags:[], id:null, Errors: null})
        let token = localStorage.getItem("auth-token");
        console.log(e)
        let response = null;
        try{
        response =  await axios.post('/accountsub/deleteuser', {id: e }, {headers:{ "auth-token": token}});
        this.getvalues()
        }catch(e){
            this.setState({Errors: e.response.data})
        }
      }
      tags_filter(e){
        var evalue = e.map((ee) => ee.value.toLowerCase());
        var temp_accounts = this.state.accounts.filter((v) => {
          if(evalue.length === 0)
            return true;
          for(let i in v.tags){
            console.log(v.tags[i], evalue)
            if(evalue.includes(v.tags[i].value.toLowerCase())){
              return true;
            }
          }
        })
        this.setState({accounts_filter: temp_accounts})
      }
      render() {
      return (
        <div className='container-account col-xs-1'>
        <img className="image-account" src={maptize} onClick={()=> window.location.href = '/'}></img>
        <div className="sidebar-account-total" onClick={() =>window.location.replace('/account')}>
          <div className="sidebar-account-each">Account Settings</div>
          <div className="sidebar-account-each" onClick={() =>window.location.replace('/tasks')}>Task Management</div>
          <div className="sidebar-account-each" onClick={() =>window.location.replace('/')}>Map View</div>
          <div className="sidebar-account-each" onClick={() =>window.location.replace('/setup')}>Setup Filters</div>
          <div className="sidebar-account-each sidebar-account-selected" >Sub Accounts</div>
        </div>
        <div className='box-account box-account-users'>
        <button className="button-account button-top-account" onClick={(this.state.newuser)?this.addaccount.bind(this):()=>{window.location.href='/account'}}>{(this.state.newuser)?"Add Account":"Upgrade Account"}</button>
        <div className="title-account">Sub Account Details</div><div className="fa fa-question-circle" id="accountsub-questionmark"></div><div className="info-value-boxtotal" id="info-value-accountsub"><div className="info-value-desc" >Add other users from your organization to task management platform.</div></div>
        {(this.state.Errors)? <div className= "error-logger">{this.state.Errors}</div>:null}
        {(this.state.adding)? <div>
        <div className="each-total-account">
            <div className="each-lable-account" >Full Name:</div>
            <input className="each-bottom-account each-input-account" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}></input>
        </div>
        
        <div className="each-total-account">
        <div className="each-lable-account" >Email:</div>
            <input className="each-bottom-account each-input-account" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}></input>
        </div>   <div className="each-total-account">       
        <div className="each-lable-account">Tags/task Filters:</div>
        <div className="each-lable-accountval">
        <Select  options={this.state.tags}   
                create
                multi
                values={this.state.temp_tags}   
                onChange={(values) => this.tags_setter(values)}/>
        </div>
        </div>
        <button className="cancelpass-account password-account" onClick={this.cancelsend.bind(this)} >Cancel</button>
        <button className="button-account"  style={{"margin-left": "18px"}} onClick={this.senddata.bind(this)}>Save</button>
        </div>:null    }
        <div>    <div className="account-value-filter"> <div className="account-value-select">  <Select  options={this.state.tags}   
                multi
                placeholder="Filter by tags"
                values={this.state.filter_tags}   
                onChange={(values) => this.tags_filter(values)}/></div></div>
            <table id="users-table-account" cellspacing="0" cellpadding="0"><thead className="users-table-header"><tr><td>Name</td><td>Email</td><td>Status</td><td>Created</td><td></td> </tr> </thead>
            <tbody>
            {this.state.accounts_filter.map((e) => {
               return <tr key={e._id}><td>{e.name}</td><td>{e.email}</td><td className={(e.emailverified)?"users-table-verified":"users-table-pending"}>{(e.emailverified)?"Verified":"Pending"}</td><td>{e.date}</td><td><button className="button-account button-account-user" onClick={()=> this.updatevalue(e)}>Edit</button><button onClick={() => this.deletevalue(e._id)} className="cancel-delete-users cancelpass-account password-account">Delete</button> </td> </tr>
            })
            }
            </tbody>
            </table>
        </div>
        </div>
        </div>
      )}}