import React from 'react';
import {Boxdataset} from './ui_components/boxdataset';
import { Bar, Pie } from 'react-chartjs-2'; // Importing chart components
import { Infoeach } from './infoeach';
import {Piecharttotal} from './piecharttotal.js';
import star from './Images/star.png';
import goldstar from './Images/goldstar.png';

const DemographicInfo = ({  state, props, industry_hidemore, dem_showmore, unsetarrangement, setarrangement, exportInd}) => {
    const starredsystem = (num) => {
        if(state.zoom == 2)
        {
          if(state.arrangement.includes(num))
            return(<img src={goldstar} onClick={() => unsetarrangement(num)} className="starredvalues-long starredvalues"></img> )
          
          return(<img src={star} onClick={() => setarrangement(num)} className="starredvalues-long starredvalues"></img> )
        }
    }

    const degree_total = () => {
        let degree = Object.values(props.degree)
        let total = degree.reduce((a, b) => a + b, 0)
        degree = degree.map((e) => Math.round((e * 100) / total)/100)
        return {
          labels: Object.keys(props.degree),
              datasets: [
                {
                  
                  backgroundColor: [
                    'rgba(0,85,139, .5)',
                    'rgba(139,54,0, .5)',
                    'rgba(0,139,54, .5)',
                    'rgba(0,38,62,0.6)',
                    'rgba(255, 153, 51,0.5)',
                    'rgba(216,222,225, 0.5)',
                    'rgba(158,127,146, 0.5)',
                    'rbga(127,158,139, 0.5)',
                    'rgba(185,192,173,.5)',
                    'rgba(159,130,148,.5)',
                    
                  ],
                  hoverBackgroundColor: [
                  'rgba(0,85,139, 1)',
                  'rgba(139,54,0, 1)',
                  'rgba(0,139,54, 1)',
                  'rgba(0,38,62,1)',
                  'rgba(255, 153, 51,1)',
                  'rgba(216,222,225, 1)',
                  'rgba(158,127,146, 1)',
                  'rbga(127,158,139,1)',
                  'rgba(185,192,173,1)',
                  'rgba(159,130,148,1)',
                  
                  ],
                  data: degree
                }
              ]}
    }

    const age_income_overall_data = () => {
        const age_income_overall_prev = props.age_income_overall[2012] ? Object.values(props.age_income_overall[2012]) : [] ;
        const age_income_overall_current = props.age_income_overall[2018] ? Object.values(props.age_income_overall[2018]) : [];
        return{
        labels: ['Under 25', '25 - 44', '44 - 64', '64 & Over'],
          datasets: [
            {
              label: "Previous Period",
              
              backgroundColor: 'rgba(255, 153, 51,0.5)',
              borderColor: 'rgba(255, 153, 51,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(255, 153, 51,0.7)',
              hoverBorderColor: 'rgba(255, 153, 51,1)',
              data: age_income_overall_prev
            },
            {
              label: "Current Period",
              
              backgroundColor: 'rgba(0,38,62,0.5)',
              borderColor: 'rgba(0,38,62,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(0,38,62,0.7)',
              hoverBorderColor: 'rgba(0,38,62,1)',
              data: age_income_overall_current
            },
          ]
        };
        
      }
      const age_income_data = () => {
        return {
          labels: ['$0 - 25', '$25 - 45', '$45 - 60', '$60 - 75', '$75 - 100', '$100 - 125', '$125 - 150', '$150 - 200', '$200+'],
          datasets: [
            {
              label: "Previous Period",
              
              backgroundColor: 'rgba(255, 153, 51,0.5)',
              borderColor: 'rgba(255, 153, 51,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(255, 153, 51,0.7)',
              hoverBorderColor: 'rgba(255, 153, 51,1)',
              data: props.age_income_prev
            },
            {
              label: 'Current Period',
              
              backgroundColor: 'rgba(0,38,62,0.5)',
              borderColor: 'rgba(0,38,62,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(0,38,62,0.7)',
              hoverBorderColor: 'rgba(0,38,62,1)',
              data: props.age_income
            },
          ]
        };
      }
      const family_income_barchart = () => {
        var income_past = [];
        var income_current = [];
  
        if(Object.keys(props.family_income).length > 0)
        {
          income_current = Object.values(props.family_income[2018])
          income_past = Object.values(props.family_income[2012])
          
        }
        return {
          labels: ['$0 - 25', '$25 - 45', '$45 - 60', '$60 - 75', '$75 - 100', '$100 - 125', '$125 - 150', '$150 - 200', '$200+'],
          datasets: [
            {
              label: "Previous Period",
              
              backgroundColor: 'rgba(255, 153, 51,0.5)',
              borderColor: 'rgba(255, 153, 51,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(255, 153, 51,0.7)',
              hoverBorderColor: 'rgba(255, 153, 51,1)',
              data: income_past
            },
            {
              label: 'Current Period',
              backgroundColor: 'rgba(0,38,62,0.5)',
              borderColor: 'rgba(0,38,62,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(0,38,62,0.7)',
              hoverBorderColor: 'rgba(0,38,62,1)',
              data: income_current
            },
          ]
        };
      }
      const bar_options = () => {
        return {
          scales: {
            x: {
              title: {
                display: true,
                text: '$1000 Increments'
              }
            }
          }
        }
      }
      
      const Horizontalbar_options = (val) => {
        const options = {
          scales: {
            y: {        
              ticks: {
              callback: function(value) {
                // Multiply by 1000 and format with commas for thousands
                return '$' + (value).toLocaleString();
              }
            },
              title: {
                display: true,
                text: val
              }
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function(context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  // Assuming context.parsed.y is the correct value for the y-axis
                  if (context.parsed.y !== null) {
                    label += '$' + context.parsed.y.toLocaleString();
                  }
                  return label;
                }
              }
            }
          }
        };
  
      
        return options;
      }
  
      const Horizontalbar_options_targeted = (val, tr) => {
        const options = {
          scales: {
            y: {
              title: {
                display: true,
                text: val
              }
            },
            x: {
              title: {
                display: true,
                text: "$1000 Increments"
              }
            }
          },
          
        };
      
        return options;
      }
      const     options = {
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                const value = context.parsed || 0;
                const total = context.dataset.data.reduce((acc, data) => acc + data, 0);
                const percentage = Math.round((value / total) * 100) + '%';
                return `${percentage}`;
              }
            }
          }
        }
      }
    return (

        <div className="container-marginleft">
            {(state.zoom == 2)? <div className="box-price box-showmore" onClick={industry_hidemore}><div id="box-goback">Back</div></div>: null}
            
            <div id="pdf-export-container-demographic" className='pt-20'>
              {exportInd && <h2 className='ml-10 mb-20 mt-100 font-weight600'>Demographic Information</h2>}
              {(state.arrangement.includes(1) || state.zoom == 2  || exportInd)?<div>
              <div className="boxdata-header">Family Income</div>
              <Boxdataset classnameval="box-container" starredval={true} margin={154} unsetarrangement={unsetarrangement} setarrangement={setarrangement} zoom={state.zoom} arrangement={1} arrangestar={state.arrangement.includes(1)} top_text='Family Income' mid_text="Number of Families" total_diff={props.family.dif} total_current={props.family.current} total_past={props.family.prev} type={props.type_income} description="Number of family's with target income in the region. Previous Period: 2012" filters={{"Income":props.cat_all.income, "Race":props.cat_all.race}}/>
              <Boxdataset classnameval="box-container" margin={154} unsetarrangement={unsetarrangement} setarrangement={setarrangement} zoom={state.zoom} arrangement={1} arrangestar={state.arrangement.includes(1)} top_text='Family Income' mid_text="Est. Income" total_diff={props.family_estincome.estincome_diff} total_current={ "$"+ props.family_estincome.estincome_2018} total_past={"$"+  props.family_estincome.estincome_2012} type={props.type_income} description="Estimate of family income in the region. Previous Period: 2012" filters={{"Income":props.cat_all.income, "Race":props.cat_all.race}}/></div>: null}
              {(state.arrangement.includes(3) || state.zoom == 2  || exportInd)?<div>
              <div className="boxdata-header">Family Expenses</div>
              <Boxdataset classnameval="box-container" starredval={true} margin={154} unsetarrangement={unsetarrangement} setarrangement={setarrangement} zoom={state.zoom} arrangement={3} arrangestar={state.arrangement.includes(3)} top_text='Family Expenses' mid_text="Est. Expenses w Mortgage" total_diff={props.expenses_diff.mortgage} total_current={ "$"+ props.expenses2018.mortgage} total_past={"$"+  props.expenses2012.mortgage} type={props.type_income} description="Estimated total spent by family during a monthly bases in the region. Previous Period: 2012" filters={{}}/>
              <Boxdataset classnameval="box-container" margin={154} unsetarrangement={unsetarrangement} setarrangement={setarrangement} zoom={state.zoom} arrangement={3} arrangestar={state.arrangement.includes(3)} top_text='Family Expenses' mid_text="Est. Expenses w/o Mortgage" total_diff={props.expenses_diff.postmortgage} total_current={ "$"+ props.expenses2018.postmortgage} total_past={"$"+  props.expenses2012.postmortgage} type={props.type_income} description="Estimated total spent by family during a month without mortgage in the region. Previous Period: 2012" filters={{}}/></div>: null}
              {(state.arrangement.includes(5) || state.zoom == 2  || exportInd) && <div className="boxdata-container">
              <div className="boxdata-header-one boxdata-header">Target Demographic</div>
              <Boxdataset classnameval="box-container2" width={true} margin={154} unsetarrangement={unsetarrangement} setarrangement={setarrangement} zoom={state.zoom} arrangement={5} arrangestar={state.arrangement.includes(5)} top_text='Population' mid_text='Target Population'  total_diff={props.total_age_sex.dif_pop} total_current={props.total_age_sex.pop_2018_f} total_past={props.total_age_sex.pop_2012_f} type={props.type} description="Estimate total target population in the region. Previous Period: 2012" filters={{"Gender": props.cat_all.gender, "Age": props.cat_all.age, "Race": props.cat_all.race}}/>
              </div>}
              {(state.arrangement.includes(6) || state.zoom == 2  || exportInd) &&<div className="boxdata-container">
              <div className="boxdata-header-one boxdata-header">Travel Time</div>
              <Boxdataset classnameval="box-container2" width={true} margin={154} unsetarrangement={unsetarrangement} setarrangement={setarrangement} zoom={state.zoom} arrangement={6} arrangestar={state.arrangement.includes(6)} top_text='Travel Time' mid_text='Avg. Travel Time to Work'  total_diff={props.traveltime_each_dif} total_current={props.traveltime_each_2018} total_past={props.traveltime_each_2012} stringval={true} type={(props.traveltime_each_2018)?true: false} description="Estimate time spent traveling to work in the region. Previous Period: 2012" filters={{"Gender":props.cat_all.gender}}/>
              </div>}
            </div>

            <div id='pdf-export-container-demographic-family' className='pt-20'>
            {(state.arrangement.includes(7) || state.zoom == 2 || exportInd)? <div className="barchart-horizontal"><Infoeach margin={385} description="Estimate of each family in each income group in the region. Previous Period: 2012" filters={{ "Race":props.cat_all.race}}/><div className="boxdata-header">Family Income</div>{starredsystem(7)}<Bar id="barchart-familyincome" data={family_income_barchart()} options={bar_options()} /></div>: null}
            {(state.arrangement.includes(8) || state.zoom == 2 || exportInd)? <div className="barchart-horizontal"><Infoeach margin={385} description="Estimate of target age group income. Previous Period: 2012" filters={{"Age": props.cat_all.age}}/><div className="boxdata-header">Income for Target Age Group</div>{starredsystem(8)}<Bar id="barchart-targetincomeage" data={age_income_data()}  options={Horizontalbar_options_targeted('Total target Age Group income')}/></div>: null}
            </div>
            <div id='pdf-export-container-demographic-income' className='pt-20'>
            {(state.arrangement.includes(9) || state.zoom == 2 || exportInd)? <div className="barchart-horizontal"><Infoeach margin={385} description="Estimate of each age group income. Previous Period: 2012" filters={{}}/><div className="boxdata-header">Income by Age Group</div>{starredsystem(9)}<Bar id="barchart-incomeage" data={age_income_overall_data()}  options={Horizontalbar_options('Avg. income by Age Group')}/></div>: null} 
            </div>
            <div id='pdf-export-container-demographic-employee' className='pt-20'>
            { (state.arrangement.includes(10) || state.zoom == 2 || exportInd)? <Piecharttotal margin={385} description="Estimate employees within each sector of employment in the region. (Click on industry to see sub-industry) Previous Period: 2012" filters={{"Gender":props.cat_all.gender }} unsetarrangement={unsetarrangement} setarrangement={setarrangement} zoom={state.zoom} arrangement={10} arrangestar={state.arrangement.includes(10)} top_text='Occupation'  occupation={props.occupation} type={props.type}/>: null }
            </div>
            <div id='pdf-export-container-demographic-education' className='pt-20'>
            {(state.arrangement.includes(11) || state.zoom == 2  || exportInd)? <div className="barchart-horizontal"><Infoeach margin={385} description="Education level of age and gender group. Previous Period: 2012" filters={{"Age": props.cat_all.age, "Gender": props.cat_all.gender}}/><div className="boxdata-header">Education Level</div>{starredsystem(11)}<Pie id="barchart-degree" data={degree_total()} options={options} /></div>: null}
            </div>
            {(state.zoom != 2 && !exportInd)? <div className="box-price box-showmore" onClick={dem_showmore}>Show More...</div>: null}
            </div>

  );
};

export default DemographicInfo;
