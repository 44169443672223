import React, {Component} from 'react';
import axios from 'axios';
import facebook from './Images/facebook.svg';
import linkedin from './Images/linkedin.svg';
import twitter from './Images/twitter.svg';
import pinterest from './Images/pinterest.svg';
import instagram from './Images/instagram.svg'

export class Competitor_brand extends React.Component{
    constructor(props) {
        super(props);
        this.state= {
            search_competitor: "",
            searched: []
        }
    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    render() {
        let brand = this.props.brand ? this.props.brand : {}

        return (
          <div id = "Competitor-brand-total">
              
                {this.props.search ? <div className="Competitor-brand-topsection">
                    {(brand.Image)?<img src={brand.Image} onError={(e)=>{e.target.onerror = null; e.target.src="https://i.ibb.co/8NYd9pw/white-background.png"}} className="Competitor-result-image"/>: <div  className="Competitor-result-image"></div>}

                    <div className="Competitor-brand-each-name">{this.props.search}
                {brand.social?<div className="Competitor-social-total">
                    {(brand.social[0])?<a className="Competitor-social" href={brand.social[0]}>
                        <img className="Competitor-social-image" src={facebook}/>
                    </a>:null}
                    {(brand.social[1])?<a className="Competitor-social" target="_blank" href={brand.social[1]}>
                        <img className="Competitor-social-image" src={twitter}/>
                    </a>:null}
                    {(brand.social[2])?<a className="Competitor-social" target="_blank" href={brand.social[2]}>
                        <img className="Competitor-social-image" src={pinterest}/>
                    </a>:null}
                    {(brand.social[3])?<a className="Competitor-social" target="_blank" href={brand.social[3]}>
                        <img className="Competitor-social-image" src={instagram}/>
                    </a>:null}
                    {(brand.social[4])?<a className="Competitor-social" target="_blank" href={brand.social[4]}>
                        <img className="Competitor-social-image" src={linkedin}/>
                    </a>:null}
                    </div>:null
                    }</div>
                    </div>:null}
                {brand.final_locations? <div>
                    <div className="Competitor-branch-name">Estimate Number of Locations</div>
                    <div className="Competitor-brand-each">{this.numberWithCommas(brand.final_locations)}</div>
                </div>:null}
                {!brand.final_locations && brand.Total? <div>
                    <div className="Competitor-branch-name">Number of Known Locations </div>
                    <div className="Competitor-brand-each">{this.numberWithCommas(brand.Total)}</div>
                </div>:null}
                {brand.website? <div>
                    <div className="Competitor-branch-name">Website </div>
                    <a className="Competitor-brand-each" href={brand.website} target="_blank">{brand.website}</a>
                </div>:null}
                {brand.Founder ?<div>
                    <div className="Competitor-branch-name">Founder</div>
                    <div className="Competitor-brand-each">{brand.Founder}</div>
                </div>:null}

          </div>
    )}
}