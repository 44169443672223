import React, {Component} from 'react';
import axios from 'axios';
import maptize from '../Images/maptize_logo_white-01.svg';
import ReCAPTCHA from "react-google-recaptcha";
import Select from 'react-dropdown-select';
import industry_avgs from './industry_avgs.json'

const occupation = industry_avgs.map((av) => {return {value: av.type, label: av.type.replace("_", " ")}})


export class Competitor_register extends React.Component{

    constructor(props) {
        super(props);

        this.state= {
            search_competitor: "",
            searched: [],
            name: "",
            handlepass: "",
            handleregemail: "",
            handlemail:"",
            handlepassword: ""

        }
        this.categoryselector = this.categoryselector.bind(this);
        this.valuesetter = this.valuesetter.bind(this);
        this.occupation_setter = this.occupation_setter.bind(this);
    }
    valuesetter(values){
        this.setState({eachcat: values})
    }
    occupation_setter(value){

        this.setState({occupation: value})
    }
    async categoryselector(){

        var token = localStorage.getItem('auth-token');
        var cat_f = [];
        var cat_all = await axios.post('/setup/cattotal');
        let cat_all_final = await cat_all.data;

           this.setState({ resp: cat_all_final})
    }

    componentWillMount(){
        this.categoryselector()
    }
    handlenameChange = (e) =>{
        this.setState({classes_name: 'has-val', name: e.target.value})
    }

    handlemail = (e) => this.setState({handlemail: e.target.value})
    handleregemail = (e) => this.setState({handleregemail: e.target.value})
    handlepass = (e) => this.setState({handlepass: e.target.value})
    handlepassword = (e) => this.setState({handlepassword: e.target.value})
    register = async () => {
        if(!this.state.token) return this.setState({error_value: "Please complete the Captcha properly"})
        if(this.state.name.trim().length < 1) return this.setState({error_value:"Please provide your full name"})
        if(this.state.handlepass.length < 8) return this.setState({error_value:"Password must be at least 8 Characters", passwordvalid: false})
        if(!this.state.handlepass.match(/(?=.*[0-9])/)) return this.setState({error_value:"Password must contain at least One Number", passwordvalid: false})
        try{
        let response = await axios.post('/signup/freetier', { token: this.state.token,   
            name: this.state.name.trim(), 
            password: this.state.handlepass, 
            email: this.state.handleregemail, 
            each_category: this.state.eachcat,
            occupation: this.state.occupation
        })
        let res = await response.data;
        localStorage.setItem("auth-token",response.headers['auth-token'])
        this.props.closeregisterer({freetier: true})
        if(this.props.gettier)
            this.props.gettier()
        }catch(e){
            this.setState({error_value: e.response.data })
        }
    }
    captcha =(e) => this.setState({token: e})
    
    login = async () => {
        if(this.state.handlepassword.length < 1) return this.setState({error_value:"Please Enter a password", passwordvalid: false})
        if(this.state.handlemail.length < 1) return this.setState({error_value:"Please Enter a Email", passwordvalid: false})

        try{
        let response = await axios.post('/signin/auth', { password: this.state.handlepassword, email: this.state.handlemail  })
        let res = await response.data;
        console.log(res);
        localStorage.setItem("auth-token", response.headers['auth-token'])
        this.props.closeregisterer({freetier: res.freetier})
        if(this.props.gettier)
            this.props.gettier()
        }catch(e){
            this.setState({signin_error_value: e.response.data })
        }
    }
    render() {
        return (
        <div className="Competitor-register-total">
            <div className="Competitor-register-main">
                <div className="Competitor-register-background">
                <img className="Competitor-research-menu-logo2"  src={maptize} />
                {this.props.type? 
                <div>
                    
                <h2 className="Competitor-search-header2 Competitor-search-header3">Register for a free account</h2>
                <div className="Competitor-register-form">
                {/* <div className="Competitor-register-form-names">Full Name</div> */}

			    <input className="Competitor-register-name"  onChange={this.handlenameChange} type="text" name="name" value={this.state.name} placeholder="Full Name" ></input>
                <input className="Competitor-register-name"  onChange={this.handleregemail} type="text" name="name" value={this.state.handleregemail} placeholder="Business Email" ></input>
                <input className="Competitor-register-name" type="password" onChange={this.handlepass}  name="name" value={this.state.handlepass} placeholder="Password" ></input>
                <div className="Competitor-register-form-selector">
                    <div className="Questions_each">Select all niche markets relevant?</div>
                    <Select
                        className="react-dropdown-select2"
                        multi
                        options={this.state.resp}
                        values={this.state.eachcat}
                        onChange={(values) => this.valuesetter(values)} 
                        create
                    />
                </div>
                <div className="Competitor-register-form-selector2">
                <div className="Demographic-each">What category is your business considered in?</div>
                <Select
                    className="react-dropdown-select2"
                    options={occupation}
                    values={this.state.occupation_final}
                    onChange={(values) => this.occupation_setter(values)}/>
                </div>
                <ReCAPTCHA sitekey="6LdY69UZAAAAAMVP_K9AVLSOOvk6Y6wNl75Q7lO-"     onChange={this.captcha}/>
                <div className="Registration-agreement">
                By clicking "Register", you agree to the <a href="https://mapchise.com/terms-and-conditions/">Terms</a> and <a href="https://mapchise.com/privacy">Privacy Policy</a>
                </div>
                {(this.state.error_value != null)? <div className= "Competitor-register-error">{this.state.error_value}</div>:null}

                <div className="Competitor-research-started Competitor-research-started-text" onClick={this.register}>Register</div>
                </div>
                <div className="Competitor-research-topline">
                </div>
                    <div className="Competitor-research-login2 Competitor-research-started-text" onClick={() => this.props.changeregister()}>Login</div>
                </div>
                :
                <div>                <div className="Competitor-register-exit fa fa-times" onClick={() => this.props.closeregisterer()}></div>

                <h2 className="Competitor-search-header2 Competitor-search-header3">Login</h2>
                <div className="Competitor-register-form">
                {/* <div className="Competitor-register-form-names">Full Name</div> */}
                <input className="Competitor-register-name"  onChange={this.handlemail} type="text" name="name" value={this.state.handlemail} placeholder="Email" ></input>
                <input className="Competitor-register-name" type="password" onChange={this.handlepassword}  name="name" value={this.state.handlepassword} placeholder="Password" ></input>
                {(this.state.signin_error_value != null)? <div className= "Competitor-register-error">{this.state.signin_error_value}</div>:null}
                <div className="Competitor-research-started Competitor-research-started-text color-change" onClick={this.login} >Login</div>
                </div>
                <div className="Competitor-research-topline">
                </div>
                     <div className="Competitor-research-login2 Competitor-research-started-text color-change2" onClick={() => this.props.changeregister()}>Register</div>
                </div>}

                </div>
            </div>
        </div>
            )}
}