
import {Bar, Line} from 'react-chartjs-2';
import {Boxdataset} from './boxdataset'

export const SidebarMainHousing = ({exportInd, housing_seasonality, housing_buy, housing_rent, type_industry}) => {

    const line_chart = () => {
        return {
         labels: ['2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
         datasets: [
           {
             label: 'Housing Buy Data',
             fill: false,
             lineTension: 0.5,
             background: '#ffffff',
             borderColor: 'rgba(0,0,0,1)',
             borderWidth: 2,
             data: housing_buy.nums
           }
         ]
       }
    }
    
    const line_chart_Buy_type = () => {
        let buy_now = housing_buy.buy_now ? Object.values(housing_buy.buy_now) : [0, 0, 0, 0, 0, 0];
        let buy_previous = housing_buy.buy_previous ? Object.values(housing_buy.buy_previous) : [0, 0, 0, 0, 0, 0];
        return {
         labels: ['1 Bedroom', '2 Bedroom', '3 Bedroom', '4 Bedroom', '5 Bedroom', 'Condos'],
         datasets: [
           {
             label: 'Housing Buy Current',
             borderColor: 'rgba(0,38,62,1)',
             borderWidth: 1,
             hoverBackgroundColor: 'rgba(0,38,62,0.7)',
             backgroundColor: 'rgba(0,38,62,0.5)',
             hoverBorderColor: 'rgba(0,38,62,1)',
             data: buy_now
           },
           {
            label: 'Housing Buy Previous',
            borderWidth: 1,
            hoverBackgroundColor: 'rgb(255,152,0,.7)',
            backgroundColor: 'rgb(255,152,0, .5)',
            hoverBorderColor: 'rgb(255,152,0,1)',
            borderWidth: 2,
            data: buy_previous
          }
         ]
       }
      }
    const line_chart_rent = () => {
        return {
          labels: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
          datasets: [
            {
              label: 'Housing Rent Data',
              fill: false,
              lineTension: 0.5,
              background: '#ffffff',
              borderColor: 'rgba(0,0,0,1)',
              borderWidth: 2,
              data: housing_rent.slice(2)
            }
          ]
        } 
      }
    
    const line_chart_seasonality = () => {
        return {
         labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
         datasets: [
           {
             label: 'Housing Price Seasonality',
             fill: false,
             lineTension: 0.5,
             borderColor: 'rgba(0,38,62,1)',
             borderWidth: 1,
             hoverBackgroundColor: 'rgba(0,38,62,0.7)',
             backgroundColor: 'rgba(0,38,62,0.5)',
             hoverBorderColor: 'rgba(0,38,62,1)',
    
             
             data: housing_seasonality.map((e, index) => (index <= 3) ? Math.round(e * .95): e)
           }
         ]
       }
      }
    return (
        <div className="sidebar-realestate-total" >
        {exportInd &&               <h2 className='ml-10 mb-20 mt-100 font-weight600'>Residential Real Estate</h2>}
        <div className="boxdata-header">Housing Data</div>
        {housing_buy.nums ? <Boxdataset classnameval="box-container" margin={167}  top_text='Housing Cost' mid_text="Avg. Cost of house" total_diff={(housing_buy.nums[10] - housing_buy.nums[0]) / housing_buy.nums[0]} total_current={"$"+housing_buy.nums[10]} total_past={"$"+housing_buy.nums[0]} type={type_industry} description="Average cost of house in the target region. Average based on research data on the Zipcode level. Previous Period: 2012" filters={{}}/>: null}
        {housing_rent.length > 0 ? <Boxdataset classnameval="box-container" margin={167}  top_text='Housing Rental' mid_text="Avg. Cost of rent" total_diff={(housing_rent[10] - housing_rent[2]) / housing_rent[2]} total_current={"$" + housing_rent[10]} total_past={"$" + housing_rent[2]} type={type_industry} description="Average cost of rent in the target region. Average based on reasearch data of all regions on the county level. Previous Period: 2014" filters={{}}/> : null}
        {(type_industry) &&
            <div><div className="boxdata-header">Housing Cost</div>
            <div style={{background:"#ffffff"}}>
            <Line data={line_chart()}/>
            </div>
            <div style={{background:"#ffffff"}}>
            <Bar data={line_chart_seasonality()} />
            </div>
            <div style={{background:"#ffffff"}}>
            <Bar data={line_chart_Buy_type()} />
            </div>
            <div className="boxdata-header">Housing Rental</div> 
            <div style={{background:"#ffffff"}}>
            {(housing_rent[10])?
            <Line data={line_chart_rent()}/>:null}
            </div> 
        </div> }
      </div>
    )
}