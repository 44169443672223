import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import maptize from '../Images/maptize_logo_white-01.svg';
import axios from 'axios';


export class Task_sidebar extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    tag_prospect: [],
    tag_business: [],
    current_business_locations: false,
    prospecting_business_locations: false,
    prospect: [],
    business: [],
    tag_names: [],
    tags: false,
  }
  this.getpreset = this.getpreset.bind(this);
  }
tagsreverse(){
    let tags = !this.state.tags;
    this.setState({tags})
}
businessreverse(){
    let current_business_locations = !this.state.current_business_locations;
    this.setState({current_business_locations})
}
prospectreverse(){
    let prospecting_business_locations = !this.state.prospecting_business_locations;
    this.setState({prospecting_business_locations})
}
async getpreset(){
    let token = localStorage.getItem("auth-token");
    var response = null;
    try{
    response = await axios.post('/gettheurl/prospect', {}, {headers:{ "auth-token": token}})
    }catch(e){
        if(!e.response) return false;
      if(e.response.status == 400) return window.location.replace("/login");
      if(e.response.status == 401) return window.location.replace(e.response.data);
    }
    let response_total = await response.data;
    let temp_arr = [];
    let temp_arr2 = [];
    let temp_tag = [];
    let temp_tag2 = [];
    let temp_values = {};
    let archived = [];
    response_total.business_data.map((e) => {
    e.tags.map((ee) => {
        // tags will have each location
        if(temp_values[ee] != null){
            temp_values[ee].push(e.name)
        }else{
            temp_values[ee] = [e.name]
        }
      
      }) 
    e.type= "business"
    if(!e.archive){
    temp_arr.push(e.name)
     temp_tag.push(e.tags)
    }else{
      archived.push(e.name)
    }
    })
    response_total.prospect_data.map((e) => {
        e.tags.map((ee) => {
            // tags will have each location
            if(temp_values[ee] != null){
                temp_values[ee].push(e.name)
            }else{
                temp_values[ee] = [e.name]
            }
        })
      e.type="prospect" 
      if(!e.archive){
      temp_arr2.push(e.name)
      temp_tag2.push(e.tags)
      temp_tag.push(e.tags)
      }else{
        archived.push(e.name)
      }
    })
    var val = response_total.prospect_data;
    var all_values = val.concat(response_total.business_data);
    temp_tag = temp_tag.map((e) => {

      if(e.length === 0)
        return false
      if(e[0].value == null){
        return e
      }
      return e.map((ee) => ee.value)
    })
    console.log(temp_tag, 'temp tag value')
    var merged = [].concat.apply([], temp_tag);
    var merg = merged.filter((item, i, ar) => ar.indexOf(item) === i);
    merg = merg.filter((mer) => mer!== false)
    var tagnameselect =  merg.map((e) => { 
      return {value: e, label: e}})
    var merged2 = [].concat.apply([], temp_tag2);
    var merg2 = merged2.filter((item, i, ar) => ar.indexOf(item) === i);
    var val = (this.props.currenttype.type == null)? {type: "tasks"}: this.props.currenttype;

    switch (val.type) {
      case "tasks":
          this.props.taskcustom({type:"tasks"}, "My Tasks")
          break;
      case "Current":
          this.props.tasktitle(val, "Current Business Locations", temp_arr)
          break;
      case "Prospect":
        this.props.tasktitle({type:"Prospect"}, "Prospecting Business Locations", temp_arr2)
        break;
      case "Tag":
        this.props.tasktitle({type: "Tag", tag: this.props.currenttype.tag}, "Tag: "+this.props.currenttype.tag, temp_values[this.props.currenttype.tag], this.props.currenttype.tag)
        break;
      case "Archive":
          this.props.tasktitle({type: "Archived"},"Archived", archived, null, true)
          break;
    }
    // this.props.tag_names(temp_values)
    this.setState({archived: archived, tag_names: temp_values, tag_prospect: merg2, tag_business: merg, business: temp_arr, prospect: temp_arr2}, () =>{

      // this.props.tasktitle(val, "Current Business Locations", this.state.business)
        this.props.tagnames(tagnameselect, all_values);
    })
  }
  UNSAFE_componentWillMount(){
    this.getpreset()
  }
  componentDidUpdate(){
    
    if(this.props.newvalueadded){
      console.log(this.props.newvalueadded, 'new value here')
      this.props.newvaluesetfalse()
      this.getpreset()
      
    }
  }
  render() {

    return (
      <div className="task-sidebar-total" data-tut="reactour__sidebar">
      <div className="task-manager-log">
      <img src={maptize} alt="AVATAR" className="task-manager-logo" onClick={() => window.location.href ="/"}></img>
      <div className="task-sidebar-close" onClick={()=> this.props.hidesidebar()}>
        <div id="task-sidebar-close-arrow" className="fa fa-angle-left"></div>
      </div>
      </div>
      <div className={(this.props.title ==="My Tasks")?"task-sidebar-tags task-sidebar-clicked": "task-sidebar-tags"} onClick={() => this.props.taskcustom({type:"tasks"}, "My Tasks")} data-tut="reactour__mytask">My Tasks</div>
      <div className="task-sidebar-tags-total"></div>
      <div>
      <div className="task-sidebar-tags" onClick={this.tagsreverse.bind(this)}>Tags</div>
      <div  className={(this.state.tags)? "fa fa-angle-up task-sidebar-up": "fa fa-angle-down task-sidebar-down" } onClick={this.tagsreverse.bind(this)}></div>
      </div>
      <div className="task-sidebar-tags-total">{(this.state.tags)? this.state.tag_business.map((e) => {
          return <div key={e} className={(this.props.title === "Tag: " + e)?"task-sidebar-tags-each task-sidebar-clicked": "task-sidebar-tags-each"} onClick={() => this.props.tasktitle({type: "Tag", tag: e}, "Tag: "+e, this.state.tag_names[e], e)}>{e}</div>
      }) : null }</div>
      <div>
      <div className={(this.props.title ==="Current Business Locations")?"task-sidebar-tags task-sidebar-clicked": "task-sidebar-tags"} onClick={() => this.props.tasktitle({type: "Current"}, "Current Business Locations", this.state.business)}>Current Business Locations</div>
      <div  className={(this.state.current_business_locations)? "fa fa-angle-up task-sidebar-up": "fa fa-angle-down task-sidebar-down" } onClick={this.businessreverse.bind(this)}></div>
      </div>
      <div className="task-sidebar-tags-total">{(this.state.current_business_locations)? this.state.business.map((e) => {
          return <div key={e} className={(this.props.title ===e)?"task-sidebar-tags-each task-sidebar-clicked":"task-sidebar-tags-each"} onClick={() => this.props.taskcustom({type:"place", value:e}, e)} >{e}</div>
      }) : null }</div>
    <div>
    <div className={(this.props.title ==="Prospecting Business Locations")?"task-sidebar-tags task-sidebar-clicked": "task-sidebar-tags"} onClick={() => this.props.tasktitle({type:"Prospect"}, "Prospecting Business Locations", this.state.prospect)}>Prospecting Business Locations</div>
      <div  className={(this.state.prospecting_business_locations)? "fa fa-angle-up task-sidebar-up": "fa fa-angle-down task-sidebar-down" } onClick={this.prospectreverse.bind(this)}></div>
      </div>
      <div className="task-sidebar-tags-total">{(this.state.prospecting_business_locations)? this.state.prospect.map((e) => {
          return <div key={e} className={(this.props.title ===e)?"task-sidebar-tags-each task-sidebar-clicked":"task-sidebar-tags-each"} onClick={() => this.props.taskcustom({type:"place", value:e}, e)} >{e}</div>
      }) : null }</div>
       <div className="task-sidebar-tags" onClick={() => this.props.tasktitle({type: "Archived"},"Archived", this.state.archived, null, true)} data-tut="reactour__archive">Archived</div>
      </div>

    )
  }

}