import React, {Component} from 'react';
import {Competitor_box} from './Competitor_box'
import { Line} from 'react-chartjs-2';

export class Competitor_social extends React.Component{
    constructor(props) {
        super(props);
        this.state= {
            social :{},
             instagram_likes: {keys:[], values:[] },
             twitter_likes: {keys:[], values:[] },
             facebook_likes: {keys:[], values: [] },
        }
    }
    normal_est(value){
        if(!value) return "No Data"
        if(value < 1000000) return (Math.round(value/100)/10).toString() + "K"
        if(value < 1000000000) return  Math.round(value/10000)/100 + "M"
        return Math.round(value/10000000)/100 + "B"
    }
    cleaning_social(props){
        if(!props) return this.setState({social:{},              instagram_likes: {keys:[], values:[] },
            twitter_likes: {keys:[], values:[] },
            facebook_likes: {keys:[], values: [] },});
        let key = Object.keys(props)
        let key_last = key[key.length - 1]
        let props_current = props[key_last]
        console.log(props)
        let twitter = props_current.Twitter ? props_current.Twitter.Followers : null;
        let instagram = props_current.Instagram ? props_current.Instagram.Followers : null;
        let facebook = props_current.Facebook ? parseInt(props_current.Facebook.Followers) : null
        let instagram_likes = {keys:[], values:[] }
        let twitter_likes = {keys:[], values:[] }
        let facebook_likes = {keys:[], values: [] }

            key.map((k) => {
                
                if(Object.keys(props[k].Instagram).length > 0){
                    instagram_likes.values.push(props[k].Instagram.LikesAvg)
                    instagram_likes.keys.push(k)
                }
                if(Object.keys(props[k].Twitter).length > 0){
                    twitter_likes.values.push(props[k].Twitter.Followers)
                    twitter_likes.keys.push(k)
                }
                if(Object.keys(props[k].Facebook).length > 0){
                    facebook_likes.values.push(props[k].Facebook.Followers)
                    facebook_likes.keys.push(k)
                }

            })
        
        this.setState({social: {twitter, facebook, instagram}, twitter_likes, instagram_likes, facebook_likes})
    }
    UNSAFE_componentWillReceiveProps(Nextprops){
        console.log(this.props)
        this.cleaning_social(Nextprops.social_data)
    }
    twitter = () =>{
        return {
            labels: this.state.twitter_likes.keys,
            datasets: [
              {
                label: 'Twitter Followers',
                fill: false,
                lineTension: 0.5,
                background: '#ffffff',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: this.state.twitter_likes.values
              }
            ]
        }
    }
    facebook = () =>{
        return {
            labels: this.state.facebook_likes.keys,
            datasets: [
              {
                label: 'Twitter Followers',
                fill: false,
                lineTension: 0.5,
                background: '#ffffff',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: this.state.facebook_likes.values
              }
            ]
        }
    }
    instagram = () =>{
        return {
            labels: this.state.instagram_likes.keys,
            datasets: [
              {
                label: 'Instagram Likes',
                fill: false,
                lineTension: 0.5,
                background: '#ffffff',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: this.state.instagram_likes.values
              }
            ]
        }
    }
    upgrade = ()=>{
        if(this.props.freetier)  window.location.href = "https://mapchise.com/pricing"
    }
    render() {
        return (
            <div>
            <div className="Competitor-financials-overall" id="Competitor-social-id">
                <div className="Competitor-financials-title">Social Media<div className="Competitor-title-line"></div></div>
                
                {(this.state.social.twitter)?<Competitor_box  title={"Twitter"} titleMain={"Twitter"}  value={this.normal_est(this.state.social.twitter)}/>:null}
                {(this.state.social.facebook)?<Competitor_box  title={"Facebook"} titleMain={"Facebook"}  value={this.normal_est(this.state.social.facebook)}/>:null}
                {(this.state.social.instagram)?<Competitor_box  title={"Instagram"} titleMain={"Instagram"}  value={this.normal_est(this.state.social.instagram)}/>:null}
                {(Object.keys(this.state.social).length === 0 && !this.props.freetier)?<div className="Competitor-box-total Competitor-socialnone"><div className="Competitor-box-value Competitor-margintop">No Social Media Found</div></div>:null}
                {(this.props.freetier)?<div className="Competitor-box-total Competitor-socialnone"><div className="Competitor-box-value Competitor-margintop100" onClick={this.upgrade}><div className="Competitor-box-lock"><div className="Competitor-box-locked fa fa-lock"></div></div></div></div>:null}
                
                </div>

                <div className="Competitor-financials-overall2">

                {(this.state.twitter_likes.keys.length > 0)?<div className="Competitor-box-total">
                <div className="Competitor-box-title Competitor-box-title2">Twitter Followers</div>
                <div className="Competitor-box-line"></div><Line data={this.twitter()} options={{ responsive:true}}/>
                </div>:null}

                {(this.state.facebook_likes.keys.length > 0)?<div  className="Competitor-box-total">
                <div className="Competitor-box-title Competitor-box-title2">Facebook Followers</div>
                <div className="Competitor-box-line"></div><Line  data={this.facebook()} />
                </div>:null}
                
                {(this.state.instagram_likes.keys.length > 0)?<div className="Competitor-box-total">
                <div className="Competitor-box-title Competitor-box-title2">Avg Instagram Likes Per Post</div>
                <div className="Competitor-box-line"></div><Line data={this.instagram()} options={{ responsive:true}}/>
                </div>:null}

            </div>
            </div>
            )}
    }