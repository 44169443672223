


import React from 'react';
import {Polarchart} from './Polarchart';

export function Psychographic_map(props) {
  let spent_total = 0;
  Object.keys(props.spent).map((e) => spent_total += props.spent[e] );
  let spent =   (spent_total > 0) ? Object.keys(props.spent).map((e) => {  return {name: e, val: Math.round((props.spent[e] / spent_total) * 100)  }}): [];
  console.log("Psychographic_map:", props)
  return <div className="task-search-background">
  <div className="Psychographic-total">  
  <div className="task-exittotal" onClick={props.Psychographic_close}><div className="fa fa-times"></div> </div>
  <div className="overflow-scroll full-height-width">  
  <div className="Psychograph-header">Psychographic Data</div>
  <div className="Psychographic-grid">
  <div className="Psychographic-main">
    <div className="boxdata-popup-total">
      <div className="box-container-values3"><div className="box-popuppyscho-eachvalue">Age Name</div><div className="box-popuppyscho-eachvalue2">{props.age_name}</div></div>
      <div className="box-container-values3"><div className="box-popuppyscho-eachvalue">Age Group</div><div className="box-popuppyscho-eachvalue2">{props.agegroup}</div></div>
      <div className="box-container-values3"><div className="box-popuppyscho-eachvalue">Average Income</div><div className="box-popuppyscho-eachvalue2">${props.avg_income}</div></div>
      <div className="box-container-values3">
      <div className="box-popuppyscho-eachvalue">Race</div>
      <div className="box-popuppyscho-allvalues">
      {props.eachvalue.map((each) => <div key={each.type} className="box-popuppyscho-racetotal margin-right15">
          <div className="box-popuppyscho-racetype2">{each.type}</div>
          <div className="box-popuppyscho-racevalue">{each.value}%</div>
      </div>) }</div>
      </div>
      <div className="box-container-values3"><div className="box-popuppyscho-eachvalue">Expenses</div></div>
      {spent.map((e) => 
            <div className="box-container-values4" key={e.name}><div className="box-popuppyscho-eachvalue box-popuppyscho-eachvalue-second">{e.name}</div><div className="box-popuppyscho-eachvalue2 box-popuppyscho-eachvalue-expense">{e.val}%</div></div>

      )}

      </div>
  </div>
  <div className="Polarchart-background">
    <Polarchart interest_category={props.interest_category} interestall={props.interestall}/>
  </div></div></div></div>
</div>
}
// export default Psychographic_map;

