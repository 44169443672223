
import React, {Component} from 'react';
import industry_avgs from './industry_avgs.json';
import {Doughnut, Pie, Bar, Line} from 'react-chartjs-2';

export class Competitor_demographic_graphs extends React.Component{
    constructor(props) {
        super(props);
        this.state= {
            showdescription: false,
            
        }
    }
    upgrade = ()=>{
        if(this.props.freetier)  window.location.href = "https://mapchise.com/pricing"
    }
    education = () =>{
        return {
            labels: ["Less than 9th grade","No highschool diploma", "High school graduate", "Some college", "Associates degree", "Bachelors degree",  "Graduate or professional degree"],
            datasets: [
              {
                backgroundColor: [
                  'rgba(74, 119, 3,0.6)',
                  'rgba(3, 119, 48,0.6)',
                  'rgba(3, 74, 119, 0.6)',
                  'rgba(3, 16, 119, 0.6)',
                  'rgba(119, 3, 74,0.6)',
                  'rgba(194, 5, 120, 0.6)',
                  'rgba(106, 3, 119, 0.6)',
                ],
                hoverBackgroundColor: [
                'rgba(74, 119, 3,1)',
                'rgba(3, 119, 48,1)',
                'rgba(3, 74, 119, 1)',
                'rgba(3, 16, 119, 1)',
                'rgba(119, 3, 74,1)',
                'rgba(194, 5, 120, 1)',
                'rgba(106, 3, 119, 1)',
                ],
                data: this.props.degree
              }
            ],

          }
    }
    gender = () =>{
        return {
            labels: ["Male", "Female"],
            datasets: [
              {
                backgroundColor: [
                  'rgba(5, 5, 194,0.6)',
                  'rgba(194, 18, 5,0.6)',
                ],
                hoverBackgroundColor: [
                'rgba(5, 5, 194,1)',
                'rgba(194, 18, 5,1)',
                ],
                data: this.props.gender
              }
            ],

          }
        }
    type =(name) =>{
      if(!this.props.type) return null
      let data = industry_avgs.filter((avg) => avg.type === this.props.type)[0][name]
      console.log(data,industry_avgs, 'data value in type')
      return {
        label: 'Industry Average',
        fill: false,
        lineTension: 0.5,
        background: '#762849',
        borderColor: '#762849',
        backgroundColor: 'rgba(118, 40, 73, .5)',
        hoverBackgroundColor: 'rgba(118, 40, 73,0.7)',

        borderWidth: 2,
        data
      }
    }
    age = () =>{
      let type = this.type("age")
      let dataset = [{
        label: 'Population Age distribution',
        fill: false,
        lineTension: 0.5,
        background: '#ffffff',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: this.props.age
      }]
      if(type) dataset.push(type)
        return {
            labels: ["Under 5", "5 - 9", "10 - 14", "15 - 17", "18 - 19", "20 - 24", "25 - 29", "30 - 34", "35 - 44", "45 - 54", "55 - 64", "65 - 74", "75 - 84", "Over 84"],

            datasets: dataset
        }
    }
    race = () => {
      let type = this.type("race")
      let dataset = [
        {
          label: 'Race Distribution',
          fill: false,
          lineTension: 0.5,
          borderColor: 'rgba(0,38,62,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(0,38,62,0.7)',
          backgroundColor: 'rgba(0,38,62,0.5)',
          hoverBorderColor: 'rgba(0,38,62,1)',
          data: this.props.race
        }]
        if(type) dataset.push(type)

        return { 
             labels: ["White", "Black/African American", "Hispanic/Latino", "Asian", "American Indian"],
             datasets: dataset
           }
          
    }
    upgrade = ()=>{
      if(this.props.freetier)  window.location.href = "https://mapchise.com/pricing"
    }


    render() {
      const     options = {
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                const value = context.parsed || 0;
                const total = context.dataset.data.reduce((acc, data) => acc + data, 0);
                const percentage = Math.round((value / total) * 100) + '%';
                return `${percentage}`;
              }
            }
          }
        }
      }
        return (
            <div className="Competitor-financials-overall2">
                <div className="Competitor-box-total  height-500">
                <div className="Competitor-box-title Competitor-box-title2">Education Level</div>
                <div className="Competitor-box-line"></div>
                <div className={this.props.freetier? "Competitor-blurred":""} onClick={this.upgrade}>
                <div className='center-content polarchart-container-competitor'>
                <Doughnut data={this.education()} options={options}/>
                </div> 
                </div> 
                {(this.props.freetier)?<div className="Competitor-box-lock"><div className="Competitor-box-locked fa fa-lock"></div></div>:null}

                </div>
                <div className="Competitor-box-total  height-500">
                <div className="Competitor-box-title Competitor-box-title2">Gender Distribution</div>
                <div className="Competitor-box-line"></div>
                <div className={this.props.freetier? "Competitor-blurred":""} onClick={this.upgrade}>
                <div className='center-content polarchart-container-competitor'>

                  <Pie data={this.gender()} options={options}/>
                </div> 

                </div> 
                {(this.props.freetier)?<div className="Competitor-box-lock"><div className="Competitor-box-locked fa fa-lock"></div></div>:null}

                </div>
                <div className="Competitor-box-total  height-500">
                <div className="Competitor-box-title Competitor-box-title2">Age Distribution</div>
                <div className="Competitor-box-line"></div>
                <div className={this.props.freetier? "Competitor-blurred":""} onClick={this.upgrade}>
                <Line data={this.age()} options={this.state.options}/>
                </div>
                {(this.props.freetier)?<div className="Competitor-box-lock"><div className="Competitor-box-locked fa fa-lock"></div></div>:null}

                </div>
                <div className="Competitor-box-total  height-500">
                <div className="Competitor-box-title Competitor-box-title2">Race Distribution</div>
                <div className="Competitor-box-line"></div>
                <div className={this.props.freetier? "Competitor-blurred":""} onClick={this.upgrade}>
                <Bar data={this.race()} options={this.state.options}/>
                
                </div>        
                {(this.props.freetier)?<div className="Competitor-box-lock"><div className="Competitor-box-locked fa fa-lock"></div></div>:null}
        
                </div>

            </div>
            )}
}