import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import OutsideClickHandler from 'react-outside-click-handler';
import { confirmAlert } from 'react-confirm-alert';
import menupost from '../Images/menupost.png'
import Select from 'react-dropdown-select';
import Status from '../Images/status.png';
import Priority from '../Images/priority.png';
import {Task_Calendar} from './task_calendar';
import Calendarimg from '../Images/calendar2.png';
import {Task_label} from './task_label';
import uuid from 'react-uuid';
import Checkmark from '../Images/checkmark.svg';
import Checkmarked from '../Images/checkmarked.svg';
import {Task_messaging} from './Task_messaging'
// import { assertTSUndefinedKeyword } from 'babel-types';
const WAIT_INTERVAL = 1500;

export class Task_new_task extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    addeduser: {},
    top_title: false,
    Title: "",
    name: '',
    checklistinput: '',
    createddate: null,
    messages: [],
    checklistitems: [],
    addchecklist: true,
    allaccounts: [],
    selectedlabels: [],
    tasknotification: {Tasknote: false, selectedaccounts: []},
    messageinput: "",
    mounted: false,
    showaccount: true,
    selectedaccounts: [],
    priority_type: [{value:"Type > Create", label:"Type to Create", disabled: 'yes'}, {value:"Low", label:"Low", color: "#008B02", }, {value: "Medium", label:"Medium" , color: "#FCCB00"},  {value:"High", label:"High", color: "#B80000"}],
    status_type: [{value:"Type > Create", label:"Type to Create", disabled: 'yes'}, {value:"Beginning", label:"Beginning", color: "#1273DE"}, {value: "In Progress", label:"In Progress" , color: "#FCCB00"},{value:"Backburner", label:"Backburner",color: "black"}, {value: "Problem", label:"Problem", color:"red"}, {value:"Finished", label:"Finished", color: "#008B02"}]
  }
  this.triggersend = this.triggersend.bind(this);
  this.updatedate = this.updatedate.bind(this)
  this.status_type = this.status_type.bind(this);
  this.priority_type = this.priority_type.bind(this);
  this.addnewaccount = this.addnewaccount.bind(this);
  this.labeledited = this.labeledited.bind(this);
  this.checklistinput = this.checklistinput.bind(this);
  this.addnewitem = this.addnewitem.bind(this);
  this.handleKeyDown = this.handleKeyDown.bind(this);
  this.checklistinput = this.checklistinput.bind(this);
  this.checklistchecked = this.checklistchecked.bind(this);
  this.deletechecklist = this.deletechecklist.bind(this);
  this.message_send = this.message_send.bind(this);
  }
  async getstarted(){
    var token = localStorage.getItem("auth-token");
    
    let single = await axios.post('/tasksapi/getsingle', {id: this.props.id}, {headers:{ "auth-token": token}});
    var data = await single.data;
    
    
    data = data.task;
    var createddate =new Date(data.createddate);
    createddate = createddate.getFullYear()+"-"+createddate.getMonth()+"-"+ createddate.getDate()+" "+ createddate.getHours() +":"+ createddate.getMinutes()
    var addchecklist = (data.subtask.length >= 12)? false: true;
    var accountnamesselected= data.selectedaccounts.map((e) => e.email)
    var priority_color = (data.priority[0])? data.priority[0].color: "";
    var status_color = (data.status[0])? data.status[0].color: "";
    var selectedlabels = (data.labels)? data.labels: [];
    console.log(this.props.all_locations, this.props.accounts, data.idval, 'id value accounts alllocations')
    var tags=  this.props.all_locations.filter((all) => all.name === data.idval)[0].tags;
    let allaccounts = this.props.all_locations.map((loc) => {return { value: loc.name, label: loc.name }})
    var Accounts = this.props.accounts.filter((acc) => { for(let j in acc.tags) { if(tags.includes(acc.tags[j].value))return true} return false});
    // var accounts = Accounts.filter((e) => !accountnamesselected.includes(e.email));
    var accounts = Accounts.filter((e) => {    
      if(this.props.useraccount){ 
      if(this.props.useraccount.email !==e.email){ /**IF EMAIL ISN"T USERACCOUNT */ return !accountnamesselected.includes(e.email) }else{ return false }
      
      }else{/** IF NOT USERACCOUNT IS HERE YET */ return !accountnamesselected.includes(e.email) }
    });
    if(this.props.useraccount)
    if(!accountnamesselected.includes(this.props.useraccount.email)) accounts.push(this.props.useraccount)
    var task = {};
    task.Tasknote = false;
    /** add a new task notification here */
    var tasknotification = (data.tasknotification)? data.tasknotification: task;
    tasknotification = (tasknotification.Tasknote)? data.tasknotification: this.state.tasknotification;
    var addeduser = (data.addeduser)? data.addeduser : {added: false, account:[]};
    this.setState({createddate,messages: data.messages, addeduser, addchecklist, allaccounts,checklistitems: data.subtask, Accounts,accounts, dateformat:data.dateformat, accountnamesselected, Title: data.title, Description: data.description, statuscurrent: data.status, prioritycurrent: data.priority, duedate: data.duedate, selectedaccounts: data.selectedaccounts, status_color, priority_color, selectedlabels, mounted: true, tasknotification})

  }
  async triggersend(name){
    console.log(name, "inside trigger")
    try{  
        var token = localStorage.getItem("auth-token");
        let save = await axios.post('/tasksapi/savesubtask', { id: this.props.id,tasknotification: this.state.tasknotification, messages: this.state.messages, title: this.state.Title, subtask: this.state.checklistitems, description: this.state.Description, duedate: this.state.duedate, dateformat: this.state.dateformat, status: this.state.statuscurrent, priority: this.state.prioritycurrent, labels: this.state.selectedlabels,  selectedaccounts: this.state.selectedaccounts, messages:this.state.messages }, {headers:{ "auth-token": token}})
    }catch(e){
        alert(e)
    }
  }
  
  componentWillMount(){
    this.timer = null;
    let labels = (this.props.labels_list)? Object.keys(this.props.labels_list): [];
    this.setState({labelobject: labels })
    this.getstarted()

  }
  /** LOGIC FOR Notification for users */
  tasknotification  = (message, checklist) => {
    if(this.state.tasknotification.Tasknote){
      /**IF its been true than we are adding one to each ALSO ADDING ANY USERS THAT WERE REMOVED (var selectd) */
    var selected = this.state.tasknotification.selectedaccounts.map((e) => {if(e.id === this.props.useraccount._id)return e; return{id: e.id, message: e.message +=message, checklist: e.checklist+=checklist}})
    this.state.selectedaccounts.map((e) => {
      if(selected.filter((sel) => sel.id === e._id).length === 0 && e._id !== this.props.useraccount._id) return selected.push({id: e._id, message, checklist})
    })
    return {Tasknote: true, selectedaccounts: selected} 
    }

    else{ 
      var selectedaccounts = this.state.selectedaccounts.filter((e) => e._id !== this.props.useraccount._id);
      return {Tasknote: true, selectedaccounts: selectedaccounts.map((e) => {return{id: e._id, message, checklist}})};
    }
  }

  message_send(messages){
    this.setState({messages, tasknotification: this.tasknotification(1, 0)}, ()=>{ this.triggersend("message") })
  }
  status_type(value){
      
    this.setState({statuscurrent: value, status_color: value[0].color}, () => {
        this.triggersend("status_type")
    })
  }
  priority_type(value){
    this.setState({prioritycurrent: value, priority_color: value[0].color}, () => {
        this.triggersend("priority_type")
    })
  }
  inputsetter(e){
    
    if(e.target.value.length < 40) {   
        clearTimeout(this.timer); 
        this.setState({Title: e.target.value}, ()=> {
            this.timer = setTimeout(this.triggersend, WAIT_INTERVAL);
        })
    }else alert("Title is too long")
  }
  async deletecurrentpost(){
    try{
    var token = localStorage.getItem("auth-token");
    let deletes = await axios.post('/tasksapi/delete', {id: this.props.id }, {headers:{ "auth-token": token}});
    this.props.deletethispost(this.props.id, this.state.selectedlabels)
    this.props.taskmoreinformation(null, false)
    }catch(e){
        alert("Could not delete this post")
    }

  }
  textarea(e){
    if(e.target.value.length < 280) {
        clearTimeout(this.timer);
        this.setState({Description: e.target.value}, () =>{
            this.timer = setTimeout(this.triggersend, WAIT_INTERVAL);
        })
  }else alert("Description is over 280 characters")
  }
  async addnewaccount(e, type){
    clearTimeout(this.timer);
    
    let selectedaccounts = this.state.selectedaccounts;
    let accountnamesselected = this.state.accountnamesselected;
    let addeduser = this.state.addeduser;
    if(type ==="Add"){
    selectedaccounts.push(e)
    var token = localStorage.getItem("auth-token");
    await axios.post('/tasksapi/notify', {_id: e._id, id:this.props.id}, {headers:{ "auth-token": token}});
    accountnamesselected.push(e.email)
    addeduser = (addeduser.added)? addeduser.account.push(e): {added:true, account:[e]};
    }else{
        addeduser = (addeduser.account.length > 0)? {added:false, account:addeduser.account.filter(add=> add._id !==e._id)}: {added:false, account:{}};
        selectedaccounts = selectedaccounts.filter((s) => s._id !== e._id)
        accountnamesselected = accountnamesselected.filter((a) => a !== e.email)
    }
    var showaccount= true
    var accounts = this.state.Accounts.filter((e) => !accountnamesselected.includes(e.email));
    if(this.props.useraccount)
    if(!accountnamesselected.includes(this.props.useraccount.email)) accounts.push(this.props.useraccount)
    if(selectedaccounts.length === this.state.Accounts.length +1)
        showaccount = false
    /** accountnamesselected is for when mapping to exclude that value */
    this.setState({selectedaccounts,accountnamesselected,  shownewuser: false,accounts, showaccount, addeduser}, () => {
        this.timer = setTimeout(this.triggersend, WAIT_INTERVAL);
    })
  }
  labeledited(label){
    this.setState({selectedlabels:label}, ()=> { this.triggersend("labeledited"); })
  }
  checklistinput(e){
    if(this.state.checklistitems.length <= 12 && e.length < 80)
    this.setState({checklistinput: e})
  }
  addnewitem(){
    var val = {id: uuid(), value:this.state.checklistinput, checked:false}
    var checklistitems = this.state.checklistitems;
    checklistitems.push(val)
    var addchecklist = (checklistitems.length >= 12)? false: true;
    if(checklistitems.length <= 12) {
      this.setState({checklistitems, checklistinput: "", addchecklist, tasknotification: this.tasknotification(0,1)}, ()=>{ this.triggersend("addnewitem"); })
    }
  }
  checklistchecked(id){
    
    var checklistitems = this.state.checklistitems.map((e) => {if(e.id ===id) e.checked = !e.checked; return e});

    this.setState({checklistitems}, () => this.triggersend("checklistchecked"))
  }
  handleKeyDown(e){
    if (e.key === 'Enter') {
    this.addnewitem()
    }
  }
  deletechecklist(e){
    var checklistitems = this.state.checklistitems.filter((fil) => fil.id !== e)
    this.setState({checklistitems}, () => this.triggersend("deletechecklist"))
  }
  updatedate(duedate, dateformat){
    this.setState({duedate, dateformat, calendarclicked: false}, () => { this.triggersend("updatedate") })
  }

  endsession(){
    this.props.taskmoreinformation(null, false, {y: this.state.y, id: this.props.id,tasknotification: this.state.tasknotification, title: this.state.Title, description: this.state.Description, duedate: this.state.duedate, dateformat: this.state.dateformat, status: this.state.statuscurrent, priority: this.state.prioritycurrent, selectedlabels: this.state.selectedlabels, idval: this.state.idval, selectedaccounts: this.state.selectedaccounts })
  }
  render() {

      return (
        
    <div className="task-search-background"> <OutsideClickHandler onOutsideClick={() => this.endsession.bind(this)} > 
    <div className="task-exittotal2" onClick={this.endsession.bind(this)}><i className="fa fa-times"></i></div>
    {(this.state.mounted)?
        <div className="task-new-total">
        <input className="task-value-input task-value-inputbox" placeholder="Title"  value={this.state.Title} onChange={(e) => this.inputsetter(e)}/>
        <img src={menupost} className="task-menubox" onClick={() => this.setState({postsettings:true})}></img>
        {(this.state.postsettings)?
        <OutsideClickHandler  onOutsideClick={() => this.setState({postsettings: false, searchduplicate: false})} > 
        <div className="task-options-total tasks-opstions-total-next3">
            {(!this.state.searchduplicate)?
            <div>
            <div className="task-options-each" onClick={this.deletecurrentpost.bind(this)}>Complete/Remove</div>
            <div className="task-options-each" onClick={() => this.setState({searchduplicate: true})}>Duplicate > location</div>
            </div>:
                <Select         className="task-value-select3"
        options={this.state.allaccounts}
        dropdownHandle={false}
        onChange={(values) => this.props.duplicateanotherlocation(values, {y: this.state.y, id: this.props.id, title: this.state.Title, description: this.state.Description, duedate: this.state.duedate, dateformat: this.state.dateformat, status: this.state.statuscurrent, priority: this.state.prioritycurrent, labels: this.state.selectedlabels, idval: this.state.idval, selectedaccounts: this.state.selectedaccounts })}
        values={[]}/>
            }
        </div>
        </OutsideClickHandler> :null
        }
        
        <div className="task-value-row">
        <div onClick={() => this.setState({calendarclicked: true})} className="task-value-total">
         <img className="task-value-calendar" src={Calendarimg}></img>
         <div className="task-value-text">Due Date</div>
         <div className="task-value-text-due">{this.state.duedate}</div>
         </div>
        {(this.state.calendarclicked)?
        <div className="task-calendar"><OutsideClickHandler onOutsideClick={() => this.setState({calendarclicked: false})} > 
          <Task_Calendar updatedate={this.updatedate} dateformat={this.state.dateformat} duedate={this.state.duedate} />
          </OutsideClickHandler> </div>
          : null
        }    
        <div className="task-value-total margin-value-total2">
        <img className="task-value-calendar" src={Status}></img>    
        <div className="task-value-text">Status</div>    
        <div style={{color: this.state.status_color}}>
        <Select
        className="task-value-select2"
        options={this.state.status_type}
        dropdownHandle={false}
        values={this.state.statuscurrent}
         placeholder=""
         isOptionDisabled={(option) => option.disabled === 'yes'}
         create
         onChange={(values) => this.status_type(values)}/></div>
         </div>
         <div className="task-value-total margin-value-total2">
        <img className="task-value-calendar" src={Priority}/>
        <div className="task-value-text">Priority</div>    
        <div style={{color: this.state.priority_color}}>
        <Select
        className="task-value-select2"
        options={this.state.priority_type}
        dropdownHandle={false}
        values={this.state.prioritycurrent}
        isOptionDisabled={(option) => option.disabled === 'yes'}
         placeholder=""
         create
         onChange={(values) => this.priority_type(values)}/></div>
        </div>
        </div>
        <div className="task-value-grap">
         <div className="task-value-row4">
         <div className="task-add-user-name">Checklist</div>
         {/** TASK NOTIFICATION VALUE IS HERE */}
         {(this.state.tasknotification.Tasknote && this.props.useraccount)?<div >{this.state.tasknotification.selectedaccounts.map((e) => {if(e.id === this.props.useraccount._id && e.checklist > 0) return <div key={e.id} className="task-rednotification task-rednotification-inside task-rednotification-checklist">{e.checklist}</div> })}</div>:null}
         <div className="task-checkcontainer">{this.state.checklistitems.map((e) => <div key={e.id} className="task-checktotal"><img className="task-subtask-input"  src={(e.checked)?Checkmarked:Checkmark} onClick={() => this.checklistchecked(e.id)}/><div className="task-checklist">{e.value}</div> <div className="task-checklistdelete" onClick={() => this.deletechecklist(e.id)}><i className="fa fa-times"></i></div></div>)}</div>
         <div><input className="task-label-checklistinput" placeholder="Add Item" onKeyDown={this.handleKeyDown}  onChange={(e) => this.checklistinput(e.target.value)} value={this.state.checklistinput}/><button className="task-label-additem" onClick={this.addnewitem}>Add Item</button> </div>
         </div>
         <div className="task-value-row3">
        <div className="task-desc-total" onClick={()=> this.setState({descriptionshow: !this.state.descriptionshow})}>
         <div className="task-add-user-name2">Description</div><div className={(this.state.descriptionshow)?"fa fa-angle-up task-sidebar-up2":"fa fa-angle-down task-sidebar-down2"}></div></div>
         {(this.state.descriptionshow)?<textarea value={this.state.Description} className="task-value-textarea" placeholder="Description" onChange={(e) => this.textarea(e)}/>:null}
         <div className="task-desc-total" onClick={()=> this.setState({labelshow: !this.state.labelshow})}><div className="task-add-user-name2">Labels</div><div className={(this.state.labelshow)?"fa fa-angle-up task-sidebar-up2":"fa fa-angle-down task-sidebar-down2"}></div></div>
      {(this.state.labelshow)?<Task_label labeledited={this.labeledited} labels_list={this.props.labels_list} selectedlabelsprops={this.props.selectedlabels} selectedlabels={this.state.selectedlabels} labelobject={this.state.labelobject} />:null}
         </div>
         </div>
         {/** TASK NOTIFICATION VALUE IS HERE */}
        {(this.state.tasknotification.Tasknote && this.props.useraccount)?<div >{this.state.tasknotification.selectedaccounts.map((e) => {if(e.id === this.props.useraccount._id && e.message > 0) return <div key={e.id} className="task-rednotification task-rednotification-inside task-rednotification-message">{e.message}</div> })}</div>:null}
        <Task_messaging id={this.props.id} message_send={this.message_send} createddate={this.state.createddate} useraccount={this.props.useraccount} messages={this.state.messages} selectedaccounts={this.state.selectedaccounts}/>
         <div className="task-add-user-total2 margin-value-total2">
         {this.state.selectedaccounts.map((e) => <div className="task-add-user-plus" key={e._id} style={{background:e.color, paddingTop:"2px"}}><div  className="task-add-img" title={e.name} onClick={() => this.addnewaccount(e, "Remove")}>{e.name.split(" ")[0][0].toUpperCase()}{e.name.split(" ")[1]?e.name.split(" ")[1][0].toUpperCase():null}</div></div>)}
        <div className="task-add-user-plus" onClick={()=> this.setState({shownewuser:true})}><i className="fa fa-plus"></i></div>
        { (this.state.shownewuser)? <OutsideClickHandler onOutsideClick={() => this.setState({shownewuser: false})} > 
              <div className="task-results-values task-results-absolute">
                  {this.state.accounts.map((e) => {
                    return <div key={e._id} className="search-result-each" onClick={() => this.addnewaccount(e, "Add")}>{e.name}, {e.email}</div>})}
                    {(this.state.accounts.length === 0)?<div className="search-result-each" onClick={() => window.location.href="/account"}>No Users Found. Click to Add New Users</div>:null}
              </div>
              </OutsideClickHandler> :null}
         </div>
        </div>
        :<div className="task-new-total"></div>}
        </OutsideClickHandler>
        </div>
      )
    }
  }