import React from 'react';
import mapboxgl, { Point } from 'mapbox-gl';
import axios from 'axios';
import {Dashboard_sidebar} from "./Dashboard_sidebar"
// import Tour from 'reactour';
import Joyride from 'react-joyride';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { confirmAlert } from 'react-confirm-alert';
import {ReactComponent as Datalayer} from './Layer_images/Datalayer.svg';
import Maptize from './Images/maptize-black.png';
import OutsideClickHandler from 'react-outside-click-handler';

var map;
let steps = [{
  "selector": "[data-tut='reactour__datalayer']",
  "content":"Limited Recommendation view for all county's in the US. We use Big Data and Machine Learning to provide you with the most accurate location intelligence. It is a blend of local interests and online activity of your target market.",
  "stepInteraction": false
  
},
{
  "selector": "[data-tut='reactour__map']",
  "content":"The markers on the map represent each location of your stores.",
  "stepInteraction": false
  
},
{
  "selector": "[data-tut='reactour__sidebar']",
  "content":
    "You can click each marker on the map to get relevant data in the driving vicinity of the location.",
    "stepInteraction": false
}]

export class Dashboard extends React.Component{

   // mapRef = React.createRef()
   
   constructor(props) {      
       
    super(props);
    this.state= {
        locationmarkers: [],
        datalayer_show: false,
        find: false,
        showsearch: false,
        showTour: true,
        datalayer: false,
        pval: [ "rgb(20, 80, 220, .65)", "rgb(20, 70, 200, .7)", "rgb(20, 60, 180, .75)",  "rgb(20, 50, 160, .8)",  "rgb(20, 45, 140, .9)", "rgb(20, 40, 120, .95)",  ]
    }
   }
   componentDidMount(){
    this.getstarted();
    }
    numberWithCommasPopUp(x) {
      if(!x) return "No Data"
      return "$"+ x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  addlayer = (geojson) => {
    if (map.getSource('places')){
        map.removeLayer('places')
        map.removeSource('places');
    }
    map.addSource('places', {
        'type': 'geojson',
        'data': {
        'type': 'FeatureCollection',
        'features': geojson
    }})
    map.addLayer({
        'id': 'places',
        'type': 'symbol',
        'source': 'places',
            'layout': {
              "icon-image": "marker-11",
              'icon-size': 1.5,
              'icon-allow-overlap': true
            }
    });
    map.on('mouseenter', 'places', function () {
    map.getCanvas().style.cursor = 'pointer';
    });
                
    // Change it back to a pointer when it leaves.
    map.on('mouseleave', 'places', function () {
    map.getCanvas().style.cursor = '';
    });
    map.on('click', 'places',  (e) => {
      console.log(e, 'e value')
      // var coordinates = e.features[0].geometry.coordinates.slice();
      if (e.features.length === 0) return; 
      e = e.features[0];
      let demo = JSON.parse(e.properties.demo)
        console.log(e.properties, e.geometry.coordinates, 'demo name')
        this.getdemo(demo, e.geometry.coordinates)
        var coordinates = e.geometry.coordinates.slice();
        let Add = (e.properties.add && e.properties.add != "")? `Address: ${e.properties.add}`: this.state.name;
        new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(`<div class="Comptitor-popup">${Add}</div>`)
        .addTo(map);
    })
}
    async getdemo(e, center){
      
      var population = 0
      var expense = 0
      var race = [0, 0, 0, 0, 0]
      var income = 0
      var age = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      var degree = [0, 0, 0, 0, 0, 0, 0]
      var gender = [0, 0]
      var expense_wo = 0;
      if(e.pop) population = e.pop;  
      if(e.expense[0] > 0) expense = e.expense[0]; 
      if(e.expense[1] > 0) expense_wo = e.expense[1]; 

      if(e.income > 0) income = e.income; 
      if(e.degree){ e.degree.map((deg, index) => { if(deg > 0) degree[index] += deg}) }
      if(e.gender){ gender[0] = e.gender.male; gender[1] = e.gender.female; }
      if(e.age){ e.age.map((a, index) => age[index] += a) }
      if(e.race){ race[4] = e.race["American Indian"]; race[3] = e.race["Asian"]; race[1] = e.race["Black/African American"]; race[2] = e.race["Hispanic/Latino"]; race[0] = e.race["White"] }
      let gender_total = gender[0] + gender[1]
      gender = [gender[0]/ gender_total, gender[1]/ gender_total] 
      let degree_total = 0 
      degree.map((deg) => degree_total += deg)
      degree = degree.map((deg) => Math.round((deg/ degree_total) *100) /100)
      let token = localStorage.getItem("auth-token");

      let response = await axios.post('/dashboardapi/getcircle', {sk: this.state.sk, name: this.state.name, center}, {headers:{ "auth-token": token}})
      let responsedata = await response.data;
      if (map.getSource('circle')){
        map.removeLayer('circle')
        map.removeSource('circle');
    }     
    map.addSource('circle',  responsedata)
    map.addLayer({
      'id': 'circle',
      'type': 'fill',
      'source': 'circle',
      'paint': {
        'fill-color': '#FFAE1A',
        'fill-opacity': 0.4,
        }
      });
      this.setState({race, age, gender, income, expense, degree, population, expense_wo})

      }
    async getstarted(){
        let token = localStorage.getItem("auth-token");
        let responsedata = null;
        let search = window.location.search;
        let params = new URLSearchParams(search);
        this.setState({showsearch: true})
        let name = params.get('name');
        let sk = params.get('sk')
        if(!sk || !name) return window.location.replace("/login");
        let center = [-98.5795, 39.8283]
        let zoomlevel = 2.5
        try{
        let response = await axios.post('/dashboardapi/getstarted', {sk, name}, {headers:{ "auth-token": token}})
        responsedata = await response.data;
        if(responsedata.zoom){
            center = responsedata.zoom.coordinates
            zoomlevel = responsedata.zoom.zoomlevel
        }
        }catch(response){
            console.log(response)
        }
        mapboxgl.accessToken = 'pk.eyJ1Ijoic25pa2FlaW4iLCJhIjoiY2s1eWNpcWxrMDJvbzNtcDdyb3puNTVrMCJ9.t-VFWsbpRo--tRSLgLa9ng';
        map = new mapboxgl.Map({
          container: 'mapbox-main',
          style: 'mapbox://styles/mapbox/streets-v11',
          center,
          zoom: zoomlevel
      });
      map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

        var geojson = []
        var layer = (responsedata.dashboard.length < 500)
        var markers = [] 
        responsedata.dashboard.map((e) =>{
            if(e.geometry){
                let coordinates = e.geometry.coordinates
                if(e.geometry.type === "Polygon"){
                    coordinates = coordinates[0][0]
                }
                if(e.geometry.type === "MultiPolygon"){
                    coordinates = coordinates[0][0][0]
                }
                if(layer){
                let marker = new mapboxgl.Marker().setLngLat(coordinates)
                .setPopup(new mapboxgl.Popup().setHTML(`<div class="Comptitor-popup">Address: ${e.add}</div>`
                )) 
                .addTo(map)
                markers.push(marker)
                marker.getElement().addEventListener('click', (m) => {
                  let demo = e.demo
                  this.getdemo(demo, coordinates)

                });
                }else{
                geojson.push({ type: "Feature", geometry: {type: "Point", coordinates: coordinates},
                    properties:{ add: e.add, demo: e.demo}})
                }
           }
        
        })
        if(!layer) map.on('load', () =>    this.addlayer(geojson));

        this.setState({ markers, sk, name, Image: responsedata.Image, showsearch: false})
      }
      types(){
        return(<div className="legend-opener">
          <div className= "legend-opener-each" >Population Density                 <div className="fa fa-lock dashboard-locked2"></div></div>
          <div className= "legend-opener-each" >Family Income Distribution <div className="fa fa-lock dashboard-locked2"></div></div>
          <div className= "legend-opener-eachclick legend-opener-each" >Recommended Locations <div className="dashboard-limited">(LIMITED)</div></div>
          </div>)
        
    }
    getcounty = async(find) =>{
      if(this.state.searching_lock) return;
      if(this.state.datalayer){
        if (map.getSource('polygon-color')){
          map.removeLayer('polygon-color')
          map.removeSource('polygon-color');
        }
        this.setState({datalayer: false})
      }else{
        this.setState({showsearch: true, searching_lock: true})
        let responsedata = this.state.responsedata
        if(!responsedata){

        let token = localStorage.getItem("auth-token");

        let response = await axios.post('/dashboardapi/getcounty', {sk: this.state.sk, name: this.state.name}, {headers:{ "auth-token": token}})
        responsedata = await response.data;
      }
        map.addSource("polygon-color", {
          'type': 'geojson',
          'data': {
          'type': 'FeatureCollection',
          'features': responsedata
          }
        })
        map.addLayer({
          'id': "polygon-color",
          'type': 'fill',
          'source': "polygon-color",
          'layout': {},
          'paint': {
            'fill-color': {
              type: 'identity',
              property: 'color',
          },
          'fill-opacity': 0.75,
          }
          });
          this.setState({datalayer: true, responsedata, showsearch: false, searching_lock: false})
        }
    }
    closeTour = () => this.setState({showTour: false})
      render(){
  
          return(
              <div>
                                <Joyride
          run={this.state.showTour}
          showProgress
          continuous
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
          callback={(data) => {
            // You can handle events (like tour end) here if needed
            if (data.status === 'finished' || data.status === 'skipped') {
              this.closeTour();
            }
          }}
        />
            {/* <Tour
            steps={steps}
            isOpen={this.state.showTour}
            onRequestClose={this.closeTour} /> */}

            <div id="search-here"  data-tut="reactour__calculate"  className={(this.state.showsearch)? "display-block":"display-none"} >
            <div id= "search-leftside-loader" style={{display: this.state.showsearch}}>

            <div className="sk-fading-circle"><div className="sk-circle1 sk-circle"></div><div className="sk-circle2 sk-circle"></div><div className="sk-circle3 sk-circle"></div><div className="sk-circle4 sk-circle"></div><div className="sk-circle5 sk-circle"></div><div className="sk-circle6 sk-circle"></div>
              <div className="sk-circle7 sk-circle"></div><div className="sk-circle8 sk-circle"></div><div className="sk-circle9 sk-circle"></div><div className="sk-circle10 sk-circle"></div><div className="sk-circle11 sk-circle"></div><div className="sk-circle12 sk-circle"></div>
            </div>
          </div>
          <div className="search-here-value">Searching</div>
          
          </div>
             <OutsideClickHandler onOutsideClick={() => {this.setState({datalayer_show: false})}} >
             <div className={(this.state.datalayer_show) ? "legend-totalval2":"legend-totalval2 legend-totalval-hide"} >
      <div className="Building-title">Data Layer</div>
      <label className="layer-toggle-switch">
        <input type="checkbox" checked={this.state.datalayer} onChange={()=> this.getcounty()} />
        <span className="layer-toggle-slider layer-toggle-round"></span>
      </label>
      <div id="legend-main">
        {this.types()}     
      </div></div></OutsideClickHandler>
             <div className="dashboard-Image-total">
               <div className="dashboard-Image-name">Powered by</div>
             <img className="dashboard-Image" src={Maptize} onClick={() => window.location.href = "https://mapchise.com/contact/"}/>
             </div>
            <div id="Layer-filter-total" className="margin-left10 margin-top30" data-tut='reactour__maplayer'>
                <div className="Layer-filter-name">LAYERS</div>
                <div className="Layer-filter-each" onMouseEnter={()=>this.setState({viewpoint: 0})} onMouseLeave={() => this.setState({viewpoint:null})} > 
                <div className= "Layer-border-right"></div>
                {(this.state.viewpoint === 0)?<div className="Layer-sidebar-info"><div className="Competitor-info">All competitors in the region and their estimated revenue, employee cost, and lease/RE cost.</div></div>:null}
                <div className="fa fa-lock dashboard-locked"></div> 
                <div className="Building-each2">Competitors</div>
                </div>
                <div className="Layer-filter-each" onMouseEnter={()=>this.setState({viewpoint: 1})} onMouseLeave={() => this.setState({viewpoint:null})} >
                {(this.state.viewpoint === 1)?<div className="Layer-sidebar-info"><div className="Competitor-info">Cluster formation of target demographic.</div></div>:null}
                <div className="fa fa-lock dashboard-locked"></div>
                     <div className= "Building-each2">Cluster</div>
                </div>
                <div data-tut="reactour__datalayer" className="Layer-filter-each" onClick={()=> { if(!this.state.datalayer) this.getcounty(true); this.setState({datalayer_show: true})}} onMouseEnter={()=>this.setState({viewpoint: 2})} onMouseLeave={() => this.setState({viewpoint:null})} >
                {(this.state.viewpoint === 2)?<div className="Layer-sidebar-info"><div className="Competitor-info">Data Layer for your target demographic/income and recommended view of the best locations. Demo limited by county and all regions in US.</div></div>:null}
                <div className={(this.state.datalayer_show)? "Layer-border-right Layer-border-right-blue": "Layer-border-right"}></div>
                <Datalayer  className={(this.state.datalayer)?"Building-imagemain-layer":"Competitor-sidebar-image-layer"}/>
                     <div className={(this.state.datalayer)?"Building-each2 Building-each-selected": "Building-each2"}>Data Layer</div>
                </div>
                <div className="Layer-filter-each"onMouseEnter={()=>this.setState({viewpoint: 3})} onMouseLeave={() => this.setState({viewpoint:null})}  >
                  {(this.state.viewpoint === 3)?<div className="Layer-sidebar-info"><div className="Competitor-info">Nearby businesses and estimate of their lease/RE cost.</div></div>:null}
                <div className="Layer-border-right"></div>
                <div className="fa fa-lock dashboard-locked"></div>
                  <div className= "Building-each2">Places</div>
                </div>
                <div className="Layer-filter-each" onMouseEnter={()=>this.setState({viewpoint: 4})} onMouseLeave={() => this.setState({viewpoint:null})} >
                {(this.state.viewpoint === 4)?<div className="Layer-sidebar-info"><div className="Competitor-info">Commercial real estate building types in the region.</div></div>:null}
                  <div className="Layer-sidebar-info"></div>
                <div className= "Layer-border-right"></div>
                <div className="fa fa-lock dashboard-locked"></div>
                     <div className= "Building-each2">Buildings</div>
                </div>
                <div className="Layer-filter-each" onMouseEnter={()=>this.setState({viewpoint: 5})} onMouseLeave={() => this.setState({viewpoint:null})} > 
                {(this.state.viewpoint === 5)?<div className="Layer-sidebar-info"><div className="Competitor-info">Zipcodes in your current viewing range.</div></div>:null}
                <div className="fa fa-lock dashboard-locked"></div>
                <div className= "Building-each2">Zipcodes</div>
                </div>
                <div className="Layer-filter-each" id="layer-traffic-total" onMouseEnter={()=>this.setState({viewpoint: 6})} onMouseLeave={() => this.setState({viewpoint:null})} > 
                {(this.state.viewpoint === 6)?<div className="Layer-sidebar-info"><div className="Competitor-info">Traffic analysis by street. Based on average traffic density in the region.</div></div>:null}
                <div className="fa fa-lock dashboard-locked"></div>
                <div className="Building-each2">Traffic</div>
                </div>
                <div className="Layer-filter-each" onMouseEnter={()=>this.setState({viewpoint: 7})} onMouseLeave={() => this.setState({viewpoint:null})} > 
                {(this.state.viewpoint === 7)?<div className="Layer-sidebar-info"><div className="Competitor-info">All shopping centers in the region.</div></div>:null}
                <div className="fa fa-lock dashboard-locked"></div>
                <div className="Building-each2">Shopping Centers</div>
                </div>
            </div>
            <div id ="mapbox-main" data-tut='reactour__map'></div>
            <Dashboard_sidebar Image={this.state.Image} expense_wo={this.state.expense_wo} dashboard={this.state.selected_location} age={this.state.age} race={this.state.race}  degree={this.state.degree} gender={this.state.gender} income={this.state.income} expense={this.state.expense} population={this.state.population} />
            {(this.state.datalayer)? <div className="legend-values-total">
    <div className="legend-eachfilter">Low</div>
    <div style={{"marginLeft":"48px", display:"inline-block"}} >
    {this.state.pval.map((e) => {
      return <div className="legend-values-each" key={e} style={{background:e}}> </div>
    })}</div>
    <div className="legend-eachfilter">High</div>
    </div>: null}
            </div>
              )};
}