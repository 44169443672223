
import React from 'react';
import {Loader_sidebar} from '../loader_sidebar'
import {Infoeach} from '../infoeach';


export class BoxdatasetPrimary extends React.Component{
  constructor(props) {
    super(props);
  } 
  numberWithCommas(x) {
    if(!x) return 'No Data'
    if(x === 0) return 'No Data'
    return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

render() {
    return (<div className={this.props.classnameval} style={{width: 202}} >
    <div class="boxdata-header-one boxdata-header">{this.props.top_text}</div>
    <div >
    <Infoeach makevalue={true} margin={this.props.margin} description={this.props.description} filters={this.props.filters}/>
    {/* <div className="box-status">{this.props.top_text}</div> */}
    {(!this.props.loading)?  
    <div>
    <div className="box-industry-type">{this.props.mid_text}</div>
    <div className="box-pop-current box-price-total" >{this.numberWithCommas(this.props.total_current)}</div>
    </div>
    : <Loader_sidebar/> }
    </div>
    </div>
    );
      }
}