import React, {Component} from 'react';
import axios from 'axios';


export class PopUpInitialFeedback extends React.Component{

    constructor(props) {
        super(props);

        this.state= {
            features_looking: '',
            primary_usage: '',
            utilize_mapchise: '',
            previous_platforms: '',
            previously_liked: '',
        }

    }
    submittedSuvery = async (values) => {
        
        values.preventDefault();
        let token = localStorage.getItem("auth-token");
        if(this.state.features_looking.length === 0) return this.setState({error_values: 'Please Answer All Questions below.'})
        if(this.state.primary_usage.length === 0) return this.setState({error_values: 'Please Answer All Questions below.'})
        if(this.state.utilize_mapchise.length === 0) return this.setState({error_values: 'Please Answer All Questions below.'})
        if(this.state.previous_platforms.length === 0) return this.setState({error_values: 'Please Answer Question 4.'})
        try{
        let response = await axios.post('/surveys/initial', {
            features_looking: this.state.features_looking, 
            primary_usage: this.state.primary_usage,
            utilize_mapchise: this.state.utilize_mapchise,
            previous_platforms: this.state.previous_platforms,
            previously_liked: this.state.previously_liked,
        }, {headers:{ "auth-token": token}})
        let res_data = await response.data;
    
        window.location.reload();
        }catch(e){
            this.setState({error_values: e.response.data})
        }
    }

    set_features_looking = (e) => {
        if(e.target.value.length > 500) return;
        this.setState({features_looking: e.target.value})
    }
    set_primary_usage = (e) => {
        if(e.target.value.length > 500) return;
        this.setState({primary_usage: e.target.value})
    }
    set_utilize_mapchise = (e) => {
        if(e.target.value.length > 500) return;
        this.setState({utilize_mapchise: e.target.value})
    }
    set_previous_platforms = (e) => {
        if(e.target.value.length > 500) return;
        this.setState({previous_platforms: e.target.value})
    }
    set_previously_liked = (e) => {
        if(e.target.value.length > 500) return;
        this.setState({previously_liked: e.target.value})
    }

    render() {
        return (
            <div>
                <div className="Competitor-register-total">
                    <div className="Competitor-register-main popup-main-color">
                        <div className="Competitor-register-background"></div>
                        <div className='Competitor-register-exit fa fa-times' onClick={this.props.closePopUp}></div>
                        <div className="Initial_popup_survey_mainText">Get a week of Premium by completing this survey!</div>
                        <div className="Competitor-register-form">
                            {(this.state.error_values)? <div className= "Competitor-register-error">{this.state.error_values}</div>:null}
                            <form onSubmit={this.submittedSuvery}>
                            <div className="Initial_Questions_each">1. Please list the features you are looking for in a location intelligence platform.*</div>
                            <textarea rows="4" className="Initial_Questions_Input_textarea"  onChange={this.set_features_looking} type="text" name="name" value={this.state.features_looking} />
                            <div className="Initial_Questions_each">2. What is your primary usage of a location intelligence platform?*</div>
                            <textarea rows="4" className="Initial_Questions_Input_textarea"  onChange={this.set_primary_usage} type="text" name="name" value={this.state.primary_usage} />
                            <div className="Initial_Questions_each">3. What type of features are you looking to utilize from Mapchise?*</div>
                            <textarea rows="4" className="Initial_Questions_Input_textarea"  onChange={this.set_utilize_mapchise}  value={this.state.utilize_mapchise}  />
                            <div className="Initial_Questions_each">4. What other location intelligence platforms have your previously used?*</div>
                            <textarea rows="4" className="Initial_Questions_Input_textarea"  onChange={this.set_previous_platforms}  value={this.state.previous_platforms}  />
                            <div className="Initial_Questions_each">5. If any, what features have stood out to you from other platforms?</div>
                            <textarea rows="4" className="Initial_Questions_Input_textarea"  onChange={this.set_previously_liked}  value={this.state.previously_liked}  />
                            <button className="Competitor-research-started Competitor-research-started-text color-change" >Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            )
    }
}