import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import 'leaflet/dist/leaflet.css';
// import {Categoryselect_cat} from './category_selector/category_selector_cat';
import axios from 'axios';
import Select from 'react-dropdown-select';
import {Sidebar_left} from './sidebar_leftreal';


export class Categoryselect extends React.Component{
    constructor(props) {
      super(props);
        this.state = {
          numberofsubs: 0,
          eachcat: [],
          reran: true,
          resp: []
        }
        //this.addthisvalue = this.addthisvalue.bind(this);
    }
     
    componentDidMount() {
      let user = localStorage.getItem("Usernamefinal");
      if(user !== null){
      document.getElementById("login_form_temp").style.display = "none";
      
    }
    }
    // addthisvalue(){
    //     var temp_num = this.state.eachcat;
    //     if(temp_num.length < 3)
    //     {    
    //     var num = temp_num.length + 1;
    //     temp_num.push({ name: "", key:  num})
    //     this.setState({ eachcat: temp_num })
    //     }else{
    //         alert("limit reached")
    //     }
    // }
    // valuename(key, value){
    //     var temp_num = this.state.eachcat;
    //     temp_num[key] = {name: value, key: key};
    //     this.setState({ eachcat: temp_num }); 
    // }
    // handlethis(keys){
    //     var temp_num = this.state.eachcat;
    //     console.log(keys, 'keysss');
    //     temp_num.splice(keys, 1);
    //     this.setState({ eachcat: temp_num }); 
    // }
    async submittedform(e){
        e.preventDefault();
        let st = this.state.eachcat
        if(st.length > 0){ 
        let brand_name = document.getElementById('brand_name').value;
        
        let user = localStorage.getItem("Usernamefinal");
       
        var cattotal = await axios.post('/sendtypes', {
          "name": st,
          "brand_name": brand_name,
          "username": user
        });
        let cattot = await cattotal.data;
        this.props.reran();
      }else{
        document.getElementsByClassName("css-12zlm52-ReactDropdownSelect")[0].style.border = "solid red 1px";
      }
    }
   async form_submit_temp(e){
      e.preventDefault();
      var users = document.getElementById("username_temp").value;
      if(users !== ''){
      document.getElementById('login_form_temp').style.display = "none";
      
      localStorage.setItem('Usernamefinal', users);
      var cat_all = await axios.post('/cattotal');
      let cat_all_final = await cat_all.data;
      let cat_f = [];
      cat_all_final.response.categories.map((i) => { 
        
        cat_f.push({value:i.id, label: i.name});
        if(i.categories !== null)
        i.categories.map((ii) => {
          let sub_name = ii.name;
          let f_name = i.name + "/" + sub_name;
          cat_f.push({value:ii.id, label:f_name})
        })
        
       })
      this.setState( {resp: cat_f});
      console.log(cat_f);
      }
    }
    valuesetter(value){
      this.setState({eachcat: value});
    }
    
    render() {
     
      return (
        <div className="category_selector_be">
            <div className= "category_selector">
            <div className="Questions">
                <form onSubmit={this.submittedform.bind(this)}>
                <div className="Questions_each">Please enter your brand below.</div>
                <input className="answer_each" id="brand_name"></input>
                <div className="Questions_each">Please enter all industries related to your company?</div>
                <div id="selector"><Select
                  multi
                  options={this.state.resp}
                  value={[]}
                  onChange={(values) => this.valuesetter(values)}
                />
                </div>
                <Sidebar_left type={false} id='search-centerside'/>
                {/* <input className="answer_each" id="answer_type" key={0} onChange={(e) => this.valuename(0, e.target.value)} value={this.state.eachcat[0].name}></input>
                <div id="add_each" className="add_sub_each" onClick={this.addthisvalue}>+</div> */}
                {/* <Categoryselect_cat numberofsubs={this.state.numberofsubs} handlethis = {this.handlethis.bind(this)} eachcat = {this.state.eachcat} valuename = {this.valuename.bind(this)} resp ={this.state.resp}/> */}
                <button className="Sending">Submit</button>
                </form>
            </div>
            {/* <form id="login_form_temp" onSubmit={this.form_submit_temp.bind(this)}>
            <div className="login_temp" id="login_temp_background">
             <div id="username_temp_text">Username</div> <input id="username_temp"></input>
             <button id="button_temp">Submit</button>
            </div>
            </form> */}
            </div>
        </div>
      )
    }
  }