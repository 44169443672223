import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';
import { Polarchart } from './Polarchart';
import Select from 'react-dropdown-select';
import { Loader_sidebar } from './loader_sidebar';

const ageFilterOptions = [
  { label: "Youth", value: "25" },
  { label: "Young Adults", value: "44" },
  { label: "Mature Adults", value: "64" },
  { label: "Elders", value: "84" }
];

const incomeFilterOptions = [
  { label: "Lowest Income", value: "0" },
  { label: "Lower Middle Income", value: "30" },
  { label: "Middle Income", value: "50" },
  { label: "Upper Middle Income", value: "100" },
  { label: "Highest Income", value: "200" }
];

const ageLabels = {
  "25": ["Youth", "Under 25"],
  "44": ["Young Adults", "25 - 44"],
  "64": ["Mature Adults", "44 - 60"],
  "84": ["Elders", "Over 60"]
};

const incomeLabels = {
  "0": "Lowest Income",
  "30": "Lower Middle Income",
  "50": "Middle Income",
  "100": "Upper Middle Income",
  "200": "Highest Income"
};

export const Psychographic = ({ Psychograph, Psychograph_change, age_income_overall, loading, Psychographic_parent, Psychographic_filters, exportInd }) => {
  const [state, setState] = useState({
    Psychograph: [],
    selected_age: [],
    Combined_Psychoraph: [],
    id: "",
    selected_income: [],
    show_all: false,
  });

  useEffect(() => {
    if (Psychographic_filters) {
      setState((prevState) => ({
        ...prevState,
        selected_age: Psychographic_filters.age || [],
        selected_income: Psychographic_filters.income || [],
        id: Psychograph.id
      }));
    }
  }, [Psychographic_filters, Psychograph.id]);

  useEffect(() => {
    if (Psychograph.total_interest) {
      getvalues(Psychograph.total_interest, Psychograph.interestvalues, true);
    }
  }, [Psychograph.id]);

  const numberWithCommas = (x) => x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const filter_age = (value) => {
    Psychograph_change(value, state.selected_income);
    setState((prevState) => ({ ...prevState, selected_age: value }), () => {
      getvalues(Psychograph.total_interest, Psychograph.interestvalues);
    });
  };

  const filter_income = (value) => {
    Psychograph_change(state.selected_age, value);
    setState((prevState) => ({ ...prevState, selected_income: value }), () => {
      getvalues(Psychograph.total_interest, Psychograph.interestvalues);
    });
  };

  const Combined_Psych = (Combined_Psychoraph, Combined_total) => {
    function compare(a, b) {
      return b.value - a.value;
    }

    const age_income = age_income_overall["2018"] || {};
    Combined_Psychoraph = Object.values(Combined_Psychoraph).map((e) => {
      e.avg_income = numberWithCommas(age_income[e.agegrouptotal] || Math.round(e.avg_income / e.value));
      e.value = numberWithCommas(e.value);

      const temp_racetype = {};
      e.eachvalue.forEach((ee) => {
        temp_racetype[ee.type] = temp_racetype[ee.type]
          ? { value: temp_racetype[ee.type].value + ee.value, num: temp_racetype[ee.type].num + 1 }
          : { value: ee.value, num: 1 };
      });

      e.eachvalue = Object.keys(temp_racetype).map((type) => ({
        ...temp_racetype[type],
        value: Math.round(temp_racetype[type].value / temp_racetype[type].num),
        type
      })).sort(compare).slice(0, 2);

      return e;
    });

    setState((prevState) => ({ ...prevState, Combined_Psychoraph }));
  };

  const getvalues = (Psychograph, Interest, New_data) => {
    const ageOrder = ["25", "44", "64", "84"]; 
    const incomeOrder = ["0", "30", "50", "100", "200"]; 
    const selected_age = state.selected_age.map((e) => e.value);
    const selected_income = state.selected_income.map((e) => e.value);

    let Psychograph_filtered = selected_income.length > 0 || selected_age.length > 0
      ? Psychograph.filter((e) => {
        return (!selected_age.length || selected_age.includes(e.age)) && (!selected_income.length || selected_income.includes(e.income.toString()));
      })
      : Psychograph;

    let Combined_total = 0;
    Psychograph_filtered = Psychograph_filtered.filter((psych) => Interest[psych.age]);
    Psychograph_filtered = Psychograph_filtered.sort((a, b) => {
      return ageOrder.indexOf(a.age) - ageOrder.indexOf(b.age) || 
             incomeOrder.indexOf(a.income.toString()) - incomeOrder.indexOf(b.income.toString());
    });
    // console.log('Psychograph_filtered: ', Psychograph_filtered)

    const Psychographval = Psychograph_filtered.map((e) => {
      const interestall = Interest[e.age];
      const interest_category = [...new Set(interestall.map((inter) => inter.cat))];
      const interestdemo = interestall.slice(0, 6);
      const age_name = ageLabels[e.age][0];
      const income_name = incomeLabels[e.income];
      const avg_income = numberWithCommas(e.avg_income);
      const value = numberWithCommas(e.value);
      const id = uuid();
      const agegroup = ageLabels[e.age][1];
      const eachvalue = e.eachvalue.slice(0, 2);

      return {
        showmore: false,
        interestdemo,
        interest_category,
        interestall,
        agegroup,
        id,
        income_name,
        age_name,
        age: e.age,
        income: e.income,
        value: e.value,
        eachvalue,
        avg_income,
        spent: e.spent
      };
    });

    const Combined_Psychoraph = {
      "25": { value: 0, age_name: "Youth", income_name: "", agegroup: "Under 25", agegrouptotal: "Under 25", avg_income: 0, id: uuid(), num: 0, eachvalue: [] },
      "44": { value: 0, age_name: "Young Adults", income_name: "", agegroup: "25 - 44", agegrouptotal: "25 - 44", avg_income: 0, id: uuid(), num: 0, eachvalue: [] },
      "64": { value: 0, age_name: "Mature Adults", income_name: "", agegroup: "44 - 60", agegrouptotal: "44 - 64", avg_income: 0, id: uuid(), num: 0, eachvalue: [] },
      "84": { value: 0, age_name: "Elders", income_name: "", agegroup: "Over 60", agegrouptotal: "64 over", avg_income: 0, id: uuid(), num: 0, eachvalue: [] }
    };

    if (New_data) {
      Psychograph.forEach((e) => {
        const interestall = Interest[e.age];
        const interest_category = [...new Set(interestall.map((inter) => inter.cat))];
        const interestdemo = interestall.slice(0, 6);

        const Combined_Psych = Combined_Psychoraph[e.age];
        if (!Combined_Psychoraph[e.age].interestdemo) {
          Combined_Psych.interestdemo = interestdemo;
          Combined_Psych.interestall = interestall;
          Combined_Psych.interest_category = interest_category;
        }
        Combined_Psych.avg_income += (e.avg_income * parseInt(e.value));
        Combined_Psych.value += e.value;
        Combined_Psych.spent = e.spent;
        Combined_total += e.value;
        Combined_Psych.eachvalue = Combined_Psych.eachvalue.concat(e.eachvalue);
        Combined_Psych.num += 1;
      });
      Combined_Psych(Combined_Psychoraph, Combined_total);
    }

    setState((prevState) => ({ ...prevState, Psychograph: Psychographval }));
  };

  const showmore = (id, show) => {
    const key = state.show_all || state.selected_age.length > 0 || state.selected_income.length > 0 ? 'Psychograph' : 'Combined_Psychoraph';
    setState((prevState) => ({
      ...prevState,
      [key]: prevState[key].map((e) => (e.id === id ? { ...e, showmore: show } : e))
    }));
  };

  const showmoreparent = (id) => {
    const key = state.show_all || state.selected_age.length > 0 || state.selected_income.length > 0 ? 'Psychograph' : 'Combined_Psychoraph';
    const Psychograph = state[key].find((e) => e.id === id);
    Psychographic_parent(Psychograph);
  };

  const eachPsychographicJSX = (e) => {
    return <div key={e.id} >
              <div className="boxdata-header">
                <div className="boxdata-percentval-total">
                  <div className="boxdata-percentval boxdata-perentcolor">
                    {e.income_name ? `${e.age_name},` : e.age_name}
                  </div>
                  <div className="boxdata-percentval">{e.income_name}</div>
                </div>
                <div className="boxdata-percentval2">{e.value}</div>
              </div>
              <div className="box-containerfull2">
                <div className="box-container-values2">
                  <div className="box-container-race box-container-eachvalue">Race</div>
                  <div className="box-container-allvalues">
                    {e.eachvalue.map((each) => (
                      <div key={each.type} className="box-container-racetotal">
                        <div className="box-container-racetype">{each.type}</div>
                        <div className="box-container-racevalue">{each.value}%</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="box-container-values">
                  <div className="box-container-eachvalue">Age Group</div>
                  <div className="box-container-eachvalue2">{e.agegroup}</div>
                </div>
                <div className="box-container-values">
                  <div className="box-container-eachvalue">Average Income</div>
                  <div className="box-container-eachvalue2">${e.avg_income}</div>
                </div>
                <div className="box-container-values2">
                  <div className="box-container-race box-container-eachvalue">Interests</div>
                  {/* {!e.showmore ? ( */}
                    <div>
                      <div className="box-container-interests">
                        {e.interestdemo?.map((inter) => (
                          <div key={inter.subcat} className="box-container-interestseach">{inter.subcat}</div>
                        ))}
                        {!exportInd && <div className="box-showmore2" onClick={() => showmoreparent(e.id, true)}>Show More...</div>}
                      </div>
                    </div>
                </div>
              </div>
            </div>
  }

  const createPsychographic = (e, index) => {
    // DEPENDING ON IF EXPORT IS TRUE
    if(!exportInd) return eachPsychographicJSX(e);
    if(index === 0) return <div id='pdf-export-container-psychographic' className='pt-20'> 
        <h2 className='ml-10 mb-20 mt-100 font-weight600'>Psychographic Data</h2>
        {e.map((sub) => eachPsychographicJSX(sub))}
    </div>;
    if(index === 1) return <div id='pdf-export-container-psychographic-second' className='pt-20'>
      {e.map((sub) => eachPsychographicJSX(sub))}
    </div>;
  }

  const { Psychograph: PsychographState, show_all, selected_age, selected_income, Combined_Psychoraph } = state;
  var PsychographDisplay = show_all || selected_age.length > 0 || selected_income.length > 0 ? PsychographState : Combined_Psychoraph;
  PsychographDisplay = (exportInd) ? [PsychographDisplay.slice(0, 2), PsychographDisplay.slice(2)] : PsychographDisplay;
  if (!Psychograph.total_interest || Psychograph?.total_interest.length === 0) return (<div className='sidebar-realestate-total'><div className='current-locations-sidebar-box2'><div className='current-locations-sidebar'>No Data Available</div></div></div>)
  return (
    <div className="container-marginleft" id="export-values-psycho">
      {!exportInd && <div className="react-class-filter">
        <div className="react-class-filter-name">Filter</div>
        <div className="react-dropdown-selectval2">
          <Select
            multi
            options={ageFilterOptions}
            placeholder={"Age"}
            values={selected_age}
            onChange={(values) => filter_age(values)}
          />
        </div>
        <div className="react-dropdown-selectval">
          <Select
            multi
            options={incomeFilterOptions}
            placeholder={"Income"}
            values={selected_income}
            onChange={(values) => filter_income(values)}
          />
        </div>
      </div>}
      {loading ? (
        PsychographDisplay.map((e, index) => createPsychographic(e, index))
      ) : (
        <Loader_sidebar />
      )}

      {!selected_age.length && !selected_income.length && !exportInd && (
        <div>
          {!show_all ? (
            <div className="box-showmore" onClick={() => setState((prevState) => ({ ...prevState, show_all: true }))}>Show All...</div>
          ) : (
            <div className="box-showmore" onClick={() => setState((prevState) => ({ ...prevState, show_all: false }))}>Show Less</div>
          )}
        </div>
      )}
    </div>
  );
};

