import React, {useMemo} from 'react';
// [
//     "Under 5", "5 - 9", "10 - 14", "15 - 17", "18 - 19", "20 - 24", "25 - 29", "30 - 34", "35 - 44",
//     "45 - 54", "55 - 64", "65 - 74", "75 - 84", "Over 84"
//   ]

const CombinePsychographics = (population) => {
    const Combined_Psychoraph = {
        "25": { value: {male: 0, female: 0}, age_name: "Youth", income_name: "", agegroup: "Under 25", agegrouptotal: "Under 25", avg_income: 0,  num: 0, eachvalue: [] },
        "44": { value: {male: 0, female: 0}, age_name: "Young Adults", income_name: "", agegroup: "25 - 44", agegrouptotal: "25 - 44", avg_income: 0,  num: 0, eachvalue: [] },
        "64": { value: {male: 0, female: 0}, age_name: "Mature Adults", income_name: "", agegroup: "45 - 64", agegrouptotal: "45 - 64", avg_income: 0,  num: 0, eachvalue: [] },
        "84": { value: {male: 0, female: 0}, age_name: "Elders", income_name: "", agegroup: "Over 64", agegrouptotal: "64 over", avg_income: 0, num: 0, eachvalue: [] }
    };
    const female = population.female
    const male = population.male
    
    Combined_Psychoraph["25"]['value']['female'] = female[0] + female[1] + female[2] + female[3] + female[4] + female[5] 
    Combined_Psychoraph["25"]['value']['male'] = male[0] + male[1] + male[2] + male[3] + male[4] + male[5] 
    
    Combined_Psychoraph["44"]['value']['female'] = female[6] + female[7] + female[8]
    Combined_Psychoraph["44"]['value']['male'] = male[6] + male[7] + male[8]

    Combined_Psychoraph["64"]['value']['female'] = female[9] + female[10]
    Combined_Psychoraph["64"]['value']['male'] = male[9] + male[10]

    Combined_Psychoraph["84"]['value']['female'] = female[11] + female[12] + female[13]
    Combined_Psychoraph["84"]['value']['male'] = male[11] + male[12] + male[13]
    return Object.values(Combined_Psychoraph)
}

const PsychographicInternational = ({ municipality_data }) => {
    const population = municipality_data.population.population_c
    const psychoData = useMemo(() => {
        const psychoDataCombined = CombinePsychographics(population)
        return psychoDataCombined
    })
    return (
        <div className="container-marginleft" id="export-values-psycho">
            {psychoData.map((e) => 
                <div key={e.age_name} >
                <div className="boxdata-header">
                    <div className="boxdata-percentval-total">
                    <div className="boxdata-percentval boxdata-perentcolor">
                        {e.age_name}
                    </div>
                    <div className="boxdata-percentval">{e.income_name}</div>
                    </div>
                    <div className="boxdata-percentval2">{e.value.female + e.value.male}</div>
                </div>
                <div className="box-containerfull2 mh-100 pt-15">
                <div className="box-container-values2">
                    <div className="box-container-race box-container-eachvalue">Age Group</div>
                    <div className='box-container-allvalues'><div className="box-container-eachvalue2">{e.agegroup}</div></div>
                    </div>
                    <div className="box-container-values2">
                    <div className="box-container-race box-container-eachvalue">Gender</div>
                    <div className="box-container-allvalues">
                        <div className="box-container-racetotal">
                            <div className="box-container-racetype w-60p">Male: </div>
                            <div className="box-container-racevalue">{e.value.male}</div>
                        </div>
                        <div className="box-container-racetotal">
                            <div className="box-container-racetype w-60p">Female: </div>
                            <div className="box-container-racevalue">{e.value.female}</div>
                        </div>
                    </div> 
                    </div>

                    {/* <div className="box-container-values">
                    <div className="box-container-eachvalue">Average Income</div>
                    <div className="box-container-eachvalue2">${e.avg_income}</div>
                    </div> */}
                    {/* <div className="box-container-values2"> */}
                    {/* <div className="box-container-race box-container-eachvalue">Interests</div> */}
                        {/* <div>
                        <div className="box-container-interests">
                            {e.interestdemo?.map((inter) => (
                            <div key={inter.subcat} className="box-container-interestseach">{inter.subcat}</div>
                            ))}
                            {!exportInd && <div className="box-showmore2" onClick={() => showmoreparent(e.id, true)}>Show More...</div>}
                        </div>
                        </div> */}
                    {/* </div> */}
                </div>
                </div>
            )}
        </div>
    );
};

export default PsychographicInternational;