import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

export const SidebarMainLocations = ({checkStatusExpanded, filterMapLocationsFunc, filterMapLocations, passesAllFilters, status, brand, handleRemoveFilter, handleAddFilter, locationFilters, getlocations, getprospectsidebar, showManagementMapping }) => {
    // console.log('checkStatusExpanded: ', checkStatusExpanded)
    const [locationFiltersLocal, setLocationFiltersLocal] = useState(locationFilters);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOperation, setSelectedOperation] = useState(null);
    const [filterOperationTypes, setFilterOperationTypes] = useState(['=', '!=']);
    const [filterValuesOptions, setFilterValuesOptions] = useState([]);
    const [filterError, setFilterError] = useState();
    const [selectedField, setSelectedField] = useState(null);
    const [filterValue, setFilterValue] = useState('');
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [filteredLocationsByStatus, setFilteredLocationsByStatus] = useState({});
    const [checkStatusExpandedLocal, setCheckStatusExpandedLocal] = useState(checkStatusExpanded);

    const filterOptions = ['Brand', 'Status']

    const showManagementClicked = (e) => {
        getprospectsidebar(e.coordinates);
        showManagementMapping(e);
    };

    useEffect(() => {
        setCheckStatusExpandedLocal(checkStatusExpanded)
    }, [checkStatusExpanded])

    const handleLocalChange = (locationFilters, searchTerm) => {
        const filteredLocations = getlocations.business_data?.filter((location) =>{
            if(locationFilters.length === 0 && searchTerm === '') return true;
            return passesAllFilters(location, locationFilters) && location.name.toLowerCase().includes(searchTerm.toLowerCase())
        });

        const groupedLocations = filteredLocations?.reduce((acc, location) => {
            if (acc[location.status]) {
                acc[location.status].push(location);
            } else {
                acc[location.status] = [location];
            }
            return acc;
        }, {});
        setFilteredLocationsByStatus(groupedLocations || {});
    }

    useEffect(() => {
        handleLocalChange(locationFilters, searchTerm);
    }, [locationFilters]);

    const handleFilterIconClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };


    const handleFilterClose = () => {
        setFilterAnchorEl(null);
    };

    const handleAddValue = () => {
        if(!selectedField) return setFilterError('Select Filter')
        if(!filterValue) return setFilterError('Select Filter Value')
        if(!selectedOperation) return setFilterError('Select Filter Operator')
        setFilterError(null)
        handleAddFilter(selectedField, filterValue, selectedOperation);
        setSelectedField(null)
        setFilterValue(null)
        setSelectedOperation(null)
        handleFilterClose()
    }

    const handleRemoveLocation = (index) => handleRemoveFilter(index)


    const set_filterOptions = (event, newValue) => {
        setSelectedField(newValue);
        const value = newValue === 'Brand' ? brand: status;
        setFilterValuesOptions(value);
    }

    const changeStatusExpand = async (status, isExpanded) => {
        const token = localStorage.getItem("auth-token");
        setCheckStatusExpandedLocal(prevStatus => {
            const updatedStatus = { ...prevStatus, [status]: isExpanded };
            // Optionally, you might want to move the API call here to ensure it uses the latest state
            axios.post('/gettheurl/change-status', {checkStatusExpanded: updatedStatus}, {headers:{ "auth-token": token}})
                 .then(response => {
                     console.log("Status update response:", response.data);
                 })
                 .catch(error => {
                     console.error("Error updating status:", error);
                 });
            return updatedStatus;
        });
    }

    const open = Boolean(filterAnchorEl);
    const id = open ? 'filter-popover' : undefined;

    return (
        Object.keys(getlocations).length !== 0 &&
        <div className="sidebar-realestate-total">

            <div className="current-locations-sidebar-box mb-20 h-100 pb-10">
            {/* <TextField
                label="Search Locations"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{  width: '80%', margin: '10px'}}
            /> */}
                <TextField
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    className='mb-5 mt-10'
                        variant="standard"
                        label="Search Locations"
                        placeholder="Search Locations"
                        style={{  width: '80%', margin: '10px'}}

                        sx={{
                            "& .MuiInputBase-input": { fontSize: '12px' }, // Sets font size of the input text
                    "& .MuiInputLabel-root": { fontSize: '12px' },
                            "& input": { fontSize: '12px' }, // Adjust color as needed
                            mb: 0.625
                        }}
                />
                <IconButton onClick={handleFilterIconClick} style={{ marginTop: '20px'}}>
                    <FilterListIcon />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={filterAnchorEl}
                    onClose={handleFilterClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div style={{ padding: '10px', backgroundColor: 'white', width: '380px' }}>
                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                            <Autocomplete
                                options={filterOptions}
                                getOptionLabel={(option) => option}
                                value={selectedField}
                                style={{  width: '50%'  }}
                                onChange={set_filterOptions}
                                renderInput={(params) => <TextField {...params} label="Select Field"                   variant="standard"
                                sx={{
                                    "& .MuiInputBase-input": { fontSize: '12px' }, // Sets font size of the input text
                            "& .MuiInputLabel-root": { fontSize: '12px' },
                                    "& input": { fontSize: '12px' }, // Adjust color as needed
                                    mb: 0.625
                                }}    
                                />}
                            />
                            <Autocomplete
                                options={filterOperationTypes}
                                getOptionLabel={(option) => option}
                                value={selectedOperation}
                                style={{  width: '30%' , marginLeft:'15px' }}
                                onChange={(event, newValue) => setSelectedOperation(newValue)}
                                renderInput={(params) => <TextField {...params} label="Condition"                   variant="standard"
                                sx={{
                                    "& .MuiInputBase-input": { fontSize: '12px' }, // Sets font size of the input text
                            "& .MuiInputLabel-root": { fontSize: '12px' },
                                    "& input": { fontSize: '12px' }, // Adjust color as needed
                                    mb: 0.625
                                }}    
                                />}
                            />
                            <Autocomplete
                                options={filterValuesOptions}
                                getOptionLabel={(option) => option}
                                value={filterValue}
                                style={{ marginLeft: '15px', width: '70%'  }}
                                onChange={(event, newValue) => setFilterValue(newValue)}
                                renderInput={(params) => <TextField {...params} label="Filter Value"                   variant="standard"
                                sx={{
                                    "& .MuiInputBase-input": { fontSize: '12px' }, // Sets font size of the input text
                            "& .MuiInputLabel-root": { fontSize: '12px' },
                                    "& input": { fontSize: '12px' }, // Adjust color as needed
                                    mb: 0.625
                                }}    
                                />}
                            />
                            {/* <TextField
                                label="Filter Value"
                                fullWidth
                                value={filterValue}
                                onChange={(e) => setFilterValue(e.target.value)}
                                variant="standard"
                                style={{ marginLeft: '15px', width: '70%'  }}
                                sx={{
                                    "& .MuiInputBase-input": { fontSize: '12px' }, // Sets font size of the input text
                            "& .MuiInputLabel-root": { fontSize: '12px' },
                                    "& input": { fontSize: '12px' }, // Adjust color as needed
                                    mb: 0.625
                                }}                    /> */}

                        </div>
                        <div>{filterError}</div>
                        {/* <Button variant="contained" color="primary" onClick={handleAddFilter}>Add Filter</Button> */}
                        <div className="main-mapping-save mb-20 mt-20" >
                            <div id="box-goback" className={`cursor-pointer color-purple`} onClick={handleAddValue}>Add Filter</div>
                        </div>
                    </div>
                </Popover>
                <div style={{ marginBottom: '20px', marginLeft: '10px' }}>
                {locationFilters?.map((filter, index) => (
                    <Chip
                        key={index}
                        label={`${filter.field} ${filter.operator} ${filter.value}`}
                        onDelete={() => handleRemoveLocation(index)}
                        style={{ marginRight: '5px', marginBottom: '5px' }}
                    />
                ))}
                </div>
                {locationFilters?.length > 0 &&
                <div className='filter-map-locations'>
                    <div className='filter-map-locations-text'>
                        Filter Map Locations
                    </div>
                    <label className="layer-toggle-switch">
                        <input type="checkbox" checked={filterMapLocations} onChange={() => filterMapLocationsFunc()} />
                        <span className="layer-toggle-slider layer-toggle-round"></span>
                    </label>
                </div>
                }
            </div>
            {Object.keys(filteredLocationsByStatus).map((status) => {
                const filteredLocations = filteredLocationsByStatus[status].filter(location =>
                    location.name.toLowerCase().includes(searchTerm.toLowerCase())
                );

                // Only render the status section if there are filtered locations
                if (filteredLocations.length === 0) {
                    return null;
                }

                return (
                    <Accordion key={status} expanded={checkStatusExpandedLocal[status] || searchTerm.length > 0} onChange={(e, isExpanded) => changeStatusExpand(status, isExpanded)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <div className="" style={{ fontWeight:'bold',paddingTop: 0, color: getlocations.status_colors[status] }}>{status}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {filteredLocations.map((location) => (
                                <div key={location.name} onClick={() => showManagementClicked(location, true)} className="total-locations-sidebar">
                                    <div className="name-locations-sidebar" style={{  color: getlocations.status_colors[status] }}>{location.name}</div>
                                    <div className="address-locations-sidebar">{location.address}</div>
                                </div>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
            {getlocations.business_data.length === 0 &&
                <div className="total-locations-sidebar">
                    <div className="name-locations-sidebar add-location-warning">
                        Add Locations either by dropping a Pin or searching
                    </div>
                </div>
            }
        </div>
    );
};
