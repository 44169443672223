import React, {Component} from 'react';
import axios from 'axios';


export class PopUpFreeTrialEnded extends React.Component{

    constructor(props) {
        super(props);

        this.state= {
            experience: '',
            paid_plan: '',
            added: '',
            features_useful: ''
        }

    }
    submittedSuvery = async (values) => {
        
        values.preventDefault();
        let token = localStorage.getItem("auth-token");
        if(this.state.experience.length === 0) return this.setState({error_values: 'Please Answer All Questions below.'})
        if(this.state.paid_plan.length === 0) return this.setState({error_values: 'Please Answer All Questions below.'})
        if(this.state.added.length === 0) return this.setState({error_values: 'Please Answer All Questions below.'})
        if(this.state.features_useful.length === 0) return this.setState({error_values: 'Please Answer Question 4.'})
        try{
        let response = await axios.post('/surveys/freetrialend', {
            experience: this.state.experience, 
            paid_plan: this.state.paid_plan,
            added: this.state.added,
            features_useful: this.state.features_useful
        }, {headers:{ "auth-token": token}})
    
        window.location.reload();
        }catch(e){
            this.setState({error_values: e.response.data})
        }
    }

    set_experience = (e) => {
        if(e.target.value.length > 500) return;
        this.setState({experience: e.target.value})
    }
    set_paid_plan = (e) => {
        if(e.target.value.length > 500) return;
        this.setState({paid_plan: e.target.value})
    }
    set_added = (e) => {
        if(e.target.value.length > 500) return;
        this.setState({added: e.target.value})
    }
    set_features_useful = (e) => {
        if(e.target.value.length > 500) return;
        this.setState({features_useful: e.target.value})
    }


    render() {
        return (
            <div>
                <div className="Competitor-register-total">
                    <div className="Competitor-register-main popup-main-color">
                        <div className="Competitor-register-background"></div>
                        <div className='Competitor-register-exit fa fa-times' onClick={this.props.closePopUp}></div>
                        <div className="Initial_popup_survey_mainText">Please give your feedback to improve Mapchise!</div>
                            <div className="Competitor-register-form">
                                {(this.state.error_values)? <div className= "Competitor-register-error">{this.state.error_values}</div>:null}
                                <form onSubmit={this.submittedSuvery}>
                                <div className="Initial_Questions_each">1. How was your initial experience of the platform?*</div>
                                <textarea rows="4" className="Initial_Questions_Input_textarea"  onChange={this.set_experience} type="text" name="name" value={this.state.experience} />
                                <div className="Initial_Questions_each">2. Will you be looking to upgrade to a paid plan?*</div>
                                <textarea rows="4" className="Initial_Questions_Input_textarea"  onChange={this.set_paid_plan} type="text" name="name" value={this.state.paid_plan} />
                                <div className="Initial_Questions_each">3. What are some features you would like to see added to Mapchise?*</div>
                                <textarea rows="4" className="Initial_Questions_Input_textarea"  onChange={this.set_added}  value={this.state.added}  />
                                <div className="Initial_Questions_each">4. What features did you find most useful?*</div>
                                <textarea rows="4" className="Initial_Questions_Input_textarea"  onChange={this.set_features_useful}  value={this.state.features_useful}  />
                                <button className="Competitor-research-started Competitor-research-started-text color-change" >Submit</button>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
            )
    }
}