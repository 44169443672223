
import React, {Component} from 'react';

export class Competitor_box extends React.Component{
    constructor(props) {
        super(props);
        this.state= {
            showdescription: false
        }
    }
    upgrade = ()=>{
        if(this.props.freetier)  window.location.href = "https://mapchise.com/pricing"
    }
    render() {
        return (
        <div className="Competitor-box-total">
            {(this.props.description)?<div className="Competitor-info-total"><i className="fa fa-info-circle Competitor-info-circle" onMouseLeave={() => this.setState({showdescription: false})} onMouseEnter={() => this.setState({showdescription: true})}></i>
            {this.state.showdescription?<div className="Competitor-info">{this.props.description}</div>:null}</div>:null}
            <div className="Competitor-box-title Competitor-box-title2">{this.props.titleMain}</div>
            <div className="Competitor-box-line"></div>
            <div className={this.props.freetier? "Competitor-blurred":""} onClick={this.upgrade}>
            <div className="Competitor-box-title">{this.props.title}</div>
            <div className="Competitor-box-value">{this.props.value}</div>
            </div>
            {(this.props.freetier)?<div className="Competitor-box-lock"><div className="Competitor-box-locked fa fa-lock"></div></div>:null}
        </div>
            )}
}