import React, {Component} from 'react';
import axios from 'axios';
import {Competitor_research_menu} from './Competitor_menu'
import {Competitor_register} from './Competitor_register'
import {Competitor_research_brand} from './Competitor_research_branding'
import maptize from '../Images/maptize_logo_white-01.svg';
import Task from './Images/task.svg'

export class Competitor_research extends React.Component{
    constructor(props) {
        super(props);
        this.state= {
            search_competitor: "",
            searched: []
        }

    }
    competitor_search = async (e) =>{
        let search_competitor = e.target.value 
        this.setState({search_competitor})
        var search_res = await axios.post('/gettheurl/competitorapi', {search: search_competitor}, );
        let search = await search_res.data;
        this.setState({searched: search})
        
    }
    gettier = async () =>{
        let token = localStorage.getItem("auth-token");
        if(token === "" || !token ) return null;
        try{
            let response = await axios.post('/signup/freetier/type', {}, {headers:{ "auth-token": token}})
            let res = await response.data
            if(res.freetier == null) return;
            this.setState({freetier: res.freetier})
        }catch(e){
            console.log(e)
        }
    }
    componentWillMount() {
        this.gettier()
    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    namevalue = (Name) =>{
        window.location.href = "/competitor-research?search="+ Name
    }
    closeregisterer = () => this.setState({register: false})
    login = () => this.setState({register: true, registertype: false})
    register = () => this.setState({register: true, registertype: true})
    changeregister = () => this.setState({registertype: !this.state.registertype})
    
    render() {
        return (
          <div id = "Competitor-research-main">
              <div className="Competitor-research-imageall">
              <div className="Competitor-research-image">
              </div>
              </div>
              <Competitor_research_menu freetier={this.state.freetier} register={this.register} login={this.login} section_hide={false}/>
              {this.state.register? <Competitor_register changeregister={this.changeregister} type={this.state.registertype} closeregisterer={this.closeregisterer} gettier={this.gettier}/>:null}

                <div className="Competitor_search-top">
                <div className="Competitor_reasearch-total">
                <h1 className="Competitor-search-header">All-in-one Location Analysis</h1>
                <h2 className="Competitor-search-header2">for Retail & Restaurant Brands</h2>
                <input className="Competitor_research-input Competitor_research-input2" placeholder="Enter a Retail/Restaurant Brand" value={this.state.search_competitor}  onChange={this.competitor_search}></input>
                <div className="Competitor-search-fields2">
                {/* <div className="Competitor-search-divider Competitor-search-divider2">|</div> */}
                <div className="Competitor-search-divider fa fa-search"></div>
                </div>
                <div className="Competitor-result-total">
                    {
                        this.state.searched.map((search) => {
                        return <div key={search._id} className="Competitor-result-each" onClick={() =>this.namevalue(search.Name)}>
                            <div className="Competitor-result-eachpicture">
                                {(search.Image)?
                                <img src={search.Image} onError={(e)=>{e.target.onerror = null; e.target.src="https://i.ibb.co/8NYd9pw/white-background.png"}} className="Competitor-result-image"/>: <div  className="Competitor-result-image"></div>
                                }
                                </div>
                            <div className="Competitor-result-eachname">{search.Name}</div>
                            <div className="Competitor-result-count"><div className="Competitor-result-count-name">Est. Locations</div>
                            <div className="Competitor-result-count-value">{search.final_locations? this.numberWithCommas(search.final_locations): this.numberWithCommas(search.Total)}</div></div>
                        </div>
                        })
                    }
                </div>
                </div>
                </div>
                <div className="Competitor-research-bottom">
                    <div className="Competitor-Break60"></div>
                    <div className="Competitor-search-header2">
                    What's inside?
                    </div>
                    <Competitor_research_brand/>
                    <div className="Competitor-Break60"></div>
                    <div className="Competitor-search-header2">Our specialty</div>

                <div className="Competitor-branding-overall-branding">
                <div className="Competitor-box-total Competitor-box-branding">
                    {/* <img src={Task} className="Competitor-sidebar-image"/> */}
                    <div className="Competitor-branding-title">Location Intelligence
                    <div className="Competitor-title-line"></div>
                    </div>
                    <ul >
                        <li className="Competitor-details">Proprietary psychographic and demographic data</li>
                        <li className="Competitor-details">Industry specific commercial real estate data</li>
                        <li className="Competitor-details">Providing the most up to date traffic flows</li>
                    </ul>
                    <div className="Competitor-link-total">
                    <a className="Competitor-link" href="https://mapchise.com/business-location-intelligence/">Learn More</a>
                    </div>
                </div>
                <div className="Competitor-box-total  Competitor-box-branding">
                    <div className="Competitor-branding-title">Location Management
                    <div className="Competitor-title-line"></div>
                    </div>
                    <ul >
                        <li className="Competitor-details">Increase efficiency and accountability</li>
                        <li className="Competitor-details">Communicate with the team through one platform</li>
                        <li className="Competitor-details">Onboard new locations with ease</li>
                    </ul>
                    <div className="Competitor-link-total">
                    <a className="Competitor-link" href="https://mapchise.com/task-management/">Learn More</a>
                    </div>
                </div>
                </div>
                <div className="Competitor-Break60"></div>

                <div className="Competitor-contact-total">
                <h1 className="Competitor-search-header">Contact Us</h1>
                <h2 className="Competitor-search-header2">Find out how we can help</h2>
                <div className="Competitor_reasearch-total Competitor-down">
                <div className="Competitor-research-login Competitor-research-loginsize" onClick={() => window.location.href = "https://mapchise.com/contact/"}>Contact Us</div>
                </div>

                </div>
                <div className="Competitor-footer">
                <img className="Competitor-research-menu-logo" onClick={() => window.location.href = "https://app.mapchise.com/competitor"} src={maptize} />
                <div className="Competitor-footer-text">Copyright © 2023 Mapchise All rights reserved.</div>
                <div className="Competitor-foooter-links">
                <div className="Competitor-footer-each">Home</div>
                <div className="Competitor-footer-each">Abount</div>
                <div className="Competitor-footer-each">Privacy</div>
                <div className="Competitor-footer-each">Terms of Use</div>
                <div className="Competitor-footer-each">Contact Us</div>
                </div>
                </div>
                </div>



              </div>
    )}
}