import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const PlaceClusterChart = ({ data }) => {
  // Define a color mapping for each specific type
  const colorMap = {
    "Auto Spa": "#FF6384",
    "Automotive Repair": "#36A2EB",
    "Automotive Services": "#FFCE56",
    "Gas Station": "#4BC0C0",
    "Motorcycle": "#9966FF",
    "Tires": "#FF9F40",
    "Trade": "#FF6B6B",
    "Wholesale": "#4ECDC4",
    "Social Services": "#45B7D1",
    "Education": "#F7DC6F",
    "Amusement & Theme Park": "#E74C3C",
    "Casino": "#3498DB",
    "Event Venue": "#2ECC71",
    "Games": "#9B59B6",
    "Museum": "#34495E",
    "Music": "#1ABC9C",
    "Night Club": "#D35400",
    "Video Games": "#7F8C8D",
    "Video Rental": "#27AE60",
    "ATM": "#C0392B",
    "Bank": "#8E44AD",
    "Financial Service": "#2980B9",
    "Fitness": "#F39C12",
    "American Restaurant": "#D98880",
    "Asian Food": "#73C6B6",
    "Bakery": "#7FB3D5",
    "Bar": "#F0B27A",
    "Beverage Store": "#B2BABB",
    "Breakfast & Brunch Restaurant": "#5499C7",
    "Burger Restaurant": "#52BE80",
    "Cafe": "#CD6155",
    "Caterer": "#5DADE2",
    "Cheese Shop": "#58D68D",
    "Chicken Restaurant": "#AF7AC5",
    "Chinese Restaurant": "#EC7063",
    "Chocolate Shop": "#A569BD",
    "Dairy": "#5DADE2",
    "Deli": "#45B39D",
    "Dessert Shop": "#F5B041",
    "Fast Food": "#EB984E",
    "Greengrocer": "#52BE80",
    "Health Food": "#58D68D",
    "Italian Restaurant": "#CD6155",
    "Mediterranean Restaurant": "#5499C7",
    "Mexican Restaurant": "#EC7063",
    "Middle Eastern Restaurant": "#AF7AC5",
    "Pizza Restaurant": "#EB984E",
    "Restaurant": "#F0B27A",
    "Sandwich Shop": "#73C6B6",
    "Seafood Market": "#7FB3D5",
    "Seafood Restaurant": "#5DADE2",
    "Specialty Food": "#52BE80",
    "Spice Shop": "#CD6155",
    "Steakhouse": "#EC7063",
    "Hotel": "#3498DB",
    "Motel": "#2ECC71",
    "RV Park": "#9B59B6",
    "Medical Service": "#E74C3C",
    "Nursing Home": "#F39C12",
    "Pharmacy / Drugstore": "#1ABC9C",
    "Rental Shop": "#D35400",
    "Bike Rental": "#7F8C8D",
    "Car Rental": "#27AE60",
    "Bicycle repair": "#C0392B",
    "Child Care": "#8E44AD",
    "Dry Cleaner": "#2980B9",
    "Equipment Rental": "#F39C12",
    "Florist": "#D98880",
    "Funeral Service": "#73C6B6",
    "Home Improvement": "#7FB3D5",
    "Laundromat": "#F0B27A",
    "Locksmith": "#B2BABB",
    "Pet Grooming": "#5499C7",
    "Photographer": "#52BE80",
    "Printing": "#CD6155",
    "Recycling": "#5DADE2",
    "Repair": "#58D68D",
    "Shoe Repair": "#AF7AC5",
    "Storage": "#EC7063",
    "Tailor": "#A569BD",
    "Tattoo & Piercing": "#5DADE2",
    "Travel Agency": "#45B39D",
    "Veterinarian": "#F5B041",
    "Antiques": "#EB984E",
    "Arts & Crafts Store": "#52BE80",
    "Baby Goods": "#58D68D",
    "Bookstore": "#CD6155",
    "Butcher": "#5499C7",
    "Candle Store": "#EC7063",
    "Cannabis": "#AF7AC5",
    "Country Store": "#EB984E",
    "Department Store": "#F0B27A",
    "Discount Store": "#73C6B6",
    "Eyewear Store": "#7FB3D5",
    "Accessories": "#5DADE2",
    "Apparel": "#52BE80",
    "Bag Store": "#CD6155",
    "Boutique": "#EC7063",
    "Children Clothing": "#A569BD",
    "Mens Clothing": "#5DADE2",
    "Womens Clothing": "#45B39D",
    "Clothing Store": "#F5B041",
    "Shoe Store": "#EB984E",
    "Fabric Store": "#52BE80",
    "Fashion Accessories": "#58D68D",
    "Hairdresser Supply": "#CD6155",
    "Jewelry Store": "#5499C7",
    "General Store": "#EC7063",
    "Gift Shop": "#AF7AC5",
    "Hobby Store": "#EB984E",
    "Appliances": "#F0B27A",
    "Bedding": "#73C6B6",
    "Building Materials": "#7FB3D5",
    "Do It Yourself": "#5DADE2",
    "Flooring": "#52BE80",
    "Hardware Store": "#CD6155",
    "Home & Garden Store": "#EC7063",
    "Houseware": "#A569BD",
    "Interior Decoration": "#5DADE2",
    "Kitchen Supply": "#45B39D",
    "Lighting": "#F5B041",
    "Nursery & Gardening": "#EB984E",
    "Paint Store": "#52BE80",
    "Sewing & Crafts": "#58D68D",
    "Kiosk": "#CD6155",
    "Liquor Store": "#5499C7",
    "Marketplace": "#EC7063",
    "Mobile Phone Shop": "#AF7AC5",
    "Musical Instrument Store": "#EB984E",
    "Newsagent": "#F0B27A",
    "Office Equipment Store": "#73C6B6",
    "Pet Store": "#7FB3D5",
    "Pottery": "#5DADE2",
    "Shopping Mall": "#52BE80",
    "Shopping Retail": "#CD6155",
    "Sporting Goods Store": "#EC7063",
    "Stationery": "#A569BD",
    "Audiovisual Equipment": "#5DADE2",
    "Computer Store": "#45B39D",
    "Electronics Store": "#F5B041",
    "Thrift Store": "#EB984E",
    "Tobacco Store": "#52BE80",
    "Toy Store": "#58D68D",
    "Variety Store": "#CD6155",
    "Martial Arts": "#5499C7",
    "Outdoor Equipment": "#EC7063",
    "Water Sports": "#AF7AC5",
    "Supermarket": "#EB984E",
    "Wellness and Beauty": "#F0B27A",
    "Hair Salon": "#73C6B6",
    "Massage": "#7FB3D5",
    "Nutrition Supplements": "#5DADE2",
    "Medical Supply": "#52BE80"
  };

  // Function to get color for a type
  const getColorForType = (type) => {
    return colorMap[type] || `#${Math.floor(Math.random()*16777215).toString(16)}`;
  };

  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        data: Object.values(data),
        backgroundColor: Object.keys(data).map(getColorForType),
        hoverBackgroundColor: Object.keys(data).map(getColorForType)
      }
    ]
  };
  console.log('data: ', data)
  return (
    <div style={{ height: '270px' }}>
      <Pie data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
    </div>
  );
};

export default PlaceClusterChart;