import React, {Component} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import 'react-calendar/dist/Calendar.css';
import { GithubPicker } from 'react-color';




export class Task_label extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    selectedlabels: [],    
    labelpickedcolorchange: "",
    labelsearch: "",
    labelobject: [],
    labelcolors: ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB'],
    
    }    
    this.colorchange = this.colorchange.bind(this);  
    this.colorchangeon = this.colorchangeon.bind(this); 
    this.labeldeletevalue = this.labeldeletevalue.bind(this);
    this.labeladderseach = this.labeladderseach.bind(this);
    }
    componentWillReceiveProps(nextProps){
        var labelobject = (nextProps.labelobject)? nextProps.labelobject:[];
        this.setState({labelobject})
    }
    colorchange(col){
        this.props.selectedlabelsprops(this.state.labelpickedcolorchange,col.hex, true, true )
        
        this.setState({ labelpickedcolorchange: ""})    
    }
    labeldeletevalue(e){
        var selectedlabels = this.props.selectedlabels
        selectedlabels = selectedlabels.filter((ee) => ee !== e)
    
        this.props.labeledited(selectedlabels)
        this.props.selectedlabelsprops(e, null, false)
        
    }
    newlabeladded(newval){
        var selectedlabels = this.props.selectedlabels
        let selectlabel = (newval)? newval:this.state.labelsearch;
        selectedlabels.push( selectlabel)
        this.props.selectedlabelsprops(selectlabel, "#396bf0", true)
        let colorchange = (newval)? '':this.state.labelsearch;
        this.setState({labelpickedcolorchange: colorchange, labelsearch:"", addlabel: false}, ()=>{
          this.props.labeledited(selectedlabels)
        })
      }
        /* SEARCHING FOR A NEW LABEL */
  labeladderseach(e){
    var valtotal = e.target.value
    if(valtotal.length > 15)
     return false 
    // var val = this.props.labels_list.filter((l) => l === valtotal)
    var addnew = true;
    if(this.props.labels_list[valtotal])
        addnew= false;
    this.setState({labelsearch: valtotal, addlabel: addnew})
  }
    colorchangeon(val){
        this.setState({labelpickedcolorchange: val})
        
    }
    render() {
        return (
       
        <div className="task-label-total"> 
        <div className="task-results-selected">{this.props.selectedlabels.map((e) => {
          
           let color = this.props.labels_list[e]
           color = (color)?color.color: "#396bf0";
            return <div className="task-results-selected-each" key={e} style={{background:color}}>{(this.state.labelpickedcolorchange === e)?<OutsideClickHandler onOutsideClick={() => this.setState({labelpickedcolorchange: ""})} ><GithubPicker colors={this.state.labelcolors} onChangeComplete={ this.colorchange } /></OutsideClickHandler>:null} <div onClick={() => this.colorchangeon(e)} className="task-label-name">{e}</div> <div className="fa fa-times" onClick={() => this.labeldeletevalue(e)}></div></div>
        })}</div>
           <input className="task-label-input" placeholder="Add labels" onChange={(e) => this.labeladderseach(e)} value={this.state.labelsearch} disabled={(this.props.selectedlabels.length > 4)?true:false} />
           {(this.state.labelsearch.length > 0)?<div className="task-results-values">
               {(this.state.addlabel)?
                   <div className="search-result-each" onClick={() => this.newlabeladded()}>add "{this.state.labelsearch}"</div>:null
               }
               {this.state.labelobject.map((e) => {
                   if(e.includes(this.state.labelsearch) && !this.props.selectedlabels.includes(e))
                   return <div className="search-result-each" key={e} onClick={() => this.newlabeladded(e)}>{e}</div>
               })}
           </div>:null}
        </div>
        )}
} 