import React, {Component} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import aticon from "../Images/aticon.png";
import moment from 'moment';
import axios from 'axios';

export class Task_messaging extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    messageinput:"",
    accountnames: [],
    addedusers: [],
    searchingvalue: null,
    messages: [],
    atsomeone: false,
    }    
    this.checkmessageinput = this.checkmessageinput.bind(this);
    this.addnewmessage = this.addnewmessage.bind(this);
    this.handleKeyDownmessage = this.handleKeyDownmessage.bind(this);
    this.atsomeone = this.atsomeone.bind(this);
    this.replaceinput = this.replaceinput.bind(this);
    }
    componentWillMount(){
        var messages = this.props.messages
        var accountnames = this.props.selectedaccounts.map((e) => e.name);
        this.setState({accountnames, messages});
    }
    componentDidMount(){
      var objDiv = document.getElementById("task-message-all");
      objDiv.scrollTop = objDiv.scrollHeight;
    }
    componentWillReceiveProps(nextProps){
        
        var accountnames = nextProps.selectedaccounts.map((e) => e.name);
        this.setState({accountnames});
    }
    checkmessageinput(e){
        if(e.length < 280){   
        if(this.state.atsomeone){
            var addedusers = this.state.addedusers;
            var sp = e.split("@");
            var atsomeone = true;
            var at = "";
            if(sp.length > 0){
                at = sp.pop();
                at = at.replace("@","")
                if(this.state.accountnames.includes(at)){
                    sp.push( "{"+at+"}")
                    e = sp.join("")
                    atsomeone = false;
                    var s = this.props.selectedaccounts.filter((e) => e.name === at)[0]
                    var sid = this.state.addedusers.filter((e) => e._id === s._id);
                    if(sid.length === 0)
                    addedusers.push(s)
                }
            }
        this.setState({messageinput: e, atsomeone, searchingvalue:at, addedusers})
        }else{
            var atsomeone = false
            if(e[e.length - 1] === "@"){
                atsomeone = true;                
            }
            this.setState({messageinput: e, atsomeone})
        }    
        }
        
      }
      async addnewmessage(){
        var messages = this.state.messages;
        var token = localStorage.getItem("auth-token");
        messages.push({color:this.props.useraccount.color, message: this.state.messageinput, user: this.props.useraccount.email, date: new Date(), addedusers:this.state.addedusers});
        await axios.post('/tasksapi/notifications', {id: this.props.id, message: this.state.messageinput}, {headers:{ "auth-token": token}});
        var objDiv = document.getElementById("task-message-all");
        objDiv.scrollTop = objDiv.scrollHeight + 10;
        this.setState({messages, atsomeone: false, messageinput: "", addedusers: []}, ()=> {
            this.props.message_send(messages)
        })
      }
      replaceinput(id){
        var messageinput = this.state.messageinput;
        var addedusers = this.state.addedusers;

        var val = this.props.selectedaccounts.filter((e) => e._id === id)[0]
        var m = messageinput.split("@")
        m.pop();
        m.push("{"+val.name+"}");
        messageinput= m.join('');
        var sid = this.state.addedusers.filter((e) => e._id === val._id);
        if(sid.length === 0)
        addedusers.push(val)
        this.setState({messageinput: m, atsomeone: false, addedusers})
      }
      handleKeyDownmessage(e){
        if (e.key === 'Enter') {
          this.addnewmessage()
          }
      }
      atsomeone(){
        var messageinput = this.state.messageinput + " @"
        this.setState({atsomeone: true, messageinput})
      }
      
    render() {
      var current_name = null;
        return (
    <div className="messagetotal">
        <div className="task-add-user-name task-add-userval">Activity</div><div className="task-message-box" id="task-message-all">
        <div className="task-message-data">Created: {this.props.createddate }</div>
        {
          this.state.messages.map((e) => {
        var date = null;
        if(e.user === this.props.useraccount.email ) 
        {
          date = new Date(e.date);
          date = (moment(date).isSame(moment(), 'day'))? moment(date).format("hh:mm A"):moment(date).format("dddd")+" " + moment(date).format("MM-DD-YYYY hh:mm A");
          return <div><div key={e.id} className="task-message-overall"><div className="task-message-container">{e.message}</div></div><div className="box-container-interestseach2">{date}</div></div>; }
        else{ 
          var select = this.props.selectedaccounts.filter((select) => select.email === e.user);
          var select_name = null;
          var showname = false;
          if(select.length > 0){
          select_name =  select[0].name;
          showname = (select[0].email !== current_name)? true:false;
          current_name = select[0].email;
          }
          date = new Date(e.date);
          date = (moment(date).isSame(moment(), 'day'))? moment(date).format("hh:mm A"):moment(date).format("dddd")+" " + moment(date).format("MM-DD-YYYY hh:mm A");
          return <div key={e.id}> {(showname)?<div><div className="box-container-interestseach3">{select_name}</div><div className="box-container-interestseach">{date}</div></div>:null}<div className="task-message-overall"><div className="task-message-container2" style={{background:e.color}}>{e.message}</div></div></div>
        }})} </div>
         <div className="task-checklistvalue" >
             <input className="task-label-checklistinput" placeholder="Message" onKeyDown={this.handleKeyDownmessage} value={this.state.messageinput} onChange={(e) => this.checkmessageinput(e.target.value)}/>
             <img className="task-label-aticon" onClick={this.atsomeone} src={aticon}></img>
         </div>
        {(this.state.atsomeone)?<div className="task-results-values2">{this.props.selectedaccounts.map((e) =>{  if(this.state.searchingvalue =="" || e.name.includes(this.state.searchingvalue))  return <div key={e._id} className="search-result-each" onClick={() => this.replaceinput(e._id)}>{e.name}</div>})}</div>:null}
    </div>
        )}
}