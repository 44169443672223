
import React from 'react';
import {Loader_sidebar} from '../loader_sidebar'
import star from '../Images/star.png';
import goldstar from '../Images/goldstar.png';
import {Infoeach} from '../infoeach';


export class Boxdataset extends React.Component{
  constructor(props) {
    super(props);

  this.arrangement_image =  this.arrangement_image.bind(this)
  } 
  numberWithCommas(x, currency, round) {
    if(round){
      x = Math.round(x / 1000)
      x += 'K'
    }
    x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if(currency) return currency + ' ' + x
    
    return x;
  }
  arrangement_image(){
    if(this.props.zoom == 2 && !this.props.starredval){
      if(this.props.arrangestar)
        return(<img src={goldstar} onClick={() => this.props.unsetarrangement(this.props.arrangement)} className="starredvalues"></img> )
          
      return(<img src={star} onClick={() => this.props.setarrangement(this.props.arrangement)} className="starredvalues"></img> )
    }
  }
render() {
  if(this.props.type)
      {
        // console.log(this.props.mid_text, this.props.total_current)
      
      var total_current = (this.props.stringval)? this.props.total_current: this.numberWithCommas(this.props.total_current, this.props.currency, this.props.round);
      if(this.props.total_past){
      var total_past = (this.props.stringval)? this.props.total_past: this.numberWithCommas(this.props.total_past, this.props.currency, this.props.round);
      var percent_diff = (this.props.total_diff * 100).toFixed(2);
      var color_percentdiff= null
      //color changer for the population based on demographic
      var typevalue = null;
      var margin =null;
      if(percent_diff >= 5){
       color_percentdiff = "rgba(30, 130, 76, 1)"; 
       typevalue = "up";
       margin = "0px"
      }
      else if(percent_diff < 5 && percent_diff >= 0){
       color_percentdiff= "rgba(247, 202, 24, 1)";
       typevalue = "up"
       margin = "0px"
      }
      else{
       color_percentdiff= "rgba(242, 38, 19, 1)";
       typevalue = "down"
       margin = "13px"
      }
      }
      }
      var width = (this.props.width)? 200: 202;
    return (<div className={this.props.classnameval} style={{width: width}} ><Infoeach makevalue={true} margin={this.props.margin} description={this.props.description} filters={this.props.filters}/>
    {/* <div className="box-status">{this.props.top_text}</div> */}
    {this.arrangement_image()}
    {(this.props.type)?  
    <div>
    <div className="box-industry-type">{this.props.mid_text}</div>
    <div className="box-pop-current box-price-total" >{total_current}</div>
    
    {(total_past)? 
    <div>
    <div className="box-industry-type-prev box-industry-type">Prev. Period: <div className="box-price-bottom box-pop-current box-price-total">{total_past}</div></div>
    <div className="box-price-bottom box-price"><div className="box-price-bottom-diff box-price-total" style={{color:color_percentdiff}}>{percent_diff}% <div className="box-price-up">
      <div className={"fa fa-angle-"+typevalue} style={{"marginTop": margin}}></div></div><div className="box-price-line">|</div></div>
    </div>
    </div>
    : null}
    </div>
    : <Loader_sidebar/> }
    </div>);
      }
}